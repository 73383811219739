import { InlineAlertType } from '@ecosystem/ui-lib/core/components/InlineAlert';
import { ToastMessage } from '@ecosystem/ui-lib/core/components/ToastMessage';
import { useState, useEffect } from 'react';

const ShowSuccess = (props) => {  
    const [message, setMessage] = useState(props.message);

    useEffect(() => {        
        setMessage(props.message)
    }, [props.message]);

    return (
    <ToastMessage type={InlineAlertType.Success} 
        message={message} 
        onDismiss={() => props.clearMessage()}
        timeout={10000} />    
    )
};

export default ShowSuccess;


