import React, { Component } from 'react';
import {Label, DatePickerCalendar, Button} from 'app/components/Basics/';
import './FiltersComponent.scss';
import {ReportDownloadFormContext} from '../../ReportDownloadContext'
import MultiSelect from 'app/components/MultiSelect'
import { Select } from '@ecosystem/ui-lib/core/components/Select';

class Filters extends Component {

    static contextType = ReportDownloadFormContext;

    constructor(props){
        super(props);        
    }

	setStartDate(date) {		
		var selectedSelectionFilters = this.context.selectedSelectionFilters;
		selectedSelectionFilters.startDate = date;
		this.context.updateSelectedSelectionFilters(selectedSelectionFilters);
	}

	setEndDate(date) {
		var selectedSelectionFilters = this.context.selectedSelectionFilters;
		selectedSelectionFilters.endDate = date;
		this.context.updateSelectedSelectionFilters(selectedSelectionFilters);
	}

	createOption(item, metadataList) {	
		if (!metadataList) { return null }
		var metadataItem = metadataList.find(m=>m.value === item);
		if (!metadataItem) { return null }

		return {
			value: item,
			label: metadataItem.label
		}
	}	

	getSelectedOptionsMultiSelect(metadata, selectedValues ) {		
		var result = [];		
		result = selectedValues.map(
			item => this.createOption(item, metadata)
		);		
		return result;
	}
	
	onChangeRegion(items)  {		
		var selectedSelectionFilters = this.context.selectedSelectionFilters;
		selectedSelectionFilters.regions = [];
		selectedSelectionFilters.regions = items.map(x => x.value);		
		this.context.updateSelectedSelectionFilters(selectedSelectionFilters);       
	}

	onRemoveRegion(item)  {		
		var selectedSelectionFilters = this.context.selectedSelectionFilters;		
		selectedSelectionFilters.regions = selectedSelectionFilters.regions.filter(function(f) { return f !== item.value });
		this.context.updateSelectedSelectionFilters(selectedSelectionFilters);
	}	

	changeSurveyTypeHandler(selectedItem) {
		var selectedSelectionFilters = this.context.selectedSelectionFilters;
		selectedSelectionFilters.subSurveyId = parseInt(selectedItem.value);
		this.context.updateSelectedSelectionFilters(selectedSelectionFilters);
    }

	getSelectedSurveyTypeOption() {
        return this.props.metadata.surveyTypes?.find(t=> parseInt(t.value) === this.context.selectedSelectionFilters.subSurveyId);
    }

    render() {
        return (
            <div>
				<div className="FiltersComponent form-row">
					<div className="form-row-left">
						<div className="field-survey-from">
							<Label>Date From</Label>
							<DatePickerCalendar value={this.context.selectedSelectionFilters.startDate} onChange={this.setStartDate.bind(this)} />
						</div>
						<div className="field-survey-to">
							<Label>Date To</Label>
							<DatePickerCalendar value={this.context.selectedSelectionFilters.endDate} minDate={this.context.selectedSelectionFilters.startDate} onChange={this.setEndDate.bind(this)} />
						</div>
						<div className="field-surveytype">
							<Label>Survey Type</Label>
							<Select name="qsetsurveytype" isSearchable={false}
								key={this.context.selectedSelectionFilters.subSurveyId}
								styles={{ option: styles => ({ minHeight: 40, ...styles }) }}								
								onChange={val=>{
									this.changeSurveyTypeHandler(val)                                
								}}
								options={this.props.metadata.surveyTypes} 
								value={this.getSelectedSurveyTypeOption()}>
							</Select>
						</div>									
					</div>
					<div className="form-row-right">
					</div>
            	</div>
				<div className="FiltersComponent form-row">
					<div className="form-row-left">
						<div className='regions-selection'>
							<Label tooltip="Select the regions that apply for this data download">Regions</Label>							
							<MultiSelect 
								selectedOptions={this.getSelectedOptionsMultiSelect(this.props.metadata.regions, this.context.selectedSelectionFilters.regions)} 
								onChange={this.onChangeRegion.bind(this)} 
								onRemove={this.onRemoveRegion.bind(this)} 
								options={this.props.metadata.regions}>									
							</MultiSelect>
						</div>				
					</div>					
            	</div>				
			</div>
        );
    }
}

Filters.contextType = ReportDownloadFormContext;

export default Filters;