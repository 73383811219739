import authHeader from './auth-header';
import Api from './api';

const API_URL = process.env.REACT_APP_AQUA_API_ENDPOINT

class QuestionService {  

  async getQuestionById(questionId: string) {
      return await Api().get(`${API_URL}/question/${questionId}`, { headers:  authHeader() as any });      
  }

  async findQuestions(term: string, onlyAdHocParentQuestions: boolean, adHocParentQuestionsIds: string, groupGridQuestion: boolean) {   
    if (onlyAdHocParentQuestions === undefined) {
      onlyAdHocParentQuestions = false;
    }
		if (groupGridQuestion === undefined) {
			groupGridQuestion = false;
		}
    	return await Api().get(`${API_URL}/question/find?text=${term}%&maxResults=-1&onlyAdHocParentQuestions=${onlyAdHocParentQuestions}&adHocParentQuestionsIds=${adHocParentQuestionsIds}&groupGridQuestion=${groupGridQuestion}`, { headers:  authHeader() as any });      
  }

  async findQuestionsPaged(term: string, onlyAdHocParentQuestions: boolean, adHocParentQuestionsIds: string, groupGridQuestion: boolean, page: number, excludeDemoQuestions: boolean, onlyDemoQuestions: boolean) {   
    if (onlyAdHocParentQuestions === undefined) {
      onlyAdHocParentQuestions = false;
    }
		if (groupGridQuestion === undefined) {
			groupGridQuestion = false;
		}
    if (excludeDemoQuestions === undefined) {
      excludeDemoQuestions = false;
    }
    if (onlyDemoQuestions === undefined) {
      onlyDemoQuestions = false;
    }

    return await Api().get(`${API_URL}/question/findPaged?text=${term}&maxResults=-1&onlyAdHocParentQuestions=${onlyAdHocParentQuestions}&adHocParentQuestionsIds=${adHocParentQuestionsIds}&groupGridQuestion=${groupGridQuestion}&page=${page}&excludeDemoQuestions=${excludeDemoQuestions}&onlyDemoQuestions=${onlyDemoQuestions}`, { headers:  authHeader() as any });      
  }

  async UpdateQuestion(question: any) {
    return await Api().put(`${API_URL}/question/${question.id}`, question, 
    { method: 'PUT',
      headers:  authHeader() as any });      
  }

  async CreateQuestion(question: any) {
    return await Api().post(`${API_URL}/question/`, question, 
    { method: 'POST',
      headers:  authHeader() as any });      
  }

  async DeleteQuestion(question: any) {
    return await Api().delete(`${API_URL}/question/${question.id}`, 
    { method: 'DELETE',
      headers:  authHeader() as any });      
  }

  async UploadVideoToQuestion(questionId: string, optionValue: number, video: any) {
	const formData = new FormData();
  formData.append('targetEntity', "question");   
  if (questionId ?? "") {
	  formData.append('targetEntityId', questionId);
  }
	formData.append('answerOption', optionValue ? optionValue.toString() : "");
	formData.append('file', video);

    return await Api().post(`${API_URL}/mediamanager/UploadVideo`, formData, 
    { method: 'POST',
      headers:  authHeader() as any });      
  }

  async UploadImageToQuestion(questionId: string, optionValue: number, image: any) {
	const formData = new FormData();
  formData.append('targetEntity', "question");
  if (questionId ?? "") {
	  formData.append('targetEntityId', questionId);
  }
	formData.append('answerOption', optionValue ? optionValue.toString() : "");
	formData.append('file', image);

    return await Api().post(`${API_URL}/mediamanager/UploadImage`, formData, 
    { method: 'POST',
      headers:  authHeader() as any });      
  }

  async DeleteImageFromQuestion(deleteRequest: any) {
    return await Api().delete(`${API_URL}/mediamanager/DeleteImage`, 
    { method: 'DELETE',
      headers:  authHeader() as any,
      data: deleteRequest });      
  }

  async DeleteVideoFromQuestion(deleteRequest: any) {
    return await Api().delete(`${API_URL}/mediamanager/DeleteVideo`, 
    { method: 'DELETE',
      headers:  authHeader() as any,
     data: deleteRequest });      
  }
}

export default new QuestionService();