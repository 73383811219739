import React, { Component } from 'react';
import { Button} from 'app/components/Basics';

import './FileUploadComponent.scss';

class FileUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileUploaded: false,
        };
    }

    render() {
        return(
            <div className={`FileUploadComponent ${this.props.className} ${this.props.disabled ? "disabled" : ""} ${this.props.error ? "error" : ""}`}>
                <div className="upload-drop-area">
                    <div className="instruction-label">{this.props.emptyLabel || "drag file here"}</div>
                    <Button disabled={this.props.disabled} small secondary>{this.props.buttonLabel || "Browse"}</Button>
                </div>
                {this.props.error ? <span className="FileUpload_error">{this.props.error}</span> : ""}
            </div>
            
        );
    }
}

export default FileUpload;