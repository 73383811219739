import React, { Component } from 'react';
import { Input as EcoInput } from '@ecosystem/ui-lib/core/components/Input';
import { Button} from 'app/components/Basics/'
import { Icons } from '@ecosystem/ui-lib/core/components/Icon';
import './InputComponent.scss';
import { ReactComponent as EditPen } from 'app/assets/icons/edit-pen.svg';

class Input extends Component {
    constructor(props) {
        super(props);

        this.input = React.createRef();
        
        this.state = {
            value: props.value || props.children,
            type: props.type && ['text','password','number'].indexOf(props.type) > -1 ? props.type : 'text',
        };
    }

    componentWillReceiveProps(props) {
        this.setState( {
            value: props.value || props.children
        });
    }

    render() {
        var value = this.state.value;
        
        return (
            <div className={`InputComponent ${this.props.className}`}>
                {this.props.iconLeft}
                <input id="test" size="1" name={this.props.name} ref={this.input}  defaultValue={value} type="text"
                    onClick={this.props.onClickHandler}
                    onKeyDown={this.props.onKeyDownHandler}
                    onBlur={this.props.onBlurHandler}
                />
                {this.props.iconRight}
            </div>
        );
    }
}

class InputInline extends Input {
    constructor(props) {
        super(props);

        this.inputComponent = React.createRef();
        this.onRefMount = this.onRefMount.bind(this);

        this.state = {...this.state, ...{
            editmode: this.props.error || false,
            onModeChange : props.onModeChange
        }};		
    }
	
	componentDidUpdate(prevProps, prevState) {		
		if (this.props.error && 			
			this.state.editmode === false) {				
				this.setState({editmode: true});
		}
	}

    startEditor(event){
		if (!this.props.disabled) {
			this.setState({editmode: true});
			if(this.state.onModeChange)
				this.state.onModeChange(true);
		}
    }

    stopEditor(event, save){
        if(save && event.target.value.length > 0){			
            this.setState({value: event.target.value});
            if(this.state.onModeChange)
                this.state.onModeChange(false,event.target.value);
        }else{
            if(this.state.onModeChange)
                this.state.onModeChange(false);
        }
        this.setState({editmode: false});
    }   

	onMouseDown(event) {
		event.preventDefault()
		event.stopPropagation();
		event.stopImmediatePropagation();		
		this.stopEditor(event, false);
	}

	
    onRefMount(ref) {
        if(ref){
            ref.focus();
            ref.onkeydown = (e) => {if(e.key === 'Enter') this.stopEditor(e, true)}
            ref.onclick = (e) => {e.stopPropagation()};
			if (this.props.enableOnBlur) {
				ref.onblur = (e) => {this.stopEditor(e, true)};
				if(ref.parentNode && ref.parentNode.nextSibling && ref.parentNode.nextSibling.classList.contains("Input__clearIcon")){
					ref.parentNode.nextSibling.onmousedown = (e) => this.onMouseDown(e);
					ref.parentNode.nextSibling.title = "Discard";
				}
			}
			else {
				ref.onblur = (e) => {this.setEditModeFalse()}
			}
            this.inputComponent = ref;
        }
    }

	cancelChanges(event) {		
		this.props.cancelHandler();
		this.setState({editmode: false})
		event.stopPropagation();
	}

	saveChanges(event) {		
		this.props.saveHandler(this.inputComponent.value);
		this.setState({editmode: false})
		event.stopPropagation();
	}

	setEditModeFalse() {
		setTimeout(() => {
			this.setState({editmode: false});
			this.state.onModeChange(false);
		}, 300);		
	}

    render() {
        var value = this.state.value;

        if(this.state.editmode){
            /*return (<div className={`InputInlineComponent ${this.props.className}`}>
                <Input ref={this.inputComponent} value={value} 
                    iconRight={(<Close className="close-icon" title={this.props.closeTooltip || "Discard"} onMouseDown={(e) => {e.stopPropagation();this.stopEditor(e, false)}}/>)}
                    onClickHandler={(e) => {e.stopPropagation()}}
                    onKeyDownHandler={(e) => {if(e.key === 'Enter') this.stopEditor(e, true)}} 
                    onBlurHandler={(e) => {this.stopEditor(e, true)}} 
                />
            </div>);*/

			let fieldProps = {				
				defaultValue: value,
				error: this.props.error
			}

			if (this.props.enableOnBlur) {
				fieldProps.onClear= (e)=>{}
			}

            return (
                <div className={`InputInlineComponent ${this.props.className} editmode ${this.props.error ? "error" : "" }`}>
                    <EcoInput id="edit" ref={this.onRefMount} {...fieldProps}/>
					
					{!this.props.enableOnBlur && (<div className='inline-text-buttons'>
						<Button title="Save" onClick={(e)=> this.saveChanges(e)} simple small icon={Icons.Check}></Button>
						<Button title="Cancel" onClick={(e)=> this.cancelChanges(e)} simple small icon={Icons.Close}></Button>
					</div>)}
                </div>
            );
        }else{
            return (
                <div className={`InputInlineComponent ${this.props.className}`}>
                    <div className="value-display" title={value}>{value}</div>
                    <EditPen disabled={this.props.disabled} title={this.props.editTooltip || "Change"} className={`edit-icon ${this.props.disabled ? "disabled" : ""} ${this.props.editOnHover ? "showOnHover" : ""}`} onClick={(e) => {e.stopPropagation();this.startEditor(e)}}/>
                </div>
            );
        }
    }
}

export default Input;
export {Input, InputInline};