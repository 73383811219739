import { adHocSurveyTypes } from 'app/constants/adhoc-survey-types'

function ValidateAdHocSurvey(selectedAdHocSurvey) {
	var validationErrors = [];
	if (!selectedAdHocSurvey.name || selectedAdHocSurvey.name === '') {
		addErrorMessage(validationErrors, 'name', 'Survey name is empty');
	}
	if (!selectedAdHocSurvey.startDate || selectedAdHocSurvey.startDate === '') {
		addErrorMessage(validationErrors, 'startDate', 'Survey start date is empty');
	}
	if (selectedAdHocSurvey.adHocSurveyTypeId === adHocSurveyTypes.FiveMinex && !selectedAdHocSurvey.sampleId && selectedAdHocSurvey.enabled === 1) {
		addErrorMessage(validationErrors, 'sampleId', 'An enabled fiveMinex must have a sampleId');
	}
	if (selectedAdHocSurvey.netQuestSurveyId && !selectedAdHocSurvey.schemaFile && !selectedAdHocSurvey.uploadedSchemaFileName) {
		addErrorMessage(validationErrors, 'sampleFile', 'Require sample file for a netquest survey');
	}
	return validationErrors;
}

function addErrorMessage(validationErrors, field, message) {
	validationErrors.push({ field: field, errorMessage: message });
}

export default ValidateAdHocSurvey;