import React, { Component } from 'react';
import './AppHeaderComponent.scss';

class AppHeader extends Component {
    render() {
        return (
            <header className="AppHeaderComponent">
                {this.props.children}
            </header>
        );
    }
}
export default AppHeader;