
import React, { Component } from 'react';
import {SideBar, SideBarHead, SideList, SideListItemGeneric} from 'app/components/SideBar/';
import DemoSetForm from './DemoSetForm';
import NewDemoSet from './models/DemoSetModel';
import DemoSetHome from './components/DemoSetHome/DemoSetHomeComponent';
import DemoSetService from "../../../services/demoSet.service";
import  GetErrorMessage  from 'app/helpers/error-message'
import {ShowError, ShowSuccess} from "app/components/toast"
import { Loading } from '@ecosystem/ui-lib/core/components/Loading';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import Modal from 'app/components/Modal';
import './DemoSetComponent.scss';

class DemoSet extends Component {	
	constructor (props) {
	super(props);
		this.state = {
			search: '',			
			allData: [],
			filteredData: [],			
			loading: false,
			errorMessage: null,
			selectedDemoSet: null,
			originalSelectedDemoSet: null,
			uiBlocked: false,
			editorOpen: false			
		}
	}

	setSearchState(event) {    
		var value = event.target.value;    
		this.setState({search: value});
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.search !== prevState.search) {     
			this.filterDemoSets(this.state.search);
		}

		if (this.state.editorOpen !== prevState.editorOpen) {
			if (this.state.editorOpen) {
			//retrigger selected question if editor was closed        
			this.setState({selectedDemoSet: this.state.selectedDemoSet});
			}
		}    
	}

	filterDemoSets(term) {		
		if (term !== '') {
			const filteredData = this.state.allData
			.filter(i => (i.name  && i.name.toLowerCase().includes(term.toLowerCase())));
			let newFilteredData = [...filteredData];
			this.setState({ filteredData: newFilteredData});      
		}
		else {
			let newFilteredData = [...this.state.allData];
			this.setState({ filteredData: newFilteredData});     
		}
	}

	componentDidMount() {		
		this.queryDemoSets('');		
	}

	openEditor() {
		this.setState({ editorOpen: true });
	}

	closeEditorButtonOnClick() {
		if (this.shouldLosePossibleChangesOnEditor(this.closeEditor)) {
			this.closeEditor();
		}
	}

	closeEditor() {
		this.setState({ editorOpen: false });
		this.setSelectedItem(null);
	}

	queryDemoSets(term) {
		this.setState({ loading: true, filteredData: [] });
		DemoSetService.find(term)
			.then(response =>{				
				this.setState({ filteredData: response.data,
					allData:  response.data,					
					loading: false				
				}, function() { this.filterDemoSets(this.state.search); });
				return response;          
			})
			.catch(error => {			  
				let errorMessage = GetErrorMessage(error);
				console.log("errorMessage on catch");
				console.log(errorMessage);
				this.setState({ loading: false, errorMessage: errorMessage});            
			})
	}

	getDemoSetById(id) {		
		this.toggleUiBlock(true)
		DemoSetService.getDemoSetById(id)
			.then(response => {				
				this.updateSelectedDemoSet(response.data);		
				this.updateSavedDemoSetOnList(response.data);					
				this.setState(
					{originalSelectedDemoSet: cloneDeep(response.data)}
				);
				this.toggleUiBlock(false)
				return response;          
			})
			.catch(error => {			  
				let errorMessage = GetErrorMessage(error);
				console.log("errorMessage on catch");
				console.log(errorMessage);
				this.setState({ errorMessage: errorMessage});    
				this.toggleUiBlock(false)        
			})
	}	

	getSelectedItem(demoSetid) {		
		this.setState({clickedDemoSetid: demoSetid});		
		this.openEditor();
		if (this.shouldLosePossibleChangesOnEditor(()=>this.getDemoSetById(demoSetid))) {			
			this.getDemoSetById(demoSetid)			
		}	
	}

	setSelectedItem(selectedDemoSet) {		
		this.updateSelectedDemoSet(selectedDemoSet);
	}	

	shouldLosePossibleChangesOnEditor(callbackAfterLoseChangesConfirmation) {		
		if (this.didSelectedDemoSetChange()) {
			this.showLoseChangeConfirmationModal(callbackAfterLoseChangesConfirmation);
			return false;	
		}
		else {	
			return true;
		}	
	}

	didSelectedDemoSetChange() {
		let selectedDemoSet = this.state.selectedDemoSet;
		if (!selectedDemoSet) return false;
		let questionsAreEquals = isEqual(selectedDemoSet, this.state.originalSelectedDemoSet);
		return !questionsAreEquals;
	  }

	showLoseChangeConfirmationModal(callbackAfterLoseChangesConfirmation) {
		this.setState({ 
			loseChangesModalText: `There are unsaved changes to the survey '${this.state.selectedDemoSet.name}'. Are you sure do you want to leave without saving?`,
			callbackAfterLoseChangesConfirmation: callbackAfterLoseChangesConfirmation
		});
	}

	confirmLoseChangesModalHandler() {
		let filteredData = this.rollbackToOriginalDemoSet(this.state.filteredData, this.state.originalSelectedDemoSet);
		let allData = this.rollbackToOriginalDemoSet(this.state.allData, this.state.originalSelectedDemoSet);	

		this.setState({allData: allData, filteredData: filteredData
		}, this.state.callbackAfterLoseChangesConfirmation);
		this.setState({clickedDemoSetid: null});
		this.hideLoseChangesModal();
	}

	rollbackToOriginalDemoSet(list, originalSelectedItem) {
		const index = list.findIndex(item => item.id === this.state.selectedDemoSet.id);	
		list[index] = originalSelectedItem;
		return list;
	}

	hideLoseChangesModal() {
		this.setState({loseChangesModalText: ''})
	}

	isItemSelected(demoSetId) {    
		if (this.state && this.state.selectedDemoSet && 
			this.state.selectedDemoSet.id === demoSetId) {
			return true;
		} else {
			return false;
		}
	}

	toggleUiBlock(blockUi) {
		this.setState ({uiBlocked: blockUi});
	} 

	addNewDemoSetHandler = event => {
		if (this.shouldLosePossibleChangesOnEditor(() => this.addNewDemoSet())) {
			this.addNewDemoSet()
		}    
		event.preventDefault();
	}

	addNewDemoSet() {		
		const newDemoSet = NewDemoSet();		
		this.setSelectedItem(null);
		this.setSelectedItem(newDemoSet);
		this.openEditor();
	}

	removeDeletedDemoSet(demoSetId) {    
		const newFilteredData = this.state.filteredData.filter(i => i.id !== demoSetId);
		const newAllData = this.state.allData.filter(i => i.id !== demoSetId);
		this.setState({ filteredData: newFilteredData,
			allData: newAllData
			});    
	}

	updateSavedDemoSetOnList(selectedDemoSet) {
		let newFilteredData = [...this.updateDemoSetList(this.state.filteredData, selectedDemoSet)];	
		let newAllData = [...this.updateDemoSetList(this.state.allData, selectedDemoSet)];
		this.setState({filteredData: []});
		this.setState({filteredData: newFilteredData, 
			allData: newAllData
		});
	}

	updateDemoSetList(list, selectedDemoSet) {		
		var index = list.findIndex(q => q.id === selectedDemoSet.id);
		if (index === -1) {
			list.push(selectedDemoSet);      
		}
		else {
			list[index] = selectedDemoSet;      
		}
		return list;
	}

	getListItems() {
		return this.state.filteredData.map(demoSet => 
			<div key={demoSet.id}>
				<SideListItemGeneric key={demoSet.id}
					id={demoSet.id}					
					getSelectedItem={this.getSelectedItem.bind(this)}				
					isItemSelected={this.isItemSelected(demoSet.id)}
					menuItemId={demoSet.id}
				>
					{demoSet.name}
				</SideListItemGeneric>
		</div>
		)
	}	

	updateSelectedDemoSet(selectedDemoSet) {		
		this.setState({selectedDemoSet: selectedDemoSet})
	}
	

    render() {
        return (
			<BlockUi tag="div" blocking={this.state.uiBlocked}>  
			<main className="app-body">                   
				<SideBar>
					<SideBarHead canChangeData={this.props.userPermissions?.CanChangeDemoQuestion} tagName="SideBarHead" headline="Demographic Sets" addButton addButtonLabel="Set" addButtonCallBack={this.addNewDemoSetHandler.bind(this)}
						toolbar={(
						<div>
							{/* <IntroTextIcon className="icon orange clickable" title="Intro Texts"/>
							<SettingsIcon className="icon orange clickable" title="Settings"/> */}
						</div>  
					)} onChange={this.setSearchState.bind(this)}
					/>                  
					<SideList tagName="SideList">                  
						<Loading active={this.state.loading}></Loading>
						<div>
							{this.getListItems()}
						</div>
					</SideList>					
				</SideBar>

				<div className="app-content scrollbox">
					{this.state.editorOpen && ( <DemoSetForm openEditor={this.openEditor.bind(this)} 
							didSelectedDemoSetChange={this.didSelectedDemoSetChange.bind(this)}
							closeEditorButtonOnClick={this.closeEditorButtonOnClick.bind(this)} 
							closeEditor={this.closeEditor.bind(this)}
							removeDeletedDemoSet={this.removeDeletedDemoSet.bind(this)} 
							toggleUiBlock={this.toggleUiBlock.bind(this)} 
							selectedDemoSet={this.state.selectedDemoSet}
							updateSelectedDemoSet={this.updateSelectedDemoSet.bind(this)}
							updateSavedDemoSetOnList={this.updateSavedDemoSetOnList.bind(this)}							
							getDemoSetById={this.getDemoSetById.bind(this)}							
							canChangeData={this.props.userPermissions?.CanChangeDemoQuestion}
							/> )}
					{!this.state.editorOpen && ( <DemoSetHome />)}
				</div>
				{this.state.errorMessage && ( <ShowError clearErrorMessage={() => this.setState({errorMessage: ''})} errorMessage={this.state.errorMessage} /> )}
				{this.state.loseChangesModalText && ( <Modal size="small" content={this.state.loseChangesModalText} confirmModalHandler={this.confirmLoseChangesModalHandler.bind(this)} cancelModalHandler={this.hideLoseChangesModal.bind(this)} />)}
			</main>
			</BlockUi>
        );
    }
}

export default DemoSet;