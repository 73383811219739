import React, { Component } from 'react';
import SurveyHistoryForm from './components/SurveyHistoryForm/SurveyHistoryFormComponent';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import './SurveyHistoryComponent.scss';

class SurveyHistory extends Component {
	constructor (props) {
	super(props);
		this.state = {
			surveyHistory: {},
			loading: false,
			uiBlocked: false			
		}		
	}
	
	toggleUiBlock(blockUi) {
		this.setState ({uiBlocked: blockUi});
	}

    render() {

        return (
			<BlockUi tag="div" blocking={this.state.uiBlocked}>  
			<main className="app-body">         
				<div className="app-content scrollbox">					
					<SurveyHistoryForm toggleUiBlock={this.toggleUiBlock.bind(this)}></SurveyHistoryForm>
				</div>				
			</main>
			</BlockUi>
        );
    }
}

export default SurveyHistory;