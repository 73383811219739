function ValidateUser(selectedUser) {
	var validationErrors = [];                
	if (!selectedUser.userName || selectedUser.userName === '') {                    
		addErrorMessage(validationErrors, 'userName', 'User name is empty');
	}
	return validationErrors;
}

function addErrorMessage(validationErrors, field, message) {      
	validationErrors.push({ field: field, errorMessage: message});      
}

export default ValidateUser;