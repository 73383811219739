import React, { Component } from 'react';
import { MemoryRouter, Switch, Route, Redirect, withRouter } from "react-router-dom";
import { TabBar, CSVDownloader } from 'app/components/Basics';
import GlobalControlLine from './components/GlobalControlLine/GlobalControlLineComponent';
import GlobalFieldLine from './components/GlobalFieldLine/GlobalFieldLineComponent';
import { AdHocSurveyFormContext } from './AdHocSurveyContext'
import AdHocSurveyService from "../../services/adHocSurvey.service";
import GetErrorMessage from '../../helpers/error-message'
import ExtractTemplateIdFromLogs from '../../helpers/extract-template-id';
import { ShowError, ShowSuccess } from "../../components/toast"
import NewAdHocSurvey from './models/AdHocSurveyModel';
import Modal from '../../components/Modal';
import cloneDeep from 'lodash/cloneDeep';
import TabQuestions from './components/TabQuestions/TabQuestionsComponent';
import isEqual from 'lodash/isEqual';
import ValidateAdHocSurvey from './AdHocSurveyValidation'
import { adHocSurveyTypes } from 'app/constants/adhoc-survey-types';
import GetValidFileName from '../../helpers/valid-filename';
import { CSVLink } from 'react-csv';

class AdHocSurveyForm extends Component {
    tabs = [];
    _adHocSurveyService = {};
    _adHocNqSchemaService = {};
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'questions',
            selectedAdHocSurvey: NewAdHocSurvey(),
            errors: [],
            surveyTypeName: this.props.surveyType === adHocSurveyTypes.Generics ? "Generics" : "FiveMinex"
        }

        this._adHocSurveyService = new AdHocSurveyService(this.props.surveyType);
    }

    getTabs() {
        return [
            { id: "questions", label: 'Questions', url: 'questions', disabled: false, error: false },
            // { id:"programSelection", label: 'Program Selection', url: 'programSelection', disabled: false}
        ];
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedAdHocSurvey != null) {
            this.setState({
                selectedAdHocSurvey: nextProps.selectedAdHocSurvey,
                activeTab: "questions"
            });
        }

        if (!isEqual(this.props.selectedAdHocSurvey, nextProps.selectedAdHocSurvey)) {
            this.setState({ errors: [] });
        }
    }

    updateSelectedAdHocSurvey(newSelectedAdHocSurvey) {
        this.setState({ selectedAdHocSurvey: newSelectedAdHocSurvey })
    }

    addErrorMessage(field, message) {
        var errors = this.state.errors;
        errors.push({ field: field, errorMessage: message });
        this.setState({ errors: errors })
    }

    SaveAdHocSurvey(adHocSurvey) {        
        this.props.toggleUiBlock(true);
        if (adHocSurvey.id) {
            this._adHocSurveyService.updateAdHocSurvey(adHocSurvey)
                .then(_ => {
                    this.props.toggleUiBlock(false);
                    this.setState({ successMessage: 'The survey was saved successfully' });
                    this.props.getAdHocSurveyById(adHocSurvey.id);                    
                })
                .catch(error => {
                    let errorMessage = GetErrorMessage(error);
                    console.log("errorMessage on catch");
                    console.log(errorMessage);
                    this.props.toggleUiBlock(false);
                    this.setState({ errorMessage: errorMessage });
                });
        }
        else {
            this._adHocSurveyService.createAdHocSurvey(adHocSurvey)
                .then(response => {
                    const newAdHocSurvey = response.data;                    
                    this.props.toggleUiBlock(false);
                    this.setState({ successMessage: 'The survey was created successfully' });
                    this.setState({ selectedAdHocSurvey: newAdHocSurvey });
                    this.props.getAdHocSurveyById(newAdHocSurvey.id);
                })
                .catch(error => {
                    let errorMessage = GetErrorMessage(error);
                    console.log("errorMessage on catch");
                    console.log(errorMessage);
                    this.props.toggleUiBlock(false);
                    this.setState({ errorMessage: errorMessage });
                });
        }

    }

    deleteAdHocSurvey = event => {
        this.setState({ deleteQuestionModalText: `Are you sure you want to delete the survey with name '${this.state.selectedAdHocSurvey.name}'?` });
    }

    duplicateAdHocSurvey = event => {
        let cloned = cloneDeep(this.state.selectedAdHocSurvey);
        cloned.id = null;
        cloned.name = `${cloned.name}_copy`;
        cloned.netQuestSurveyId = null;
        cloned.startDate = null;
        cloned.endDate = null;
        cloned.sampleId = null;
        cloned.uploadedSchemaFileName = null;
        cloned.forceSchemaQuestionUpdate = false;
        cloned.questions.forEach(surveyQuestion => {
            surveyQuestion.id = null;
            surveyQuestion.questionConditionsDefinition.id = null;
            this.cleanClonedConditionIds(surveyQuestion.questionConditionsDefinition.conditions);
            surveyQuestion.answerOptionsConditionsDefinitions.forEach(conditionsDefinition => {
                conditionsDefinition.id = null;
                this.cleanClonedConditionIds(conditionsDefinition.conditions);
            });
        });
        this.props.updateSelectedAdHocSurvey(cloned);
    }

    cleanClonedConditionIds(conditions) {
        conditions.forEach(condition => {
            condition.id = null;
        });
    }

    confirmDelete() {
        this.hideDeleteModal();
        this.props.toggleUiBlock(true);
        const adHocSurveyId = this.state.selectedAdHocSurvey.id;
        this._adHocSurveyService.deleteAdHocSurvey(this.state.selectedAdHocSurvey)
            .then(response => {
                this.setState({ successMessage: 'The survey was deleted successfully' });
                this.props.removeDeletedAdHocSurvey(adHocSurveyId);
                setTimeout(() => {
                    this.props.toggleUiBlock(false);
                    this.props.closeEditor();
                }, 2000);


            })
            .catch(error => {
                let errorMessage = GetErrorMessage(error);
                console.log("errorMessage on catch");
                console.log(errorMessage);
                this.props.toggleUiBlock(false);
                this.setState({ errorMessage: errorMessage });
            });
    }

    hideDeleteModal() {
        this.setState({ deleteQuestionModalText: '' })
    }

    handleSubmitAction = event => {
        var validationErrors = ValidateAdHocSurvey(this.state.selectedAdHocSurvey);
        this.setState({ errors: validationErrors });
        if (validationErrors && validationErrors.length === 0) {
            this.SaveAdHocSurvey(this.state.selectedAdHocSurvey);
        }
        return;
    }

    createTestLinks() {
        this.props.toggleUiBlock(true);        
        this._adHocSurveyService.getAdHocSurveyTestLinks(this.state.selectedAdHocSurvey.id).then(response => {
            this.props.toggleUiBlock(false);
            let modalContent = response.data.testLinks.map((url, index) => (<div>Respondent {index + 1} - <a rel="noreferrer" href={url} target="_blank">{url}</a></div>));
            let linksToClipBoard = response.data.testLinks.map(url => `${url} \n`).join('');
            let logsToDownload = response.data.logs.map(log => `${log} \n`).join('');
            var templateId = "";
            if (this.state.selectedAdHocSurvey.netQuestSurveyId) {
                templateId = this.state.selectedAdHocSurvey.netQuestSurveyId;
            }
            else {
                templateId = ExtractTemplateIdFromLogs(response.data.logs);
            }
            // modalContent.unshift(<CSVDownloader filename={`testlinks_${this.state.surveyTypeName}_set_${GetValidFileName(this.state.selectedAdHocSurvey.name)}.csv`} data={linksToClipBoard} target="_blank" />)
            modalContent.unshift((<div><strong>Test links using TemplateId <span className='orange'>{templateId}</span> for set "<span className='orange'>{this.state.selectedAdHocSurvey.name}</span>":</strong></div>));
            modalContent.push((<CSVLink filename={`testlinks_${this.state.surveyTypeName}_set_${GetValidFileName(this.state.selectedAdHocSurvey.name)}.csv`} data={linksToClipBoard}>Download Links</CSVLink>))
            modalContent.push(<div></div>);
		    if (this.state.selectedAdHocSurvey.netQuestSurveyId) {
                modalContent.push((<div>No Logs Available to be Downloaded</div>))
            }
            else {
                modalContent.push((<CSVLink filename={`logs_for_testlinks_${this.state.surveyTypeName}_set_${GetValidFileName(this.state.selectedAdHocSurvey.name)}.csv`} data={logsToDownload}>Download Logs</CSVLink>));
            }
            this.setState({
                testLinksModalContent: modalContent,
                linksToClipBoard: linksToClipBoard
            });
        })
            .catch(error => {
                let errorMessage = GetErrorMessage(error);
                console.log("errorMessage on catch");
                console.log(errorMessage);
                this.props.toggleUiBlock(false);
                this.setState({ errorMessage: errorMessage });
            });
    }

    hideTestLinksModal() {
        this.setState({
            testLinksModalContent: '',
            linksToClipBoard: ''
        });
    }

    copyLinksToClipboard() {
        navigator.clipboard.writeText(this.state.linksToClipBoard);
    }

    render() {

        this.tabs = this.getTabs(this.state.selectedAdHocSurvey);
        return (

            <div className="AppFormComponent adHocSurveyForm">
                <AdHocSurveyFormContext.Provider value={{
                    updateSelectedAdHocSurvey: this.updateSelectedAdHocSurvey.bind(this),
                    selectedAdHocSurvey: this.state.selectedAdHocSurvey
                }}>
                    <form ref={form => this.formEl = form} onSubmit={this.handleSubmitAction}>
                        <div className="form-head">
                            <GlobalControlLine errors={this.state.errors}
                                handleSubmitAction={this.handleSubmitAction.bind(this)}
                                deleteAdHocSurvey={this.deleteAdHocSurvey.bind(this)}
                                duplicateAdHocSurvey={this.duplicateAdHocSurvey.bind(this)}
                                closeEditorButtonOnClick={this.props.closeEditorButtonOnClick}
                                didSelectedAdHocSurveyChange={this.props.didSelectedAdHocSurveyChange}
                                createTestLinks={this.createTestLinks.bind(this)}
                                canChangeData={this.props.canChangeData}
                            />
                            <GlobalFieldLine canChangeData={this.props.canChangeData}
                                folders={this.props.folders}
                                key={this.state.selectedAdHocSurvey.id}
                                metadata={this.props.metadata} />
                        </div>
                        <div className="form-sections">
                            <MemoryRouter>
                                <div className="tab-bar">
                                    <TabBar key="AdHocSurveyForm" activeTab={this.state.activeTab} tabs={this.tabs} onTabActivate={item => this.setState({ activeTab: item.id })} />
                                    {/* <div title="Load the current tab settings from another question." className="duplicate-tab"><ButtonIcon icon={DuplicateIcon}/></div> */}
                                </div>
                                <div className="section-content">
                                    <Switch>
                                        <Route exact path="/"><Redirect to={this.state.activeTab} /></Route>
                                        <Route exact path="/questions"><TabQuestions canChangeData={this.props.canChangeData} errors={this.state.errors} /></Route>
                                    </Switch>
                                    <Redirect to={this.state.activeTab} />
                                </div>
                            </MemoryRouter>
                        </div>
                    </form>
                </AdHocSurveyFormContext.Provider>

                {this.state.errorMessage && (<ShowError clearErrorMessage={() => this.setState({ errorMessage: '' })} errorMessage={this.state.errorMessage} />)}
                {this.state.successMessage && (<ShowSuccess clearMessage={() => this.setState({ successMessage: '' })} message={this.state.successMessage} />)}
                {this.state.deleteQuestionModalText && (<Modal content={this.state.deleteQuestionModalText} size="small" confirmModalHandler={this.confirmDelete.bind(this)} cancelModalHandler={this.hideDeleteModal.bind(this)} />)}
                {this.state.testLinksModalContent && (<Modal hideConfirmButton content={this.state.testLinksModalContent} cancelButtonLabel="Close" confirmButtonLabel="Copy Test Links to Clipboard" cancelModalHandler={this.hideTestLinksModal.bind(this)} confirmModalHandler={this.copyLinksToClipboard.bind(this)} />)}
            </div>
        );
    }
}
export default withRouter(AdHocSurveyForm); 