import React, { Component } from 'react';
import {Label, Spacer, Button, InputInline} from 'app/components/Basics/'
import { Icons } from '@ecosystem/ui-lib/core/components/Icon';
import './GlobalControlLineComponent.scss';
import { ReactComponent as SaveIcon } from 'app/assets/icons/Save.svg';
import { ReactComponent as DuplicateIcon } from 'app/assets/icons/Duplicate-Plus.svg';
import { ReactComponent as ArchiveIcon } from 'app/assets/icons/Archive.svg';
import {ChannelFormContext} from '../../ChannelContext'
import ValidateChannel from '../../../channel/ChannelValidation'
import { Input as EcoInput } from '@ecosystem/ui-lib/core/components/Input';


 
class GlobalControlLine extends Component {

    constructor(props){
        super(props);

        this.state = {           
        }        
    }

    componentWillReceiveProps(nextProps) {        
        if (nextProps && nextProps.errors) {            
            var error = nextProps.errors.find(e => e.field === "name")
            if (error) {
                this.setState({errorChannelName: error.errorMessage})
            }
        }
    }

	isSaveDisabled() {
		if (this.isChangeDisabled())
		{
			return true;
		}
		let result = false;
		var errors = ValidateChannel(this.context.selectedChannel);		
		if (errors.length > 0) {
			result = true;
		}		
		if (!this.props.didSelectedChannelChange()) {
			result = true;
		}
		return result;
	}

	isButtonDisabled() {	
		if (this.isChangeDisabled())
		{
			return true;
		}
		return this.context.selectedChannel.id === null;
	}

	getChannelName() {		
        return this.context.selectedChannel.name !== '' ? 
            this.context.selectedChannel.name : "New Channel Name";
    }

    getChannelCode() {		
        return this.context.selectedChannel.id != null && this.context.selectedChannel.id !== '' ? 
            this.context.selectedChannel.id : "New Channel Code";
    }

	channelNameChange(isEditorOpen, newName) {
        if (!isEditorOpen) {
            var selectedChannel = this.context.selectedChannel;
            selectedChannel.name = newName;
            this.context.updateSelectedChannel(selectedChannel);
        }
    }

    channelCodeChange(isEditorOpen, newCode) {
        if (!isEditorOpen) {
            var selectedChannel = this.context.selectedChannel;
            selectedChannel.id = newCode;
            this.context.updateSelectedChannel(selectedChannel);
        }
    }

    channelCodeHandler = event => {		
        var selectedChannel = this.context.selectedChannel;
		selectedChannel.id = event.target.value ? parseInt(event.target.value) : undefined;
		this.context.updateSelectedChannel(selectedChannel);
    }

	isChangeDisabled() {
		return !this.context.canChangeData || this.context.selectedChannel.isSysAdmin === 1;
	}

    render() {
        return (
            <div className="GlobalControlLineComponent form-row">
                <div/>
				{/* <InputInline disabled={this.isChangeDisabled()} id="channel-code-field" enableOnBlur className="name-field" value={this.getChannelCode()} onModeChange={this.channelCodeChange.bind(this)} />
                <InputInline disabled={this.isChangeDisabled()} id="channel-name-field" enableOnBlur className="name-field" value={this.getChannelName()} onModeChange={this.channelNameChange.bind(this)} /> */}                       
                <div className="contol-buttons">                    
                    <Button disabled={this.isSaveDisabled()} title="Save all changes in the channel" icon={<SaveIcon/>} onClick={this.props.handleSubmitAction}>Save</Button>                    
                    <Button title="Close current editor and discard all unsaved changes" onClick={this.props.closeEditorButtonOnClick} simple icon={Icons.Close}>Close</Button>
                    <Spacer width={20}/>                   
                    <Button disabled={this.isButtonDisabled()} title="Delete the channel is irreversible" small secondary icon={Icons.Delete} onClick={this.props.deleteChannel}>Delete</Button>
                    <Button disabled={this.isButtonDisabled()} title="Create a new channel as duplicate from this channel" small secondary icon={<DuplicateIcon/>} onClick={this.props.duplicateChannel}>Duplicate</Button>                   
                </div>
                
            </div>  
        );
    }
}
GlobalControlLine.contextType = ChannelFormContext;
export default GlobalControlLine;