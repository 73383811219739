function ValidateReportDownloadSet(selectedReportDownloadSet) {
	var validationErrors = [];	
	if (!selectedReportDownloadSet.startDate || selectedReportDownloadSet.startDate === '') {
		addErrorMessage(validationErrors, 'startDate', 'Start date is empty');
	}
	if (!selectedReportDownloadSet.endDate || selectedReportDownloadSet.endDate === '') {
		addErrorMessage(validationErrors, 'startDate', 'End date is empty');
	}	
	if (selectedReportDownloadSet.subSurveyId == null) {
		addErrorMessage(validationErrors, 'subSurveyId', 'Survey Type cannot be empty');
	}	
	
	return validationErrors;
}

function addErrorMessage(validationErrors, field, message) {      
	validationErrors.push({ field: field, errorMessage: message});      
}

export default ValidateReportDownloadSet;