import React, { Component } from 'react';
import ReactSlider from 'react-slider'

import './RangeSliderComponent.scss';


class RangeSlider extends Component {
    static defaultProps = {
        name:"range-slider",
        min: 0,
        max: 100,
        startValue: false,
        endValue: false,
        markDistance: 1,
        markLabelDistance: 1,
        disabled: false,
        markLabelFormat: (idx, val) => val,
        valueFieldFormat: (idx, val ) => isNaN(val)?"":val,
        valueFormat: (vals) => Array.isArray(vals) ? vals.join(" - ") : vals
    }
    range = 'both' 
    offest = 0

    constructor(props) {
        
        super(props);

        var values = []; 

        this.offset = -this.props.min;

        if(this.props.startValue !== false && this.props.endValue !== false){
            var values = [this.props.startValue, this.props.endValue];
            this.range = "both";
        }else if(this.props.startValue === false && this.props.endValue === false){
            var values = null;
            this.range = "none";
        }else if(this.props.startValue !== false){
            var values = this.props.startValue;
            this.range = "right";
        }else if(this.props.endValue !== false){
            var values = this.props.endValue;
            this.range = "left";
        }

        this.state = {
            values: values
        }
       
    }

   

    render() {
        return (
            <div className={`RangeSliderComponent RangeSlider-${this.range} ${this.props.disabled?"disabled":""} ${this.props.className}`}>
                <div class="LabelDisplay">
                    {this.props.label}
                    <div className="RangeDisplay">{this.props.valueFormat(this.state.values)}</div>
                </div>
               <ReactSlider
                    className="RangeSlider"
                    thumbClassName="SliderThumb"
                    trackClassName="SliderTrack"
                    markClassName="SliderMark"
                    disabled={this.props.disabled}
                    min={this.props.min + this.offset}
                    max={this.props.max + this.offset}
                    marks={this.props.markDistance}
                    onChange={(value) => this.setState({values:Array.isArray(value) ? value.map(v => v-this.offset) : value - this.offset})}
                    defaultValue={Array.isArray(this.state.values) ? this.state.values.map(v => v+this.offset) : this.state.values + this.offset}
                    renderThumb={(props, state) => <div {...props}></div>}
                    renderTrack={(props, state) => <div {...props}><div className="TrackLine"></div></div>}
                    renderMark={(props) => <div {...props}><span/>
                        {(((props.key - this.props.min + this.offset)/this.props.markDistance) % this.props.markLabelDistance === 0 ) ? <div>{this.props.markLabelFormat(props.key, props.key - this.offset)}</div> : ""}
                    </div>}
                    pearling
                />
                <input type="hidden" name={`${this.props.name}_start`} value={
                        this.props.valueFieldFormat(0,this.range=='both'?this.state.values[0]: (this.range=='right'?this.state.values:undefined)+this.offset)
                }/>
                <input type="hidden" name={`${this.props.name}_end`} value={
                    this.props.valueFieldFormat(1,this.range=='both'?this.state.values[1]: (this.range=='left'?this.state.values:undefined)+this.offset)
                }/>
            </div>
        );
    }
}


export default RangeSlider;