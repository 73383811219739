import authHeader from './auth-header';
import Api from './api';

const API_URL = process.env.REACT_APP_AQUA_API_ENDPOINT

class InDepthService {
	
  async getIndepthSetById(indepthSetId: string) {
	return await Api().get(`${API_URL}/inDepth/${indepthSetId}`, { headers:  authHeader() as any });      
  }
  async find(term: string, date: any) {
    if (date == null) {
      date = '';
    }
    return await Api().get(`${API_URL}/inDepth/find?text=${term}%&date=${date}`, { headers:  authHeader() as any });      
  }

  async updateIndepthSet(indepthSet: any) {
    var formData = new FormData();
    formData.append('schemaFile', indepthSet.schemaFile);
    var indepthSetCopy = Object.assign({}, indepthSet);
    delete indepthSetCopy.schemaFile;
    formData.append('inDepthDtoString', JSON.stringify(indepthSetCopy));

    return await Api().put(`${API_URL}/inDepth/${indepthSet.id}`, formData, 
    { method: 'PUT',
      headers:  authHeader() as any });      
  }

  async createIndepthSet(indepthSet: any) {
    var formData = new FormData();
    formData.append('schemaFile', indepthSet.schemaFile);
    var indepthSetCopy = Object.assign({}, indepthSet);
    delete indepthSetCopy.schemaFile;
    formData.append('inDepthDtoString', JSON.stringify(indepthSetCopy));

    return await Api().post(`${API_URL}/inDepth/`, formData, 
    { method: 'POST',
      headers:  authHeader() as any });      
  }

  async deleteIndepthSet(indepthSet: any) {
    return await Api().delete(`${API_URL}/inDepth/${indepthSet.id}`, 
    { method: 'DELETE',
      headers:  authHeader() as any });      
  }

  async createFolder(folder: any) {
    return await Api().post(`${API_URL}/inDepth/createFolder`, folder, 
    { method: 'POST',
      headers:  authHeader() as any });      
  }

  async getMetadata() {
    return await Api().get(`${API_URL}/inDepth/metadata`, { headers:  authHeader() as any });      
  }

  async getInDepthTestLinks(surveyDate: Date, surveyType: number, programsDate: Date) {
    const data = {
      surveyDate: surveyDate,
      surveyType: surveyType,
      programsDate: programsDate
    };
    const jsonString = JSON.stringify(data);
	  return await Api().get(`${API_URL}/inDepthTestLinks/create?json=${jsonString}`, { headers:  authHeader() as any });      
  }

  async createInDepthTestLinks(testLinksRequest: any) {
    return await Api().post(`${API_URL}/inDepthTestLinks/create`, testLinksRequest, 
    { method: 'POST',
      headers:  authHeader() as any });      
  }

  async getTestLinksMetadata() {
    return await Api().get(`${API_URL}/inDepthTestLinks/metadata`, { headers:  authHeader() as any });      
  }
}

export default new InDepthService();