import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import UserService from "../../services/user.service";
import AuthService from "../../services/auth.service";
import './changepassword.scss';
import { Button } from '@ecosystem/ui-lib/core/components/Button';
import { Loading } from '@ecosystem/ui-lib/core/components/Loading';
import { InlineAlertType } from '@ecosystem/ui-lib/core/components/InlineAlert';
import { ToastMessage } from '@ecosystem/ui-lib/core/components/ToastMessage';
import GetErrorMessage  from '../../helpers/error-message'
import Modal from '../../components/Modal';
import { history } from '../../helpers/history'

interface RouterProps {
  history: string;
}

type Props = RouteComponentProps<RouterProps>;

type State = {
  username: string,
  password: string,
  loading: boolean,
  message: string,  
  errorMessage: string,  
};

export default class ChangePassword extends Component<Props, State> {  

  constructor(props: Props) {
	super(props);
	this.handleChangePassword = this.handleChangePassword.bind(this);	

	this.state = {
		username: "",
		password: "",
		loading: false,
		message: "",
		errorMessage: ""
	};
  }

  
  isPasswordValid = (password: string, confirmPassword: string) => {
	const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!?"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{10,}$/;
	let passwordMatch = password === confirmPassword;
	if (!passwordMatch) {
		this.setState({errorMessage: "Passwords does not match!"});
		return false;
	}
    let passwordIsValid = regex.test(password);
	if (!passwordIsValid) {
		this.setState({errorMessage: "Passwords must be at least 10 characters in length and must contain at least 1 upper case, 1 lower case and 1 number"});
		return false;
	}
	return true;
  };

  async handleChangePassword(formValue: { password: string; confirmPassword: string }) {
    const { password, confirmPassword } = formValue;
	let isValid = this.isPasswordValid(password, confirmPassword);
	if (!isValid) {
		return;
	}
    this.setState({
      message: "",
      loading: true,
    });	
    UserService.changeLoggedUserPassword(password).then(
      (response) => {
		this.setState({
			message: "Your password has been changed! Click 'Ok' to login again with your new password.",
			loading: false,
		  });
      },
      error => {
		let errorMessage = GetErrorMessage(error);
        this.setState({
          loading: false,
          errorMessage: errorMessage
        });
      }
    );
  }

  redirectToLoginPage() {
	AuthService.logout();
	history.push('/login');
  }

  render() {
    const { loading, message, errorMessage } = this.state;

    const initialValues = {
      password: "",
	  confirmPassword: ""
    };

    return (
<div className="changepasswordWrapper">
  <div className="changepasswordForm">
    <Formik initialValues={initialValues} onSubmit={this.handleChangePassword}>
          <Form>
            <div className="form-header">
                <h4>Hi {AuthService.getLoggedUserName()}, choose a new password</h4>
            </div>
            <div className="form-group">                
              <Field className="form-input" placeholder="New Password" name="password" type="password" />
            </div>
            <div className="form-group">                 
                <Field className="form-input" placeholder="Confirm New Password" name="confirmPassword" type="password"  />                            
            </div>
            <div className="form-group">
              <Button disabled={this.state.message !== ''} className="form-button" type="submit">Change Password</Button>                     
            </div>			
			{errorMessage && (
                  <ToastMessage type={InlineAlertType.DataError} message={errorMessage} onDismiss={() =>
                        this.setState({errorMessage: ''})} timeout={100000} />
              )}
			  {this.state.message && ( <Modal content={this.state.message} size="small" hideConfirmButton cancelButtonLabel="Ok" cancelModalHandler={this.redirectToLoginPage.bind(this)} />)}
            <Loading active={loading}></Loading>             
          </Form>
      </Formik>
    </div>              
</div>     
    );
  }
}
