import React, { Component } from 'react';
import { Badge as EcoBadge } from '@ecosystem/ui-lib/core/components/Badge';
import './StatusBadgeComponent.scss';



class StatusBadge extends Component {
    render() {

        var badgeclass = 'StatusBadgeComponent';
        
        switch(this.props.children.toLowerCase()){
            case 'active':
            case 'in-use':      badgeclass+=' badge-brand badge-filled';
                                break;
            case 'planned':     badgeclass+=' badge-brand';
                                break;
            case 'archived':    badgeclass+=' badge-grey badge-filled';
                                break;
            case 'disabled':    badgeclass+=' badge-red';
                                break;
            case 'ended':       
            default:            badgeclass+=' badge-grey';
                                break;
        };

        return (
            <EcoBadge text={this.props.children} className={`${this.props.className} ${badgeclass}`}></EcoBadge>
        );
    }
}

export default StatusBadge;