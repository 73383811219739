function NewQuestionWithCondition() {
    return {
        id: null,       
        question: {},
		sortOrder: null,
		enabled: 1,
		questionConditionsDefinition: {
			id: null,
			answerOption: null,
			conditionOperator: 0,
			conditions: []
		},
		answerOptionsConditionsDefinitions: []		
      }
}
export default NewQuestionWithCondition;