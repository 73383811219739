import React, { Component } from 'react';
import {Button} from 'app/components/Basics/'
import './NewFolderCreatorComponent.scss';
import InlineTextEditor from 'app/components/Basics/InlineTextEditor/InlineTextEditorComponent'

class NewFolderCreator extends Component {

    constructor(props){
        super(props);
        this.state = {            
            newFolderText: ''        
        }        
    }

	showAddFolderInput() {		
		this.setState({showAddFolderField: 'visible'});
	}

	hideAddFolderInput() {		
		this.setState({showAddFolderField: '', newFolderText: ''});
	}

	saveNewFolder(newFolderName) {
		this.props.saveNewFolderHandler(newFolderName);
		this.hideAddFolderInput();
	}	

	cancelNewFolder() {
		this.hideAddFolderInput();
	}

    render() {
		return (
            <div className='new-folder-container'>
				<div className={`new-folder-input-container ${this.state.showAddFolderField}`}>					
					<InlineTextEditor value={this.state.newFolderText} saveHandler={this.saveNewFolder.bind(this)} cancelHandler={this.cancelNewFolder.bind(this)}/>				
				</div>
				<div className={`add-new-folder-button ${this.state.showAddFolderField === 'visible' ? 'hidden' : ""}`}>
					<Button disabled={!this.props.canChangeData} title="Add a new folder" onClick={()=> this.showAddFolderInput()} simple small> + Folder </Button>
				</div>
			</div>
        );
    }
}

export default NewFolderCreator;