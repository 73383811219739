
import React, { Component } from 'react';
import {SideBar, SideBarHead, SideList, SideListItemGeneric} from 'app/components/SideBar/';
import RoleForm from './RoleForm';
import NewRole from './RoleModel';
import RoleHome from './components/RoleHome/RoleHomeComponent';
import RoleService from "../../../services/role.service";
import  GetErrorMessage  from '../../../helpers/error-message'
import {ShowError, ShowSuccess} from "../../../components/toast"
import { Loading } from '@ecosystem/ui-lib/core/components/Loading';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import Modal from '../../../components/Modal';
import { Button } from 'app/components/Basics/'
import { ReactComponent as PagesIcon } from 'app/assets/icons/pagepage.svg';
import './RoleComponent.scss';

class Role extends Component {
	constructor (props) {
	super(props);
		this.state = {
			search: '',			
			allData: [],
			filteredData: [],
			folders: [],
			loading: false,
			errorMessage: null,
			selectedRole: null,
			originalSelectedRole: null,
			uiBlocked: false,
			editorOpen: false,			
			metadata: {
				permissions: []
			}
		}		
	}

	setSearchState(event) {    
		var value = event.target.value;    
		this.setState({search: value});
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.search !== prevState.search) {     
			this.filterRoles(this.state.search);
		}

		if (this.state.editorOpen !== prevState.editorOpen) {
			if (this.state.editorOpen) {
			//retrigger selected question if editor was closed        
			this.setState({selectedRole: this.state.selectedRole});
			}
		}    
	}

	filterRoles(term) {		
		if (term !== '') {
			const filteredData = this.state.allData
			.filter(i => (i.name  && i.name.toLowerCase().includes(term.toLowerCase())));
			let newFilteredData = [...filteredData];
			this.setState({ filteredData: newFilteredData});      
		}
		else {
			let newFilteredData = [...this.state.allData];
			this.setState({ filteredData: newFilteredData});     
		}
	}

	componentDidMount() {		
		this.queryMetadata();
		this.queryRoles('');
	}	

	openEditor() {
		this.setState({editorOpen: true});		
	}	

	closeEditorButtonOnClick() {
		if (this.shouldLosePossibleChangesOnEditor(this.closeEditor)) {
			this.closeEditor();
		}
	}

	closeEditor() {
		this.setState({ editorOpen: false });
		this.setSelectedItem(null);
	}

	queryMetadata() {		
		RoleService.getMetadata()
				.then(response =>{					
					this.setState({
						metadata: response.data					
					});
					return response;          
				})
				.catch(error => {			  
					let errorMessage = GetErrorMessage(error);
					console.log("errorMessage on catch");
					console.log(errorMessage);
					this.setState({ loading: false, errorMessage: errorMessage});            
			})
	}	

	queryRoles(term) {
		this.setState({ loading: true, filteredData: [] });
		RoleService.find(term)
			.then(response =>{				
				this.setState({ filteredData: response.data,
					allData:  response.data,					
					loading: false				
				}, function() { this.filterRoles(this.state.search); });
				return response;          
			})
			.catch(error => {			  
				let errorMessage = GetErrorMessage(error);
				console.log("errorMessage on catch");
				console.log(errorMessage);
				this.setState({ loading: false, errorMessage: errorMessage});            
			})
	}

	getRoleById(id) {		
		this.toggleUiBlock(true)
		RoleService.getRoleById(id)
			.then(response =>{				
				this.setState({originalSelectedRole: cloneDeep(response.data)});
				this.updateSelectedRole(response.data);
				this.updateSavedRoleOnList(response.data);				
				this.toggleUiBlock(false)
				return response;          
			})
			.catch(error => {			  
				let errorMessage = GetErrorMessage(error);
				console.log("errorMessage on catch");
				console.log(errorMessage);
				this.setState({ errorMessage: errorMessage});    
				this.toggleUiBlock(false)        
			})
	}	

	getSelectedItem(roleId) {		
		this.setState({clickedRoleId: roleId});		
		this.openEditor();
		if (this.shouldLosePossibleChangesOnEditor(()=>this.getRoleById(roleId))) {			
			this.getRoleById(roleId)			
		}	
	}

	setSelectedItem(selectedRole) {		
		this.updateSelectedRole(selectedRole);
	}	

	shouldLosePossibleChangesOnEditor(callbackAfterLoseChangesConfirmation) {		
		if (this.didSelectedRoleChange()) {
			this.showLoseChangeConfirmationModal(callbackAfterLoseChangesConfirmation);
			return false;	
		}
		else {	
			return true;
		}	
	}

	didSelectedRoleChange() {
		let selectedRole = this.state.selectedRole;
		if (!selectedRole) return false;
		return !isEqual(selectedRole, this.state.originalSelectedRole);		
	  }

	showLoseChangeConfirmationModal(callbackAfterLoseChangesConfirmation) {
		this.setState({ 
			loseChangesModalText: `There are unsaved changes to the role '${this.state.selectedRole.name}'. Are you sure do you want to leave without saving?`,
			callbackAfterLoseChangesConfirmation: callbackAfterLoseChangesConfirmation
		});
	}

	confirmLoseChangesModalHandler() {
		let filteredData = this.rollbackToOriginalRole(this.state.filteredData, this.state.originalSelectedRole);
		let allData = this.rollbackToOriginalRole(this.state.allData, this.state.originalSelectedRole);	

		this.setState({allData: allData, filteredData: filteredData
		}, this.state.callbackAfterLoseChangesConfirmation);
		this.setState({clickedRoleId: null});
		this.hideLoseChangesModal();
	}

	rollbackToOriginalRole(list, originalSelectedItem) {
		const index = list.findIndex(item => item.id === this.state.selectedRole.id);	
		list[index] = originalSelectedItem;
		return list;
	}

	hideLoseChangesModal() {
		this.setState({loseChangesModalText: ''})
	}

	isItemSelected(roleId) {    
		if (this.state && this.state.selectedRoleSet && 
			this.state.selectedRole.id === roleId) {
			return true;
		} else {
			return false;
		}
	}

	toggleUiBlock(blockUi) {
		this.setState ({uiBlocked: blockUi});
	} 

	addNewRoleHandler = event => {
		if (this.shouldLosePossibleChangesOnEditor(() => this.addNewRole())) {
			this.addNewRole()
		}    
		event.preventDefault();
	}

	addNewRole() {
		const newRole = NewRole();		
		this.setSelectedItem(null);
		this.setSelectedItem(newRole);
		this.openEditor();
	}

	removeDeletedRole(roleId) {    
		const newFilteredData = this.state.filteredData.filter(i => i.id !== roleId);
		const newAllData = this.state.allData.filter(i => i.id !== roleId);
		this.setState({ filteredData: newFilteredData,
			allData: newAllData
			});    
	}

	updateSavedRoleOnList(selectedRole) {
		let newFilteredData = [...this.updateRoleList(this.state.filteredData, selectedRole)];	
		let newAllData = [...this.updateRoleList(this.state.allData, selectedRole)];
		this.setState({filteredData: []});
		this.setState({filteredData: newFilteredData, 
			allData: newAllData
		});
	}

	updateRoleList(list, selectedRole) {		
		var index = list.findIndex(q => q.id === selectedRole.id);
		if (index === -1) {
			list.push(selectedRole);      
		}
		else {
			list[index] = selectedRole;      
		}
		return list;
	}
	
	getListItems() {
		return this.state.filteredData.map(role => 
			<div key={role.id}>
				<SideListItemGeneric key={role.id}
					id={role.id}					
					getSelectedItem={this.getSelectedItem.bind(this)}				
					isItemSelected={this.isItemSelected(role.id)}
					menuItemId={role.id}
				>
					{role.name}
				</SideListItemGeneric>
		</div>
		)
	}

	updateSelectedRole(selectedRole) {		
		this.setState({selectedRole: selectedRole})
	}	

    render() {

        return (
			<BlockUi tag="div" blocking={this.state.uiBlocked}>  
			<main className="app-body">         
				<div className={`${this.props.isSurveyTest ? "survey-test-sidebar" : ""}`}>
					<SideBar>
						<SideBarHead  canChangeData={this.props.userPermissions?.CanChangeRole} tagName="SideBarHead" headline="Role" addButton addButtonLabel="Role" addButtonCallBack={this.addNewRoleHandler.bind(this)}
							onChange={this.setSearchState.bind(this)}
						/>                  
						<SideList tagName="SideList">                  
							<Loading active={this.state.loading}></Loading>							
							<div>								
								{this.getListItems()}
							</div>
						</SideList>					
					</SideBar>
				</div>          

				<div className="app-content scrollbox">
					{this.state.editorOpen && ( <RoleForm openEditor={this.openEditor.bind(this)} 
							didSelectedRoleChange={this.didSelectedRoleChange.bind(this)}
							closeEditorButtonOnClick={this.closeEditorButtonOnClick.bind(this)} 
							closeEditor={this.closeEditor.bind(this)}
							removeDeletedRole={this.removeDeletedRole.bind(this)} 
							toggleUiBlock={this.toggleUiBlock.bind(this)} 
							selectedRole={this.state.selectedRole}
							updateSelectedRole={this.updateSelectedRole.bind(this)}
							updateSavedRoleOnList={this.updateSavedRoleOnList.bind(this)}
							getRoleById={this.getRoleById.bind(this)}							
							canChangeData={this.props.userPermissions?.CanChangeRole}
							metadata={this.state.metadata}
							/> )}
					{!this.state.editorOpen && ( <RoleHome></RoleHome>)}					
				</div>				
				
				{this.state.successMessageFolder && ( <ShowSuccess clearMessage={() => this.setState({successMessageFolder: ''})} message={this.state.successMessageFolder} /> )}
				{this.state.errorMessage && ( <ShowError clearErrorMessage={() => this.setState({errorMessage: ''})} errorMessage={this.state.errorMessage} /> )}
				{this.state.loseChangesModalText && ( <Modal size="small" content={this.state.loseChangesModalText} confirmModalHandler={this.confirmLoseChangesModalHandler.bind(this)} cancelModalHandler={this.hideLoseChangesModal.bind(this)} />)}
			</main>
			</BlockUi>
        );
    }
}

export default Role;