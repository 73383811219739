import React, { Component } from "react";
import { IntroPage } from "app/components/IntroPage";
import { Card } from '@ecosystem/ui-lib/core/components/Card';
import SubSurveysTable from "../SubSurveysTable/SubSurveysTableComponent"
import './SubSurveyHomeComponent.scss';

class SubSurveyHome extends Component{

	constructor (props) {
		super(props);
			this.state = {}		
	}
	
    render() {
        return (
            <IntroPage headerText={"Sub-Surveys"}>       
				<Card>
					<SubSurveysTable subSurveys={this.props.subSurveys}/>					
				</Card>         
            </IntroPage>
        )
    }
}

export default SubSurveyHome;