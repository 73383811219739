import React, { Component } from 'react';
import { Label } from 'app/components/Basics';
import { Card } from '@ecosystem/ui-lib/core/components/Card';
import {UserFormContext} from '../../UserContext'
import MultiSelect from 'app/components/MultiSelect'
import RolesToSelectBox from '../../helpers/RolesToSelectBox'
import SelectOptionsToRoles from '../../helpers/SelectOptionsToRoles'

import './TabRoleComponent.scss';

class TabRole extends Component {
    constructor(props){
        super(props);
        this.state = {
        }    
    }

	getSelectedOptionsMultiSelect( ) {
		return RolesToSelectBox(this.context.selectedUser.roles);
	}

	onChangeRole(items)  {
		var selectedRoles = SelectOptionsToRoles(items);
		var selectedUser = this.context.selectedUser;
		selectedUser.roles = [];
		selectedUser.roles = selectedRoles;
		this.context.updateSelectedUser(selectedUser);       
	}

	onRemoveRole(item)  {		
		var selectedUser = this.context.selectedUser;		
		selectedUser.roles = selectedUser.roles.filter(function(p) { return p.id !== parseInt(item.value) });
		this.context.updateSelectedUser(selectedUser);
	}

    render() {
        return (
            <div className="TabRoleComponent content-box">
                <Card>
					<div className="form-row">
						<div className="form-row-left">
							<div className='role-selection'>
								<Label>Roles: { this.context.selectedUser.isSysAdmin === 1 && (<span className='admin-user-msg'>({this.context.selectedUser.name} user must have all roles)</span>)}</Label>
								<MultiSelect key={this.context.selectedUser.id} isDisabled={!this.context.canChangeData || this.context.selectedUser.isSysAdmin}
									selectedOptions={this.getSelectedOptionsMultiSelect()} 
									onChange={this.onChangeRole.bind(this)} 
									onRemove={this.onRemoveRole.bind(this)} 
									options={this.props.metadata.roles}>									
								</MultiSelect>
							</div>
						</div>						
                	</div>
                </Card>
            </div>			
        );
    }
}
TabRole.contextType = UserFormContext;
export default TabRole;