function NewAdHocSurvey(defaultWeightingMethodCode) {
	return {
		id: null,
		name: "New Question Set",
		startDate: null,
		endDate: null,
		folderId: null,
		questions: [],
		enabled: 0,
		adHocSurveyTypeId: null,
		weightingMethod: defaultWeightingMethodCode,
		deliveryMethod: 0,
		uploadedSchemaFileName: null,
		schemaFile: null
	}
}
export default NewAdHocSurvey;