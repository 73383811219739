import React, { Component } from 'react';
import {Button} from 'app/components/Basics/'
import { Table, GenericCell } from '@ecosystem/ui-lib/core/components/Table';
import './TemplateFormComponent.scss';
import { DateTime } from "luxon";
import {Label, DatePickerCalendar, StatusBadge, Switch} from 'app/components/Basics/';
import { Select } from '@ecosystem/ui-lib/core/components/Select';
import { Checkbox } from '@ecosystem/ui-lib/core/components/Checkbox';
import { Input as EcoInput } from '@ecosystem/ui-lib/core/components/Input';
import {NqTemplateContext} from '../../NqTemplateContext'

class TemplateForm extends Component {

    constructor(props){
        super(props);
        this.state = {
        }        
    }

	setStartDate(date) {		
		var template = this.props.template;
		template.startDate = date;		
		this.context.updateSelectedNqTemplate(template);
	}

	enabledSwitchHandler() {
		var template = this.props.template;
		template.enabled = this.props.template.enabled === 1 ? 0 : 1;
		this.context.updateSelectedNqTemplate(template);
	}

	templateNameHandler = event => {		
		var template = this.props.template;
		template.name = event.target.value;
		if (template.name && template.name.length > 50) {
			template.name = template.name.substring(0, 49);
		}
		this.context.updateSelectedNqTemplate(template);
    }

	getSurveyPhaseOption() {		
        return this.props.metadata.surveyPhases
			.find(t=> parseInt(t.value) === this.props.template.surveyPhaseId);
    }

	surveyPhaseHandler(selectedItem) {
		var template = this.props.template;
		template.surveyPhaseId = parseInt(selectedItem.value);
		this.context.updateSelectedNqTemplate(template);
    }

	getSurveyTypeOption() {		
        return this.props.metadata.surveyTypes
			.find(t=> t.value && parseInt(t.value) === this.props.template.surveyTypeId);
    }

	surveyTypeHandler(selectedItem) {
		var template = this.props.template;
		template.surveyTypeId = selectedItem.value ? parseInt(selectedItem.value) : null;
		this.context.updateSelectedNqTemplate(template);
    }

	templateIdHandler = event => {		
		var template = this.props.template;
		template.nqTemplateId = parseInt(event.target.value);
		this.context.updateSelectedNqTemplate(template);
    }

	templateVersionHandler = event => {		
		var template = this.props.template;
		template.version = parseInt(event.target.value);
		this.context.updateSelectedNqTemplate(template);
    }

	templatRemarksHandler = event => {		
		var template = this.props.template;
		template.remarks = event.target.value;		
		this.context.updateSelectedNqTemplate(template);
    }

	
    render() {
		return (
            <div className='AppFormComponent'>
				<div className="TemplateFormComponent form-row">
					<div className="form-row-left">
						<div className="field-nq-template-name">
							<Label tooltip="">Name</Label>
							<EcoInput disabled={!this.context.canChangeData || this.props.editExistingTemplate === true}
								value={this.props.template.name} 
								onChange={this.templateNameHandler}
								key={`nq-template-name_${this.props.template.dateLastModified}`}
								id="nq-template-name"
								>							
							</EcoInput>						
						</div>
						<div className="field-date-from">
							<Label>Date From</Label>
							<DatePickerCalendar disabled={!this.context.canChangeData} value={this.props.template.startDate} onChange={this.setStartDate.bind(this)} />
						</div>						
						<div className="field-enabled">
							<Label>Enabled</Label>
							<div className="default-height centered"><Switch disabled={!this.context.canChangeData} name="enabled" setSwitchValue={this.enabledSwitchHandler.bind(this)} checked={this.props.template.enabled === 1}/></div>
						</div>
					</div>
				</div>
				<div className="TemplateFormComponent form-row">
					<div className="form-row-left">
						<div className="field-nq-template-id">
							<Label tooltip="">Template Id</Label>
							<EcoInput disabled={!this.context.canChangeData}		
								type='Number'						
								value={this.props.template.nqTemplateId} 
								onChange={this.templateIdHandler}
								key={`nq-template-id_${this.props.template.dateLastModified}`}
								id="nq-template-id"
								>							
							</EcoInput>						
						</div>
						<div className="field-nq-template-version">
							<Label tooltip="">Version</Label>
							<EcoInput disabled={!this.context.canChangeData}		
								type='Number'						
								value={this.props.template.version} 
								onChange={this.templateVersionHandler}
								key={`nq-template-version${this.props.template.dateLastModified}`}
								id="nq-template-version"
								>							
							</EcoInput>						
						</div>
						<div className="field-survey-phase">
							<Label>Survey Phase</Label>
							<Select isDisabled={!this.context.canChangeData} name="surveyPhase" isSearchable={false}
								styles={{ option: styles => ({ minHeight: 40, ...styles }) }}                            
								onChange={val=>{
									this.surveyPhaseHandler(val)                                
								}}							
								options={this.props.metadata.surveyPhases}
								value={this.getSurveyPhaseOption()}>							
							</Select>
						</div>	
						<div className="field-survey-type">
							<Label>Survey Type</Label>
							<Select name="surveyType" isSearchable={false}
								styles={{ option: styles => ({ minHeight: 40, ...styles }) }}
								isDisabled={!this.context.canChangeData || this.props.template.surveyPhaseId !== 40}
								onChange={val=>{
									this.surveyTypeHandler(val)                                
								}}							
								options={this.props.metadata.surveyTypes}
								value={this.getSurveyTypeOption()}>							
							</Select>
						</div>	

					</div>
				</div>
				<div className="TemplateFormComponent form-row">
					<div className="form-row-left">
						<div className="field-nq-template-remarks">
							<Label tooltip="">Remarks</Label>
							<EcoInput disabled={!this.context.canChangeData}								
								value={this.props.template.remarks} 
								onChange={this.templatRemarksHandler}
								key={`nq-template-remarks_${this.props.template.dateLastModified}`}
								id="nq-template-remarks"
								>							
							</EcoInput>						
						</div>
					</div>
				</div>
					
				
				
			</div>
        );
    }
}
TemplateForm.contextType = NqTemplateContext;
export default TemplateForm;