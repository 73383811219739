import React, { Component } from 'react';
import {Link} from "react-router-dom";
import './TabBarComponent.scss';
import { ReactComponent as ErrorIcon } from 'app/assets/icons/Warning.svg';
import DropdownSubMenu from './DropdownSubMenu';
import { ReactComponent as ChevronDown } from 'app/assets/icons/chevron_down.svg';

const defaultTabProps = {
    id: false,
    label: "unnamed",
    url: false,
    disabled: false,
    error: false,
    className:null,
    onClick: (event, item)=>true,
    onActivate: (item)=>true,
    onInactivate: (item)=>true
}

class TabBar extends Component {
    static defaultProps = {
        tabs:[],
        activeTab: null,
        onTabClick: (event, item)=>true,
        onTabActivate: (item)=>true,
        onTabInactivate: (item)=>true
    }    

    constructor(props){
        super(props);
        this.onTabClickHandler = this.onTabClickHandler.bind(this);
		this.state = {
			dropdownOpened: false
		}
    }

    onTabClickHandler(event,item){
        if(this.props.onTabClick(event,item) !== false){
            if(item.onClick(event, item) !== false){
                if(this.props.activeTab!==(item.url)){
                    var activeItem = this.props.tabs.find(e => e.url === this.props.activeTab);
                    if(activeItem){
                        activeItem = {...defaultTabProps, ...activeItem};
                        this.props.onTabInactivate(activeItem);
                        activeItem.onInactivate(activeItem);
                    }
                    this.setState({activeTab: item.url});
                    this.props.onTabActivate(item);
                    item.onActivate(item);
                }
            }
        }
    }

	setDropdownSubMenu(value) {		
		this.setState({dropdownOpened: value})
	}

	onMouseEnter = (subMenuId) => {
		this.setDropdownSubMenu(subMenuId);
	};
	
	onMouseLeave = () => {
		this.setDropdownSubMenu(null);
	};

    render() {
        var renderTab = (item) => {
            if(React.isValidElement(item)){
                return (item);
            }else{
                item = {...defaultTabProps, ...item};
                if(item.disabled)
                    return null;
                
                var isActive = this.props.activeTab === item.url ? 'active' : '';
                var isError = item.error ? "error" : null;
                var errorIcon = item.error ? <ErrorIcon title="Please check the tab for warnings and errors."/> : null;

                return (
                    <li 
						onMouseEnter={item.subMenu && this.onMouseEnter.bind(this, item.id)}
						onMouseLeave={item.subMenu && this.onMouseLeave.bind(this)}
                        key={item.url} 
                        onClick={(e) => {this.onTabClickHandler(e, item)}}
                        className={`tab-item ${item.className} ${isActive} ${isError}`}>
							{item.subMenu ? (
								<>
									<div className="tab-link" aria-haspopup="menu"
           	 								aria-expanded={this.state.dropdownOpened ? 'true' : 'false'}>
										<div className="dropdown-parent">
											<div>{item.label}<ChevronDown className='submenu-down-arrow'/>{' '}</div>											
											<DropdownSubMenu subMenuId={item.id} submenus={item.subMenu} dropdownOpened={this.state.dropdownOpened}/>
										</div>
									</div>
								</>
							) : (
								<Link className="tab-link" to={{pathname: item.url}}>{item.label}{errorIcon}</Link>
							)}                        
                    </li>
                );
            }
        };
     
        const tabs = this.props.tabs.map((item) => renderTab(item));

        return (
            <ul className={`TabBarComponent ${this.props.className}`}>
                {tabs}
            </ul>
        );
    }
}

export default TabBar;