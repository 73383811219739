import { InlineAlertType } from '@ecosystem/ui-lib/core/components/InlineAlert';
import { ToastMessage } from '@ecosystem/ui-lib/core/components/ToastMessage';
import { useState, useEffect } from 'react';

const ShowError = (props) => {  
    const [errorMessage, setErrorMessage] = useState(props.errorMessage);

    useEffect(() => {        
        setErrorMessage(props.errorMessage)
    }, [props.errorMessage]);

    return (
    <ToastMessage type={InlineAlertType.DataError} 
        message={errorMessage} 
        onDismiss={() => props.clearErrorMessage()}
        timeout={10000} />    
    )
};

export default ShowError;


