import React, { Component } from 'react';
import 'app/components/SideBar/SideBarComponent.scss';
import './ConditionComponent.scss';
import { MemoryRouter,Switch,Route,Redirect} from "react-router-dom";
import {Label, Switch as SwitchCheck} from 'app/components/Basics/'
import {TabBar} from 'app/components/Basics';
import {AdHocSurveyFormContext} from '../../AdHocSurveyContext'
import TabQuestionConditions from './TabQuestionConditionsComponent';
import TabOptionConditions from './TabOptionConditionsComponent';

class Condition extends Component {
    constructor(props){
        super(props);

        this.state = {
			loading: false,
            search: "",
			allData: [],
			filteredData: [],
			typingTimeout: 0,  
			activeTab: 'questions'
        }
    }	

	getTabs(){        
        return [
            { id:"questions", label: 'Question', url: 'questions', disabled:false, error:false},
            { id:"options", label: 'Options', url: 'options', disabled:this.props.questionConditions.question.questionType==="OPEN"}
        ];
    }

	enabledSwitchHandler() {		
		var selectedAdHocSurvey = this.context.selectedAdHocSurvey;		
		let surveyQuestion = selectedAdHocSurvey.questions.find(q=>q.question.id === this.props.questionConditions.question.id);
		surveyQuestion.enabled = this.props.questionConditions.enabled === 1 ? 0 : 1;
		this.context.updateSelectedAdHocSurvey(selectedAdHocSurvey);		
	}
    
    render() {		
        return (
            <div className="ConditionComponent content-box">
				<div className='form-row condition-header'>
					<h4>
						Condition for question <span className='orange'>{this.props.questionConditions.question.code}</span>					
					</h4>
				</div>	
				<div className='form-row'>
					<hr className='line-header'></hr>
				</div>
				<div className='form-row'>	
					<div className="condition-enabled">
                        <Label>Enabled</Label>
                        <div className="condition-enabled-switch"><SwitchCheck disabled={!this.props.canChangeData} name="enabled" checked={this.props.questionConditions.enabled === 1} setSwitchValue={this.enabledSwitchHandler.bind(this)} /></div>
                    </div>
				</div>

				<div className="form-sections">
					<MemoryRouter>
						<div className="tab-bar">
							<TabBar key="QuestionForm" activeTab={this.state.activeTab} tabs={this.getTabs()} onTabActivate={item=>this.setState({activeTab:item.id})}/>							
						</div>
						<div className="section-content">
							<Switch>
								<Route exact path="/"><Redirect to={this.state.activeTab}/></Route>
								<Route exact path="/questions"><TabQuestionConditions canChangeData={this.props.canChangeData} question={this.props.questionConditions.question} conditionsDefinition={this.props.questionConditions.questionConditionsDefinition} errors={this.state.errors} /></Route>
								<Route exact path="/options"><TabOptionConditions canChangeData={this.props.canChangeData} question={this.props.questionConditions.question} conditionsDefinitions={this.props.questionConditions.answerOptionsConditionsDefinitions} errors={this.state.errors} /></Route>
							</Switch>
							<Redirect to={this.state.activeTab}/>
						</div>
					</MemoryRouter>
				</div>                
            </div>
        );
    }
}

Condition.contextType = AdHocSurveyFormContext;
export default Condition;