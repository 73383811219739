function NewNqTemplate() {
    return {
		name: "",
		nqTemplateId: null,
		Version: 1,
		surveyPhaseId: null,
		surveyTypeId: null,
		startDate: null,
		enabled: 0,		
		Remarks: null		
      }
}
export default NewNqTemplate;
