import React, { Component } from 'react';
import {Button} from 'app/components/Basics/'
import './modal.scss';

class Modal extends Component {
    constructor(props){
        super(props);

        this.state = {
			confirmButtonLabel: props.confirmButtonLabel ? props.confirmButtonLabel : "Confirm",
			cancelButtonLabel: props.cancelButtonLabel ? props.cancelButtonLabel : "Cancel",
        }        
    }

    render() {
        return (
            <div className="modal">				
                <div className={`modal-content ${this.props.size}`}>
                    <div>{this.props.content}</div>
                    <div className="contol-buttons">                        
                        {!this.props.hideConfirmButton && (<Button disabled={this.props.disableConfirmButton} small onClick={this.props.confirmModalHandler}>{this.state.confirmButtonLabel}</Button>)}
                        <Button disabled={this.props.disableCancelButton} small onClick={this.props.cancelModalHandler}>{this.state.cancelButtonLabel}</Button>
                    </div>
                </div>
            </div>
        );
    }
}
export default Modal;