import React, { Component } from 'react';
import { Label, Button, ButtonGroup } from 'app/components/Basics';
import { Select } from '@ecosystem/ui-lib/core/components/Select';
import { Card } from '@ecosystem/ui-lib/core/components/Card';
import { ReactComponent as ButtonIcon } from 'app/assets/icons/button.svg';
import { ReactComponent as RadioIcon } from 'app/assets/icons/radiobutton.svg';
import { ReactComponent as CheckboxIcon } from 'app/assets/icons/checkbox.svg';
import { ReactComponent as ButtonsIcon } from 'app/assets/icons/buttons.svg';
import { ReactComponent as RadiosIcon } from 'app/assets/icons/radios.svg';
import { ReactComponent as StarsIcon } from 'app/assets/icons/stars.svg';
import { ReactComponent as SliderIcon } from 'app/assets/icons/slider.svg';
import { ReactComponent as CircleSliderIcon } from 'app/assets/icons/half-circle.svg';
import { ReactComponent as TextLineIcon } from 'app/assets/icons/text-line.svg';
import { Input as EcoInput } from '@ecosystem/ui-lib/core/components/Input';
import './TabPresentationComponent.scss';
import {QuestionFormContext} from '../../QuestionContext'
import { questionInputLayoutTypes } from '../../../../constants/question-input-layout-types'

class TabPresentationSelect extends Component {    

    constructor(props){
        super(props);        
    }

    onClickAppearence(buttonType) {        
        let selectedQuestion = this.context.selectedQuestion;
        selectedQuestion.layoutType = parseInt(buttonType);
        this.context.updateSelectedQuestion(selectedQuestion);
    }

	isDefaultButtonDisabled() {
		if (!this.context.canChangeData) {
			 return true;
		}
		else {
			return this.context.selectedQuestion.questionType==="SSOPEN" || this.context.selectedQuestion.questionType==="MSOPEN";
		}
	}

    render() {
        return (
            <div className="TabPresentationComponent TabPresentationComponentSelect content-box">
                <Card>
                    <div className="form-row">
                        <div className="form-row-left">
                            <div className="button-presentation-appearance">
                                <Label tooltip="Here you can define how the question should be presented to the respondent.">Appearance</Label>
								<ButtonGroup>
                                    <Button disabled={this.isDefaultButtonDisabled()} selected={this.context.selectedQuestion.layoutType === questionInputLayoutTypes.Button} onClick={this.onClickAppearence.bind(this, questionInputLayoutTypes.Button)} key="appearance-button" defaultActive icon={<ButtonIcon/>}>Button</Button>
                                    {this.context.selectedQuestion.questionType==="SS" && <Button disabled={!this.context.canChangeData} selected={this.context.selectedQuestion.layoutType === questionInputLayoutTypes.DefaultInputType} onClick={this.onClickAppearence.bind(this, questionInputLayoutTypes.DefaultInputType)} key="appearance-raio" icon={<RadioIcon/>}>Radio Button</Button>}
									{this.context.selectedQuestion.questionType==="SS" && <Button disabled={!this.context.canChangeData} selected={this.context.selectedQuestion.layoutType === questionInputLayoutTypes.HorizontalSlider} onClick={this.onClickAppearence.bind(this, questionInputLayoutTypes.HorizontalSlider)} key="appearance-slider" icon={<SliderIcon/>}>Horizontal slider</Button>}
									{this.context.selectedQuestion.questionType==="MS" && <Button disabled={!this.context.canChangeData} selected={this.context.selectedQuestion.layoutType === questionInputLayoutTypes.DefaultInputType} onClick={this.onClickAppearence.bind(this, questionInputLayoutTypes.DefaultInputType)} key="appearance-checkbox" icon={<CheckboxIcon/>}>Checkbox</Button>}
								</ButtonGroup>
                            </div>
                            <div className="field-presentation-orientation">
                                {/* <Label tooltip={(<div>Description of <br/>Orientation</div>)}>Orientation</Label>
                                <Select name="qtype" isSearchable={false}
                                    defaultValue={{value:"VERTICAL", label: "Vertical"}}
                                    options={[
                                        {value:"VERTICAL", label: "Vertical"},
                                        {value:"FLOAT", label: "Float"}
                                    ]}/> */}
                            </div>
                        </div>
                    </div>
                    
                </Card>
            </div>
        );
    }
}

class TabPresentationNumeric extends Component {    

    constructor(props){
        super(props);        
    }

    onClickAppearence(buttonType) {        
        let selectedQuestion = this.context.selectedQuestion;
        selectedQuestion.layoutType = parseInt(buttonType);
        this.context.updateSelectedQuestion(selectedQuestion);
    }

	scaleMinLabelHandler = event => {		
        var selectedQuestion = this.context.selectedQuestion;
        selectedQuestion.scaleMinLabel = event.target.value;
        this.context.updateSelectedQuestion(selectedQuestion);
    }

	scaleMaxLabelHandler = event => {		
        var selectedQuestion = this.context.selectedQuestion;
        selectedQuestion.scaleMaxLabel = event.target.value;
        this.context.updateSelectedQuestion(selectedQuestion);
    }

	getValidationError(field) { 		
		if (!this.props.errors || this.props.errors.length === 0) {			
			return "";
		}		
		var errorText = this.props.errors.find(e => e.field === field);		
		return errorText ? errorText.errorMessage : "";
	}

    render() {
        return (
            <div className="TabPresentationComponent TabPresentationComponentSelect content-box">
                <Card>
                    <div className="form-row">
                        <div className="form-row-left">
                            <div className="button-presentation-appearance">
                                <Label tooltip="Here you can define how the question should be presented to the respondent.">Appearance</Label>
                                <ButtonGroup>                                    
										<Button selected={this.context.selectedQuestion.layoutType === questionInputLayoutTypes.DefaultInputType} onClick={this.onClickAppearence.bind(this, questionInputLayoutTypes.DefaultInputType)} key="appearance-checkbox" icon={<TextLineIcon/>}>Number Input</Button>
										<Button className='circleSliderButton'selected={this.context.selectedQuestion.layoutType === questionInputLayoutTypes.CircularSlider} onClick={this.onClickAppearence.bind(this, questionInputLayoutTypes.CircularSlider)} key="appearance-checkbox" icon={<CircleSliderIcon/>}>Circular slider</Button>
                                </ButtonGroup>
                            </div>
                            {this.context.selectedQuestion.layoutType === questionInputLayoutTypes.CircularSlider && (<>
							<div className={`field-presentation-scale-min-text ${this.getValidationError("scaleMinLabel") ? "error" : ""}`}>								
								<Label tooltip="">Slider Min Label</Label>								
								<EcoInput id="minLabel" name="minLabel" value={this.context.selectedQuestion.scaleMinLabel} onChange={this.scaleMinLabelHandler.bind(this)}></EcoInput>								
								<span className={`error-container`}>{this.getValidationError("scaleMinLabel")}</span>                            	
                            </div>
							<div className={`field-presentation-scale-max-text ${this.getValidationError("scaleMaxLabel") ? "error" : ""}`}>								
								<Label tooltip="">Slider Max Label</Label>								
								<EcoInput id="maxLabel" name="maxLabel" value={this.context.selectedQuestion.scaleMaxLabel} onChange={this.scaleMaxLabelHandler.bind(this)}></EcoInput>								
								<span className={`error-container`}>{this.getValidationError("scaleMaxLabel")}</span>                            	
                            </div></>)}
                        </div>
                    </div>
                    
                </Card>
            </div>
        );
    }
}


class TabPresentationRating extends Component {
    

    constructor(props){
        super(props);

        this.state = {
            appearanceSetting:"BULLETS"
        }
    }

    render() {

        return (
            <div className="TabPresentationComponent TabPresentationComponentRating content-box">
                <Card>
                    <div className="form-row">
                        <div className="form-row-left">
                            <div className="button-presentation-appearance">
                                <Label tooltip="Here you can define how the question should be presented to the respondent.">Appearance</Label>
                                <ButtonGroup>
                                    <Button key="appearance-bullets" onActivate={()=>{this.setState({appearanceSetting:"BULLETS"})}} defaultActive={this.state.appearanceSetting==="BULLETS"} icon={<RadiosIcon/>}>Bullets</Button>
                                    <Button key="appearance-stars" onActivate={()=>{this.setState({appearanceSetting:"STARS"})}} defaultActive={this.state.appearanceSetting==="STARS"} icon={<StarsIcon/>}>Stars</Button>
                                    <Button key="appearance-buttons" onActivate={()=>{this.setState({appearanceSetting:"BUTTONS"})}} defaultActive={this.state.appearanceSetting==="BUTTONS"} icon={<ButtonsIcon/>}>Buttons</Button>
                                    <Button key="appearance-slider" onActivate={()=>{this.setState({appearanceSetting:"SLIDER"})}} defaultActive={this.state.appearanceSetting==="SLIDER"} icon={<SliderIcon/>}>Slider</Button>   
                                </ButtonGroup>
                            </div>
                            <div className="field-presentation-orientation">
                                <Label tooltip={(<div>Description of <br/>Orientation</div>)}>Orientation</Label>
                                <Select name="qtype" isSearchable={false} isDisabled={this.state.appearanceSetting!="SLIDER"}
                                    defaultValue={{value:"HORIZONTAL", label: "Horizontal"}}
                                    options={[
                                        {value:"HORIZONTAL", label: "Horizontal"},
                                        {value:"VERTICAL", label: "Vertical"},
                                        {value:"CIRCULAR", label: "Circular"}
                                    ]}/>
                            </div>
                        </div>
                    </div>
                    
                </Card>
            </div>
        );
    }
}

class TabPresentationInput extends Component {
    

    constructor(props){
        super(props);
    }

    render() {

        return (
            <div className="TabPresentationComponent TabPresentationComponentInput content-box">
                <Card>
                    <div className="form-row">
                        <div className="form-row-left">
                            <div className="field-presentation-numberfield">
                                <Label>Number Field</Label>
                                <Select name="qtype" isSearchable={false}
                                    defaultValue={{value:"INPUT", label: "Input Field"}}
                                    options={[
                                        {value:"INPUT", label: "Input Field"},
                                        {value:"UPDOWN", label: "Input with Up/Down"},
                                        {value:"SELECT", label: "Dropdown List"}
                                    ]}/>
                            </div>
                        </div>
                    </div>
                    
                </Card>
            </div>
        );
    }
}
TabPresentationSelect.contextType = QuestionFormContext;
TabPresentationNumeric.contextType = QuestionFormContext;
export default TabPresentationSelect;
export {TabPresentationSelect, TabPresentationRating, TabPresentationInput, TabPresentationNumeric};