import './app.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import {AppHeader, AppTitle, Navigation} from "app/components/AppHeader"
import AppContainer from "app/components/AppContainer/AppContainerComponent"
import Homepage from "./pages/homepage";
import Question from "./pages/question";
import Login from './pages/login';
import ChangePassword from './pages/changepassword';
import InDepth from './pages/indepth';
import AdHocSurvey from './pages/adhocsurvey';
import Nqtemplate from './pages/admin/nqtemplate';
import Role from './pages/admin/role';
import User from './pages/admin/user';
import SubSurvey from './pages/admin/subsurvey';
import SurveyHistory from './pages/surveyhistory';
import Region from './pages/admin/region';
import Channel from './pages/admin/channel';
import DemoSet from './pages/admin/demoset';
import ResponseDownload from './pages/responsedownload';
import ReportDownload from './pages/reportdownload';
import MediaType from './pages/admin/mediatype';
import {adHocSurveyTypes} from './constants/adhoc-survey-types'
import { useState } from "react";
import AuthService from "./services/auth.service";

function App() {
	const [panelConfig, setPanelConfig] = useState();
	const [userPermissions, setUserPermissions] = useState();	

	function getPanelConfig(panelConfig) {		
		setPanelConfig(panelConfig);
	}

	function getUserPermissions(userPermissions) {
		setUserPermissions(userPermissions);
	}

	function loggedToChangePassword() {
		let user = AuthService.getCurrentUser();	
		return user?.resetPassword === true;		
	}

	function isModuleEnabled(module, userModulePermission) {
		return panelConfig?.modules.includes(module) && userModulePermission && !loggedToChangePassword();
	}

	function isInDepthAskAlwaysEnabled() {
		return panelConfig?.enabledAskAlways;
	}

	return (
		<div className="App">
			<Router>      
				<Switch>
				<Route exact path="/login" component={Login} />
				<Route exact path="/" component={Login} />				
				<AppContainer getPanelConfig={getPanelConfig} getUserPermissions={getUserPermissions}>
					<AppHeader>
						<AppTitle panelConfig={panelConfig}/>
						<Navigation selectedItem={window.location.pathname} panelConfig={panelConfig} userPermissions={userPermissions} disableNavigation={loggedToChangePassword()} />
					</AppHeader>         
					<Route exact path="/question">
						{isModuleEnabled("question", userPermissions?.CanReadQuestion) && <Question userPermissions={userPermissions}/> }
					</Route>
					<Route path="/indepth/:surveyTest">
						{isModuleEnabled("indepth", userPermissions?.CanReadIndepth) && <InDepth isSurveyTest={true} userPermissions={userPermissions}/> }
					</Route>
					<Route exact path="/indepth">
						{isModuleEnabled("indepth", userPermissions?.CanReadIndepth) && <InDepth userPermissions={userPermissions} isAskAlwaysEnabled={isInDepthAskAlwaysEnabled()} /> }
					</Route>
					<Route exact path="/generics">
						{isModuleEnabled("generics", userPermissions?.CanReadGenerics) && <AdHocSurvey surveyType={adHocSurveyTypes.Generics} userPermissions={userPermissions}/>}
					</Route>
					<Route exact path="/fiveminex">
						{isModuleEnabled("fiveminex", userPermissions?.CanReadFiveMinex) && <AdHocSurvey surveyType={adHocSurveyTypes.FiveMinex} userPermissions={userPermissions}/>}
					</Route>
					<Route exact path="/nqtemplate">
						{isModuleEnabled("nqtemplate", userPermissions?.CanReadNqTemplate) && <Nqtemplate userPermissions={userPermissions}/>}
					</Route>
					<Route exact path="/role">
						{isModuleEnabled("role", userPermissions?.CanReadRole) && <Role userPermissions={userPermissions}/>}
					</Route>
					<Route exact path="/user">
						{isModuleEnabled("user", userPermissions?.CanReadUser) && <User userPermissions={userPermissions}/>}
					</Route>
					<Route exact path="/subsurvey">
						{isModuleEnabled("subsurvey", userPermissions?.CanReadSubSurvey) && <SubSurvey userPermissions={userPermissions}/>}
					</Route>
					<Route exact path="/surveyhistory">
						{isModuleEnabled("surveyhistory", userPermissions?.CanDownloadCodeBook) && <SurveyHistory userPermissions={userPermissions}/>}
					</Route>
					<Route exact path="/region">
						{isModuleEnabled("region", userPermissions?.CanReadRegion) && <Region userPermissions={userPermissions}/>}
					</Route>
					<Route exact path="/channel">
						{isModuleEnabled("channel", userPermissions?.CanReadChannel) && <Channel userPermissions={userPermissions}/>}
					</Route>
					<Route exact path="/demoset">
						{isModuleEnabled("demoset", userPermissions?.CanChangeDemoQuestion) && <DemoSet userPermissions={userPermissions}/>}
					</Route>
					<Route exact path="/responsedownload">
						{isModuleEnabled("responsedownload", userPermissions?.CanReadRespondentResponse) && <ResponseDownload userPermissions={userPermissions}/>}
					</Route>
					<Route exact path="/reportdownload">
						{isModuleEnabled("reportdownload", userPermissions?.CanReadReportDownload) && <ReportDownload userPermissions={userPermissions}/>}
					</Route>
					<Route exact path="/mediatype">
						{isModuleEnabled("mediatype", userPermissions?.CanReadMediaType) && <MediaType userPermissions={userPermissions}/>}
					</Route>
					<Route exact path="/changepassword">
						{loggedToChangePassword() && <ChangePassword />}
					</Route>
					<Route exact path="/homepage">
					<Homepage />
					</Route>
				</AppContainer>
				</Switch>
			</Router>     
		</div>
	);
}

export default App;