import React, { Component } from 'react';
import {Spacer, Button, InputInline} from 'app/components/Basics/'
import { Icons } from '@ecosystem/ui-lib/core/components/Icon';
import './GlobalControlLineComponent.scss';
import { ReactComponent as SaveIcon } from 'app/assets/icons/Save.svg';
import { ReactComponent as DuplicateIcon } from 'app/assets/icons/Duplicate-Plus.svg';
import {UserFormContext} from '../../UserContext'
import ValidateUser from '../../../user/UserValidation'
 
class GlobalControlLine extends Component {

    constructor(props){
        super(props);

        this.state = {           
        }        
    }

    componentWillReceiveProps(nextProps) {        
        if (nextProps && nextProps.errors) {            
            var error = nextProps.errors.find(e => e.field === "userName")
            if (error) {
                this.setState({errorUserName: error.errorMessage})
            }
        }
    }

	isSaveDisabled() {
		if (this.isChangeDisabled())
		{
			return true;
		}
		let result = false;
		var errors = ValidateUser(this.context.selectedUser);		
		if (errors.length > 0) {
			result = true;
		}		
		if (!this.props.didSelectedUserChange()) {
			result = true;
		}
		return result;
	}

	isButtonDisabled() {	
		if (this.isChangeDisabled())
		{
			return true;
		}
		return this.context.selectedUser.id === null;
	}

	getUserName() {		
        return this.context.selectedUser.userName !== '' ? 
            this.context.selectedUser.userName : "New User";
    }

	userNameChange(isEditorOpen, newName) {
        if (!isEditorOpen) {
            var selectedUser = this.context.selectedUser;
            selectedUser.userName = newName;
            this.context.updateSelectedUser(selectedUser);
        }
    }

	isChangeDisabled() {
		return !this.context.canChangeData;
	}

    render() {		
        return (            
				<div className="GlobalControlLineComponent form-row">        
					<InputInline disabled={this.isChangeDisabled()} enableOnBlur className="name-field" value={this.getUserName()} onModeChange={this.userNameChange.bind(this)} />
					<div className="contol-buttons">
						<Button disabled={this.isSaveDisabled()} title="Save all changes in the user" icon={<SaveIcon/>} onClick={this.props.handleSubmitAction}>Save</Button>                    
						<Button title="Close current editor and discard all unsaved changes" onClick={this.props.closeEditorButtonOnClick} simple icon={Icons.Close}>Close</Button>
						<Spacer width={20}/>                   
						<Button disabled={this.isButtonDisabled()} title="Delete the user is irreversible" small secondary icon={Icons.Delete} onClick={this.props.deleteUser}>Delete</Button>
						<Button disabled={this.isButtonDisabled()} title="Reset user password" small secondary icon={Icons.Lock} onClick={this.props.resetUserPassword}>Reset Password</Button>
						<Button disabled={this.isButtonDisabled()} title="Create a new user as duplicate from this user" small secondary icon={<DuplicateIcon/>} onClick={this.props.duplicateUser}>Duplicate</Button>						
					</div>                
				</div>
        );
    }
}
GlobalControlLine.contextType = UserFormContext;
export default GlobalControlLine;