import React, { Component } from 'react';
import { ButtonIcon } from '@ecosystem/ui-lib/core/components/ButtonIcon';
import { MemoryRouter,Switch,Route,Redirect,withRouter} from "react-router-dom";
import {TabBar} from 'app/components/Basics';
import { ReactComponent as DuplicateIcon } from 'app/assets/icons/Duplicate.svg';
import GlobalControlLine from './components/GlobalControlLine/GlobalControlLineComponent';
import {UserFormContext} from './UserContext'
import UserService from "../../../services/user.service";
import  GetErrorMessage  from '../../../helpers/error-message'
import {ShowError, ShowSuccess} from "../../../components/toast"
import NewUser from './UserModel';
import Modal from '../../../components/Modal';
import cloneDeep from 'lodash/cloneDeep';
import TabRole from './components/TabRole/TabRoleComponent';
import isEqual from 'lodash/isEqual';
import ValidateUser from './UserValidation'
import GeneratePassword from 'app/helpers/password-generator';

class UserForm extends Component {
    tabs = [];
    constructor(props){
        super(props);
        this.state = {           
            activeTab:'roles',
            selectedUser: NewUser(),
            errors: []           
        }        
    }    

	getTabs(){        
        return [
            { id:"roles", label: 'Roles', url: 'roles', disabled:false, error:false}
        ];
    }
	
	componentWillReceiveProps(nextProps) {
		if (nextProps.selectedUser != null) {
			this.setState({ 
				selectedUser: nextProps.selectedUser,
				activeTab: "roles"
			});
		}	

        if (!isEqual(this.props.selectedUser, nextProps.selectedUser)) {
            this.setState({errors: []});            
        }
    }	

    updateSelectedUser(newSelectedUser) {
		this.setState({selectedUser: newSelectedUser})
    }

    addErrorMessage(field, message) {
        var errors = this.state.errors;
        errors.push({ field: field, errorMessage: message});
        this.setState({errors: errors})
    }	

    SaveUser(user) {                
        this.props.toggleUiBlock(true);
        if (user.id) {
            UserService.updateUser(user)
                .then(response =>{                    
                    this.props.toggleUiBlock(false);
                    this.setState({successMessage: 'The user was saved successfully' });                    
					this.props.getUserById(user.id);
                })
                .catch(error => {
					let errorMessage = GetErrorMessage(error);
					console.log("errorMessage on catch");
					console.log(errorMessage);
					this.props.toggleUiBlock(false);
					this.setState({ errorMessage: errorMessage});
                });
        }
        else {
            UserService.createUser(user)
                .then(response => {					
                    const newUser = response.data;                    
                    this.props.toggleUiBlock(false);
                    this.setState({successMessage: 'The user was created successfully' });  					
                    this.setState({selectedUser: newUser});					
					this.props.getUserById(newUser.id);
					this.showNewUserPassword(user.userPassword);
                })
                .catch(error => {
					let errorMessage = GetErrorMessage(error);
					console.log("errorMessage on catch");
					console.log(errorMessage);
					this.props.toggleUiBlock(false);
					this.setState({ errorMessage: errorMessage});
                });
        }

    }

	resetUserPassword = event => {		
        if (this.props.canChangeData) {    
        	this.setState({ resetPasswordModalConfirmText: `Are you sure you want to reset the user's '${this.state.selectedUser.userName}' password?`});
		}
    }

	hideResetPasswordModalConfirm() {
        this.setState({resetPasswordModalConfirmText: ''})
    } 

	confirmResetUserPassword() {	
		this.hideResetPasswordModalConfirm()	;
        this.props.toggleUiBlock(true);
		var user = this.state.selectedUser;
		var newUserPassword = GeneratePassword();
		UserService.resetUserPassword(user.id, newUserPassword)
                .then(response =>{
					this.showNewUserPassword(newUserPassword);
					this.props.toggleUiBlock(false);
                })
                .catch(error => {
					let errorMessage = GetErrorMessage(error);
					console.log("errorMessage on catch");
					console.log(errorMessage);
					this.props.toggleUiBlock(false);
					this.setState({ errorMessage: errorMessage});
                });
    }

	showNewUserPassword(userPassword) {
		setTimeout(() => {
			this.setState({newUserPasswordText: 'The new user password is: ' +  userPassword});			
		}, 500);		
	}

	hideNewUserPasswordModal() {
        this.setState({newUserPasswordText: ''})
    } 

    deleteUser = event => {
		if (this.props.canChangeData) {    
        	this.setState({ deleteModalText: `Are you sure you want to delete the user with name '${this.state.selectedUser.userName}'?`});
		}
    }
	
    duplicateUser = event => {		
        let clonedUser = cloneDeep(this.state.selectedUser);		
        clonedUser.id = null;
        clonedUser.userName = `${clonedUser.userName}_copy`;
		clonedUser.userPassword = GeneratePassword();
		this.props.updateSelectedUser(clonedUser);
    }

    confirmDelete() {        
        this.hideDeleteModal();
        this.props.toggleUiBlock(true);
        const userId = this.state.selectedUser.id;
        UserService.deleteUser(this.state.selectedUser)
        .then(response =>{            
            this.setState({successMessage: 'The user was deleted successfully' });            
            this.props.removeDeletedUser(userId);
			setTimeout(() => {
				this.props.toggleUiBlock(false);
				this.props.closeEditor();				
			}, 2000);

            
        })
        .catch(error => {
            let errorMessage = GetErrorMessage(error);
            console.log("errorMessage on catch");
            console.log(errorMessage);
            this.props.toggleUiBlock(false);
            this.setState({ errorMessage: errorMessage});
        });
    }

    hideDeleteModal() {
        this.setState({deleteModalText: ''})
    }

    handleSubmitAction = event => { 
		if (this.props.canChangeData) {
			var validationErrors = ValidateUser(this.state.selectedUser);
			this.setState({errors: validationErrors});
			if (validationErrors && validationErrors.length === 0) {                    
				this.SaveUser(this.state.selectedUser);
			}        
		}
        return;
    }

    render() {

        this.tabs = this.getTabs(this.state.selectedUser);
        return (        
               
            <div className="AppFormComponent UserForm">                 
                <UserFormContext.Provider value={{
					updateSelectedUser: this.updateSelectedUser.bind(this), 
					selectedUser: this.state.selectedUser,
					canChangeData: this.props.canChangeData}}>                
                    <form ref={ form => this.formEl = form } onSubmit={ this.handleSubmitAction }>
                        <div className="form-head">
                            <GlobalControlLine errors={this.state.errors} 
                                handleSubmitAction={this.handleSubmitAction.bind(this)} 
                                deleteUser={this.deleteUser.bind(this)}
                                duplicateUser={this.duplicateUser.bind(this)}
                                closeEditorButtonOnClick={this.props.closeEditorButtonOnClick}
								didSelectedUserChange={this.props.didSelectedUserChange}
								resetUserPassword={this.resetUserPassword.bind(this)}
								/>                            
                        </div>
                        <div className="form-sections">
                            <MemoryRouter>
                                <div className="tab-bar">
                                    <TabBar key="UserForm" activeTab={this.state.activeTab} tabs={this.tabs} onTabActivate={item=>this.setState({activeTab:item.id})}/>                                    
                                </div>
                                <div className="section-content">
                                    <Switch>
                                        <Route exact path="/"><Redirect to={this.state.activeTab}/></Route>
                                        <Route exact path="/roles"><TabRole key={this.state.selectedUser?.id} metadata={this.props.metadata} errors={this.state.errors} /></Route>                                        
                                    </Switch>
                                    <Redirect to={this.state.activeTab}/>
                                </div>
                            </MemoryRouter>
                        </div>
                    </form>                    
                </UserFormContext.Provider>

                {this.state.errorMessage && ( <ShowError clearErrorMessage={() => this.setState({errorMessage: ''})} errorMessage={this.state.errorMessage} /> )}
                {this.state.successMessage && ( <ShowSuccess clearMessage={() => this.setState({successMessage: ''})} message={this.state.successMessage} /> )}
                {this.state.deleteModalText && ( <Modal content={this.state.deleteModalText} size="small" confirmModalHandler={this.confirmDelete.bind(this)} cancelModalHandler={this.hideDeleteModal.bind(this)} />)}
				{this.state.resetPasswordModalConfirmText && ( <Modal content={this.state.resetPasswordModalConfirmText} size="small" confirmModalHandler={this.confirmResetUserPassword.bind(this)} cancelModalHandler={this.hideResetPasswordModalConfirm.bind(this)} />)}
				{this.state.newUserPasswordText && ( <Modal content={this.state.newUserPasswordText} size="small" hideConfirmButton cancelButtonLabel="Ok" cancelModalHandler={this.hideNewUserPasswordModal.bind(this)} />)}
            </div>            
        );
    }
}
export default withRouter(UserForm); 