import './DropdownSubMenu.scss';
import {Link} from "react-router-dom";

const DropdownSubMenu = ({ subMenuId, submenus, dropdownOpened }) => {
	return (
	  <ul className={`top-menu-dropdown subMenuId_${subMenuId} ${dropdownOpened === subMenuId ? "show" : ""}`}>
		{submenus.map((submenu, index) => (
		  <li key={index} className="submenu-items">			
			<Link className='submenu-link' to={{pathname: submenu.url}}>{submenu.label}</Link>
		  </li>
		))}
	  </ul>
	);
  };  
  export default DropdownSubMenu;