import React, { Component } from "react";
import { IntroPage } from "app/components/IntroPage";
import './UserHomeComponent.scss';

class UserHome extends Component{
    render() {
        return (
            <IntroPage headerText="Welcome to Users Homepage">                
                <b>Here you can</b>
                <ul className="introHomeItems">
					<li>Create new users</li>
					<li>Add role to users</li>					
                </ul>
            </IntroPage>
        )
    }
}

export default UserHome;