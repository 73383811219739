import authHeader from './auth-header';
import Api from './api';

const API_URL = process.env.REACT_APP_AQUA_API_ENDPOINT

class NqTemplateService {
	
  async getNqTemplateByName(name: string) {
	return await Api().get(`${API_URL}/nqtemplate/${name}`, { headers:  authHeader() as any });      
  }
  async find(surveyPhaseId: string, surveyTypeId: string) {
	if (surveyTypeId == null) {
		surveyTypeId = '';
	}
    return await Api().get(`${API_URL}/nqtemplate/find?surveyPhaseId=${surveyPhaseId}&surveyTypeId=${surveyTypeId}`, { headers:  authHeader() as any });      
  }

  async getActives() {
    return await Api().get(`${API_URL}/nqtemplate/getActives`, { headers:  authHeader() as any });      
  }

  async getMenuItems() {
    return await Api().get(`${API_URL}/nqtemplate/getMenuItems`, { headers:  authHeader() as any });      
  }

  async updateNqTemplate(nqTemplate: any) {
    return await Api().put(`${API_URL}/nqtemplate/${nqTemplate.name}`, nqTemplate, 
    { method: 'PUT',
      headers:  authHeader() as any });      
  }

  async createNqTemplate(nqTemplate: any) {
    return await Api().post(`${API_URL}/nqtemplate/`, nqTemplate, 
    { method: 'POST',
      headers:  authHeader() as any });      
  }

  async deleteNqTemplate(name: string) {
    return await Api().delete(`${API_URL}/nqtemplate/${name}`, 
    { method: 'DELETE',
      headers:  authHeader() as any });      
  }  
  
  async getMetadata() {
    return await Api().get(`${API_URL}/nqtemplate/metadata`, { headers:  authHeader() as any });      
  }

  
}

export default new NqTemplateService();