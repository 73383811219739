function ConvertToInDepthSetMenuItem(inDepthSet) {
    return {
        id: inDepthSet.id,       
        name: inDepthSet.name,
		startDate: inDepthSet.startDate,
		endDate: inDepthSet.endDate,
		folderId: inDepthSet.folderId,
		questionsCount: inDepthSet.questions.length		
      }
}
export default ConvertToInDepthSetMenuItem;