import React, { Component } from 'react';
import { Card} from '@ecosystem/ui-lib/core/components/Card';
import {AdHocSurveyFormContext} from '../../AdHocSurveyContext'
import './TabQuestionConditionsComponent.scss';
import ConditionLogics from './ConditionLogicsComponent';

class TabQuestionConditions extends Component {	

    constructor(props){
        super(props);
        this.state = {     
            errorQuestionText: ''			
        }    
    }
	
	addConditionHandler(condition) {
		var selectedAdHocSurvey = this.context.selectedAdHocSurvey;		
		let surveyQuestion = selectedAdHocSurvey.questions.find(q=>q.question.id === this.props.question.id);
		surveyQuestion.questionConditionsDefinition.conditions.push(condition);			
		this.context.updateSelectedAdHocSurvey(selectedAdHocSurvey);		
	}

	removeConditionHandler(index) {
		var selectedAdHocSurvey = this.context.selectedAdHocSurvey;		
		let surveyQuestion = selectedAdHocSurvey.questions.find(q=>q.question.id === this.props.question.id);
		surveyQuestion.questionConditionsDefinition.conditions.splice(index,1)
		this.context.updateSelectedAdHocSurvey(selectedAdHocSurvey);		
	}

	validAnswersHandler(condition, options)  {
		var selectedAdHocSurvey = this.context.selectedAdHocSurvey;		
		let surveyQuestion = selectedAdHocSurvey.questions.find(q=>q.question.id === this.props.question.id);		
		var conditionToUpdate = surveyQuestion.questionConditionsDefinition.conditions.find(c=>c.questionToCheck.id === condition.questionToCheck.id);
		conditionToUpdate.validAnswerValues = [];
		conditionToUpdate.validAnswerValues.push(...options);
		this.context.updateSelectedAdHocSurvey(selectedAdHocSurvey);
	}

	conditionNegateHandler(index, isChecked) {
		var selectedAdHocSurvey = this.context.selectedAdHocSurvey;		
		let surveyQuestion = selectedAdHocSurvey.questions.find(q=>q.question.id === this.props.question.id);		
		var conditionToUpdate = surveyQuestion.questionConditionsDefinition.conditions[index];
		conditionToUpdate.negate = isChecked ? 1 : 0;
		this.context.updateSelectedAdHocSurvey(selectedAdHocSurvey);		
	}

	logicOperatorHandler(conditionOperator) {        
        var selectedAdHocSurvey = this.context.selectedAdHocSurvey;		
		let surveyQuestion = selectedAdHocSurvey.questions.find(q=>q.question.id === this.props.question.id);		
		surveyQuestion.questionConditionsDefinition.conditionOperator = conditionOperator;
		this.context.updateSelectedAdHocSurvey(selectedAdHocSurvey);
    }

	getSelectedQuestions() {
		var selectedAdHocSurvey = this.context.selectedAdHocSurvey;		
		let surveyQuestion = selectedAdHocSurvey.questions.find(q=>q.question.id === this.props.question.id);		
		var questions = [];
		surveyQuestion.questionConditionsDefinition.conditions.forEach(condition => {
			questions.push(condition.questionToCheck);
		});
		return questions;
	}

    render() {
        return (
            <div className="TabQuestionConditionsComponent content-box">
				<div className='scrollable-content'>
					<Card>
						<div className="form-row">
							<div className="form-row-left">
								<h4>Show question <span className='orange'>{this.props.question.code}</span> only if all/any of the following statements of previous questions are fulfilled.</h4>							
							</div>						
						</div>
						<div className="form-row">
							<ConditionLogics 
								canChangeData={this.props.canChangeData}								
								question={this.props.question} 
								conditionOperator={this.props.conditionsDefinition.conditionOperator}
								conditions={this.props.conditionsDefinition.conditions}
								addConditionHandler={this.addConditionHandler.bind(this)}
								removeConditionHandler={this.removeConditionHandler.bind(this)}
								validAnswersHandler={this.validAnswersHandler.bind(this)}
								conditionNegateHandler={this.conditionNegateHandler.bind(this)}
								logicOperatorHandler={this.logicOperatorHandler.bind(this)}
								getSelectedQuestions={this.getSelectedQuestions.bind(this)}
								>
							</ConditionLogics>
						</div>
						
					</Card>			
				</div>
            </div>
        );
    }
}
TabQuestionConditions.contextType = AdHocSurveyFormContext;
export default TabQuestionConditions;