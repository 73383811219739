import React, { Component } from 'react';
import { Label } from 'app/components/Basics';
import { Card } from '@ecosystem/ui-lib/core/components/Card';
import {RegionFormContext} from '../../RegionContext'
import MultiSelect from 'app/components/MultiSelect'
import './TabChannelComponent.scss';

class TabChannel extends Component {
    constructor(props){
        super(props);
        this.state = {
        }    
    }

	createOption(item, metadataList) {
		if (item.id != null) {
			item = item.id;
		}
		if (!metadataList) { return null }
		return {
			value: item,
			label: metadataList.find(m=>m.value === item).label
		}
	}	

	getSelectedOptionsMultiSelect(metadata, selectedValues ) {
		var result = [];		
		result = selectedValues.map(
			item => this.createOption(item, metadata)
		);		
		return result;
	}

	getChannels() {		
		let channels = this.props.metadata.channels;
		return channels.map(function (obj) {			
			return {value: obj.id, label: obj.name}
		});
	}

	onChangeChannel(items)  {
		var selectedRegion = this.context.selectedRegion;
		selectedRegion.channels = [];
		selectedRegion.channels = items.map(function (obj) {			
			return {id: obj.value, name: obj.label}
		});
		this.context.updateSelectedRegion(selectedRegion);       
	}

	onRemoveChannel(item)  {
		var selectedRegion = this.context.selectedRegion;        
		selectedRegion.channels = selectedRegion.channels.filter(function(f) { return f.id !== item.value })
		this.context.updateSelectedRegion(selectedRegion);        
	}

    render() {
        return (
            <div className="TabChannelComponent content-box">
                <Card>
					<div className="form-row">
						<div className="form-row-left">
							<div className='channels-wrapper'>
								<div className='channels-selection'>
									<Label>Channels</Label>							
									<MultiSelect isDisabled={!this.context.canChangeData}
										selectedOptions={this.getSelectedOptionsMultiSelect(this.getChannels(), this.context.selectedRegion.channels)} 
										onChange={this.onChangeChannel.bind(this)} 
										onRemove={this.onRemoveChannel.bind(this)} 
										options={this.getChannels()}>
									</MultiSelect>
								</div>
							</div>							
						</div>						
                	</div>
					<div className="form-row-right"></div>
                </Card>
            </div>			
        );
    }
}
TabChannel.contextType = RegionFormContext;
export default TabChannel;