import authHeader from './auth-header';
import Api from './api';

const API_URL = process.env.REACT_APP_AQUA_API_ENDPOINT

class SurveyHistoryService {
  async download(startDate: Date, endDate: Date) {
	return await Api().get(`${API_URL}/codeBookDownload/download?startDate=${startDate}&endDate=${endDate}`, { headers:  authHeader() as any, responseType: 'blob' });      
  }
}

export default new SurveyHistoryService();