import React, { Component } from 'react';
import { Label, Button, RichTextInput, FileUpload } from 'app/components/Basics';
import {QuestionFormContext} from '../../QuestionContext'
import { Select } from '@ecosystem/ui-lib/core/components/Select';
import { Card } from '@ecosystem/ui-lib/core/components/Card';
import { ButtonIcon } from '@ecosystem/ui-lib/core/components/ButtonIcon';
import { Icons } from '@ecosystem/ui-lib/core/components/Icon';
import { Input as EcoInput } from '@ecosystem/ui-lib/core/components/Input';
import { GenericCell } from '@ecosystem/ui-lib/core/components/Table';
import { ReactComponent as PlusIcon } from 'app/assets/icons/Plus.svg';
import TableDraggableRows from 'app/components/TableDraggableRows'
import NewQuestion from '../../QuestionModel';
import { Constants } from '../../../../constants/constants'
import cloneDeep from 'lodash/cloneDeep';
import './TabGridComponent.scss';

class TabGrid extends Component {

	GridIsRandomOptions = [
        {value:1, label: "Random"},
        {value:0, label: "Ascending"}        
    ]

    constructor(props){
        super(props);
        this.state = {           
        };

    }

	getTableHeaders() {		
		return [
			{
				id: 'column-grid-code',
				label: 'Code'
			},			
			{
				id: 'column-grid-question-text',
				label: 'Question'
			},			
			{
				id: 'column-grid-remove',
				label: 'Remove'
			}
		];
	}

	getDraggableRows() {
		const draggableRows = [];		
        this.context.selectedQuestion.gridQuestions.forEach((question) => {            
			draggableRows.push(this.getDraggableRowCells(question));			
        });
		return draggableRows;
	}

	getDraggableRowCells(question) {
		var draggableRow = {};
		draggableRow.itemId = question._frontEndQuestionId;
		draggableRow.item = question;
		draggableRow.cells = this.getCells(question);
		return draggableRow;
	}

	getCells(question) {		
        const cols = [];
        cols.push(<GenericCell className="column-code" primary={<EcoInput  	
		disabled={!this.context.canChangeData}	
		value={question.code} 
		onChange={this.statementCodeHandler(question._frontEndQuestionId)}
		key={question._frontEndQuestionId}
		id={question._frontEndQuestionId}
		error={this.getEmptyStatementCodeValidationError(question)}
		></EcoInput>}/>);        
		cols.push(<GenericCell className="column-question-text" primary={
            <RichTextInput 
			key={question._frontEndQuestionId}		
			id={question._frontEndQuestionId}
			disabled={!this.context.canChangeData}
			value={question.text}			      
            onValueChange={this.statementTextHandler.bind(this)}            
            ctrlsPosition="right" 
            maxLines={2}
			error={this.getEmptyStatementTextValidationError(question)} />
        }/>);       
        cols.push(<GenericCell className="column-remove" primary={<div><ButtonIcon isDisabled={!this.context.canChangeData} onClick={() => this.removeQuestionHandler(question._frontEndQuestionId)}  icon={Icons.Close}/></div>}/>);        
        return cols;
    }

	statementCodeHandler = questionId => (event) => {
		var selectedQuestion = this.context.selectedQuestion;
		let statement = selectedQuestion.gridQuestions.find(g=>g._frontEndQuestionId === questionId);
		statement.code = event.target.value;
		this.context.updateSelectedQuestion(selectedQuestion);
    }
	
	statementTextHandler(newText, richTextInputId) {
		var selectedQuestion = this.context.selectedQuestion;
		let statement = selectedQuestion.gridQuestions.find(g=>g._frontEndQuestionId === richTextInputId);
		statement.text = newText;
		this.context.updateSelectedQuestion(selectedQuestion);
    }

	getEmptyStatementTextValidationError(question) {
		if (this.props.hasError && question.text === '') {
			return "question text is empty"
		}
		else {
			return "";
		}
	}
	
	getEmptyStatementCodeValidationError(question) {
		if (this.props.hasError && question.code === '') {
			return "question code is empty"
		}
		else {
			return "";
		}
	}

	removeQuestionHandler(questionId) {
		var selectedQuestion = this.context.selectedQuestion;		
        if (selectedQuestion.gridQuestions) {
			selectedQuestion.gridQuestions = selectedQuestion.gridQuestions.filter(function(f) { return f._frontEndQuestionId !== questionId });
            this.context.updateSelectedQuestion(selectedQuestion);
        }
	}

	validateNewOrderedList() {
		return true;
	}	

	updateNewOrderedQuestionList(newOrderedList) {
		var selectedQuestion = this.context.selectedQuestion;
		selectedQuestion.gridQuestions = newOrderedList;
		this.context.updateSelectedQuestion(selectedQuestion);		
	}	

	addStatement() {		
		var selectedQuestion = this.context.selectedQuestion;
		if (selectedQuestion._frontEndQuestionId == null) {
			selectedQuestion._frontEndQuestionId = Constants.TEMP_ID_PLACEHOLDER + "_new_parent_" + Date.now();
		}		
		const newQuestion = NewQuestion();		
		newQuestion._frontEndQuestionId = Constants.TEMP_ID_PLACEHOLDER + Date.now();
		newQuestion.groupQuestionId = selectedQuestion._frontEndQuestionId;
		selectedQuestion.gridQuestions.push(newQuestion);		
		this.context.updateSelectedQuestion(selectedQuestion);
	}

	getSelectedGridOrder() {        
        return this.GridIsRandomOptions.find(t=> t.value === this.context.selectedQuestion.randomizeGridQuestions)
    }

	changeGridQuestionOrder(gridOrder) {		
		var selectedQuestion = this.context.selectedQuestion;
		selectedQuestion.randomizeGridQuestions = gridOrder.value;
		this.context.updateSelectedQuestion(selectedQuestion);		
	}	

    render() {
		const draggableRows = this.getDraggableRows();
        return (
			<div className="TabGridComponent content-box">
				<Card>
					<div className="form-row">
						<div className="form-row-left">
							<div className="field-option-order">
								<Label>Order</Label>
								<Select name="gridSortOrder" isDisabled={!this.context.canChangeData} 
									isSearchable={false}
									defaultValue={{value:1, label: "Random"}}
									value={this.getSelectedGridOrder()}
									options={this.GridIsRandomOptions}
									onChange={val=>{
										this.changeGridQuestionOrder(val)                                
									}}/>
							</div>
						</div>
						<div className="form-row-right">
							<Button disabled={!this.context.canChangeData} secondary icon={<PlusIcon/>} onClick={() => this.addStatement()}>Statement</Button>
						</div>
					</div>
					
					<div style={{display: draggableRows.length === 0 ? 'none' : 'block' }}>
					<TableDraggableRows 
						disabled={!this.context.canChangeData} 
						tableHeaders={this.getTableHeaders()} 
						tableRows={draggableRows}
						validateNewOrderedList={this.validateNewOrderedList.bind(this)}
						updateNewOrderedList={this.updateNewOrderedQuestionList.bind(this)}
						>						
					</TableDraggableRows>
					</div>

					<div style={{display: draggableRows && draggableRows.length > 0 ? 'none' : 'block' }}>
						<p className='EmptyQuestionListInfoTitle'>Statement list is empty</p>
						<p className='EmptyQuestionListInfoText'>Please, add statements via <span className='span-box'>+Statement</span></p>
					</div>
				</Card>
			</div>
        );
    }
}
TabGrid.contextType = QuestionFormContext;
export default TabGrid;