import React, { Component } from 'react';
import DateObject from "react-date-object";
import { ReactComponent as Question } from 'app/assets/icons/question-bubble.svg';
import { ReactComponent as TimeFrom } from 'app/assets/icons/arrow-right-from.svg';
import { ReactComponent as TimeTo } from 'app/assets/icons/arrow-right-to.svg';
//import { ReactComponent as TimeSpan } from 'app/assets/icons/arrow-right.svg';
import { ReactComponent as Condition } from 'app/assets/icons/connections.svg';
import {StatusBadge} from 'app/components/Basics/'

import './SideListComponent.scss';

import {SideListContext} from './SideListComponent.js';


class SideListItem extends Component {
    static contextType = SideListContext;
    
    constructor(props) {
        super(props);

        this.state = {
            id: props.id,
            label: props.children,
            itemStatus: props.itemStatus,
            itemStatusLabel: props.itemStatusLabel ? props.itemStatusLabel : props.itemStatus          
        };

        this.onClickEvent = this.onClickEvent.bind(this)

        
    }

    onClickEvent = (e) => {        
        this.props.onClick(e);        
    };
	
    render() {        
        var isSelected =  this.props.isItemSelected === true ? "selected" : "";
        return (
            <li key={this.props.id} className={`SideListItemComponent ${isSelected}`} onClick={this.onClickEvent}>
                <div className="left">
                    <div className="top"><div className="text-overflow">{this.props.top}</div></div>
                    <div className="label"><div className="text-overflow">{this.state.label}</div></div>
                    <div className="bottom"><div className="text-overflow">{this.props.bottom}</div></div>
                </div>
                {/* <StatusBadge>{this.state.itemStatusLabel}</StatusBadge>                 */}
            </li>
        );
    }
}

SideListItem.contextType = SideListContext;

class SideListItemQuestion extends SideListItem {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    setItemClick(e) {
        this.props.getSelectedItem(this.props.qid);        
    }   

    render() {
        var top=this.props.qcode;
        var bottom=this.props.qtype;
        return (
            <SideListItem isItemSelected={this.props.isItemSelected} selectedItemId={this.props.qid} top={top} bottom={bottom} onClick={this.setItemClick.bind(this)} {...this.props}/>
        );
    }
}

class SideListItemInDepthSet extends SideListItem {
    constructor(props) {
        super(props);
        this.state = {...this.state, ...{			
            start: new DateObject(props.start).setHour(0).setMinute(0).setSecond(0).setMillisecond(0),
            end: props.end ? new DateObject(props.end).setHour(23).setMinute(59).setSecond(59).setMillisecond(999) : null,
            today: new DateObject(),
            selector: props.selector,
			selectedIndepthSetId: null,
        }};
    }

	getSelectedQuestionCode() {
        return this.state.id;
    }

    setItemClick(e) {
        this.props.getSelectedItem(this.props.indepthSetid);
        this.setState({selectedIndepthSetId: this.props.id})
    }    

    render() {

        var top = "";
        if(this.props.top){
            top=this.props.top;
        }else{
			
            var duration = this.state.end ? Math.ceil((this.state.end - this.state.start) / 1000 / 60 / 60 / 24) : null;
            /*var validity = "";
            if(this.state.today >= this.state.start){
                validity=(<div><TimeTo/>{this.state.end.format("DD MMM 'YY")}</div>);
            }else{
                validity=(<div>{this.state.start.format("DD MMM 'YY")}<TimeFrom/></div>);
            }*/
            var validity=(<div>{this.state.start.format("DD MMM 'YY")}<TimeFrom/>
			{duration ? duration + ' days' : ''}			 
			{this.state.end && <TimeTo/>}
			{this.state.end ? this.state.end.format("DD MMM 'YY") : ""} </div>)
            top=[<div><Question/><span>{this.props.questions}</span></div>, validity] ;
        }
        
        var bottom = this.props.bottom ? this.props.bottom : this.state.selector;        
        return (
            <SideListItem key={this.props.indepthSetid} id={this.props.indepthSetid} isItemSelected={this.props.isItemSelected} selectedItemId={this.state.selectedIndepthSetId} top={top} bottom={bottom} onClick={this.setItemClick.bind(this)} getSelectedQuestionCode={this.getSelectedQuestionCode.bind(this)} {...this.props}/>
        );
    }
}

class SideListItemAdHocSurvey extends SideListItem {
    constructor(props) {
        super(props);
        this.state = {...this.state, ...{			
            start: new DateObject(props.start).setHour(0).setMinute(0).setSecond(0).setMillisecond(0),
            end: props.end ? new DateObject(props.end).setHour(23).setMinute(59).setSecond(59).setMillisecond(999) : null,
            today: new DateObject(),
            selector: props.selector,
			selectedAdHocSurveyId: null,
        }};
    }

	getSelectedQuestionCode() {
        return this.state.id;
    }

    setItemClick(e) {
        this.props.getSelectedItem(this.props.adHocSurveyId);
        this.setState({selectedAdHocSurveyId: this.props.id})
    }    

    render() {

        var top = "";
        if(this.props.top){
            top=this.props.top;
        }else{
			
            var duration = this.state.end ? Math.ceil((this.state.end - this.state.start) / 1000 / 60 / 60 / 24) : null;
            /*var validity = "";
            if(this.state.today >= this.state.start){
                validity=(<div><TimeTo/>{this.state.end.format("DD MMM 'YY")}</div>);
            }else{
                validity=(<div>{this.state.start.format("DD MMM 'YY")}<TimeFrom/></div>);
            }*/
            var validity=(<div>{this.state.start.format("DD MMM 'YY")}<TimeFrom/>
			{duration ? duration + ' days' : ''}			 
			{this.state.end && <TimeTo/>}
			{this.state.end ? this.state.end.format("DD MMM 'YY") : ""} </div>)
            top=[<div><Question/><span>{this.props.questions}</span></div>, validity] ;
        }
        
        var bottom = this.props.bottom ? this.props.bottom : this.state.selector;        
        return (
            <SideListItem key={this.props.adHocSurveyId} id={this.props.indepthSetid} isItemSelected={this.props.isItemSelected} selectedItemId={this.state.selectedAdHocSurveyId} top={top} bottom={bottom} onClick={this.setItemClick.bind(this)} getSelectedQuestionCode={this.getSelectedQuestionCode.bind(this)} {...this.props}/>
        );
    }
}


class SideListItemNqTemplate extends SideListItem {
    constructor(props) {
        super(props);
        this.state = {...this.state, ...{           
			selectedNqTemplateName: null,
        }};
    }

	getSelectedNqTemplateByName() {
        return this.props.name;
    }

    setItemClick(e) {
        this.props.getSelectedItem(this.props.nqTemplateMenuItem);
        this.setState({selectedNqTemplateName: this.props.name})
    }    

    render() {

        var top = "";
        if(this.props.top){
            top=this.props.top;
        }else{
            //top=[<div><Question/><span>{this.props.nqTemplateId}</span></div>] ;
        }
		var bottom = "";
		if(this.props.bottom){
            bottom=this.props.bottom;
        }else{
            //bottom=[<div>Version {this.props.version}</div>] ;
        }       
       
        return (
            <SideListItem key={this.props.name} id={this.props.name} isItemSelected={this.props.isItemSelected} selectedItemId={this.state.selectedNqTemplateName} top={top} bottom={bottom} onClick={this.setItemClick.bind(this)}  {...this.props}/>
        );
    }
}

class SideListItemGeneric extends SideListItem {
    constructor(props) {
        super(props);
        this.state = {...this.state, ...{           
			
        }};
    }	

    setItemClick(e) {
        this.props.getSelectedItem(this.props.menuItemId);      
    }    

	

    render() {

        var top = "";
        if(this.props.top){
            top=this.props.top;
        }
		var bottom = "";
		if(this.props.bottom){
            bottom=this.props.bottom;
        }     
       
        return (
            <SideListItem key={this.props.menuItemId} id={this.props.menuItemId} isItemSelected={this.props.isItemSelected} selectedItemId={this.props.menuItemId} top={top} bottom={bottom} onClick={this.setItemClick.bind(this)}  {...this.props}/>
        );
    }
}

// class SideListItemAdHocSurvey extends SideListItemInDepthSet {
//     constructor(props) {
//         super(props);
//         this.state = {...this.state, ...{
//             conditions: props.conditions
//         }};
//     }

//     render() {
//         /*var duration = Math.ceil((this.state.end - this.state.start) / 1000 / 60 / 60 / 24);
//         var validity=(<div>{this.state.start.format("DD MMM")}<TimeFrom/>{duration} days <TimeTo/>{this.state.end.format("DD MMM 'YY")}</div>);
//         var top=[<div><Question/><span>{this.state.questions}</span></div>, validity];*/
//         var bottom= (<div><Condition/><span>{this.state.conditions} inner conditions</span></div>);

//         return (
//             <SideListItemInDepthSet /*top={top}*/ bottom={bottom} {...this.props}/>
//         );
//     }
// }

export default SideListItemQuestion;
export {SideListItem, SideListItemQuestion, SideListItemInDepthSet, SideListItemAdHocSurvey, SideListItemNqTemplate, SideListItemGeneric};