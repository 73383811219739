import React, { Component } from 'react';
import { Editor, EditorState, RichUtils, ContentState, Modifier, convertFromHTML } from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import { Select } from '@ecosystem/ui-lib/core/components/Select';
import './RichTextInputComponent.scss';
import { ReactComponent as BoldIcon } from 'app/assets/icons/bold.svg';
import { ReactComponent as ItalicIcon } from 'app/assets/icons/italic.svg';
import { ReactComponent as UnderlineIcon } from 'app/assets/icons/underline.svg';

const iconMap = {
    BOLD: BoldIcon,
    ITALIC: ItalicIcon,
    UNDERLINE: UnderlineIcon
};

const styleMap = {
    'SMALL': {
        fontSize: '12px',
    },
    'MEDIUM': {
        fontSize:'inherit',
    },
    'LARGE': {
        fontSize: '20px',
    }
};

const ctrlPosMap = {
    'top': 'ctrls-top',
    'right': 'ctrls-right',
    'right-singleline': 'ctrls-right-single'
};

class RichTextInput extends Component {    
    constructor(props) {
        super(props);		
		this.editorRef = React.createRef();      
		if (props.value) {
			const blocksFromHTML = convertFromHTML(props.value);
            const inputState = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
              );
			  const editorState = EditorState.createWithContent(inputState);
			  const html = this.getHtmlFromEditor(editorState)
			  this.state = {
				editorState: editorState,
				htmlContent: html
			};
		}
		else {
			this.state = {
				editorState: EditorState.createEmpty(),
				htmlContent: ''
			};
		}         
        
        this.onChange = editorState => {
            const html = this.getHtmlFromEditor(editorState);        
            this.setState({editorState: editorState,
                htmlContent: html
            });
        };
        this.handleKeyCommand = this.handleKeyCommand.bind(this);
        this.selectTextAgain = this.selectTextAgain.bind(this);
     
    }    

    handleKeyCommand(command, editorState) {
        if(command==="split-block" && (editorState.getCurrentContent().getBlocksAsArray().length+1) > (this.props.maxLines || 999)){
            return 'handled';
        }

        const newState = RichUtils.handleKeyCommand(editorState, command);    

        if (newState) {
          this.onChange(newState);
          return 'handled';
        }

        return 'not-handled';
    }


    handlePastedText(text, html, editorState) {        
        const pastedBlocks = ContentState.createFromText(text).blockMap;
        const newState = Modifier.replaceWithFragment(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            pastedBlocks,
        );
        const blockMap = newState.getBlockMap();

        if(blockMap.size > (this.props.maxLines || 999)){
            return 'handled';
        }

        return 'not-handled';
    }

    handleReturn(e) {
        const { editorState } = this.state;
        if (e.key === 'Enter') {
          this.setState({ editorState: RichUtils.insertSoftNewline(editorState) });
          return 'handled';
        }
        return 'not-handled';
      }
    
    selectTextAgain () {
        if(this.editorRef.current){
            this.editorRef.current.focus();
        }
    }

    renderButton(name,style) {
        const currentInlineStyle = this.state.editorState.getCurrentInlineStyle();
        let className = style;
        if (currentInlineStyle.has(style)) {
          className+=' active';
        }
        
        var FormatIcon = iconMap[style];

        return (
            <button 
                key={style} 
                disabled={this.props.disabled}
                data-style={style} 
                className={className} 
                onClick={(e)=>{
                    e.preventDefault()
                    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState,style))
                }} 
                onMouseDown={(e)=>{e.preventDefault();}}>
                    <FormatIcon/>
            </button>
        );
    }

    renderSelect(){
        const currentInlineStyle = this.state.editorState.getCurrentInlineStyle();
        const sizes = [
            {value:"SMALL",label:"small"},
            {value:"MEDIUM",label:"regular"},
            {value:"LARGE",label:"large"}
        ];

        var current = {value:"MEDIUM",label:"regular"};
        sizes.forEach(size => {if(currentInlineStyle.has(size.value)) {current=size;}});

        return (
            <Select key="size"
            id="siza"
                isSearchable={false}
                isDisabled={this.props.disabled}
                value={current}
                options={sizes}
                onChange={
                    value=>{
                        this.selectTextAgain();
                        setTimeout(function(){
                            this.onChange(RichUtils.toggleInlineStyle(this.state.editorState,value.value));
                        }.bind(this),50);
                    }
                }
            />
        )

    }

    onEditorChange(newEditorState) {        
        const html = this.getHtmlFromEditor(newEditorState)
        this.setState({
            editorState: newEditorState,
            htmlContent: html 
        }, this.props.onValueChange(html, this.props.id));
    }
	  
    getHtmlFromEditor(editorState) {
        let html = stateToHTML(editorState.getCurrentContent());
        if (html) {
            html = html.replace(/<\/?p[^>]*>/g, "");
            if (html === "<br>") html = '';
        }
        return html;
    }

    render() {        

        var cls1 = "hide-controls";
        var ctrls = "";
        if(!this.props.hideCtrls){
            cls1 = ctrlPosMap[this.props.ctrlsPosition || 'top' ] ;
            ctrls = (
                <div className="format-controls">
                    <div className="format-buttons">
                        {this.renderButton("Bold","BOLD")}
                        {this.renderButton("Italic","ITALIC")}
                        {this.renderButton("Underline","UNDERLINE")}
                    </div>
                    {/* <div className="format-size">
                        {this.renderSelect()}
                    </div> */}
                </div>
            );
        }

        var cls2 = this.props.label ? "hasLabel" : ""

        var style={
           height: Math.min(this.props.maxLines, this.props.editorLines || this.props.maxLines) * 24 + 16,
           textAlign: this.props.textAlign || 'left'
        }

        if (this.props.exactHeight) {
            style.height = this.props.exactHeight;
        }


        return (
            <div className={`RichTextInputComponent ${this.className} ${this.props.error ? "error" : ""} ${cls1} ${cls2}`}>
                {this.props.label}
                <div className="RTEcontainer">
                    {ctrls}
                    <div className={`editor-field ${this.props.disabled ? "disabled" : ""}`} style={style}>
                        <Editor							
                            readOnly={this.props.disabled}
                            ref={this.editorRef}
                            customStyleMap={styleMap}
                            editorState={this.state.editorState}
                            handleKeyCommand={this.handleKeyCommand.bind(this)}
                            handlePastedText={this.handlePastedText.bind(this)}
                            handleReturn={this.handleReturn.bind(this)}
                            onChange={this.onEditorChange.bind(this)}
                           
                        />
                        {this.props.error ? <span className="RTE_error">{this.props.error}</span> : ""}
                    </div>
                </div>
            </div>
        );
    }
}



export default RichTextInput;
export {RichTextInput};