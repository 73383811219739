import React, { Component } from "react";
import { IntroPage } from "app/components/IntroPage";
import './RegionHomeComponent.scss';

class RegionHome extends Component{
    render() {
        return (
            <IntroPage headerText="Welcome to Regions Homepage">                
                <b>Here you can</b>
                <ul className="introHomeItems">
					<li>Create new regions</li>
					<li>Add channels to regions</li>					
                </ul>
            </IntroPage>
        )
    }
}

export default RegionHome;