function ExtractTemplateIdFromLogs(logs) {	
	const errorMessage = 'Template Id not found in the logs';
	const templateLine = logs.find((line) => line.includes('Template ID: '));	
	
	if (templateLine == null) {
		return errorMessage;
	}
	const regex = /Template ID: (.*?);/;
	const match = templateLine.match(regex);	  
	if (match && match[1]) {
		// Remove leading and trailing spaces from the captured content
		const templateId = match[1].trim();
		return templateId;
	} else {
		return errorMessage;
	}
}
export default ExtractTemplateIdFromLogs;




