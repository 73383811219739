import React, { Component } from 'react';
import { Label, Button, ButtonGroup, RichTextInput, FileUpload } from 'app/components/Basics';

import { Select } from '@ecosystem/ui-lib/core/components/Select';
import { Card } from '@ecosystem/ui-lib/core/components/Card';
import { ButtonIcon } from '@ecosystem/ui-lib/core/components/ButtonIcon';
import { Icons } from '@ecosystem/ui-lib/core/components/Icon';
import { Input as EcoInput } from '@ecosystem/ui-lib/core/components/Input';
import { Table, GenericCell } from '@ecosystem/ui-lib/core/components/Table';
import { Checkbox } from '@ecosystem/ui-lib/core/components/Checkbox';

import { ReactComponent as PlusIcon } from 'app/assets/icons/Plus.svg';
import { ReactComponent as OffIcon } from 'app/assets/icons/off.svg';
import { ReactComponent as PageByPageIcon } from 'app/assets/icons/pagepage.svg';
import { ReactComponent as AccordionIcon } from 'app/assets/icons/accordion.svg';


import './TabLoopComponent.scss';



class TabLoop extends Component {

    constructor(props){
        super(props);
        this.state = {
            displayType:"OFF"
        };

    }

    render() {

        return (
            <div className="TabLoopComponent content-box">
                <Card>
                    <div className="form-row">
                        <div className="form-row-left">
                            <div className="button-loop-display">
                            <Label tooltip={(<div>Description of <br/>Display Loop</div>)}>Display Loop</Label>
                                <ButtonGroup>
                                    <Button key="display-off" onActivate={()=>{this.setState({displayType:"OFF"})}} defaultActive={this.state.displayType==="OFF"} icon={<OffIcon/>}>Off</Button>
                                    <Button key="display-page" onActivate={()=>{this.setState({displayType:"PAGE"})}} defaultActive={this.state.displayType==="PAGE"} icon={<PageByPageIcon/>}>Page by Page</Button>
                                    <Button key="display-accordion" onActivate={()=>{this.setState({displayType:"ACCORDION"})}} defaultActive={this.state.displayType==="ACCORDION"} icon={<AccordionIcon/>}>Accordion</Button>
                                </ButtonGroup>
                            </div>
                            <div className="field-loop-order">
                                <Label tooltip={(<div>Description of <br/>Order</div>)}>Order</Label>
                                <Select name="qtype" isSearchable={false}
                                    isDisabled={this.state.displayType==="OFF"}
                                    defaultValue={{value:"RANDOM", label: "Random"}}
                                    options={[
                                        {value:"RANDOM", label: "Random"},
                                        {value:"ASC", label: "Ascending"},
                                        {value:"DESC", label: "Descending"}
                                    ]}/>
                            </div>
                        </div>
                        <div className="form-row-right">
                            <Button disabled={this.state.displayType==="OFF"} secondary icon={<PlusIcon/>}>Loop Item</Button>
                        </div>
                    </div>
                    <Table
                        className="ItemList"
                        columns={[
                            {   headerKey: `column-code`,
                                className: "AppFormTableHeader column-code",
                                name: `Item Code`
                            },{
                                headerKey: `column-label`,
                                className: "AppFormTableHeader column-label",
                                name: <Label tooltip={(<div>Description of <br/>Label/Question</div>)}>Label / Question</Label>
                            },{
                                headerKey: `column-image`,
                                className: "AppFormTableHeader column-image",
                                name: <Label tooltip={(<div>Description of <br/>Image/Media</div>)}>Image / Media</Label>
                            },{
                                headerKey: `column-showlabel`,
                                className: "AppFormTableHeader column-showlabel",
                                name: (<div>Show Label</div>)
                            },{
                                headerKey: `column-remove`,
                                className: "AppFormTableHeader column-remove",
                                name: `Remove`
                            }
                        ]}
                        rows={[
                            [
                                <GenericCell className="column-code" primary={<EcoInput disabled={this.state.displayType==="OFF"} id="item-code-1" name="item-code-1"/>}/>,
                                <GenericCell className="column-label" primary={<RichTextInput disabled={this.state.displayType==="OFF"} ctrlsPosition="right" maxLines={3}/>}/>,
                                <GenericCell className="column-image" primary={<FileUpload disabled={this.state.displayType==="OFF"}/>}/>,
                                <GenericCell className="column-showlabel" primary={<Checkbox disabled={this.state.displayType==="OFF"} checked={true}/>}/>,
                                <GenericCell className="column-remove" primary={<ButtonIcon isDisabled={this.state.displayType==="OFF"} icon={Icons.Close}/>}/>
                            ],[
                                <GenericCell className="column-code" primary={<EcoInput disabled={this.state.displayType==="OFF"} id="item-code-2" name="item-code-2"/>}/>,
                                <GenericCell className="column-label" primary={<RichTextInput disabled={this.state.displayType==="OFF"}  ctrlsPosition="right" maxLines={3}/>}/>,
                                <GenericCell className="column-image" primary={<FileUpload disabled={this.state.displayType==="OFF"}/>}/>,
                                <GenericCell className="column-showlabel" primary={<Checkbox disabled={this.state.displayType==="OFF"} checked={true}/>}/>,
                                <GenericCell className="column-remove" primary={<ButtonIcon isDisabled={this.state.displayType==="OFF"} icon={Icons.Close}/>}/>
                            ]
                        ]}
                        sortingOptions={{headerKey: null}}
                        isHeaderSticky={false}
                        isFirstColumnSticky={false}
                    />
                </Card>
            </div>
        );
    }
}
export default TabLoop;