import React, { Component } from "react";
import { Card } from '@ecosystem/ui-lib/core/components/Card';
import { Table, GenericCell } from '@ecosystem/ui-lib/core/components/Table';
import { SortDirection } from '@ecosystem/ui-lib/core/components/Table/Sorting/types';
import sortBy from 'array-sort-by';
import { ReactComponent as EditPen } from 'app/assets/icons/edit-pen.svg';
import OverviewFilters from 'app/components/OverviewFilters'
import {adHocSurveyTypes} from 'app/constants/adhoc-survey-types';
import './AdHocSurveyHomeComponent.scss';

class AdHocSurveyHome extends Component {
    constructor(props) {
        super(props);        
        this.state = {
            sortOrder: null           
        };
    }    

    getOverviewColumns() {
		var columns = [];       
        columns.push(
        {   headerKey: `column-edit`,
            className: "AppFormTableHeader column-edit",
            name: ``                
        });
        columns.push(
        {   headerKey: `name`,
            className: "AppFormTableHeader column-name",
            name: `Name`,
            isSortable: true,
            defaultSortDirection: SortDirection.asc            
        });

        columns.push({
            headerKey: `startDate`,
            className: "AppFormTableHeader column-startDate",
            name: `Start Date`,
            isSortable: true,
            defaultSortDirection: SortDirection.desc
        });

        columns.push(
        {
            headerKey: `endDate`,
            className: "AppFormTableHeader column-endDate",
            name: `End Date`,
            isSortable: true,
            defaultSortDirection: SortDirection.desc
        });

        if (this.props.surveyType === adHocSurveyTypes.FiveMinex) {
            columns.push(
                {
                    headerKey: `sampleId`,
                    className: "AppFormTableHeader column-sampleId",
                    name: `Sample Id`,
                    isSortable: true,
                    defaultSortDirection: SortDirection.desc
                });
        }        

        columns.push(
        {
            headerKey: `nqSurveyId`,
            className: "AppFormTableHeader column-nqSurveyId",
            name: `NQ Survey Id`,
            isSortable: true,
            defaultSortDirection: SortDirection.desc
        });
    
        columns.push(
        {
            headerKey: `enabled`,
            className: "AppFormTableHeader column-enabled",
            name: `Enabled`,
            isSortable: true,
        });		
		return columns;
	}

    formatDate(dateString) {
        if (dateString) {
            return new Date(dateString).toLocaleDateString('en-GB');
        }
        else {
            return '';
        }
    }

    openSelectedItem(adhocSurvey) {
        this.props.openSelectedItem(adhocSurvey.id);
    }

    addOverviewRow(adhocSurvey) {        
        const cols = [];
        cols.push(<GenericCell className="column-edit" primary={<EditPen className='edit-icon' onClick={(e) => {this.openSelectedItem(adhocSurvey)}}/>}/>);
        cols.push(<GenericCell className="column-name" primary={adhocSurvey.name}/>);
        cols.push(<GenericCell className="column-startDate" primary={this.formatDate(adhocSurvey.startDate)}/>);
		cols.push(<GenericCell className="column-endDate" primary={this.formatDate(adhocSurvey.endDate)}/>);
        if (this.props.surveyType === adHocSurveyTypes.FiveMinex) {
            cols.push(<GenericCell className="column-sampleId" primary={adhocSurvey.sampleId}/>);
        }
        cols.push(<GenericCell className="column-nqSurveyId" primary={adhocSurvey.netQuestSurveyId}/>);
        cols.push(<GenericCell className="column-enabled" primary={this.getEnabledLabel(adhocSurvey.enabled)}/>);
        return cols;
    }

    getEnabledLabel(enabled) {
        return enabled ? 'True' : 'False';
    }

    setSortingOptions(tableSortOrder) {        
        this.setState({sortOrder: tableSortOrder});
    }

    getOverviewRows() {
        const rows = [];        
        let adhocSurveys = this.setSortOrder(this.props.overview);
        adhocSurveys.forEach((adhocSurvey) => {
            rows.push(this.addOverviewRow(adhocSurvey));
        });
        return rows;
    }

    setSortOrder(adhocSurveys) {
        if (this.state && this.state.sortOrder) {            
            if (this.state.sortOrder.sortKey === 'name') {
                sortBy(adhocSurveys, item => [item.name]);                
            }
            else if (this.state.sortOrder.sortKey === 'startDate') {
                sortBy(adhocSurveys, item => [new Date(item.startDate)]);                
            }
            else if (this.state.sortOrder.sortKey === 'endDate') {
                sortBy(adhocSurveys, item => [new Date(item.endDate)]);                
            }
            else if (this.state.sortOrder.sortKey === 'enabled') {
                sortBy(adhocSurveys, item => [item.enabled]);                
            }
            
            this.setSortDirection(adhocSurveys);
        }
        return adhocSurveys
    }

    setSortDirection(adhocSurveys) {
        if (this.state.sortOrder.sortDir === 'desc') {
		    adhocSurveys = adhocSurveys.reverse();
        }        
    }

    getOverviewFilterPlaceholder() {
        if (this.props.surveyType === adHocSurveyTypes.FiveMinex) {
            return 'Filter FiveMinex by Name, SampleId or NQ Survey Id';
        }
        else {
            return 'Filter Generics by Name or NQ Survey Id';
        }
    }
    
    render() {
        const rows = this.getOverviewRows();
        return (
            <div className="AppFormComponent TabAdHocSurveyOverviewComponent content-box">
                <OverviewFilters todayDate={this.props.todayDate} filterOverview={this.props.filterOverview} filterPlaceholders={this.getOverviewFilterPlaceholder()}></OverviewFilters>
                <Card>
                    <Table
                            className="AdHocSurveyOverviewList"
                            columns={this.getOverviewColumns()}
                            rows= {rows}                                            
                            isHeaderSticky={false}
                            isFirstColumnSticky={false}
                            setSortingOptions={this.setSortingOptions.bind(this)}
                            sortingOptions={this.state?.sortOrder}
                            defaultSortingOptions={{
                                headerKey: 'startDate',
                                sortKey: 'startDate',
                                sortDir: SortDirection.desc,
                              }
                          }
                    />    
                    <div style={{display: rows && rows.length > 0 ? 'none' : 'block' }}>
                        <p className='EmptyAdHocOverviewListInfoTitle'>No {this.props.surveyType === adHocSurveyTypes.Generics ? 'Generics' : '5minEx'} for the selected filters </p>                        
                    </div>               
                </Card>
            </div>
        )
    }
}
export default AdHocSurveyHome;