import React, { Component } from 'react';
import {SideBar, SideBarHead, SideList, SideListItemNqTemplate} from 'app/components/SideBar/';
import NqTemplateHome from './components/NqTemplateHome/NqTemplateHomeComponent';
import NqTemplateService from "../../../services/nqTemplate.service";
import  GetErrorMessage  from '../../../helpers/error-message'
import {ShowError, ShowSuccess} from "../../../components/toast"
import { Loading } from '@ecosystem/ui-lib/core/components/Loading';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import './NqTemplateComponent.scss';
import NqTemplateList from './NqTemplateList';
import {NqTemplateContext} from './NqTemplateContext'
import ValidateNqTemplate from './NqTemplateValidation'

class NqTemplate extends Component {
	constructor (props) {
	super(props);
		this.state = {			
			menuItems: [],			
			loading: false,
			errorMessage: null,
			selectedNqTemplate: null,			
			uiBlocked: false,			
			templateListOpen: false,
			templatesByType: [],
			metadata: {
				surveyTypes: [],
				surveyPhases: []
			}	
		}		
	}
	
	componentDidMount() {
		this.queryNqTemplatesItemMenu();
		this.queryMetadata();
		this.queryActiveNqTemplates();
	}	

	openTemplatesList() {
		this.setState({ templateListOpen: true });		
	}

	closeTemplatesList() {
		this.setState({ templateListOpen: false, templatesByType: [], clickedNqTemplateMenuItem: null });
	}	

	queryMetadata() {		
		NqTemplateService.getMetadata()
				.then(response =>{					
					this.setState({
						metadata: response.data					
					});
					return response;          
				})
				.catch(error => {			  
					let errorMessage = GetErrorMessage(error);
					console.log("errorMessage on catch");
					console.log(errorMessage);
					this.setState({ loading: false, errorMessage: errorMessage});            
			})
	}	
	
	queryNqTemplatesItemMenu() {
		this.setState({ loading: true, menuItems: [] });
		NqTemplateService.getMenuItems()
			.then(response =>{				
				this.setState({ menuItems: response.data,							
					loading: false				
				});
				return response;          
			})
			.catch(error => {			  
				let errorMessage = GetErrorMessage(error);
				console.log("errorMessage on catch");
				console.log(errorMessage);
				this.setState({ loading: false, errorMessage: errorMessage});            
			})
	}

	queryActiveNqTemplates() {
		this.toggleUiBlock(true);
		this.setState({ activeTemplates: [] });
		NqTemplateService.getActives()
			.then(response =>{				
				this.setState({activeTemplates: response.data});
				this.toggleUiBlock(false);
				return response;          
			})
			.catch(error => {			  
				let errorMessage = GetErrorMessage(error);
				console.log("errorMessage on catch");
				console.log(errorMessage);				
				this.setState({ errorMessage: errorMessage});
				this.toggleUiBlock(false);
			})
	}	

	getNqTemplatesByType(nqTemplateNameManuItem) {
		this.toggleUiBlock(true);
		NqTemplateService.find(nqTemplateNameManuItem.surveyPhaseId, nqTemplateNameManuItem.surveyTypeId)
			.then(response =>{
				this.setState({templatesByType: response.data});
				this.toggleUiBlock(false);
				return response;          
			})
			.catch(error => {			  
				this.closeTemplatesList();
				let errorMessage = GetErrorMessage(error);
				console.log("errorMessage on catch");
				console.log(errorMessage);
				this.setState({ errorMessage: errorMessage});    
				this.toggleUiBlock(false);
			})
	}	

	getSelectedItemSideMenu(nqTemplateNameManuItem) {		
		this.setState({clickedNqTemplateMenuItem: nqTemplateNameManuItem});		
		this.openTemplatesList();		
		this.getNqTemplatesByType(nqTemplateNameManuItem);
	}	

	isItemSelected(NqTemplateName) {    
		if (this.state && this.state.clickedNqTemplateMenuItem && 
			this.state.clickedNqTemplateMenuItem.name === NqTemplateName) {
			return true;
		} else {
			return false;
		}
	}

	toggleUiBlock(blockUi) {
		this.setState ({uiBlocked: blockUi});
	}

	getListItems() {		
		return this.state.menuItems.map(nqTemplateMenuItem => 
			<div key={nqTemplateMenuItem.name}>
				<SideListItemNqTemplate key={nqTemplateMenuItem.name}
					id={nqTemplateMenuItem.name}		
					name={nqTemplateMenuItem.name}
					nqTemplateMenuItem={nqTemplateMenuItem}			
					getSelectedItem={this.getSelectedItemSideMenu.bind(this)}  
					isItemSelected={this.isItemSelected(nqTemplateMenuItem.name)}					
				>
					{nqTemplateMenuItem.name}
				</SideListItemNqTemplate>
			</div>
		)
	}	

	updateSelectedNqTemplate(selectedNqTemplate) {		
		this.setState({selectedNqTemplate: selectedNqTemplate})
	}	

	saveNqTemplate(template, action) {		
		let validation = ValidateNqTemplate(template);		
		
		if (action === "update") {
            return NqTemplateService.updateNqTemplate(template)
                .then(response =>{                    
                    this.toggleUiBlock(false);
                    this.setState({successMessage: 'The NQ template was saved successfully' });                    
					this.refreshTemplates();
					return true;
                })
                .catch(error => {
					let errorMessage = GetErrorMessage(error);
					console.log("errorMessage on catch");
					console.log(errorMessage);
					this.toggleUiBlock(false);
					this.setState({ errorMessage: errorMessage});
					return false;
                });
        }
        else {
            return NqTemplateService.createNqTemplate(template)
                .then(response => {                    
                    this.toggleUiBlock(false);
                    this.setState({successMessage: 'The NQ Template was created successfully' });                                        
                    this.refreshTemplates();
					return true;
                })
                .catch(error => {
					let errorMessage = GetErrorMessage(error);
					console.log("errorMessage on catch");
					console.log(errorMessage);
					this.toggleUiBlock(false);
					this.setState({ errorMessage: errorMessage});
					return false;
                });
        }
	}

	refreshTemplates() {
		this.queryActiveNqTemplates();
		if (this.state.clickedNqTemplateMenuItem) {
			this.getNqTemplatesByType(this.state.clickedNqTemplateMenuItem);
		}
	}

	deleteNqTemplate(templateName) {		
        this.toggleUiBlock(true);       
        NqTemplateService.deleteNqTemplate(templateName)
        .then(response =>{            
            this.setState({successMessage: 'The NQ Template was deleted successfully' });            
            this.refreshTemplates();
			setTimeout(() => {
				this.toggleUiBlock(false);				
			}, 2000);            
        })
        .catch(error => {
            let errorMessage = GetErrorMessage(error);
            console.log("errorMessage on catch");
            console.log(errorMessage);
            this.toggleUiBlock(false);
            this.setState({ errorMessage: errorMessage});
        });
	}

    render() {
        return (
			<BlockUi tag="div" blocking={this.state.uiBlocked}>  
			<main className="app-body">                   
				<SideBar>				
					<SideBarHead tagName="SideBarHead" headline="NQ Templates"/>                  
					<SideList tagName="SideList">                  
						<Loading active={this.state.loading}></Loading>
						{<div key="noFolderItems">
							{this.getListItems()}
						</div>}
					</SideList>					
				</SideBar>

				<NqTemplateContext.Provider value={
					{
						updateSelectedNqTemplate: this.updateSelectedNqTemplate.bind(this), 
						saveNqTemplate: this.saveNqTemplate.bind(this), 
						deleteNqTemplate: this.deleteNqTemplate.bind(this),
						selectedNqTemplate: this.state.selectedNqTemplate,
						canChangeData:this.props.userPermissions?.CanChangeNqTemplate
					}
				}>                			
					<div className="app-content scrollbox">				
						{this.state.templateListOpen && ( <NqTemplateList 
							metadata={this.state.metadata}
							templates={this.state.templatesByType}
							nqTemplateMenuItem={this.state.clickedNqTemplateMenuItem}							
							closeList={this.closeTemplatesList.bind(this)} /> )}
						{!this.state.templateListOpen && ( <NqTemplateHome templates={this.state.activeTemplates} toggleUiBlock={this.toggleUiBlock.bind(this)} metadata={this.state.metadata} />)}
					</div>
				</NqTemplateContext.Provider>
				
				{this.state.successMessage && ( <ShowSuccess clearMessage={() => this.setState({successMessage: ''})} message={this.state.successMessage} /> )}
				{this.state.errorMessage && ( <ShowError clearErrorMessage={() => this.setState({errorMessage: ''})} errorMessage={this.state.errorMessage} /> )}
				
			</main>
			</BlockUi>
        );
    }
}

export default NqTemplate;