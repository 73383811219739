import React, { Component } from 'react';
import {Spacer, Button, InputInline} from 'app/components/Basics/'
import { Icons } from '@ecosystem/ui-lib/core/components/Icon';
import './GlobalControlLineComponent.scss';
import { ReactComponent as SaveIcon } from 'app/assets/icons/Save.svg';
import { ReactComponent as DuplicateIcon } from 'app/assets/icons/Duplicate-Plus.svg';
import {RoleFormContext} from '../../RoleContext'
import ValidateRole from '../../../role/RoleValidation'
 
class GlobalControlLine extends Component {

    constructor(props){
        super(props);

        this.state = {           
        }        
    }

    componentWillReceiveProps(nextProps) {        
        if (nextProps && nextProps.errors) {            
            var error = nextProps.errors.find(e => e.field === "name")
            if (error) {
                this.setState({errorRoleName: error.errorMessage})
            }
        }
    }

	isSaveDisabled() {
		if (this.isChangeDisabled())
		{
			return true;
		}
		let result = false;
		var errors = ValidateRole(this.context.selectedRole);		
		if (errors.length > 0) {
			result = true;
		}		
		if (!this.props.didSelectedRoleChange()) {
			result = true;
		}
		return result;
	}

	isButtonDisabled() {	
		if (this.isChangeDisabled())
		{
			return true;
		}
		return this.context.selectedRole.id === null;
	}

	getRoleName() {		
        return this.context.selectedRole.name !== '' ? 
            this.context.selectedRole.name : "New Role";
    }

	roleNameChange(isEditorOpen, newName) {
        if (!isEditorOpen) {
            var selectedRole = this.context.selectedRole;
            selectedRole.name = newName;
            this.context.updateSelectedRole(selectedRole);
        }
    }

	isChangeDisabled() {
		return !this.context.canChangeData || this.context.selectedRole.isSysAdmin === 1;
	}

    render() {
        return (
            <div className="GlobalControlLineComponent form-row">        
				<InputInline disabled={this.isChangeDisabled()} enableOnBlur className="name-field" value={this.getRoleName()} onModeChange={this.roleNameChange.bind(this)} />
                <div className="contol-buttons">                    
                    <Button disabled={this.isSaveDisabled()} title="Save all changes in the role" icon={<SaveIcon/>} onClick={this.props.handleSubmitAction}>Save</Button>                    
                    <Button title="Close current editor and discard all unsaved changes" onClick={this.props.closeEditorButtonOnClick} simple icon={Icons.Close}>Close</Button>
                    <Spacer width={20}/>                   
                    <Button disabled={this.isButtonDisabled()} title="Delete the role is irreversible" small secondary icon={Icons.Delete} onClick={this.props.deleteRole}>Delete</Button>
                    <Button disabled={this.isButtonDisabled()} title="Create a new role as duplicate from this role" small secondary icon={<DuplicateIcon/>} onClick={this.props.duplicateRole}>Duplicate</Button>                   
                </div>
                
            </div>  
        );
    }
}
GlobalControlLine.contextType = RoleFormContext;
export default GlobalControlLine;