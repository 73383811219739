function ValidateNqTemplate(template) {
	var validationErrors = [];                
	if (!template.name || template.name === '') {                    
		addErrorMessage(validationErrors, 'name', 'Template name is empty');
	}
	if (!template.nqTemplateId || template.nqTemplateId === '') {
		addErrorMessage(validationErrors, 'nqTemplateId', 'Template Id is empty');
	}
	if (!template.version || template.version === '') {
		addErrorMessage(validationErrors, 'version', 'Template version is empty');
	}
	if (!template.surveyPhaseId || template.surveyPhaseId === '') {
		addErrorMessage(validationErrors, 'version', 'Template survey phase is empty');
	}
	
	return validationErrors;
}

function addErrorMessage(validationErrors, field, message) {      
	validationErrors.push({ field: field, errorMessage: message});      
}

export default ValidateNqTemplate;