import React, { Component } from 'react';
import {SideBar, SideBarHead, SideList, SideListItemGeneric} from 'app/components/SideBar/';
import ChannelForm from './ChannelForm';
import NewChannel from './ChannelModel';
import ChannelHome from './components/ChannelHome/ChannelHomeComponent';
import ChannelService from "../../../services/channel.service";
import  GetErrorMessage  from '../../../helpers/error-message'
import {ShowError, ShowSuccess} from "../../../components/toast"
import { Loading } from '@ecosystem/ui-lib/core/components/Loading';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import Modal from '../../../components/Modal';
import { Button } from 'app/components/Basics/'
import { ReactComponent as PagesIcon } from 'app/assets/icons/pagepage.svg';
import './ChannelComponent.scss';

class Channel extends Component {
	constructor (props) {
	super(props);
		this.state = {
			search: '',			
			allData: [],
			filteredData: [],
			folders: [],
			loading: false,
			errorMessage: null,
			selectedChannel: null,
			originalSelectedChannel: null,
			uiBlocked: false,
			editorOpen: false,			
			metadata: {
				permissions: []
			}
		}		
	}

	setSearchState(event) {    
		var value = event.target.value;    
		this.setState({search: value});
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.search !== prevState.search) {     
			this.filterChannels(this.state.search);
		}

		if (this.state.editorOpen !== prevState.editorOpen) {
			if (this.state.editorOpen) {
			//retrigger selected question if editor was closed        
			this.setState({selectedChannel: this.state.selectedChannel});
			}
		}    
	}

	filterChannels(term) {		
		if (term !== '') {
			const filteredData = this.state.allData
			.filter(i => (i.name  && i.name.toLowerCase().includes(term.toLowerCase())));
			let newFilteredData = [...filteredData];
			this.setState({ filteredData: newFilteredData});      
		}
		else {
			let newFilteredData = [...this.state.allData];
			this.setState({ filteredData: newFilteredData});     
		}
	}

	componentDidMount() {		
		this.queryMetadata();
		this.queryChannels('');
	}	

	openEditor() {
		this.setState({editorOpen: true});		
	}	

	closeEditorButtonOnClick() {
		if (this.shouldLosePossibleChangesOnEditor(this.closeEditor)) {
			this.closeEditor();
		}
	}

	closeEditor() {
		this.setState({ editorOpen: false });
		this.setSelectedItem(null);
	}

	queryMetadata() {		
		ChannelService.getMetadata()
				.then(response =>{					
					this.setState({
						metadata: response.data					
					});
					return response;          
				})
				.catch(error => {			  
					let errorMessage = GetErrorMessage(error);
					console.log("errorMessage on catch");
					console.log(errorMessage);
					this.setState({ loading: false, errorMessage: errorMessage});            
			})
	}	

	queryChannels(term) {
		this.setState({ loading: true, filteredData: [] });
		ChannelService.find(term)
			.then(response =>{				
				this.setState({ filteredData: response.data,
					allData:  response.data,					
					loading: false				
				}, function() { this.filterChannels(this.state.search); });
				return response;          
			})
			.catch(error => {			  
				let errorMessage = GetErrorMessage(error);
				console.log("errorMessage on catch");
				console.log(errorMessage);
				this.setState({ loading: false, errorMessage: errorMessage});            
			})
	}

	getChannelById(id) {		
		this.toggleUiBlock(true)
		ChannelService.getChannelById(id)
			.then(response => {				
				response.data.imageHash = Date.now();
				this.setState({originalSelectedChannel: cloneDeep(response.data)});
				this.updateSelectedChannel(response.data);
				this.updateSavedChannelOnList(response.data);				
				this.toggleUiBlock(false)
				return response;          
			})
			.catch(error => {			  
				let errorMessage = GetErrorMessage(error);
				console.log("errorMessage on catch");
				console.log(errorMessage);
				this.setState({ errorMessage: errorMessage});    
				this.toggleUiBlock(false)        
			})
	}	

	getSelectedItem(channelId) {		
		this.setState({clickedChannelId: channelId});		
		this.openEditor();
		if (this.shouldLosePossibleChangesOnEditor(()=>this.getChannelById(channelId))) {			
			this.getChannelById(channelId)			
		}	
	}

	setSelectedItem(selectedChannel) {		
		this.updateSelectedChannel(selectedChannel);
	}	

	shouldLosePossibleChangesOnEditor(callbackAfterLoseChangesConfirmation) {		
		if (this.didSelectedChannelChange()) {
			this.showLoseChangeConfirmationModal(callbackAfterLoseChangesConfirmation);
			return false;	
		}
		else {	
			return true;
		}	
	}

	didSelectedChannelChange() {
		let selectedChannel = this.state.selectedChannel;
		if (!selectedChannel) return false;
		return !isEqual(selectedChannel, this.state.originalSelectedChannel);		
	  }

	showLoseChangeConfirmationModal(callbackAfterLoseChangesConfirmation) {
		this.setState({ 
			loseChangesModalText: `There are unsaved changes to the channel '${this.state.selectedChannel.name}'. Are you sure do you want to leave without saving?`,
			callbackAfterLoseChangesConfirmation: callbackAfterLoseChangesConfirmation
		});
	}

	confirmLoseChangesModalHandler() {
		let filteredData = this.rollbackToOriginalChannel(this.state.filteredData, this.state.originalSelectedChannel);
		let allData = this.rollbackToOriginalChannel(this.state.allData, this.state.originalSelectedChannel);	

		this.setState({allData: allData, filteredData: filteredData
		}, this.state.callbackAfterLoseChangesConfirmation);
		this.setState({clickedChannelId: null});
		this.hideLoseChangesModal();
	}

	rollbackToOriginalChannel(list, originalSelectedItem) {
		const index = list.findIndex(item => item.id === this.state.selectedChannel.id);	
		list[index] = originalSelectedItem;
		return list;
	}

	hideLoseChangesModal() {
		this.setState({loseChangesModalText: ''})
	}

	isItemSelected(channelId) {    
		if (this.state && this.state.selectedChannelSet && 
			this.state.selectedChannel.id === channelId) {
			return true;
		} else {
			return false;
		}
	}

	toggleUiBlock(blockUi) {
		this.setState ({uiBlocked: blockUi});
	} 

	addNewChannelHandler = event => {
		if (this.shouldLosePossibleChangesOnEditor(() => this.addNewChannel())) {
			this.addNewChannel()
		}    
		event.preventDefault();
	}

	addNewChannel() {
		const newChannel = NewChannel();		
		this.setSelectedItem(null);
		this.setSelectedItem(newChannel);
		this.openEditor();
	}

	removeDeletedChannel(channelId) {    
		const newFilteredData = this.state.filteredData.filter(i => i.id !== channelId);
		const newAllData = this.state.allData.filter(i => i.id !== channelId);
		this.setState({ filteredData: newFilteredData,
			allData: newAllData
			});    
	}

	updateSavedChannelOnList(selectedChannel) {
		let newFilteredData = [...this.updateChannelList(this.state.filteredData, selectedChannel)];	
		let newAllData = [...this.updateChannelList(this.state.allData, selectedChannel)];
		this.setState({filteredData: []});
		this.setState({filteredData: newFilteredData, 
			allData: newAllData
		});
	}

	updateChannelList(list, selectedChannel) {		
		var index = list.findIndex(q => q.id === selectedChannel.id);
		if (index === -1) {
			list.push(selectedChannel);      
		}
		else {
			list[index] = selectedChannel;      
		}
		return list;
	}
	
	getListItems() {
		return this.state.filteredData.map(channel => 
			<div key={channel.id}>
				<SideListItemGeneric key={channel.id}
					id={channel.id}					
					getSelectedItem={this.getSelectedItem.bind(this)}				
					isItemSelected={this.isItemSelected(channel.id)}
					menuItemId={channel.id}
				>
					{channel.name}
				</SideListItemGeneric>
		</div>
		)
	}

	updateSelectedChannel(selectedChannel) {		
		this.setState({selectedChannel: selectedChannel})
	}	

    render() {

        return (
			<BlockUi tag="div" blocking={this.state.uiBlocked}>  
			<main className="app-body">         
				<div className={`${this.props.isSurveyTest ? "survey-test-sidebar" : ""}`}>
					<SideBar>
						<SideBarHead  canChangeData={this.props.userPermissions?.CanChangeChannel} tagName="SideBarHead" headline="Channel" addButton addButtonLabel="Channel" addButtonCallBack={this.addNewChannelHandler.bind(this)}
							onChange={this.setSearchState.bind(this)}
						/>                  
						<SideList tagName="SideList">                  
							<Loading active={this.state.loading}></Loading>							
							<div>								
								{this.getListItems()}
							</div>
						</SideList>					
					</SideBar>
				</div>          

				<div className="app-content scrollbox">
					{this.state.editorOpen && ( <ChannelForm openEditor={this.openEditor.bind(this)} 
							didSelectedChannelChange={this.didSelectedChannelChange.bind(this)}
							closeEditorButtonOnClick={this.closeEditorButtonOnClick.bind(this)} 
							closeEditor={this.closeEditor.bind(this)}
							removeDeletedChannel={this.removeDeletedChannel.bind(this)} 
							toggleUiBlock={this.toggleUiBlock.bind(this)} 
							selectedChannel={this.state.selectedChannel}
							updateSelectedChannel={this.updateSelectedChannel.bind(this)}
							updateSavedChannelOnList={this.updateSavedChannelOnList.bind(this)}
							getChannelById={this.getChannelById.bind(this)}							
							canChangeData={this.props.userPermissions?.CanChangeChannel}
							metadata={this.state.metadata}
							/> )}
					{!this.state.editorOpen && ( <ChannelHome></ChannelHome>)}					
				</div>				
				
				{this.state.successMessageFolder && ( <ShowSuccess clearMessage={() => this.setState({successMessageFolder: ''})} message={this.state.successMessageFolder} /> )}
				{this.state.errorMessage && ( <ShowError clearErrorMessage={() => this.setState({errorMessage: ''})} errorMessage={this.state.errorMessage} /> )}
				{this.state.loseChangesModalText && ( <Modal size="small" content={this.state.loseChangesModalText} confirmModalHandler={this.confirmLoseChangesModalHandler.bind(this)} cancelModalHandler={this.hideLoseChangesModal.bind(this)} />)}
			</main>
			</BlockUi>
        );
    }
}

export default Channel;