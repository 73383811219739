function ValidateInDepthSet(selectedInDepthSet) {
	var validationErrors = [];                
	if (!selectedInDepthSet.name || selectedInDepthSet.name === '') {                    
		addErrorMessage(validationErrors, 'name', 'In-depth set name is empty');
	}
	if (!selectedInDepthSet.startDate || selectedInDepthSet.startDate === '') {
		addErrorMessage(validationErrors, 'startDate', 'In-depth start date is empty');
	}
	if (!selectedInDepthSet.surveyTypeId || selectedInDepthSet.surveyTypeId === '') {
		addErrorMessage(validationErrors, 'surveyTypeId', 'In-depth survey type is empty');
	}

	// if (!selectedInDepthSet.questions || selectedInDepthSet.questions.length === 0) {
	// 	addErrorMessage(validationErrors, 'questions', 'In-depth questions cannot be empty');
	// }	
	
	return validationErrors;
}

function addErrorMessage(validationErrors, field, message) {      
	validationErrors.push({ field: field, errorMessage: message});      
}

export default ValidateInDepthSet;