import React, { Component } from 'react';
import './videoplayer.scss';
import IsWistiaEmbedVideo from 'app/helpers/valid-wistia-video'
import { ReactComponent as PlayButton } from 'app/assets/icons/play-button.svg';
import Modal from 'app/components/Modal';

class VideoPlayer extends Component {
  constructor(props) {
    super(props);
		this.state = {
			videoPlayerModal: ""
		};
  	}
  
  	getFullVideoUrl() {
		return this.props.url + "?seo=true&videoFoam=true";
  	}
	openVideoPlayerModal() {
		this.setState({videoPlayerModal: 
			<iframe		
			className='video-player'
			title="Embedded Video"
			width={560}
			height={315}
			src={this.getFullVideoUrl()}
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen>				
			</iframe>
		}); 
	}

	closeVideoPlayerModal = () => {
		this.setState({videoPlayerModal: ""}); 
	}

  	render() {
		const { url, thumbnailUrl, width = 280, height = 158 } = this.props;		
		if (url == null || url === "") {
			return(<div></div>);
		} 
		else if (IsWistiaEmbedVideo(url)) {
			return (
				<div>
					{!this.state.videoPlayerModal && (
						<div title={url}><img src={thumbnailUrl} onClick={this.openVideoPlayerModal.bind(this)} width={70} height={50} style={{cursor: 'pointer'}}/></div>
					)}	

					{this.state.videoPlayerModal && ( <Modal content={this.state.videoPlayerModal} size="small" hideConfirmButton cancelButtonLabel="Close" cancelModalHandler={this.closeVideoPlayerModal.bind(this)} />)}
				</div>
			);
		} 
		else {
			return (    
				<svg className='video-player' width={width} height={height} viewBox="0 0 100">
					<rect width="100%" height="100%" fill="#ebecf0" />
					<text x="50%" y="40%" dominantBaseline="middle" textAnchor="middle" fill="#ff0000" fontSize="36px" fontWeight="bold">
						&#x2716;
					</text>
					<text x="50%" y="70%" dominantBaseline="middle" textAnchor="middle" fill="#333" fontSize="24px"fontWeight="bold">
						Invalid URL
					</text>
				</svg>
			);
		}
	};
}
export default VideoPlayer;