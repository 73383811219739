import React, { Component } from 'react';
import Spacer, {TabBar} from 'app/components/Basics'
import { ReactComponent as HelpIcon } from 'app/assets/icons/Help.svg';
import { ReactComponent as PowerOffIcon } from 'app/assets/icons/PowerOff.svg';
import './NavigationComponent.scss';
import AuthService from 'app/services/auth.service';
import { history } from '../../../helpers/history'


class Navigation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: props.selectedItem
        };        
		
    }

	getModules() {
		let modules = [];		
		if (!this.props.panelConfig || this.props.disableNavigation) {
			return modules;
		}
		if (this.props.userPermissions?.CanReadQuestion && this.props.panelConfig.modules.includes('question')) {
			modules.push({
				id: 1,
				label:'Questions',
				url: '/question'
			});
		}
		if (this.props.userPermissions?.CanReadIndepth && this.props.panelConfig.modules.includes('indepth')) {
			modules.push({
				id: 3,
				label:'In-Depth',
				url: '/indepth'
			});
		}
		if (this.props.userPermissions?.CanReadGenerics && this.props.panelConfig.modules.includes('generics')) {
			modules.push({
				id: 4,
				label:'Generics',
				url: '/generics'   
			});
		}
		if (this.props.userPermissions?.CanReadFiveMinex && this.props.panelConfig.modules.includes('fiveminex')) {
			modules.push({
				id: 5,
				label:'5minEx',
				url: '/fiveminex'   
			});
		}
		if (this.props.userPermissions?.CanReadIndepth && this.props.panelConfig.modules.includes('indepth')) {
			modules.push({
				id: 6,
				label:'Survey Test',
				url: '/indepth/surveyTest'
			});
		}
		
		let downloadModules = this.getDownloadModules();
		if (downloadModules.length > 0) {
			modules.push({
				id: 8,
				label:'Download',
				url: '/download',
				subMenu: downloadModules
			});
		}

		let adminModules = this.getAdminModules();
		if (adminModules.length > 0) {
			modules.push({
				id: 7,
				label:'Admin',
				url: '/admin',
				subMenu: adminModules
			});
		}
		return modules;
	}

	getDownloadModules() {
		var downloadModules = [];
		if (this.props.userPermissions?.CanDownloadCodeBook && this.props.panelConfig.modules.includes('surveyhistory')) {
			downloadModules.push({
				id: 800,
				label:'Survey History',
				url: '/surveyhistory'
			});
		}

		if (this.props.userPermissions?.CanReadRespondentResponse && this.props.panelConfig.modules.includes('responsedownload')) {
			downloadModules.push({
				id: 801,
				label:'Download Responses',
				url: '/responsedownload'
			});
		}

		if (this.props.userPermissions?.CanReadReportDownload && this.props.panelConfig.modules.includes('reportdownload')) {
			downloadModules.push({
				id: 802,
				label:'Download Report',
				url: '/reportdownload'
			});
		}
		return downloadModules;
	}

	getAdminModules() {
		var adminModules = [];
		if (this.props.userPermissions?.CanReadNqTemplate && this.props.panelConfig.modules.includes('nqtemplate')) {
			adminModules.push({
				id: 701,
				label:'NQ Templates',
				url: '/nqtemplate'   
			});
		}
		if (this.props.userPermissions?.CanReadRole && this.props.panelConfig.modules.includes('role')) {
			adminModules.push({
				id: 702,
				label:'Roles',
				url: '/role'   
			});
		}
		if (this.props.userPermissions?.CanReadUser && this.props.panelConfig.modules.includes('user')) {
			adminModules.push({
				id: 703,
				label:'Users',
				url: '/user'   
			});
		}
		if (this.props.userPermissions?.CanReadSubSurvey && this.props.panelConfig.modules.includes('subsurvey')) {
			adminModules.push({
				id: 704,
				label:'Sub-Surveys',
				url: '/subsurvey'
			});
		}
		if (this.props.userPermissions?.CanReadRegion && this.props.panelConfig.modules.includes('region')) {
			adminModules.push({
				id: 705,
				label:'Regions',
				url: '/region'   
			});
		}
		if (this.props.userPermissions?.CanReadChannel && this.props.panelConfig.modules.includes('channel')) {
			adminModules.push({
				id: 705,
				label:'Channels',
				url: '/channel'   
			});
		}
		if (this.props.userPermissions?.CanChangeDemoQuestion && this.props.panelConfig.modules.includes('demoset')) {
			adminModules.push({
				id: 706,
				label:'Demographic Sets',
				url: '/demoset'   
			});
		}
		if (this.props.userPermissions?.CanReadMediaType && this.props.panelConfig.modules.includes('mediatype')) {
			adminModules.push({
				id: 707,
				label:'Media Types',
				url: '/mediatype'   
			});
		}
		return adminModules;
	}


    logout(event) {
        AuthService.logout();
        history.push('/login');
    }

    render() {		

        return (			
            <nav className="NavigationComponent">
                <TabBar key="NavigationComponent" className="main" tabs={this.getModules()} activeTab={this.state.selected} onTabActivate={item=>this.setState({selected:item.url})}/>
                <ul className="secondary">
                    <li className='navi-item help'>
                        <HelpIcon/>
                        Help Center
                    </li>                   
                    <li className='navi-item user-account'>                       
                        {AuthService.getLoggedUserName()}
                    </li>
                    <Spacer width={20} line="right"/>       
                    <li className='navi-item logout' onClick={this.logout} style={{cursor:'pointer'}}>
                        <PowerOffIcon/>
                    </li>
                                
                </ul>
            </nav>
        );
    }
}

export default Navigation;