function ValidateMediaType(selectedMediaType) {
	var validationErrors = [];
	if (!selectedMediaType.id || selectedMediaType.id === '') {                    
		addErrorMessage(validationErrors, 'id', 'Media Type Id is empty');
	}           
	if (!selectedMediaType.name || selectedMediaType.name === '') {                    
		addErrorMessage(validationErrors, 'name', 'Media Type name is empty');
	}
	if (!selectedMediaType.name || selectedMediaType.sortOrder == null) {                    
		addErrorMessage(validationErrors, 'sortOrder', 'Media Type sort order is empty');
	}
	if (!selectedMediaType.name || selectedMediaType.startTime === '') {                    
		addErrorMessage(validationErrors, 'startTime', 'Media Type start time is empty');
	}
	return validationErrors;
}

function addErrorMessage(validationErrors, field, message) {      
	validationErrors.push({ field: field, errorMessage: message});      
}

export default ValidateMediaType;