import React, { Component } from 'react';
import { Button } from 'app/components/Basics';
import { ButtonIcon } from '@ecosystem/ui-lib/core/components/ButtonIcon';
import { Icons } from '@ecosystem/ui-lib/core/components/Icon';
import { ReactComponent as PlusIcon } from 'app/assets/icons/Plus.svg';
import { Card } from '@ecosystem/ui-lib/core/components/Card';
import { Table, GenericCell } from '@ecosystem/ui-lib/core/components/Table';
import Modal from 'app/components/Modal';
import { QuestionSearch } from "app/components/QuestionSearch";
import {DemoSetFormContext} from '../../DemoSetContext'
import './TabQuestionsComponent.scss';

class TabQuestions extends Component {
    constructor(props){
        super(props);
        this.state = {     
            errorQuestionText: '',
			showQuestionModal: false,			
			selectedQuestionToAdd: null
        }    
    }

	openAddQuestionModal() {
		this.setState({showQuestionModal: true});
	}	

	closeQuestionModal() {		
		this.setState({
			showQuestionModal: false,
			selectedQuestionToAdd: null
		});
	}	

	addQuestionHandler(question) {		
		var selectedDemoSet = this.context.selectedDemoSet;		
		selectedDemoSet.questions.push(question);
		this.context.updateSelectedDemoSet(selectedDemoSet);		
	}

	removeQuestionHandler(questionId) {
        var selectedDemoSet = this.context.selectedDemoSet;
		if (selectedDemoSet.questions) {
			let newQuestionList = selectedDemoSet.questions.filter(function(q) { return q.id !== questionId });			
        
            selectedDemoSet.questions = newQuestionList
            this.context.updateSelectedDemoSet(selectedDemoSet);        
        }
    }	

	getQuestionsTableColumns() {
		var columns = [];
		columns.push(
			{   headerKey: `column-code`,
				className: "AppFormTableHeader column-code",
				name: `Code`
			});
			columns.push({
				headerKey: `column-question-text`,
				className: "AppFormTableHeader column-question-text",
				name: `Question`
			});
			columns.push(
			{
				headerKey: `column-remove`,
				className: "AppFormTableHeader column-remove",
				name: `Remove`
			});		
		return columns;
	}

	addQuestionRow(question) {        
        const cols = [];
        cols.push(<GenericCell className="column-code" primary={question.code}/>);       
		cols.push(<GenericCell className="column-question-text" primary={question.text}/>);
        cols.push(<GenericCell className="column-remove" primary={<div><ButtonIcon onClick={() => this.removeQuestionHandler(question.id)}  icon={Icons.Close}/></div>}/>);        
        return cols;
    }	
	
	userCannotChangeData() {
		return !this.props.canChangeData;
	}

    render() {		
		
		const rows = [];       
        this.context.selectedDemoSet.questions.forEach((question) => {
            rows.push(this.addQuestionRow(question));
        });
		
        return (
            <div className="TabDemoSetQuestionsComponent content-box">
                <Card>
					<div className="form-row">
						<div className="form-row-left">
							
						</div>
						<div className="form-row-right">
							<Button disabled={this.userCannotChangeData() || this.context.selectedDemoSet.netQuestSurveyId} secondary icon={<PlusIcon/>} onClick={() => this.openAddQuestionModal()}>Question</Button>
						</div>
                	</div>
					<Table
						className="QuestionList"
						columns={this.getQuestionsTableColumns()}
						rows= {rows}                
						sortingOptions={{headerKey: null}}
						isHeaderSticky={false}
						isFirstColumnSticky={false}
					/>					

					<div style={{display: rows && rows.length > 0 ? 'none' : 'block' }}>
						<p className='EmptyQuestionListInfoTitle'>Question list is empty</p>
						<p className='EmptyQuestionListInfoText'>Please, add questions via <span className='span-box'>+Question</span></p>
					</div>
                </Card>			
				{this.state.showQuestionModal && ( <Modal content={
						<QuestionSearch selectedQuestions={this.context.selectedDemoSet.questions} 
							addQuestionHandler={this.addQuestionHandler.bind(this)} 
							removeQuestionHandler={this.removeQuestionHandler.bind(this)}
							excludeDemoQuestions={false}
							onlyDemoQuestions={true}
						/>
					} 
					disableConfirmButton={this.state.selectedQuestionToAdd == null}					
					hideConfirmButton
					cancelButtonLabel="Close"
					cancelModalHandler={this.closeQuestionModal.bind(this)} 					
					/>)
				}
            </div>			
        );
    }
}
TabQuestions.contextType = DemoSetFormContext;
export default TabQuestions;