import React, { Component } from 'react';
import { MemoryRouter,Switch,Route,Redirect,withRouter} from "react-router-dom";
import {TabBar} from 'app/components/Basics';
import GlobalControlLine from './components/GlobalControlLine/GlobalControlLineComponent';
import GlobalFieldLine from './components/GlobalFieldLine/GlobalFieldLineComponent';
import {DemoSetFormContext} from './DemoSetContext'
import DemoSetService from "app/services/demoSet.service";
import  GetErrorMessage  from 'app/helpers/error-message'
import {ShowError, ShowSuccess} from "app/components/toast"
import NewDemoSet from './models/DemoSetModel';
import Modal from 'app/components/Modal';
import cloneDeep from 'lodash/cloneDeep';
import TabQuestions from './components/TabQuestions/TabQuestionsComponent';
import isEqual from 'lodash/isEqual';
import ValidateDemoSet from './DemoSetValidation'

class DemoSetForm extends Component {
    tabs = [];	
    constructor(props){
        super(props);
        this.state = {           
            activeTab:'questions',
            selectedDemoSet: NewDemoSet(),
            errors: []		
        }
    }

    getTabs(){        
        return [
            { id:"questions", label: 'Questions', url: 'questions', disabled:false, error:false},            
        ];
    }

	componentWillReceiveProps(nextProps) {
		if (nextProps.selectedDemoSet != null) {
			this.setState({ 
				selectedDemoSet: nextProps.selectedDemoSet,
				activeTab: "questions"
			});
		}	

        if (!isEqual(this.props.selectedDemoSet, nextProps.selectedDemoSet)) {
            this.setState({errors: []});            
        }
    }	

    updateSelectedDemoSet(newSelectedDemoSet) {
		this.setState({selectedDemoSet: newSelectedDemoSet})
    }

    addErrorMessage(field, message) {
        var errors = this.state.errors;
        errors.push({ field: field, errorMessage: message});
        this.setState({errors: errors})
    }	

    SaveDemoSet(demoSet) {
        this.props.toggleUiBlock(true);
        if (demoSet.id) {
            DemoSetService.updateDemoSet(demoSet)
                .then(response =>{                    
                    this.props.toggleUiBlock(false);
                    this.setState({successMessage: 'The demographic set was saved successfully' });                    
					this.props.getDemoSetById(demoSet.id);
                })
                .catch(error => {
					let errorMessage = GetErrorMessage(error);
					console.log("errorMessage on catch");
					console.log(errorMessage);
					this.props.toggleUiBlock(false);
					this.setState({ errorMessage: errorMessage});
                });
        }
        else {
            DemoSetService.createDemoSet(demoSet)
                .then(response => {					
                    const newDemoSet = response.data;                    
                    this.props.toggleUiBlock(false);
                    this.setState({successMessage: 'The demographic set was created successfully' });                                        
                    this.setState({selectedDemoSet: newDemoSet});					
					this.props.getDemoSetById(newDemoSet.id);
					
                })
                .catch(error => {
					let errorMessage = GetErrorMessage(error);
					console.log("errorMessage on catch");
					console.log(errorMessage);
					this.props.toggleUiBlock(false);
					this.setState({ errorMessage: errorMessage});
                });
        }

    }
    deleteDemoSet = event => {        
        this.setState({ deleteQuestionModalText: `Are you sure you want to delete the demographic set with name '${this.state.selectedDemoSet.name}'?`});
    }

    duplicateDemoSet = event => {        
        let cloned = cloneDeep(this.state.selectedDemoSet);
        cloned.id = null;
        cloned.name = `${cloned.name}_copy`;
		this.props.updateSelectedDemoSet(cloned);
    }

    confirmDelete() {        
        this.hideDeleteModal();
        this.props.toggleUiBlock(true);
        const demoSetId = this.state.selectedDemoSet.id;
        DemoSetService.deleteDemoSet(this.state.selectedDemoSet)
        .then(response =>{            
            this.setState({successMessage: 'The demographic set was deleted successfully' });            
            this.props.removeDeletedDemoSet(demoSetId);
			setTimeout(() => {
				this.props.toggleUiBlock(false);
				this.props.closeEditor();				
			}, 2000);

            
        })
        .catch(error => {
            let errorMessage = GetErrorMessage(error);
            console.log("errorMessage on catch");
            console.log(errorMessage);
            this.props.toggleUiBlock(false);
            this.setState({ errorMessage: errorMessage});
        });
    }

    hideDeleteModal() {
        this.setState({deleteQuestionModalText: ''})
    }    

    handleSubmitAction = event => { 
		var validationErrors = ValidateDemoSet(this.state.selectedDemoSet);
		this.setState({errors: validationErrors});
		if (validationErrors && validationErrors.length === 0) {                    
			this.SaveDemoSet(this.state.selectedDemoSet);
		}        
        return;
    }

    render() {

        this.tabs = this.getTabs(this.state.selectedDemoSet);
        return (        
               
            <div className="AppFormComponent DemoSetForm">                 
                <DemoSetFormContext.Provider value={{updateSelectedDemoSet: this.updateSelectedDemoSet.bind(this), selectedDemoSet: this.state.selectedDemoSet}}>                
                    <form ref={ form => this.formEl = form } onSubmit={ this.handleSubmitAction }>
                        <div className="form-head">
                            <GlobalControlLine errors={this.state.errors} 
                                handleSubmitAction={this.handleSubmitAction.bind(this)} 
                                deleteDemoSet={this.deleteDemoSet.bind(this)}
                                duplicateDemoSet={this.duplicateDemoSet.bind(this)}
                                closeEditorButtonOnClick={this.props.closeEditorButtonOnClick}
								didSelectedDemoSetChange={this.props.didSelectedDemoSetChange}								
								canChangeData={this.props.canChangeData}
								/>
                            <GlobalFieldLine canChangeData={this.props.canChangeData} folders={this.props.folders} key={this.state.selectedDemoSet.id} metadata={this.props.metadata}/>
                        </div>
                        <div className="form-sections">
                            <MemoryRouter>
                                <div className="tab-bar">
                                    <TabBar key="DemoSetForm" activeTab={this.state.activeTab} tabs={this.tabs} onTabActivate={item=>this.setState({activeTab:item.id})}/>
                                    {/* <div title="Load the current tab settings from another question." className="duplicate-tab"><ButtonIcon icon={DuplicateIcon}/></div> */}
                                </div>
                                <div className="section-content">
                                    <Switch>
                                        <Route exact path="/"><Redirect to={this.state.activeTab}/></Route>
                                        <Route exact path="/questions"><TabQuestions canChangeData={this.props.canChangeData} errors={this.state.errors} /></Route>                                        
                                    </Switch>
                                    <Redirect to={this.state.activeTab}/>
                                </div>
                            </MemoryRouter>
                        </div>
                    </form>                    
                </DemoSetFormContext.Provider>

                {this.state.errorMessage && ( <ShowError clearErrorMessage={() => this.setState({errorMessage: ''})} errorMessage={this.state.errorMessage} /> )}
                {this.state.successMessage && ( <ShowSuccess clearMessage={() => this.setState({successMessage: ''})} message={this.state.successMessage} /> )}
                {this.state.deleteQuestionModalText && ( <Modal content={this.state.deleteQuestionModalText} size="small" confirmModalHandler={this.confirmDelete.bind(this)} cancelModalHandler={this.hideDeleteModal.bind(this)} />)}				
            </div>            
        );
    }
}
export default withRouter(DemoSetForm); 