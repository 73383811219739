import React, { Component } from 'react';
import { ButtonIcon } from '@ecosystem/ui-lib/core/components/ButtonIcon';
import { MemoryRouter,Switch,Route,Redirect,withRouter} from "react-router-dom";
import {TabBar} from 'app/components/Basics';
import { ReactComponent as DuplicateIcon } from 'app/assets/icons/Duplicate.svg';
import GlobalControlLine from './components/GlobalControlLine/GlobalControlLineComponent';
import {RegionFormContext} from './RegionContext'
import RegionService from "../../../services/region.service";
import  GetErrorMessage  from '../../../helpers/error-message'
import {ShowError, ShowSuccess} from "../../../components/toast"
import NewRegion from './RegionModel';
import Modal from '../../../components/Modal';
import cloneDeep from 'lodash/cloneDeep';
import TabChannel from './components/TabChannel/TabChannelComponent';
import isEqual from 'lodash/isEqual';
import ValidateRegion from './RegionValidation'
import GlobalFieldLine from './components/GlobalFieldLine/GlobalFieldLineComponent';

class RegionForm extends Component {
    tabs = [];
    constructor(props){
        super(props);
        this.state = {           
            activeTab:'channels',
            selectedRegion: NewRegion(),
            errors: []           
        }        
    }    

	getTabs(){        
        return [
            { id:"channels", label: 'Channels', url: 'channels', disabled:false, error:false}
        ];
    }
	
	componentWillReceiveProps(nextProps) {
		if (nextProps.selectedRegion != null) {
			this.setState({ 
				selectedRegion: nextProps.selectedRegion,
				activeTab: "channels"
			});
		}	

        if (!isEqual(this.props.selectedRegion, nextProps.selectedRegion)) {
            this.setState({errors: []});            
        }
    }	

    updateSelectedRegion(newSelectedRegion) {
		this.setState({selectedRegion: newSelectedRegion})
    }

    addErrorMessage(field, message) {
        var errors = this.state.errors;
        errors.push({ field: field, errorMessage: message});
        this.setState({errors: errors})
    }	

    SaveRegion(region) {                
        this.props.toggleUiBlock(true);
        if (!region._isNew) {
            RegionService.updateRegion(region)
                .then(response =>{                    
                    this.props.toggleUiBlock(false);
                    this.setState({successMessage: 'The region was saved successfully' });                    
					this.props.getRegionById(region.id);
                })
                .catch(error => {
					let errorMessage = GetErrorMessage(error);
					console.log("errorMessage on catch");
					console.log(errorMessage);
					this.props.toggleUiBlock(false);
					this.setState({ errorMessage: errorMessage});
                });
        }
        else {
            RegionService.createRegion(region)
                .then(response => {					
                    const newRegion = response.data;                    
                    this.props.toggleUiBlock(false);
                    this.setState({successMessage: 'The region was created successfully' });                                        
                    this.setState({selectedRegion: newRegion});					
					this.props.getRegionById(newRegion.id);
					
                })
                .catch(error => {
					let errorMessage = GetErrorMessage(error);
					console.log("errorMessage on catch");
					console.log(errorMessage);
					this.props.toggleUiBlock(false);
					this.setState({ errorMessage: errorMessage});
                });
        }

    }
    deleteRegion = event => {
		if (this.props.canChangeData) {    
        	this.setState({ deleteModalText: `Are you sure you want to delete the region with name '${this.state.selectedRegion.name}'?`});
		}
    }

    duplicateRegion = event => {		
        let clonedRegion = cloneDeep(this.state.selectedRegion);		
        clonedRegion.id = null;
        clonedRegion._isNew = true;
        clonedRegion.name = `${clonedRegion.name}_copy`;
		this.props.updateSelectedRegion(clonedRegion);
    }

    confirmDelete() {        
        this.hideDeleteModal();
        this.props.toggleUiBlock(true);
        const regionId = this.state.selectedRegion.id;
        RegionService.deleteRegion(this.state.selectedRegion)
        .then(response =>{            
            this.setState({successMessage: 'The region was deleted successfully' });            
            this.props.removeDeletedRegion(regionId);
			setTimeout(() => {
				this.props.toggleUiBlock(false);
				this.props.closeEditor();				
			}, 2000);

            
        })
        .catch(error => {
            let errorMessage = GetErrorMessage(error);
            console.log("errorMessage on catch");
            console.log(errorMessage);
            this.props.toggleUiBlock(false);
            this.setState({ errorMessage: errorMessage});
        });
    }

    hideDeleteModal() {
        this.setState({deleteModalText: ''})
    }    

    handleSubmitAction = event => { 
		if (this.props.canChangeData) {
			var validationErrors = ValidateRegion(this.state.selectedRegion);
			this.setState({errors: validationErrors});
			if (validationErrors && validationErrors.length === 0) {                    
				this.SaveRegion(this.state.selectedRegion);
			}        
		}
        return;
    }

    render() {

        this.tabs = this.getTabs(this.state.selectedRegion);
        return (        
               
            <div className="AppFormComponent RegionForm">                 
                <RegionFormContext.Provider value={{
					updateSelectedRegion: this.updateSelectedRegion.bind(this), 
					selectedRegion: this.state.selectedRegion,
					canChangeData: this.props.canChangeData}}>                
                    <form ref={ form => this.formEl = form } onSubmit={ this.handleSubmitAction }>
                        <div className="form-head">
                            <GlobalControlLine errors={this.state.errors} 
                                handleSubmitAction={this.handleSubmitAction.bind(this)} 
                                deleteRegion={this.deleteRegion.bind(this)}
                                duplicateRegion={this.duplicateRegion.bind(this)}
                                closeEditorButtonOnClick={this.props.closeEditorButtonOnClick}
								didSelectedRegionChange={this.props.didSelectedRegionChange}/>    
                            <GlobalFieldLine canChangeData={this.props.canChangeData} key={this.state.selectedRegion?._isNew} />                        
                        </div>
                        <div className="form-sections">
                            <MemoryRouter>
                                <div className="tab-bar">
                                    <TabBar key="RegionForm" activeTab={this.state.activeTab} tabs={this.tabs} onTabActivate={item=>this.setState({activeTab:item.id})}/>                                    
                                </div>
                                <div className="section-content">
                                    <Switch>
                                        <Route exact path="/"><Redirect to={this.state.activeTab}/></Route>
                                        <Route exact path="/channels"><TabChannel key={this.state.selectedRegion?.id} metadata={this.props.metadata} errors={this.state.errors} /></Route>                                        
                                    </Switch>
                                    <Redirect to={this.state.activeTab}/>
                                </div>
                            </MemoryRouter>
                        </div>
                    </form>                    
                </RegionFormContext.Provider>

                {this.state.errorMessage && ( <ShowError clearErrorMessage={() => this.setState({errorMessage: ''})} errorMessage={this.state.errorMessage} /> )}
                {this.state.successMessage && ( <ShowSuccess clearMessage={() => this.setState({successMessage: ''})} message={this.state.successMessage} /> )}
                {this.state.deleteModalText && ( <Modal content={this.state.deleteModalText} size="small" confirmModalHandler={this.confirmDelete.bind(this)} cancelModalHandler={this.hideDeleteModal.bind(this)} />)}
            </div>            
        );
    }
}
export default withRouter(RegionForm); 