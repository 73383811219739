import React, { Component } from 'react';
import { Select } from '@ecosystem/ui-lib/core/components/Select';
import Spacer  from 'app/components/Basics'
import './AppTitleComponent.scss';

import AppLogo from 'app/assets/images/AquaLogo.svg';




// const customStyles = {
//     placeholder: (provided, state) => {
//         const textAlign = 'right';
//         return { ...provided, textAlign };
//     },
//     singleValue: (provided, state) => {
//       const color = 'inherit';
//       const textAlign = 'right';
//       return { ...provided, color, textAlign };
//     },
//     menu: (provided, state) => {
//         const minWidth = '200px';
//         return { ...provided, minWidth };
//     }
// };


class AppTitle extends Component {
    render() {		
		let panelName = this.props.panelConfig ? this.props.panelConfig.panelAquaTitle : "";
        return (
            <div className="AppTitleComponent">
                <div className="apptitle">
                    <img src={AppLogo} alt="GfK AQuA"/>
                </div>
                <div className="environment">
                    {/* <Select isDisabled={true} id="envSelect" 
                            styles={customStyles}
                            isSearchable={false}
                            defaultValue={{value:panelName,label:panelName}}
                            options={[                            
								{value:panelName,label:panelName}                          
                        ]}
                    >
                    </Select> */}
					<p className='panelName'>{panelName}</p><Spacer width={40}/>
                </div>
            </div>
        );
    }
}
export default AppTitle;