import React, { Component } from "react";

import './IntroPageComponent.scss';

class IntroPage extends Component{
    render() {
        return (
            <div className="IntroComponent">
                <div className="IntroHeader">{this.props.headerText}</div>
                <br/>
                <div className="IntroContent">
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default IntroPage;