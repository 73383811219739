function NewInDepthSet(defaultFolderId) {
    return {
        id: null,       
        name: "New Question Set",
		startDate: null,
		endDate: null,
		folderId: defaultFolderId,
		questions: [],
		enabled: 0,
		programSelection: {
			isForNonMatchedPrograms: 0,
			titles: [],
			genres: [],
			channels: [],
			broadcasters: []
		},
		uploadedSchemaFileName: null
      }
}
export default NewInDepthSet;