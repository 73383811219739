import authHeader from './auth-header';
import Api from './api';

const API_URL = process.env.REACT_APP_AQUA_API_ENDPOINT

class RegionService {
	
  async getRegionById(regionId: string) {
	return await Api().get(`${API_URL}/region/${regionId}`, { headers:  authHeader() as any });      
  }
  async find(term: string) {
    return await Api().get(`${API_URL}/region/find?text=${term}%`, { headers:  authHeader() as any });      
  }

  async updateRegion(region: any) {
    return await Api().put(`${API_URL}/region/${region.id}`, region, 
    { method: 'PUT',
      headers:  authHeader() as any });      
  }

  async createRegion(region: any) {
    return await Api().post(`${API_URL}/region/`, region, 
    { method: 'POST',
      headers:  authHeader() as any });      
  }

  async deleteRegion(region: any) {
    return await Api().delete(`${API_URL}/region/${region.id}`, 
    { method: 'DELETE',
      headers:  authHeader() as any });      
  }  

  async getMetadata() {
    return await Api().get(`${API_URL}/region/metadata`, { headers:  authHeader() as any });      
  }  
}

export default new RegionService();