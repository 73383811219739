import React, { Component } from 'react';
import { Label, RangeSlider, FileUpload} from 'app/components/Basics';
import { ButtonGroup as EcoButtonGroup} from '@ecosystem/ui-lib/core/components/ButtonGroup';
import { Card } from '@ecosystem/ui-lib/core/components/Card';
import { Input as EcoInput } from '@ecosystem/ui-lib/core/components/Input';
import { Checkbox } from '@ecosystem/ui-lib/core/components/Checkbox';
import { CardOptionsAlternative } from '../TabOptions/TabOptionsComponent';

import './TabScaleComponent.scss';
import { ReactComponent as NoneIcon } from 'app/assets/icons/off.svg';
import { ReactComponent as TextIcon } from 'app/assets/icons/text.svg';
import { ReactComponent as MinusIcon } from 'app/assets/icons/l-minus.svg';
import { ReactComponent as PlusIcon } from 'app/assets/icons/l-plus.svg';
import { ReactComponent as ThumbUpIcon } from 'app/assets/icons/thumb-up.svg';
import { ReactComponent as ThumbDownIcon } from 'app/assets/icons/thumb-down.svg';
import { ReactComponent as SmileyGoodIcon } from 'app/assets/icons/smiley-good.svg';
import { ReactComponent as SmileySadIcon } from 'app/assets/icons/smiley-sad.svg';
import { ReactComponent as ImageIcon } from 'app/assets/icons/image.svg';

class TabScale extends Component {

    constructor(props){
        super(props);

        this.state = {
            minExtrema: "none",
            maxExtrema: "none"

        }
    }
    
    
    render() {
        return (
            <div className="TabScaleComponent content-box">
                <Card>
                    <div className="form-row">
                        <div className="form-row-left">
                            <div className="field-min-value">
                                <Label>Min. Value</Label>
                                <EcoInput id="minvalue" name="minvalue"></EcoInput>
                            </div>
                            <div className="field-pointslider">
                                <RangeSlider 
                                    name="field-points"
                                    className="field-points"
                                    startValue={false} 
                                    endValue={3} 
                                    min={3} 
                                    max={15}  
                                    markDistance={1} 
                                    markLabelDistance={2}
                                    label={<Label>Points</Label>}
                                />
                            </div>
                            <div className="field-revert">
                                <Label tooltip={(<div>Description of <br/>Revert</div>)}>Revert</Label>
                                <div className="default-height centered"><Checkbox checked={false}/></div>
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-row-50">
                            <div className="buttons-extrema extrema-min">
                                <Label>Extrema Labels / Icons</Label>
                                <EcoButtonGroup defaultIconIndex={0} icons=
                                    {[
                                        {
                                            icon: NoneIcon,
                                            title: 'No Extrema',
                                            onClick: ()=>{this.setState({minExtrema:'none'})}
                                        },{
                                            icon: TextIcon,
                                            title: 'Extrema Label',
                                            onClick: ()=>{this.setState({minExtrema:'label'})}
                                        },{
                                            icon: MinusIcon,
                                            title: 'Minus',
                                            onClick: ()=>{this.setState({minExtrema:'icon-plusminus'})}
                                        },{
                                            icon: ThumbDownIcon,
                                            title: 'Thumb Down',
                                            onClick: ()=>{this.setState({minExtrema:'icon-thumb'})}
                                        },{
                                            icon: SmileySadIcon,
                                            title: 'Sad Smiley',
                                            onClick: ()=>{this.setState({minExtrema:'icon-smiley'})}
                                        },{
                                            icon: ImageIcon,
                                            title: 'Custom Image',
                                            onClick: ()=>{this.setState({minExtrema:'image'})}
                                        }
                                    ]}
                                />
                            </div>
                            <div className="field-extrema-label extrema-min">
                                <EcoInput disabled={this.state.minExtrema!=="label"} id="minextremalabel" name="minextremalabel" placeholder="- minimum label -"></EcoInput>
                            </div>
                            <div className="field-extrema-image extrema-min">
                                <FileUpload disabled={this.state.minExtrema!=="image"}/>
                            </div>

                        </div>
                        <div className="form-row-50">
                            <div className="buttons-extrema extrema-max">
                                <Label></Label>
                                <EcoButtonGroup defaultIconIndex={0} icons=
                                    {[
                                        {
                                            icon: NoneIcon,
                                            title: 'No Extrema',
                                            onClick: ()=>{this.setState({maxExtrema:'none'})}
                                        },{
                                            icon: TextIcon,
                                            title: 'Extrema Label',
                                            onClick: ()=>{this.setState({maxExtrema:'label'})}
                                        },{
                                            icon: PlusIcon,
                                            title: 'Plus',
                                            onClick: ()=>{this.setState({maxExtrema:'icon-plusminus'})}
                                        },{
                                            icon: ThumbUpIcon,
                                            title: 'Thumb Up',
                                            onClick: ()=>{this.setState({maxExtrema:'icon-thumb'})}
                                        },{
                                            icon: SmileyGoodIcon,
                                            title: 'Smiley',
                                            onClick: ()=>{this.setState({maxExtrema:'icon-smiley'})}
                                        },{
                                            icon: ImageIcon,
                                            title: 'Custom Image',
                                            onClick: ()=>{this.setState({maxExtrema:'image'})}
                                        }
                                    ]}
                                />
                            </div>
                            <div className="field-extrema-label extrema-max">
                                <EcoInput disabled={this.state.maxExtrema!=="label"} id="maxextremalabel" name="maxextremalabel" placeholder="- maximum label -"></EcoInput>
                            </div>
                            <div className="field-extrema-image extrema-max">
                                <FileUpload disabled={this.state.maxExtrema!=="image"}/>
                            </div>
                        </div>
                    </div>
                    
                </Card>
                <CardOptionsAlternative/>
            </div>
        );
    }
}

export default TabScale;