import React, { Component } from 'react';
import './NumericInputComponent.scss';

class NumericInput extends Component {

    constructor(props){
        super(props);          
    }
	
    render() {
		return (
			<div className={`Input__wrapper ${this.props.disabled ? "Input__wrapper--disabled" : ""}`}>
				<div className='Input__container'>
					<input disabled={this.props.disabled} className='Input__field' value={this.props.value} onChange={this.props.onChange}
						onKeyPress={(event) => {
							if (!/[0-9]/.test(event.key)) {
								event.preventDefault();
							}
						}}
					/>
				</div>
			</div>
        );
    }
}

export default NumericInput;