function ValidateRegion(selectedRegion) {
	var validationErrors = [];                
	if (!selectedRegion.name || selectedRegion.name === '') {                    
		addErrorMessage(validationErrors, 'name', 'Region name is empty');
	}
	return validationErrors;
}

function addErrorMessage(validationErrors, field, message) {      
	validationErrors.push({ field: field, errorMessage: message});      
}

export default ValidateRegion;