import React, { Component } from 'react';
import {Label, DatePickerCalendar } from 'app/components/Basics/';
import { Input as EcoInput } from '@ecosystem/ui-lib/core/components/Input';
import { Select } from '@ecosystem/ui-lib/core/components/Select';
import {ChannelFormContext} from '../../ChannelContext'
import  ImageUrlHandler  from 'app/pages/admin/channel/components/ImageUrlHandler';
import './GlobalFieldLineComponent.scss';

class GlobalFieldLine extends Component {

    static contextType = ChannelFormContext;

    constructor(props){
        super(props);        
    }	

	isChangeDisabled() {
		return !this.context.canChangeData;
	}

    channelCodeHandler = event => {		
        var selectedChannel = this.context.selectedChannel;
		selectedChannel.id = event.target.value ? event.target.value : "";
		this.context.updateSelectedChannel(selectedChannel);
    }

	channelNameHandler = event => {		
        var selectedChannel = this.context.selectedChannel;
		selectedChannel.name = event.target.value ? event.target.value : "";
		this.context.updateSelectedChannel(selectedChannel);
    }

	shortChannelNameHandler = event => {		
        var selectedChannel = this.context.selectedChannel;
		selectedChannel.shortName = event.target.value ? event.target.value : "";
		this.context.updateSelectedChannel(selectedChannel);
    }

	channelSortOrderHandler = event => {		
        var selectedChannel = this.context.selectedChannel;
		selectedChannel.sortOrder = event.target.value ? event.target.value : undefined;
		this.context.updateSelectedChannel(selectedChannel);
    }

	changeChannelTypeHandler(selectedItem) {
		var selectedChannel = this.context.selectedChannel;
		selectedChannel.channelTypeId = parseInt(selectedItem.value);
		this.context.updateSelectedChannel(selectedChannel);
    }

	getSelectedChannelTypeOption() {
        return this.props.metadata.channelTypes
			.find(t=> parseInt(t.value) === this.context.selectedChannel.channelTypeId);
    }

	changeMediaTypeHandler(selectedItem) {
		var selectedChannel = this.context.selectedChannel;
		selectedChannel.mediaTypeId = parseInt(selectedItem.value);
		this.context.updateSelectedChannel(selectedChannel);
    }

	getSelectedMediaTypeOption() {
        return this.props.metadata.mediaTypes
			.find(t=> parseInt(t.value) === this.context.selectedChannel.mediaTypeId);
    }

	startDateHandler(date) {		
		var selectedChannel = this.context.selectedChannel;
		selectedChannel.startDate = date;
		this.context.updateSelectedChannel(selectedChannel);
	}

	endDateHandler(date) {		
		var selectedChannel = this.context.selectedChannel;
		selectedChannel.endDate = date;
		this.context.updateSelectedChannel(selectedChannel);
	}

    render() {
        return (
			<div>
				<div className="GlobalFieldLineComponent form-row">
					<div className="form-row-left">						
						<div className="field-channel-code">
							<Label tooltip="">Channel Code</Label>
							<EcoInput disabled={this.isChangeDisabled() || !this.context.selectedChannel._isNew}								
								value={this.context.selectedChannel.id} 
								onChange={this.channelCodeHandler}
								key={"channel-code-id"}
								id="channel-code-id"
								>							
							</EcoInput>
						</div>	
						
						<div className="field-short-channel-name">
							<Label tooltip="">Short Channel Name</Label>
							<EcoInput disabled={this.isChangeDisabled()}
								value={this.context.selectedChannel.shortName} 
								onChange={this.shortChannelNameHandler}
								key={"short-channel-name-id"}
								id="short-channel-name-id"
								>							
							</EcoInput>							
						</div>	

						<div className="field-channel-name">
							<Label tooltip="">Channel Name</Label>
							<EcoInput disabled={this.isChangeDisabled()}
								value={this.context.selectedChannel.name} 
								onChange={this.channelNameHandler}
								key={"channel-name-id"}
								id="channel-name-id"
								>							
							</EcoInput>							
						</div>	

						<div className="field-channel-sort-order">
							<Label tooltip="">Sort order</Label>
							<EcoInput disabled={this.isChangeDisabled()}
								type="number"
								value={this.context.selectedChannel.sortOrder} 
								onChange={this.channelSortOrderHandler}
								key={"channel-sort-order"}
								id="channel-sort-order"
								>							
							</EcoInput>							
						</div>
						
					</div>
				</div>
				<div className="GlobalFieldLineComponent form-row">
					<div className="form-row-left">						
						<div className="field-surveytype">
							<Label>Channel Type</Label>
							<Select isDisabled={!this.context.canChangeData} name="channeltype" isSearchable={false}
								styles={{ option: styles => ({ minHeight: 40, ...styles }) }}								
								onChange={val=>{ this.changeChannelTypeHandler(val) }}
								options={this.props.metadata.channelTypes} 
								value={this.getSelectedChannelTypeOption()}>
							</Select>
						</div>
						<div className="field-surveytype">
							<Label>Media Type</Label>
							<Select isDisabled={!this.context.canChangeData} name="mediatype" isSearchable={false}
								styles={{ option: styles => ({ minHeight: 40, ...styles }) }}								
								onChange={val=>{ this.changeMediaTypeHandler(val) }}
								options={this.props.metadata.mediaTypes} 
								value={this.getSelectedMediaTypeOption()}>
							</Select>
						</div>
						<div className="field-survey-from">
							<Label>Start Date</Label>
							<DatePickerCalendar disabled={!this.context.canChangeData} value={this.context.selectedChannel.startDate} onChange={this.startDateHandler.bind(this)} />
						</div>
						<div className="field-survey-to">
							<Label>End Date</Label>
							<DatePickerCalendar disabled={!this.context.canChangeData} value={this.context.selectedChannel.endDate} minDate={this.context.selectedChannel.startDate} onChange={this.endDateHandler.bind(this)} />
						</div>
					</div>
				</div>				
				<div className="GlobalFieldLineComponent form-row">
					<div className="form-row-left">
						<div className="field-image">
							<Label>Image</Label>
							<ImageUrlHandler key={`image_${this.context.selectedChannel.id}`} id={this.context.selectedChannel.id} url={this.context.selectedChannel.imageUrl}/>
						</div>
						<div className="field-video">
							
						</div>
					</div>
				</div>
				
			</div>
        );
    }
}

GlobalFieldLine.contextType = ChannelFormContext;

export default GlobalFieldLine;