import React, { Component } from "react";
import { IntroPage } from "app/components/IntroPage";
import './MediaTypeHomeComponent.scss';

class MediaTypeHome extends Component{
    render() {
        return (
            <IntroPage headerText="Welcome to Media Types Homepage">                
                <b>Here you can</b>
                <ul className="introHomeItems">
					<li>Manage Media Types</li>
			    </ul>
            </IntroPage>
        )
    }
}

export default MediaTypeHome;