function NewDemoSet() {
    return {
        id: null,       
        name: "New Demographic Set",
		startDate: null,
		endDate: null,		
		questions: [],
		enabled: 0,
      }
}
export default NewDemoSet;