import React, { Component } from 'react';
import {Label, StatusBadge, Switch} from 'app/components/Basics/';
import { Select } from '@ecosystem/ui-lib/core/components/Select';
import { Checkbox } from '@ecosystem/ui-lib/core/components/Checkbox';
import { Input as EcoInput } from '@ecosystem/ui-lib/core/components/Input';
import './GlobalFieldLineComponent.scss';
import {QuestionFormContext} from '../../QuestionContext'
import { questionTypes } from '../../../../constants/question-types'
import { questionCategoriesOptions } from '../../../../constants/question-categories-options'
import { questionCategories } from '../../../../constants/question-categories'
import { questionInputLayoutTypes } from '../../../../constants/question-input-layout-types'

class GlobalFieldLine extends Component {

    static contextType = QuestionFormContext;

    constructor(props){
        super(props);
        this.state = {
            itsMe: true
        }        
    }

    changeQuestionTypeHandler(newType) {        
		var selectedQuestion = this.context.selectedQuestion;
		if (newType.value === 'NUMERIC') {
			if (selectedQuestion.layoutType === questionInputLayoutTypes.Button) {
				selectedQuestion.layoutType = questionInputLayoutTypes.DefaultInputType;
			}
			if (!selectedQuestion.options) {				
				selectedQuestion.options = [];
			}
			selectedQuestion.options = selectedQuestion.options.filter(opt => opt.value <= 0);
		}
		if (newType.value === 'OPEN') {
			if (selectedQuestion.options) {				
				selectedQuestion.options = [];
			}
		}

        if (newType.value !== 'SS') {
            selectedQuestion.isSys = questionCategories.Default;
        }
		
		selectedQuestion.questionType = newType.value;   
        this.context.updateSelectedQuestion(selectedQuestion);
        this.props.questionTypeChange()
    }
    
    changeQuestionCategoryHandler(newCategory) {        
		var selectedQuestion = this.context.selectedQuestion;
		selectedQuestion.isSys = parseInt(newCategory.value);
        this.context.updateSelectedQuestion(selectedQuestion);        
    }

    getSelectedQuestionTypeOption() {
        return questionTypes.find(t=> t.value === this.context.selectedQuestion.questionType)        
    }

    getSelectedQuestionCategoryOption() {        
        return questionCategoriesOptions.find(t=> parseInt(t.value) === this.context.selectedQuestion.isSys);
    }

    getQuestionArchived() {        
        if (this.context.selectedQuestion.inArchive === 0) {            
            return false
        } else {            
            return true
        }
    }

    setInArchiveValue(newSwitchValue) {        
        var selectedQuestion = this.context.selectedQuestion;
        if (newSwitchValue === true) {
            selectedQuestion.inArchive = 1;
        }
        else {
            selectedQuestion.inArchive = 0;
        }        
        this.context.updateSelectedQuestion(selectedQuestion);
    }

    render() {
        return (
            <div className="GlobalFieldLineComponent form-row">
                <div className="form-row-left">
                    <div className="field-question-type">
                        <Label>Type</Label>
                        <Select isDisabled={!this.context.canChangeData} 
							name="qtype" isSearchable={false}
                            defaultValue={questionTypes[0]}
                            onChange={val=>{
                                this.changeQuestionTypeHandler(val)                                
                            }}
                            options={questionTypes} value={this.getSelectedQuestionTypeOption()}></Select>
                    </div>
                    {this.context.selectedQuestion.questionType === "SS" && (
                    <div className="field-question-category">
                        <Label>Category</Label>
                        <Select isDisabled={!this.context.canChangeData || !this.context.canChangeDemoQuestion} 
							name="qcategory" isSearchable={false}
                            defaultValue={questionCategoriesOptions[0]}
                            onChange={val=>{
                                this.changeQuestionCategoryHandler(val)                                
                            }}
                            options={questionCategoriesOptions} value={this.getSelectedQuestionCategoryOption()}></Select>
                    </div>
                    )}
                    {/* <div className="field-question-label">
                        <Label tooltip={(<div>Description of <br/>Question Label</div>)}>Question Label</Label>
                        <EcoInput id="qlabel" name="qlabel" placeholder="- label -"></EcoInput>
                    </div> */}
                    {/* <div className="field-question-enabled">
                        <Label tooltip={(<div>Description of <br/>what <b>Enabled</b> means.</div>)}>Enabled</Label>
                        <div className="default-height centered"><Switch name="enabled" checked/></div>
                    </div> */}
                    {/* <div className="field-question-enabled">
                        <Label tooltip={(<div>Description of <br/>what <b>Archived</b> means.</div>)}>Archived</Label>
                        <div className="default-height centered"><Switch name="archived" checked={this.getQuestionArchived()} setSwitchValue={this.setInArchiveValue.bind(this)} /></div>
                    </div> */}
                    {/* <div className="field-question-status">
                        <Label>Status</Label>
                        <div className="default-height centered"><StatusBadge>Planned</StatusBadge></div>
                    </div> */}
                    {/* <div className="field-question-archive">
                        <Label>Auto-Archive</Label>
                        <div className="default-height centered"><Checkbox checked={true}/></div>
                    </div> */}
                </div>
                <div className="form-row-right">
                </div>
            </div>
        );
    }
}

GlobalFieldLine.contextType = QuestionFormContext;

export default GlobalFieldLine;