import React, { Component } from 'react';
import {Label, DatePickerCalendar, StatusBadge, Switch} from 'app/components/Basics/';
import { Select } from '@ecosystem/ui-lib/core/components/Select';
import { Checkbox } from '@ecosystem/ui-lib/core/components/Checkbox';
import './GlobalFieldLineComponent.scss';
import {IndepthSetFormContext} from '../../InDepthContext'
import Upload from 'app/components/Upload';

class GlobalFieldLine extends Component {

    static contextType = IndepthSetFormContext;

    constructor(props){
        super(props);        
    }

	setStartDate(date) {		
		var selectedIndepthSet = this.context.selectedIndepthSet;
		selectedIndepthSet.startDate = date;
		this.context.updateselectedIndepthSet(selectedIndepthSet);
	}

	setEndDate(date) {
		var selectedIndepthSet = this.context.selectedIndepthSet;
		selectedIndepthSet.endDate = date;
		this.context.updateselectedIndepthSet(selectedIndepthSet);
	}

	getSelectedFolderOption() {		
        return this.getFolders()
			.find(t=> t.value === this.context.selectedIndepthSet.folderId);
    }

	getFolders() {		
		return this.props.folders.map(function (obj) {			
			return {value: obj.id, label: obj.name === 'default'? "" : obj.name}
		});
	}

	changeFolderHandler(selectedItem) {
		var selectedIndepthSet = this.context.selectedIndepthSet;
		selectedIndepthSet.folderId = selectedItem.value;
		this.context.updateselectedIndepthSet(selectedIndepthSet);
    }

	getSelectedSurveyTypeOption() {
        return this.props.surveyTypes
			.find(t=> parseInt(t.value) === this.context.selectedIndepthSet.surveyTypeId);
    }

	changeSurveyTypeHandler(selectedItem) {
		var selectedIndepthSet = this.context.selectedIndepthSet;
		selectedIndepthSet.surveyTypeId = parseInt(selectedItem.value);
		this.context.updateselectedIndepthSet(selectedIndepthSet);
    }

	enabledSwitchHandler() {
		var selectedIndepthSet = this.context.selectedIndepthSet;
		selectedIndepthSet.enabled = this.context.selectedIndepthSet.enabled === 1 ? 0 : 1;
		this.context.updateselectedIndepthSet(selectedIndepthSet);
	}

	isNetquestSwitchHandler() {
		var selectedIndepthSet = this.context.selectedIndepthSet;
		selectedIndepthSet.isNetquest = this.context.selectedIndepthSet.isNetquest ? false : true;
		this.context.updateselectedIndepthSet(selectedIndepthSet);
	}

	netQuestSchemaUploadHandler = (file) => {
		var selectedIndepthSet = this.context.selectedIndepthSet;
		selectedIndepthSet.schemaFile = file;
		selectedIndepthSet.uploadedSchemaFileName = file.name;
		this.context.updateselectedIndepthSet(selectedIndepthSet);
	}

	forceSchemaQuestionUpdateHandler(event) {		
		var selectedIndepthSet = this.context.selectedIndepthSet;
		selectedIndepthSet.forceSchemaQuestionUpdate = event.target.checked;
		this.context.updateselectedIndepthSet(selectedIndepthSet);	
	}

    render() {
        return (
			<div>
				<div className="GlobalFieldLineComponent form-row">
					<div className="form-row-left">
						<div className="field-survey-from">
							<Label>Survey From</Label>
							<DatePickerCalendar disabled={!this.context.canChangeData} value={this.context.selectedIndepthSet.startDate} onChange={this.setStartDate.bind(this)} />
						</div>
						<div className="field-survey-to">
							<Label>Survey To</Label>
							<DatePickerCalendar disabled={!this.context.canChangeData} value={this.context.selectedIndepthSet.endDate} minDate={this.context.selectedIndepthSet.startDate} onChange={this.setEndDate.bind(this)} />
						</div>
						<div className="field-question-enabled">
							<Label>Enabled</Label>
							<div className="default-height centered"><Switch disabled={!this.context.canChangeData} name="enabled" setSwitchValue={this.enabledSwitchHandler.bind(this)} checked={this.context.selectedIndepthSet.enabled === 1}/></div>
						</div>
						<div className="field-folder">
							<Label>Folder</Label>
							<Select isDisabled={!this.context.canChangeData} name="qsetfolder" isSearchable={false}
								styles={{ option: styles => ({ minHeight: 40, ...styles }) }}                            
								onChange={val=>{
									this.changeFolderHandler(val)                                
								}}							
								options={this.getFolders()} 							
								value={this.getSelectedFolderOption()}>							
							</Select>
						</div>
						<div className="field-surveytype">
							<Label>Survey Type</Label>
							<Select isDisabled={!this.context.canChangeData} name="qsetsurveytype" isSearchable={false}
								styles={{ option: styles => ({ minHeight: 40, ...styles }) }}
								
								onChange={val=>{
									this.changeSurveyTypeHandler(val)                                
								}}
								options={this.props.surveyTypes} 
								value={this.getSelectedSurveyTypeOption()}>
							</Select>
						</div>						
						{/* <div className="field-question-archive">
							<Label>Auto-Archive</Label>
							<div className="default-height centered"><Checkbox checked={true}/></div>
						</div> */}
					</div>
					<div className="form-row-right">
					</div>
				</div>
				<div className="GlobalFieldLineComponent form-row">
					<div className="form-row-left">
						<div className="field-isnetquest">
							<Label>Netquest Survey</Label>
							<div className="default-height centered"><Switch disabled={!this.context.canChangeData} name="isNetquest" setSwitchValue={this.isNetquestSwitchHandler.bind(this)} checked={this.context.selectedIndepthSet.isNetquest === true}/></div>
						</div>
						<div className="field-netquest-schema">
							<Upload buttonLabel="Upload Netquest Schema"
								buttonDisabled={!this.context.canChangeData || !this.context.isNetQuestSurvey } 
								acceptTypes="application/json"
								onFileSelected={this.netQuestSchemaUploadHandler}
								existingFile={this.context.selectedIndepthSet.uploadedSchemaFileName}
								id="netquest-schema-file"
								key={this.context.selectedIndepthSet.uploadedSchemaFileName}
								>							
							</Upload>						
						</div>
						<div className="field-netquest-schema">
							<Checkbox disabled={!this.context.canChangeData || !this.context.isNetQuestSurvey} label="Force update" checked={this.context.selectedIndepthSet.forceSchemaQuestionUpdate === true} onChange={this.forceSchemaQuestionUpdateHandler.bind(this)} />
							<Label tooltip="Update any existing question(s) with the question(s) from this schema file and re-use them here. Effectively, all other questionnaires using these question(s) will be updated." />
						</div>						
					</div>
				</div>
			</div>
        );
    }
}

GlobalFieldLine.contextType = IndepthSetFormContext;

export default GlobalFieldLine;