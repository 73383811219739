export const appPermissions = {
	READ_QUESTION: "READ_QUESTION",
    CHANGE_QUESTION: "CHANGE_QUESTION",
    READ_DEMO_QUESTION: "READ_DEMO_QUESTION",
	CHANGE_DEMO_QUESTION: "CHANGE_DEMO_QUESTION",
	READ_INDEPTH: "READ_INDEPTH",
	CHANGE_INDEPTH: "CHANGE_INDEPTH",
	READ_GENERICS: "READ_GENERICS",
	CHANGE_GENERICS: "CHANGE_GENERICS",
	READ_FIVEMINEX: "READ_FIVEMINEX",
	CHANGE_FIVEMINEX: "CHANGE_FIVEMINEX",
	READ_NQTEMPLATE: "READ_NQTEMPLATE",
	CHANGE_NQTEMPLATE: "CHANGE_NQTEMPLATE",
	READ_ROLE: "READ_ROLE",
	CHANGE_ROLE: "CHANGE_ROLE",
	READ_USER: "READ_USER",
	CHANGE_USER: "CHANGE_USER",
	DOWNLOAD_CODEBOOK: "DOWNLOAD_CODEBOOK",
	READ_SUBSURVEY: "READ_SUBSURVEY",
	CHANGE_SUBSURVEY: "CHANGE_SUBSURVEY",
	READ_REGION: "READ_REGION",
	CHANGE_REGION: "CHANGE_REGION",
	READ_CHANNEL: "READ_CHANNEL",
	CHANGE_CHANNEL: "CHANGE_CHANNEL",
	READ_RESPONDENT_RESPONSE: "READ_RESPONDENT_RESPONSE",
	READ_REPORT_DOWNLOAD: "READ_REPORT_DOWNLOAD",	
	READ_MEDIATYPE: "READ_MEDIATYPE",
	CHANGE_MEDIATYPE: "CHANGE_MEDIATYPE"
}