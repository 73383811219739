import React, { Component } from 'react';
import './AppContainerComponent.scss';
import PanelConfigService from "../../services/panelConfig.service";
import UserPermissionService from "../../services/userPermission.service";
import {appPermissions} from '../../constants/app-permissions'
import BlockUi from 'react-block-ui';

class AppContainer extends Component {
	constructor (props) {
		super(props);
			this.state = {				
				uiBlocked: false,				
			}		
		}
	componentDidMount() {
		this.getPanelConfig();
		this.getLoggedUserPermissions();
	}

	getPanelConfig() {
		this.toggleUiBlock(true);
		PanelConfigService.getPanelConfig().then(response =>{			
			this.props.getPanelConfig(response.data);
			this.toggleUiBlock(false);
		});		
	}	

	getLoggedUserPermissions() {
		this.toggleUiBlock(true);
		UserPermissionService.get().then(response =>{		
			let userPermissionsMap = this.getUserPermissionMap(response.data);
			this.props.getUserPermissions(userPermissionsMap);
			this.toggleUiBlock(false);
		});		
	}

	getUserPermissionMap(userPermissions) {
		return {
			CanReadQuestion: userPermissions.includes(appPermissions.READ_QUESTION),
			CanChangeQuestion:  userPermissions.includes(appPermissions.CHANGE_QUESTION),
			CanReadDemoQuestion: userPermissions.includes(appPermissions.READ_DEMO_QUESTION),
			CanChangeDemoQuestion: userPermissions.includes(appPermissions.CHANGE_DEMO_QUESTION),
			CanReadIndepth: userPermissions.includes(appPermissions.READ_INDEPTH),
			CanChangeIndepth: userPermissions.includes(appPermissions.CHANGE_INDEPTH),
			CanReadGenerics: userPermissions.includes(appPermissions.READ_GENERICS),
			CanChangeGenerics: userPermissions.includes(appPermissions.CHANGE_GENERICS),
			CanReadFiveMinex: userPermissions.includes(appPermissions.READ_FIVEMINEX),
			CanChangeFiveMinex: userPermissions.includes(appPermissions.CHANGE_FIVEMINEX),
			CanReadNqTemplate: userPermissions.includes(appPermissions.READ_NQTEMPLATE),
			CanChangeNqTemplate: userPermissions.includes(appPermissions.CHANGE_NQTEMPLATE),
			CanReadRole: userPermissions.includes(appPermissions.READ_ROLE),
			CanChangeRole: userPermissions.includes(appPermissions.CHANGE_ROLE),
			CanReadUser: userPermissions.includes(appPermissions.READ_USER),
			CanChangeUser: userPermissions.includes(appPermissions.CHANGE_USER),
			CanDownloadCodeBook: userPermissions.includes(appPermissions.DOWNLOAD_CODEBOOK),
			CanReadSubSurvey: userPermissions.includes(appPermissions.READ_SUBSURVEY),
			CanChangeSubSurvey: userPermissions.includes(appPermissions.CHANGE_SUBSURVEY),
			CanReadRegion: userPermissions.includes(appPermissions.READ_REGION),
			CanChangeRegion: userPermissions.includes(appPermissions.CHANGE_REGION),
			CanReadChannel: userPermissions.includes(appPermissions.READ_CHANNEL),
			CanChangeChannel: userPermissions.includes(appPermissions.CHANGE_CHANNEL),
			CanReadRespondentResponse: userPermissions.includes(appPermissions.READ_RESPONDENT_RESPONSE),
			CanReadReportDownload: userPermissions.includes(appPermissions.READ_REPORT_DOWNLOAD),
			CanReadMediaType: userPermissions.includes(appPermissions.READ_MEDIATYPE),
			CanChangeMediaType: userPermissions.includes(appPermissions.CHANGE_MEDIATYPE)
		};		
	}

	toggleUiBlock(blockUi) {
		this.setState ({uiBlocked: blockUi});
	} 
    render() {		
        return (
			<BlockUi tag="div" blocking={this.state.uiBlocked}>  
				<div>		
					{this.props.children}
				</div>
			</BlockUi>
        );
    }
}
export default AppContainer;