
import React, { Component } from 'react';
import {SideBar, SideBarHead, SideList, SideListFolder, SideListItemInDepthSet} from 'app/components/SideBar/';
import IndepthSetForm from './InDepthForm';
import NewInDepthSet from './InDepthModel';
import ConvertToInDepthSetMenuItem from './InDepthMenuItemModel';
import InDepthHome from './components/InDepthHome/InDepthHomeComponent';
import InDepthTestSurvey from './components/InDepthTestSurvey/InDepthTestSurveyComponent';
import InDepthService from "../../services/indepth.service";
import InDepthFolderService from "../../services/indepthFolder.service";
import  GetErrorMessage  from '../../helpers/error-message'
import {ShowError, ShowSuccess} from "../../components/toast"
import { Loading } from '@ecosystem/ui-lib/core/components/Loading';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import Modal from '../../components/Modal';
import NewFolderCreator from './components/NewFolderCreator/NewFolderCreatorComponent';
import { Button } from 'app/components/Basics/'
import { ReactComponent as PagesIcon } from 'app/assets/icons/pagepage.svg';
import sortBy from 'array-sort-by';
import RemoveTimezone from "app/helpers/remove-timezone";
import './InDepthComponent.scss';

class InDepth extends Component {
	constructor (props) {
	super(props);
		this.state = {
			search: '',			
			allData: [],
			filteredData: [],
			inDepthOverview: [],
			folders: [],
			loading: false,
			errorMessage: null,
			selectedIndepthSet: null,
			originalSelectedIndepthSet: null,
			uiBlocked: false,
			editorOpen: false,
			testSurveyOpen: false,
			metadata: {
				broadcasters: [],
				channels: [],
				genres: [],
				surveyTypes: []
			}
		}		
	}

	setSearchState(event) {    
		var value = event.target.value;    
		this.setState({search: value});
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.search !== prevState.search) {     
			this.filterInDepths(this.state.search);
		}

		if (this.state.editorOpen !== prevState.editorOpen) {
			if (this.state.editorOpen) {
			//retrigger selected question if editor was closed        
			this.setState({selectedIndepthSet: this.state.selectedIndepthSet});
			}
		}    
	}

	filterInDepths(term) {		
		if (term !== '') {
			const filteredData = this.state.allData
			.filter(i => (i.name  && i.name.toLowerCase().includes(term.toLowerCase())));
			let newFilteredData = [...filteredData];
			this.setState({ filteredData: newFilteredData});      
		}
		else {
			let newFilteredData = [...this.state.allData];
			this.setState({ filteredData: newFilteredData});     
		}
	}

	componentDidMount() {		
		this.queryMetadata();		
		if (this.props.isSurveyTest) {
			this.openTestSurvey();
		}
		else {
			let date = new Date();
			let todayDate = RemoveTimezone(new Date(date.getFullYear(), date.getMonth(), date.getDate()));
			this.setState({todayDate: todayDate});
			this.queryInDepthsOverview('', todayDate);
			this.queryInDepthFolders('');			
			this.queryInDepths('');
			this.closeTestSurvey();
		}
	}

	openTestSurvey() {
		this.setState({testSurveyOpen: true});
		this.closeEditor();
	}

	closeTestSurvey() {
		this.setState({testSurveyOpen: false});
	}

	openEditor() {
		this.setState({editorOpen: true});
		this.closeTestSurvey();
	}	

	closeEditorButtonOnClick() {
		if (this.shouldLosePossibleChangesOnEditor(this.closeEditor)) {
			this.closeEditor();
		}
	}

	closeEditor() {
		this.setState({ editorOpen: false });
		this.setSelectedItem(null);
	}

	queryMetadata() {		
		InDepthService.getMetadata()
				.then(response =>{					
					this.setState({
						metadata: response.data					
					});
					return response;          
				})
				.catch(error => {			  
					let errorMessage = GetErrorMessage(error);
					console.log("errorMessage on catch");
					console.log(errorMessage);
					this.setState({ loading: false, errorMessage: errorMessage});            
			})
	}

	queryInDepthFolders(term) {
		this.setState({ loading: true, filteredData: [] });		
		InDepthFolderService.find(term)
			.then(response =>{
				this.setState({
				folders: response.data,
				foldersOriginal: cloneDeep(response.data),				
				defaultFolder: response.data.find(f=>f.name === 'default'),
				loading: false			
				});
				return response;          
			})
			.catch(error => {			  
				let errorMessage = GetErrorMessage(error);
				console.log("errorMessage on catch");
				console.log(errorMessage);
				this.setState({ loading: false, errorMessage: errorMessage});            
			})
	}

	queryInDepths(term) {
		this.setState({ loading: true, filteredData: [] });
		const _this = this;
		InDepthService.find(term)
			.then(response =>{				
				this.setState({ filteredData: response.data,
					allData:  response.data,					
					loading: false				
				}, function() { this.filterInDepths(this.state.search); });
				return response;          
			})
			.catch(error => {			  
				let errorMessage = GetErrorMessage(error);
				console.log("errorMessage on catch");
				console.log(errorMessage);
				this.setState({ loading: false, errorMessage: errorMessage});            
			})
	}

	queryInDepthsOverview(term, date) {
		this.setState({ loading: true, filteredData: [] });
		const _this = this;
		InDepthService.find(term, date)
			.then(response =>{				
				this.setState({ 
					inDepthOverview: _this.orderOverview(cloneDeep(response.data)),
					loading: false				
				}, function() { this.filterInDepths(this.state.search); });
				return response;          
			})
			.catch(error => {			  
				let errorMessage = GetErrorMessage(error);
				console.log("errorMessage on catch");
				console.log(errorMessage);
				this.setState({ loading: false, errorMessage: errorMessage});            
			})
	}

	getIndepthSetById(id) {		
		this.toggleUiBlock(true)
		InDepthService.getIndepthSetById(id)
			.then(response =>{
				this.updateselectedIndepthSet(response.data);
				this.updateSavedIndepthSetOnList(response.data);
				this.setState(
					{originalSelectedIndepthSet: cloneDeep(response.data)}
				);
				this.toggleUiBlock(false)
				return response;          
			})
			.catch(error => {			  
				let errorMessage = GetErrorMessage(error);
				console.log("errorMessage on catch");
				console.log(errorMessage);
				this.setState({ errorMessage: errorMessage});    
				this.toggleUiBlock(false)        
			})
	}

	createFolder(folder) {
		this.toggleUiBlock(true)
		InDepthFolderService.createFolder(folder)
		.then(response => {                    
			const newFolder = response.data;
			var folders = this.state.folders;
			folders.push(newFolder)
			this.setState({folders: folders})			
			this.toggleUiBlock(false)
			this.setState({successMessageFolder: `The folder ${folder.name} was created successfully` });
			
		})
		.catch(error => {
			let errorMessage = GetErrorMessage(error);
			console.log("errorMessage on catch");
			console.log(errorMessage);
			this.setState({ errorMessage: errorMessage});
			this.toggleUiBlock(false)	
		});
	}

	updateFolder(folder) {		
		this.toggleUiBlock(true)
		InDepthFolderService.updateFolder(folder)
		.then(response => {			
			this.toggleUiBlock(false);
			this.setState({successMessageFolder: `The folder ${folder.name} was saved successfully` });			
		})
		.catch(error => {
			this.rollbackFolderNameChange(folder);
			let errorMessage = GetErrorMessage(error);
			console.log("errorMessage on catch");
			console.log(errorMessage);	
			this.setState({ errorMessage: errorMessage});		
			this.toggleUiBlock(false)	
		});
	}

	rollbackFolderNameChange(folder) {
		var originalFolder = cloneDeep(this.state.foldersOriginal.find(f=>f.id === folder.id));
		var folders = this.state.folders;
		var index = this.state.folders.findIndex(({ id }) => id === folder.id);
		folders[index].name = originalFolder.name;
	}

	deleteFolder(folder)  {		
		if (this.folderContainQuestionSets(folder))
		{
			this.setState({errorMessage: `The folder '${folder.name}' cannot be deleted because it contains question sets`})
			return;
		}
		this.setState({ 
			deleteFolderModalText: `Are you sure you want to delete the folder '${folder.name}'?`,
			folderToDelete: folder
		});
	}

	folderContainQuestionSets(folder) {
		var result = this.state.allData.find(i=>i.folderId === folder.id);
		return result !== undefined;
	}

	confirmDeleteFolder() {		
		this.hideDeleteFolderModal();
		this.toggleUiBlock(true);
		let _this = this;   
		InDepthFolderService.deleteFolder(this.state.folderToDelete)
		.then(response =>{
			this.toggleUiBlock(false);
			this.setState({successMessageFolder: 'The folder was deleted successfully' });
			let folders = this.state.folders;
			folders = folders.filter(function( folder ) {
				return folder.id !== _this.state.folderToDelete.id;
			});
			this.setState({folders: folders});			
		})
		.catch(error => {
			let errorMessage = GetErrorMessage(error);
			console.log("errorMessage on catch");
			console.log(errorMessage);
			this.toggleUiBlock(false);
			this.setState({ errorMessage: errorMessage});
		})
		.finally(function() {
			_this.setState({folderToDelete: null})
		});		
	}

	hideDeleteFolderModal() {
		this.setState({deleteFolderModalText: ''})
	}

	getSelectedItem(indepthSetid) {		
		this.setState({clickedIndepthSetid: indepthSetid});		
		this.openEditor();
		if (this.shouldLosePossibleChangesOnEditor(()=>this.getIndepthSetById(indepthSetid))) {			
			this.getIndepthSetById(indepthSetid)			
		}	
	}

	setSelectedItem(selectedIndepthSet) {		
		this.updateselectedIndepthSet(selectedIndepthSet);
	}	

	shouldLosePossibleChangesOnEditor(callbackAfterLoseChangesConfirmation) {		
		if (this.didSelectedIndepthChange()) {
			this.showLoseChangeConfirmationModal(callbackAfterLoseChangesConfirmation);
			return false;	
		}
		else {	
			return true;
		}	
	}

	didSelectedIndepthChange() {
		let selectedIndepthSet = this.state.selectedIndepthSet;
		if (!selectedIndepthSet) return false;
		let questionsAreEquals = isEqual(selectedIndepthSet, this.state.originalSelectedIndepthSet);
		return !questionsAreEquals;
	  }

	showLoseChangeConfirmationModal(callbackAfterLoseChangesConfirmation) {
		this.setState({ 
			loseChangesModalText: `There are unsaved changes to the indepth question set '${this.state.selectedIndepthSet.name}'. Are you sure do you want to leave without saving?`,
			callbackAfterLoseChangesConfirmation: callbackAfterLoseChangesConfirmation
		});
	}

	confirmLoseChangesModalHandler() {
		let filteredData = this.rollbackToOriginalIndepthSet(this.state.filteredData, this.state.originalSelectedIndepthSet);
		let allData = this.rollbackToOriginalIndepthSet(this.state.allData, this.state.originalSelectedIndepthSet);	

		this.setState({allData: allData, filteredData: filteredData
		}, this.state.callbackAfterLoseChangesConfirmation);
		this.setState({clickedIndepthSetid: null});
		this.hideLoseChangesModal();
	}

	rollbackToOriginalIndepthSet(list, originalSelectedItem) {
		const index = list.findIndex(item => item.id === this.state.selectedIndepthSet.id);	
		list[index] = originalSelectedItem;
		return list;
	}

	hideLoseChangesModal() {
		this.setState({loseChangesModalText: ''})
	}

	isItemSelected(indepthSetId) {    
		if (this.state && this.state.selectedIndepthSet && 
			this.state.selectedIndepthSet.id === indepthSetId) {
			return true;
		} else {
			return false;
		}
	}

	toggleUiBlock(blockUi) {
		this.setState ({uiBlocked: blockUi});
	} 

	addNewIndepthSetHandler = event => {
		if (this.shouldLosePossibleChangesOnEditor(() => this.addNewIndepthSet())) {
			this.addNewIndepthSet()
		}    
		event.preventDefault();
	}

	addNewIndepthSet() {
		const newIndepthSet = NewInDepthSet(this.state.defaultFolder.id);		
		this.setSelectedItem(null);
		this.setSelectedItem(newIndepthSet);
		this.openEditor();
	}

	removeDeletedIndepthSet(indepthSetId) {    
		const newFilteredData = this.state.filteredData.filter(i => i.id !== indepthSetId);
		const newAllData = this.state.allData.filter(i => i.id !== indepthSetId);
		this.setState({ filteredData: newFilteredData,
			allData: newAllData
			});    
	}

	updateSavedIndepthSetOnList(selectedIndepthSet) {
		let newFilteredData = [...this.updateIndepthSetList(this.state.filteredData, selectedIndepthSet)];	
		let newAllData = [...this.updateIndepthSetList(this.state.allData, selectedIndepthSet)];
		this.setState({filteredData: []});
		this.setState({filteredData: newFilteredData, 
			allData: newAllData
		});
	}

	updateIndepthSetList(list, selectedIndepthSet) {
		let indepthSetMenuItem = ConvertToInDepthSetMenuItem(selectedIndepthSet);
		var index = list.findIndex(q => q.id === selectedIndepthSet.id);
		if (index === -1) {
			list.push(indepthSetMenuItem);      
		}
		else {
			list[index] = indepthSetMenuItem;      
		}
		return list;
	}

	saveNewFolder(newFolderText) {
		var folder = {
			id: null,
			name: newFolderText,
			sortOrder:0
		}
		this.createFolder(folder);
	}	

	getFolderItems(indepthFolder) {
		return this.state.filteredData.filter(i=>i.folderId === indepthFolder.id).map(indepthSet => 
			<div key={indepthSet.id}>
				<SideListItemInDepthSet key={indepthSet.id}
					indepthFolderId={indepthFolder.folderId} 
					indepthSetid={indepthSet.id}										
					questions={indepthSet.questionsCount} 
					selector="" 
					start={indepthSet.startDate}
					end={indepthSet.endDate}
					state="Ended"
					getSelectedItem={this.getSelectedItem.bind(this)}  
					isItemSelected={this.isItemSelected(indepthSet.id)}
				>
					{indepthSet.name}
				</SideListItemInDepthSet>
		</div>
		)
	}

	getFolder(indepthFolder, folderItems) {		
		//dont show folder if user's search resulted in a empty folder
		if (folderItems.length === 0 & this.state.search !== '') {
			return null
		}
		return 	(<SideListFolder canChangeData={this.props.userPermissions?.CanChangeIndepth} folder={indepthFolder} updateFolder={this.updateFolder.bind(this)} deleteFolder={this.deleteFolder.bind(this)}>
					{folderItems}
				</SideListFolder>)
	}

	updateselectedIndepthSet(selectedIndepthSet) {		
		this.setState({selectedIndepthSet: selectedIndepthSet})
	}	

	orderOverview(indepths) {		
		sortBy(indepths, item => [item.startDate]);		
		return indepths.reverse();		
	}

    render() {

        return (
			<BlockUi tag="div" blocking={this.state.uiBlocked}>  
			<main className="app-body">         
				<div className={`${this.props.isSurveyTest ? "survey-test-sidebar" : ""}`}>
					<SideBar>
						<SideBarHead  canChangeData={this.props.userPermissions?.CanChangeIndepth} tagName="SideBarHead" headline="In-Depth" addButton addButtonLabel="Set" addButtonCallBack={this.addNewIndepthSetHandler.bind(this)}
							toolbar={(
							<div>
								{/* <IntroTextIcon className="icon orange clickable" title="Intro Texts"/>
								<SettingsIcon className="icon orange clickable" title="Settings"/> */}
							</div>  
						)} onChange={this.setSearchState.bind(this)}
						/>                  
						<SideList tagName="SideList">                  
							<Loading active={this.state.loading}></Loading>

							{this.state.folders.map(indepthFolder => 
							<div key={indepthFolder.id}>							
								{indepthFolder.name === 'default' && (<div key={indepthFolder.id}>
									{this.getFolderItems(indepthFolder)}
								</div>)}

								{indepthFolder.name !== 'default' && (<div key={indepthFolder.id}>
									{this.getFolder(indepthFolder, this.getFolderItems(indepthFolder))}							  
								</div>)}
							</div>
							)}

							<NewFolderCreator saveNewFolderHandler={this.saveNewFolder.bind(this)} />
						</SideList>					
					</SideBar>
				</div>          

				<div className="app-content scrollbox">
					{this.state.editorOpen && ( <IndepthSetForm openEditor={this.openEditor.bind(this)} 
							didSelectedIndepthSetChange={this.didSelectedIndepthChange.bind(this)}
							closeEditorButtonOnClick={this.closeEditorButtonOnClick.bind(this)} 
							closeEditor={this.closeEditor.bind(this)}
							removeDeletedIndepthSet={this.removeDeletedIndepthSet.bind(this)} 
							toggleUiBlock={this.toggleUiBlock.bind(this)} 
							selectedIndepthSet={this.state.selectedIndepthSet}
							updateselectedIndepthSet={this.updateselectedIndepthSet.bind(this)}
							updateSavedIndepthSetOnList={this.updateSavedIndepthSetOnList.bind(this)}
							getIndepthSetById={this.getIndepthSetById.bind(this)}
							folders={this.state.folders}
							metadata={this.state.metadata}
							canChangeData={this.props.userPermissions?.CanChangeIndepth}
							isAskAlwaysEnabled={this.props.isAskAlwaysEnabled}
							/> )}
					{!this.state.editorOpen && !this.state.testSurveyOpen && this.state.todayDate && ( <InDepthHome todayDate={this.state.todayDate} overview={this.state.inDepthOverview} filterOverview={this.queryInDepthsOverview.bind(this)} openSelectedItem={this.getSelectedItem.bind(this)}></InDepthHome>)}
					{!this.state.editorOpen && this.state.testSurveyOpen && ( <InDepthTestSurvey surveyTypes={this.state.metadata.surveyTypes} toggleUiBlock={this.toggleUiBlock.bind(this)}></InDepthTestSurvey>)}
				</div>
				
				{this.state.deleteFolderModalText && ( <Modal size="small" content={this.state.deleteFolderModalText} confirmModalHandler={this.confirmDeleteFolder.bind(this)} cancelModalHandler={this.hideDeleteFolderModal.bind(this)} />)}
				{this.state.successMessageFolder && ( <ShowSuccess clearMessage={() => this.setState({successMessageFolder: ''})} message={this.state.successMessageFolder} /> )}
				{this.state.errorMessage && ( <ShowError clearErrorMessage={() => this.setState({errorMessage: ''})} errorMessage={this.state.errorMessage} /> )}
				{this.state.loseChangesModalText && ( <Modal size="small" content={this.state.loseChangesModalText} confirmModalHandler={this.confirmLoseChangesModalHandler.bind(this)} cancelModalHandler={this.hideLoseChangesModal.bind(this)} />)}
			</main>
			</BlockUi>
        );
    }
}

export default InDepth;