import React, { Component } from 'react';
import './SwitchComponent.scss';

class Switch extends Component {

    constructor(props){
        super(props);
        this.state = {
            selected : this.props.checked ? true : false
        }
    }

    onClick(e){
		if (this.props.disabled) {
			return;
		}
        this.setState({selected: !this.state.selected});
        this.props.setSwitchValue(!this.state.selected);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps) {            
            if (nextProps.checked !== this.props.checked) {
                this.setState({selected: nextProps.checked ? true : false})
            }
        }        
    }

    render() {
        return (
            <div onClick={(e)=>{this.onClick(e);}} className={`SwitchComponent ${this.props.disabled ? 'SwitchComponentDisabled' : 'SwitchComponentEnabled'} ${this.props.className} ${(this.state.selected ? "isOn" : "")}`}>
                <div className="switch-dot"></div>
                <input type="hidden" name={this.props.name} value={this.state.selected} disabled={this.props.disabled}></input>
            </div>
        );
    }
}

export default Switch;