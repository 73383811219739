function GetErrorMessage(error) {
	console.log(error);  
	let errorMessage = '';
	if (error && error.response) {
		console.log("response error");
		console.log(error.response)
		if (error.response.data) {
			if (error.response.data.errorMessages) {        
				errorMessage = error.response.data.errorMessages[0];
				return errorMessage;
			}      
		}
		if (error.response.status === 403) {			
			return "You don't have permission to execute this action"
		}
		errorMessage = "response error"    
	}
	else if (error && error.request) {
		console.log("request error");
		console.log(error.request)
		errorMessage = error.message;
	}	
	else if (error && error.message) {
		console.log("network error?");
		errorMessage = error.message;
	}
	else {
		console.log("other error");
		errorMessage = error;
	}
	return errorMessage;
}
export default GetErrorMessage;