import React, { Component } from 'react';
import {Spacer, Button, InputInline} from 'app/components/Basics/'
import { Icons } from '@ecosystem/ui-lib/core/components/Icon';
import './GlobalControlLineComponent.scss';
import { ReactComponent as SaveIcon } from 'app/assets/icons/Save.svg';
import { ReactComponent as DuplicateIcon } from 'app/assets/icons/Duplicate-Plus.svg';
import {DemoSetFormContext} from '../../DemoSetContext'
import ValidateDemoSet from '../../DemoSetValidation'
 
class GlobalControlLine extends Component {

    constructor(props){
        super(props);

        this.state = {            
        }        
    }

    componentWillReceiveProps(nextProps) {        
        if (nextProps && nextProps.errors) {            
            var error = nextProps.errors.find(e => e.field === "name")
            if (error) {
                this.setState({errorDemoSetName: error.errorMessage})
            }
        }
    }

    getNewDemoSetName(isEditorOpen, newDemoSetName) {
        if (!isEditorOpen) {
            var selectedDemoSet = this.context.selectedDemoSet;
            selectedDemoSet.name = newDemoSetName;
            this.context.updateSelectedDemoSet(selectedDemoSet);
        }
    }
	
	isSaveDisabled() {
		let result = false;
		var errors = ValidateDemoSet(this.context.selectedDemoSet);		
		if (errors.length > 0) {
			result = true;
		}		
		if (!this.props.didSelectedDemoSetChange()) {
			result = true;
		}
		return result;
	}

	isNewDemoSet() {
		return this.context.selectedDemoSet.id === null;
	}	

	userCannotChangeData() {
		return !this.props.canChangeData;
	}

    render() {
        return (
            <div className="GlobalControlLineComponent form-row">
                <InputInline disabled={this.userCannotChangeData()} enableOnBlur className="name-field" error={this.state.errorDemoSetName} value={this.context.selectedDemoSet.name} onModeChange={this.getNewDemoSetName.bind(this)} />
                <div className="contol-buttons">                    
                    <Button disabled={this.userCannotChangeData() || this.isSaveDisabled()} title="Save all changes in the survey" icon={<SaveIcon/>} onClick={this.props.handleSubmitAction}>Save</Button>                    
                    <Button title="Close current editor and discard all unsaved changes" onClick={this.props.closeEditorButtonOnClick} simple icon={Icons.Close}>Close</Button>
                    <Spacer width={20}/>                   
                    <Button disabled={this.userCannotChangeData() || this.isNewDemoSet()} title="Delete the survey is irreversible" small secondary icon={Icons.Delete} onClick={this.props.deleteDemoSet}>Delete</Button>
                    <Button disabled={this.userCannotChangeData() || this.isNewDemoSet()} title="Create a new survey as duplicate from this survey" small secondary icon={<DuplicateIcon/>} onClick={this.props.duplicateDemoSet}>Duplicate</Button>					
                </div>
                
            </div>  
        );
    }
}
GlobalControlLine.contextType = DemoSetFormContext;
export default GlobalControlLine;