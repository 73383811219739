import React, { Component } from 'react';
import { Label, Button } from 'app/components/Basics';
import { Select } from '@ecosystem/ui-lib/core/components/Select';
import { ButtonIcon } from '@ecosystem/ui-lib/core/components/ButtonIcon';
import { Icons } from '@ecosystem/ui-lib/core/components/Icon';
import { Checkbox } from '@ecosystem/ui-lib/core/components/Checkbox';
import { ReactComponent as PlusIcon } from 'app/assets/icons/Plus.svg';
import { Card, CardContent, CardFooter, CardItem } from '@ecosystem/ui-lib/core/components/Card';
import { Table, GenericCell } from '@ecosystem/ui-lib/core/components/Table';
import Modal from 'app/components/Modal';
import { QuestionSearch } from "app/components/QuestionSearch";
import {IndepthSetFormContext} from '../../InDepthContext'
import {StatusBadge} from 'app/components/Basics/'
import { questionTypes } from '../../../../constants/question-types'
import './TabQuestionsComponent.scss';

class TabQuestions extends Component {

	OptionOrders = [
        {value:3, label: "Random"},
        {value:1, label: "Ascending"},
        {value:2, label: "Descending"}
    ]

    constructor(props){
        super(props);
        this.state = {     
            errorQuestionText: '',
			showQuestionModal: false,
			selectedQuestionToAdd: null
        }    
    }   

    getSelectedOptionOrder() {        
        return this.OptionOrders.find(t=> t.value === this.props.order)
    }
		
	getQuestionTypeLabel(questionTypeCode) {
		let questionType = questionTypes.find(q=>q.value === questionTypeCode);
		return questionType == null ? "" : questionType.label;
	}
	
	questionEnforceHandler(questionId, event) {		
		let selectedIndepthSet = this.context.selectedIndepthSet;
		let question = selectedIndepthSet.questions.find(q=>q.id === questionId)		
		question.askAlways = event.target.checked ? 1 : 0;
		this.context.updateselectedIndepthSet(selectedIndepthSet);
	}

	addQuestionRow(question) {        
        const cols = [];
        cols.push(<GenericCell className="column-code" primary={question.code}/>);
        cols.push(<GenericCell className="column-type" primary={<StatusBadge>{this.getQuestionTypeLabel(question.questionType)}</StatusBadge>}/>);
		cols.push(<GenericCell className="column-question-text" primary={question.textPlain}/>);        
        if (this.props.isAskAlwaysEnabled) {
			cols.push(<GenericCell className="column-question-enforce" primary={<Checkbox disabled={!this.context.canChangeData} checked={question.askAlways === 1} onChange={this.questionEnforceHandler.bind(this, question.id)}/>}/>);
		}
        cols.push(<GenericCell className="column-remove" primary={<div><ButtonIcon isDisabled={!this.context.canChangeData || this.context.isNetQuestSurvey} onClick={() => this.removeQuestionHandler(question.id)}  icon={Icons.Close}/></div>}/>);        
        return cols;
    }

	openAddQuestionModal() {
		if (!this.context.canChangeData) {
			return;
		}
		this.setState({showQuestionModal: true});
	}

	closeModal() {		
		this.setState({
			showQuestionModal: false,
			selectedQuestionToAdd: null
		});
	}	

	addQuestionHandler(question) {
		let inDepthQuestion = {
			id: question.id,
			code: question.code,
			text: question.text,
			textPlain: question.textPlain,
			questionType: question.questionType,
			enforce: 0,
			sortOrder: 0
		};
		var selectedIndepthSet = this.context.selectedIndepthSet;
		selectedIndepthSet.questions.push(inDepthQuestion);
		this.context.updateselectedIndepthSet(selectedIndepthSet);		
	}

	setSelectedQuestion(selectedQuestion) {		
		this.setState({selectedQuestionToAdd: selectedQuestion});
	}

	removeQuestionHandler(questionId) {    
		if (!this.context.canChangeData) {
			return;
		}
        var selectedIndepthSet = this.context.selectedIndepthSet;
        if (selectedIndepthSet.questions) {
            selectedIndepthSet.questions = selectedIndepthSet.questions.filter(function(f) { return f.id !== questionId })
            this.context.updateselectedIndepthSet(selectedIndepthSet);        
        }
    }

	getQuestionsTableColumns() {
		var columns = [];
		columns.push(
			{   headerKey: `column-code`,
				className: "AppFormTableHeader column-code",
				name: `Code`
			});

			columns.push({
				headerKey: `column-type`,
				className: "AppFormTableHeader column-type",
				name: `Type`
			});

			columns.push(
			{
				headerKey: `column-question-text`,
				className: "AppFormTableHeader column-question-text",
				name: `Question`
			});

			if (this.props.isAskAlwaysEnabled) {
				columns.push(
				{
					headerKey: `column-question-enforce`,
					className: "AppFormTableHeader column-question-enforce",
					name: `Ask Always`
				});
			}

			columns.push(
			{
				headerKey: `column-remove`,
				className: "AppFormTableHeader column-remove",
				name: `Remove`
			});		
		return columns;
	}

    render() {		
		const rows = [];       
        this.context.selectedIndepthSet.questions.forEach((question) => {
            rows.push(this.addQuestionRow(question));
        });

        return (
            <div className="TabIndepthQuestionsComponent content-box">
                <Card>
					<div className="form-row">
						<div className="form-row-left">
							{/* <div className="field-option-order">
								<Label tooltip="Please select the sorting method for the questions">Order</Label>
								<Select name="optionSortOrder" isSearchable={false}
									defaultValue={{value:"3", label: "Random"}}
									value={this.getSelectedOptionOrder()}
									options={this.OptionOrders}
									onChange={val=>{
										this.changeQuestionOptionOrder(val)                                
									}}/>
							</div>							 */}
						</div>
						<div className="form-row-right">
							<Button disabled={!this.context.canChangeData || this.context.isNetQuestSurvey} secondary icon={<PlusIcon/>} onClick={() => this.openAddQuestionModal()}>Question</Button>
						</div>
                	</div>
					
					<Table
						className="QuestionList"
						columns={this.getQuestionsTableColumns()}
						rows= {rows}                
						sortingOptions={{headerKey: null}}
						isHeaderSticky={false}
						isFirstColumnSticky={false}
					/>
					<div style={{display: rows && rows.length > 0 ? 'none' : 'block' }}>
						<p className='EmptyQuestionListInfoTitle'>Question list is empty</p>
						<p className='EmptyQuestionListInfoText'>Please, add questions via <span className='span-box'>+Question</span></p>
					</div>
                </Card>			
				{this.state.showQuestionModal && ( <Modal content={						
						<QuestionSearch selectedQuestions={this.context.selectedIndepthSet.questions} 
							addQuestionHandler={this.addQuestionHandler.bind(this)} 
							removeQuestionHandler={this.removeQuestionHandler.bind(this)}
							excludeDemoQuestions={false}
						/>
					} 
					disableConfirmButton={this.state.selectedQuestionToAdd == null}					
					hideConfirmButton
					cancelButtonLabel="Close"
					cancelModalHandler={this.closeModal.bind(this)} 
					selectedQuestions={this.context.selectedIndepthSet.questions}
					/>)}					
            </div>			
        );
    }
}
TabQuestions.contextType = IndepthSetFormContext;
export default TabQuestions;