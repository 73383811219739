import { questionInputLayoutTypes } from '../../constants/question-input-layout-types'

function ValidateQuestion(selectedQuestion) {
	var validationErrors = [];
	validateQuestionCode(validationErrors, selectedQuestion.code);

	if (selectedQuestion.text === '') {
		addErrorMessage(validationErrors, 'text', 'question text is empty');
	}
	
	if (selectedQuestion.questionType !== "OPEN" && selectedQuestion.questionType !== "NUMERIC") {
		if (!selectedQuestion.options || selectedQuestion.options.length === 0) {
			addErrorMessage(validationErrors, 'options', 'questions other than Open or Number Input must have options');                        
		}

		validadeOptions(validationErrors, selectedQuestion.options);
	}

	if (selectedQuestion.questionType === "NUMERIC") {		
		if (selectedQuestion.numericMin === '' || selectedQuestion.numericMin == null) {
			addErrorMessage(validationErrors, 'numericMin', 'Number Input questions must have a min. value');                        
		}

		if (selectedQuestion.numericMax === '' || selectedQuestion.numericMax == null) {
			addErrorMessage(validationErrors, 'numericMax', 'Number Input questions must have a max. value');                        
		}
		validadeOptions(validationErrors, selectedQuestion.options);
		
		if (selectedQuestion.layoutType === questionInputLayoutTypes.CircularSlider) {
			if (parseInt(selectedQuestion.numericMax) - parseInt(selectedQuestion.numericMin) > 100) {
				addErrorMessage(validationErrors, 'numericMax', 'Circular slider must have a maximum of 100 steps.');  
			}
		}
	}

	if (selectedQuestion.questionType === "GGROUP") {
		if (selectedQuestion.gridQuestions) {
			for (let index = 0; index < selectedQuestion.gridQuestions.length; index++) {
				const gridQuestion = selectedQuestion.gridQuestions[index];
				if (gridQuestion.groupQuestionId) {
					if (!gridQuestion.code || gridQuestion.code === '') {                    
						addErrorMessage(validationErrors, `gridQuestion_code_${index}`, 'question code is empty');					
					}
					if (gridQuestion.text === '') {
						addErrorMessage(validationErrors, `gridQuestion_text_${index}`, 'question text is empty');
					}
				}
			}			
		}
	}
	return validationErrors;
}

function validateQuestionCode(validationErrors, questionCode) {	
	// pattern: starting with a letter, containing only letters, numbers, '-' and '_', with a length between 3 and 20 characters
    const pattern = /^[a-zA-Z][a-zA-Z0-9_-]{2,19}$/;
	const isValid = pattern.test(questionCode);
	if (!questionCode || questionCode === '') {                    
		addErrorMessage(validationErrors, 'code', 'question code is empty');
	} else if (!isValid ) {                    
		addErrorMessage(validationErrors, 'code', 'Question code can contain only letters, numbers and underscore and must start with a letter. It must be between 3 and 20 characters long.');
	}
}

function validadeOptions(validationErrors, options) {
	options.forEach((questionOption) => {            
		if (!questionOption.text || questionOption.text === '') {
			addErrorMessage(validationErrors, `option_${questionOption.value}`, 'Option must have a text');                            
		}
	});
}

function addErrorMessage(validationErrors, field, message) {      
	validationErrors.push({ field: field, errorMessage: message});      
}

export default ValidateQuestion;