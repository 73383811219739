import React, { Component } from "react";
import { Card } from '@ecosystem/ui-lib/core/components/Card';
import { Table, GenericCell } from '@ecosystem/ui-lib/core/components/Table';
import { SortDirection } from '@ecosystem/ui-lib/core/components/Table/Sorting/types';
import sortBy from 'array-sort-by';
import { ReactComponent as EditPen } from 'app/assets/icons/edit-pen.svg';
import OverviewFilters from 'app/components/OverviewFilters'
import './InDepthHomeComponent.scss';

class InDepthHome extends Component {
    constructor(props) {
        super(props);        
        this.state = {
            sortOrder: null           
        };
    }    

    getOverviewColumns() {
		var columns = [];       
        columns.push(
        {   headerKey: `column-edit`,
            className: "AppFormTableHeader column-edit",
            name: ``                
        });
        columns.push(
        {   headerKey: `name`,
            className: "AppFormTableHeader column-name",
            name: `Name`,
            isSortable: true,
            defaultSortDirection: SortDirection.asc            
        });

        columns.push({
            headerKey: `startDate`,
            className: "AppFormTableHeader column-startDate",
            name: `Start Date`,
            isSortable: true,
            defaultSortDirection: SortDirection.desc
        });

        columns.push(
        {
            headerKey: `endDate`,
            className: "AppFormTableHeader column-endDate",
            name: `End Date`,
            isSortable: true,
            defaultSortDirection: SortDirection.desc
        });
    
        columns.push(
        {
            headerKey: `enabled`,
            className: "AppFormTableHeader column-enabled",
            name: `Enabled`,
            isSortable: true,
        });		
		return columns;
	}

    formatDate(dateString) {
        if (dateString) {
            return new Date(dateString).toLocaleDateString('en-GB');
        }
        else {
            return '';
        }
    }

    openSelectedItem(indepth) {
        this.props.openSelectedItem(indepth.id);
    }

    addOverviewRow(indepth) {        
        const cols = [];
        cols.push(<GenericCell className="column-edit" primary={<EditPen className='edit-icon' onClick={(e) => {this.openSelectedItem(indepth)}}/>}/>);
        cols.push(<GenericCell className="column-name" primary={indepth.name}/>);
        cols.push(<GenericCell className="column-startDate" primary={this.formatDate(indepth.startDate)}/>);
		cols.push(<GenericCell className="column-endDate" primary={this.formatDate(indepth.endDate)}/>);
        cols.push(<GenericCell className="column-enabled" primary={this.getEnabledLabel(indepth.enabled)}/>);
        return cols;
    }

    getEnabledLabel(enabled) {
        return enabled ? 'True' : 'False';
    }

    setSortingOptions(tableSortOrder) {        
        this.setState({sortOrder: tableSortOrder});
    }

    getOverviewRows() {
        const rows = [];        
        let inDepths = this.setSortOrder(this.props.overview);
        inDepths.forEach((indepth) => {
            rows.push(this.addOverviewRow(indepth));
        });
        return rows;
    }

    setSortOrder(inDepths) {
        if (this.state && this.state.sortOrder) {            
            if (this.state.sortOrder.sortKey === 'name') {
                sortBy(inDepths, item => [item.name]);                
            }
            else if (this.state.sortOrder.sortKey === 'startDate') {
                sortBy(inDepths, item => [new Date(item.startDate)]);                
            }
            else if (this.state.sortOrder.sortKey === 'endDate') {
                sortBy(inDepths, item => [new Date(item.endDate)]);                
            }
            else if (this.state.sortOrder.sortKey === 'enabled') {
                sortBy(inDepths, item => [item.enabled]);                
            }
            
            this.setSortDirection(inDepths);
        }
        return inDepths
    }

    setSortDirection(inDepths) {
        if (this.state.sortOrder.sortDir === 'desc') {
		    inDepths = inDepths.reverse();
        }        
    }
    
    render() {
        const rows = this.getOverviewRows();
        return (
            <div className="AppFormComponent TabIndepthOverviewComponent content-box">
                <OverviewFilters todayDate={this.props.todayDate} filterOverview={this.props.filterOverview} filterPlaceholders="Filter by In-Depth Name..."></OverviewFilters>
                <Card>
                    <Table
                            className="InDepthOverviewList"
                            columns={this.getOverviewColumns()}
                            rows= {rows}                                            
                            isHeaderSticky={false}
                            isFirstColumnSticky={false}
                            setSortingOptions={this.setSortingOptions.bind(this)}
                            sortingOptions={this.state?.sortOrder}
                            defaultSortingOptions={{
                                headerKey: 'startDate',
                                sortKey: 'startDate',
                                sortDir: SortDirection.desc,
                              }
                          }
                    />
                    <div style={{display: rows && rows.length > 0 ? 'none' : 'block' }}>
                        <p className='EmptyInDepthOverviewListInfoTitle'>No In-Depth for the selected filters</p>                        
                    </div>
                </Card>
            </div>
        )
    }
}
export default InDepthHome;