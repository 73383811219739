import React, { Component } from 'react';
import { Card} from '@ecosystem/ui-lib/core/components/Card';
import {AdHocSurveyFormContext} from '../../AdHocSurveyContext'
import './TabOptionConditionsComponent.scss';
import ConditionLogics from './ConditionLogicsComponent';
import { Select } from '@ecosystem/ui-lib/core/components/Select';
import NewAdHocConditionDefinition from '../../models/AdHocConditionDefinition'
class TabOptionConditions extends Component {	

    constructor(props){
        super(props);		
        this.state = {     
            errorQuestionText: '',
			selectedAnswerOptionValue: props.question.options[0]?.value
        }    
    }
	

	getOptionsForDropdown() {		
		var _this = this;
		return this.props.question.options.map(function (obj) {			
			let extraLabel = "";
			let conditionCounter = _this.getOptionConditions(obj.value).length;
			if ( conditionCounter === 1) {				
				extraLabel = ` (${conditionCounter} condition applied)`;
			} else if ( conditionCounter > 1) {				
				extraLabel = ` (${conditionCounter} conditions applied)`;
			}
			return {value: obj.value, label: (<div><span>{obj.text}</span><span className='option-condition-applied'>{extraLabel}</span></div>)}
		});
	}

	getSelectedOptionForDropdown() {
		let options = this.getOptionsForDropdown();
		return options.find(o=>o.value === this.state.selectedAnswerOptionValue);
	}

	getSelectedOption() {		
		return this.props.question.options.find(o=>o.value === this.state.selectedAnswerOptionValue);
	}

	changeOptionHandler(option) {		
		this.setState({selectedAnswerOptionValue: option.value})
	}

	getConditionsSelectedOption() {			
		return this.getOptionConditions(this.state.selectedAnswerOptionValue);
	}

	getOptionConditions(optionValue) {
		let conditionsSelectedOption = this.props.conditionsDefinitions.find(c=>c.answerOption.value === optionValue);
		if (conditionsSelectedOption && conditionsSelectedOption.conditions) {
			return conditionsSelectedOption.conditions;
		}
		else {
			return [];
		}
	}

	getConditionOperatorSelectedOption() {		
		let conditionsSelectedOption = this.props.conditionsDefinitions.find(c=>c.answerOption.value === this.state.selectedAnswerOptionValue);
		if (conditionsSelectedOption) {
			return conditionsSelectedOption.conditionOperator;
		}
	}

	addConditionHandler(condition) {		
		var selectedOption = this.getSelectedOption();
		var selectedAdHocSurvey = this.context.selectedAdHocSurvey;		
		let surveyQuestion = selectedAdHocSurvey.questions.find(q=>q.question.id === this.props.question.id);		
		let optionDefinition = surveyQuestion.answerOptionsConditionsDefinitions.find(o=>o.answerOption.value === selectedOption.value);
		if (optionDefinition == null) {			
			optionDefinition = NewAdHocConditionDefinition();
			optionDefinition.answerOption = {
				value: selectedOption.value,
				text: selectedOption.text
			};
			surveyQuestion.answerOptionsConditionsDefinitions.push(optionDefinition);			
		}
		optionDefinition.conditions.push(condition);
		this.context.updateSelectedAdHocSurvey(selectedAdHocSurvey);		
	}

	removeConditionHandler(index) {
		var selectedOption = this.getSelectedOption();
		var selectedAdHocSurvey = this.context.selectedAdHocSurvey;		
		let surveyQuestion = selectedAdHocSurvey.questions.find(q=>q.question.id === this.props.question.id);
		let optionDefinition = surveyQuestion.answerOptionsConditionsDefinitions.find(o=>o.answerOption.value === selectedOption.value);
		optionDefinition.conditions.splice(index,1)
		this.context.updateSelectedAdHocSurvey(selectedAdHocSurvey);		
	}
	
	validAnswersHandler(condition, options)  {
		var selectedAdHocSurvey = this.context.selectedAdHocSurvey;		
		let surveyQuestion = selectedAdHocSurvey.questions.find(q=>q.question.id === this.props.question.id);		
		var answerOptionsConditionsDefinition = surveyQuestion.answerOptionsConditionsDefinitions.find(a=>a.answerOption.value === this.state.selectedAnswerOptionValue);
		var conditionToUpdate = answerOptionsConditionsDefinition.conditions.find(c=>c.questionToCheck.id === condition.questionToCheck.id);
		conditionToUpdate.validAnswerValues = [];
		conditionToUpdate.validAnswerValues.push(...options);
		this.context.updateSelectedAdHocSurvey(selectedAdHocSurvey);
	}

	conditionNegateHandler(index, isChecked) {		
		var selectedAdHocSurvey = this.context.selectedAdHocSurvey;		
		let surveyQuestion = selectedAdHocSurvey.questions.find(q=>q.question.id === this.props.question.id);
		var answerOptionsConditionsDefinition = surveyQuestion.answerOptionsConditionsDefinitions.find(a=>a.answerOption.value === this.state.selectedAnswerOptionValue);
		var conditionToUpdate = answerOptionsConditionsDefinition.conditions[index];
		conditionToUpdate.negate = isChecked ? 1 : 0;
		this.context.updateSelectedAdHocSurvey(selectedAdHocSurvey);		
	}

	logicOperatorHandler(conditionOperator) {        
        var selectedAdHocSurvey = this.context.selectedAdHocSurvey;		
		let surveyQuestion = selectedAdHocSurvey.questions.find(q=>q.question.id === this.props.question.id);
		var answerOptionsConditionsDefinition = surveyQuestion.answerOptionsConditionsDefinitions.find(a=>a.answerOption.value === this.state.selectedAnswerOptionValue);	
		answerOptionsConditionsDefinition.conditionOperator = conditionOperator;
		this.context.updateSelectedAdHocSurvey(selectedAdHocSurvey);
    }

	getSelectedQuestions() {		
		var selectedOption = this.getSelectedOption();
		var selectedAdHocSurvey = this.context.selectedAdHocSurvey;		
		let surveyQuestion = selectedAdHocSurvey.questions.find(q=>q.question.id === this.props.question.id);		
		let optionDefinition = surveyQuestion.answerOptionsConditionsDefinitions.find(o=>o.answerOption.value === selectedOption.value);
		var questions = [];
		if (optionDefinition == null) return questions;

		optionDefinition.conditions.forEach(condition => {
			questions.push(condition.questionToCheck);
		});
		return questions;
	}

    render() {		
        return (
            <div className="TabOptionConditionsComponent content-box">
                <div className='scrollable-content'>
					<Card>
						<div className="form-row">
							<div className="form-row-left">
								<h4>Show option 
								<Select isDisabled={!this.props.canChangeData} className='optionCondition' name="option" isSearchable={false}
									styles={{ option: styles => ({ minHeight: 40, ...styles }) }}                            
									onChange={val=>{
										this.changeOptionHandler(val)                                
									}}							
									options={this.getOptionsForDropdown()} 							
									value={this.getSelectedOptionForDropdown()}>							
								</Select>
									only if all/any of the following statements of previous questions are fulfilled.</h4>							
							</div>						
						</div>
						<div className="form-row">							
							<ConditionLogics 								
								question={this.props.question}								
								conditionOperator={this.getConditionOperatorSelectedOption()}
								conditions={this.getConditionsSelectedOption()}								
								addConditionHandler={this.addConditionHandler.bind(this)}
								removeConditionHandler={this.removeConditionHandler.bind(this)}								
								validAnswersHandler={this.validAnswersHandler.bind(this)}
								conditionNegateHandler={this.conditionNegateHandler.bind(this)}
								logicOperatorHandler={this.logicOperatorHandler.bind(this)}
								selectedAnswerOptionValue={this.state.selectedAnswerOptionValue}
								getSelectedQuestions={this.getSelectedQuestions.bind(this)}
								>
							</ConditionLogics>
						</div>
						
					</Card>
				</div>
            </div>			
        );
    }
}
TabOptionConditions.contextType = AdHocSurveyFormContext;
export default TabOptionConditions;