import authHeader from './auth-header';
import Api from './api';

const API_URL = process.env.REACT_APP_AQUA_API_ENDPOINT

export default class  AdHocSurveyFolderService {
	_surveyType: number = 0; 
	constructor(surveyType: number) {		
		this._surveyType = surveyType;
	}

	getHeaders() {
		var header = authHeader() as Record<string, string>;	
		header.adhocSurveyType = this._surveyType.toString();
		return header;
	}
	  
	async find(term: string, surveyType: number) {
		return await Api().get(`${API_URL}/adHocSurveyFolder/find?text=${term}%&surveyType=${surveyType}`, { headers:  this.getHeaders() as any });      
	}

	async createFolder(folder: any) {
		return await Api().post(`${API_URL}/adHocSurveyFolder/`, folder, 
		{ method: 'POST',
		headers:  this.getHeaders() as any });      
	}

	async updateFolder(folder: any) {
		return await Api().put(`${API_URL}/adHocSurveyFolder/${folder.id}`, folder, 
		{ method: 'PUT',
		headers:  this.getHeaders() as any });      
  	}

	async deleteFolder(folder: any) {
		return await Api().delete(`${API_URL}/adHocSurveyFolder/${folder.id}`, 
		{ method: 'DELETE',
		headers:  this.getHeaders() as any });      
	}
}