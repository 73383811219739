import React, { Component } from 'react';
import {Label } from 'app/components/Basics/';
import { Input as EcoInput } from '@ecosystem/ui-lib/core/components/Input';
import './GlobalFieldLineComponent.scss';
import {RegionFormContext} from '../../RegionContext'


class GlobalFieldLine extends Component {

    static contextType = RegionFormContext;

    constructor(props){
        super(props);        
    }	

	isChangeDisabled() {
		return !this.context.canChangeData;
	}

    regionCodeHandler = event => {		
        var selectedRegion = this.context.selectedRegion;
		selectedRegion.id = event.target.value ? event.target.value : undefined;
		this.context.updateSelectedRegion(selectedRegion);
    }

	regionNameHandler = event => {		
        var selectedRegion = this.context.selectedRegion;
		selectedRegion.name = event.target.value ? event.target.value : "";
		this.context.updateSelectedRegion(selectedRegion);
    }

    render() {
        return (
			<div>
				<div className="GlobalFieldLineComponent form-row">
					<div className="form-row-left">						
						<div className="field-region-code">
							<Label tooltip="">Region Code</Label>
							<EcoInput disabled={this.isChangeDisabled() || !this.context.selectedRegion._isNew}
								type="number"
								value={this.context.selectedRegion.id} 
								onChange={this.regionCodeHandler}
								key={"region-code-id"}
								id="region-code-id"
								>							
							</EcoInput>						
						</div>	
						
						<div className="field-region-name">
							<Label tooltip="">Region Name</Label>
							<EcoInput disabled={this.isChangeDisabled()}
								value={this.context.selectedRegion.name} 
								onChange={this.regionNameHandler}
								key={"region-name-id"}
								id="region-name-id"
								>							
							</EcoInput>							
						</div>	
						
					</div>
				</div>				
				
			</div>
        );
    }
}

GlobalFieldLine.contextType = RegionFormContext;

export default GlobalFieldLine;