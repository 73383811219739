import authHeader from './auth-header';
import Api from './api';

const API_URL = process.env.REACT_APP_AQUA_API_ENDPOINT

class UserPermissionService {
  async get() {
    return await Api().get(`${API_URL}/userPermission/get`, { headers:  authHeader() as any });      
  }
}

export default new UserPermissionService();