import React, { Component } from 'react';
import {Label } from 'app/components/Basics/';
import { Input as EcoInput } from '@ecosystem/ui-lib/core/components/Input';
import {MediaTypeFormContext} from '../../MediaTypeContext'
import './GlobalFieldLineComponent.scss';

class GlobalFieldLine extends Component {

    static contextType = MediaTypeFormContext;

    constructor(props){
        super(props);        
    }	

	isChangeDisabled() {
		return !this.context.canChangeData;
	}

    mediaTypeIdHandler = event => {		
        var selectedMediaType = this.context.selectedMediaType;
		selectedMediaType.id = event.target.value ? event.target.value : "";
		this.context.updateSelectedMediaType(selectedMediaType);
    }

	mediaTypeNameHandler = event => {		
        var selectedMediaType = this.context.selectedMediaType;
		selectedMediaType.name = event.target.value ? event.target.value : "";
		this.context.updateSelectedMediaType(selectedMediaType);
    }

	mediaTypePlaceholderHandler = event => {		
        var selectedMediaType = this.context.selectedMediaType;
		selectedMediaType.placeholder = event.target.value ? event.target.value : "";
		this.context.updateSelectedMediaType(selectedMediaType);
    }

	mediaTypeStartTimeHandler = event => {		
        var selectedMediaType = this.context.selectedMediaType;
		selectedMediaType.startTime = event.target.value ? event.target.value : "";
		this.context.updateSelectedMediaType(selectedMediaType);
    }

	shortMediaTypeNameHandler = event => {		
        var selectedMediaType = this.context.selectedMediaType;
		selectedMediaType.shortName = event.target.value ? event.target.value : "";
		this.context.updateSelectedMediaType(selectedMediaType);
    }

	mediaTypeSortOrderHandler = event => {		
        var selectedMediaType = this.context.selectedMediaType;
		selectedMediaType.sortOrder = event.target.value ? event.target.value : undefined;
		this.context.updateSelectedMediaType(selectedMediaType);
    }

	changeMediaTypeTypeHandler(selectedItem) {
		var selectedMediaType = this.context.selectedMediaType;
		selectedMediaType.mediaTypeTypeId = parseInt(selectedItem.value);
		this.context.updateSelectedMediaType(selectedMediaType);
    }

	getSelectedMediaTypeTypeOption() {
        return this.props.metadata.mediaTypeTypes
			.find(t=> parseInt(t.value) === this.context.selectedMediaType.mediaTypeTypeId);
    }

	changeMediaTypeHandler(selectedItem) {
		var selectedMediaType = this.context.selectedMediaType;
		selectedMediaType.mediaTypeId = parseInt(selectedItem.value);
		this.context.updateSelectedMediaType(selectedMediaType);
    }

	getSelectedMediaTypeOption() {
        return this.props.metadata.mediaTypes
			.find(t=> parseInt(t.value) === this.context.selectedMediaType.mediaTypeId);
    }

	startDateHandler(date) {		
		var selectedMediaType = this.context.selectedMediaType;
		selectedMediaType.startDate = date;
		this.context.updateSelectedMediaType(selectedMediaType);
	}

	endDateHandler(date) {		
		var selectedMediaType = this.context.selectedMediaType;
		selectedMediaType.endDate = date;
		this.context.updateSelectedMediaType(selectedMediaType);
	}

    render() {
        return (
			<div>
				<div className="GlobalFieldLineComponent form-row">
					<div className="form-row-left">						
						<div className="field-mediaType-id">
							<Label tooltip="">Id</Label>
							<EcoInput disabled={this.isChangeDisabled() || !this.context.selectedMediaType._isNew}								
								type="number"
								value={this.context.selectedMediaType.id} 
								onChange={this.mediaTypeIdHandler}
								key={"mediaType-code-id"}
								id="mediaType-code-id"								
								>							
							</EcoInput>
						</div>
						
						<div className="field-mediaType-name">
							<Label tooltip="">Name</Label>
							<EcoInput disabled={this.isChangeDisabled()}
								value={this.context.selectedMediaType.name} 
								onChange={this.mediaTypeNameHandler}
								key={"mediaType-name-id"}
								id="mediaType-name-id"
								>							
							</EcoInput>							
						</div>					
						
					</div>
				</div>
				<div className="GlobalFieldLineComponent form-row">
					<div className="form-row-left">						
						<div className="field-mediaType-sort-order">
							<Label tooltip="">Sort order</Label>
							<EcoInput disabled={this.isChangeDisabled()}
								type="number"
								value={this.context.selectedMediaType.sortOrder} 
								onChange={this.mediaTypeSortOrderHandler}
								key={"mediaType-sort-order"}
								id="mediaType-sort-order"
								>							
							</EcoInput>							
						</div>

						<div className="field-surveytype">
							<Label>Placeholder</Label>
							<EcoInput disabled={this.isChangeDisabled()}
								value={this.context.selectedMediaType.placeholder} 
								onChange={this.mediaTypePlaceholderHandler}
								key={"mediaType-placeholder-id"}
								id="mediaType-placeholder-id"
								>							
							</EcoInput>		
						</div>						
						<div className="field-survey-startTime">
							<Label>Start Time</Label>
							<EcoInput disabled={this.isChangeDisabled()}
								value={this.context.selectedMediaType.startTime} 
								onChange={this.mediaTypeStartTimeHandler}
								key={"mediaType-startTime-id"}
								id="mediaType-startTime-id"
								>							
							</EcoInput>
						</div>						
					</div>
				</div>
			</div>
        );
    }
}

GlobalFieldLine.contextType = MediaTypeFormContext;

export default GlobalFieldLine;