import React, { Component } from 'react';

import './SpacerComponent.scss';

class Spacer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: props.width || 10,
            line: props.line ? (['center','left','right'].indexOf(props.line) > -1 ? props.line : 'center' ): false
        };
    }

    render() {

        var style = {
            marginLeft: this.state.line ? (this.state.line==='center' ? (this.state.width-1)/2 : (this.state.line==='left' ? 0 : this.state.width-1)) : this.state.width/2,
            marginRight: this.state.line ? (this.state.line==='center' ? (this.state.width-1)/2 : (this.state.line==='right' ? 0 : this.state.width-1)) : this.state.width/2
        };

        return (
            <div className={`SpacerComponent ${this.props.className} ${this.state.line ? "line" : ""}`} style={style}>
            </div>
        );
    }
}

export default Spacer;