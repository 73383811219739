import React, { Component } from 'react';
import { Label } from 'app/components/Basics';
import { Select } from '@ecosystem/ui-lib/core/components/Select';
import { Card } from '@ecosystem/ui-lib/core/components/Card';
import {IndepthSetFormContext} from '../../InDepthContext'
import './TabProgramSelectionComponent.scss';
import MultiSelect from 'app/components/MultiSelect'
import MultiFreeText from 'app/components/MultiFreeText'

class TabProgramSelection extends Component {

	OptionsShowSet = [
        {value:0, label: "for all matched programs"},
        {value:1, label: "for all non matched programs"}        
    ]

    constructor(props){
        super(props);
        this.state = {     
            errorQuestionText: '',
			showQuestionModal: false,
			selectedQuestionToAdd: null
        }    
    }   

    getSelectedOptionShowSet() {
        return this.OptionsShowSet.find(t=> t.value === this.context.selectedIndepthSet.programSelection.isForNonMatchedPrograms)
    }

	changeOptionShowSet(showSet) {		
		var selectedIndepthSet = this.context.selectedIndepthSet;
		selectedIndepthSet.programSelection.isForNonMatchedPrograms = showSet.value;		
		this.context.updateselectedIndepthSet(selectedIndepthSet);       
	}	

	createOption(item, metadataList) {	
		if (!metadataList) { return null }
		return {
			value: item,
			label: metadataList.find(m=>m.value === item).label
		}
	}	

	getSelectedOptionsMultiSelect(metadata, selectedValues ) {		
		var result = [];		
		result = selectedValues.map(
			item => this.createOption(item, metadata)
		);		
		return result;
	}	

	onChangeGenre(items)  {		
		var selectedIndepthSet = this.context.selectedIndepthSet;
		selectedIndepthSet.programSelection.genres = [];
		selectedIndepthSet.programSelection.genres = items.map(x => x.value);		
		this.context.updateselectedIndepthSet(selectedIndepthSet);       
	}

	onRemoveGenre(item)  {		
		var selectedIndepthSet = this.context.selectedIndepthSet;		
		selectedIndepthSet.programSelection.genres = selectedIndepthSet.programSelection.genres.filter(function(f) { return f !== item.value });
		this.context.updateselectedIndepthSet(selectedIndepthSet);
	}

	getChannels(ignoreSurveyTypeId) {		
		let channels = this.props.metadata.channels;
		if (!ignoreSurveyTypeId && this.context.selectedIndepthSet.surveyTypeId) {			
			channels = channels.filter(channel=> channel.surveyTypeIds.includes(this.context.selectedIndepthSet.surveyTypeId));
		}
		
		return channels.map(function (obj) {			
			return {value: obj.id, label: obj.name}
		});
	}

	onChangeChannel(items)  {		
		var selectedIndepthSet = this.context.selectedIndepthSet;
		selectedIndepthSet.programSelection.channels = [];
		selectedIndepthSet.programSelection.channels = items.map(x => x.value);		
		this.context.updateselectedIndepthSet(selectedIndepthSet);       
	}

	onRemoveChannel(item)  {
		var selectedIndepthSet = this.context.selectedIndepthSet;        
		selectedIndepthSet.programSelection.channels = selectedIndepthSet.programSelection.channels.filter(function(f) { return f !== item.value })
		this.context.updateselectedIndepthSet(selectedIndepthSet);        
	}

	onChangeBroadcaster(items)  {		
		var selectedIndepthSet = this.context.selectedIndepthSet;
		selectedIndepthSet.programSelection.broadcasters = [];
		selectedIndepthSet.programSelection.broadcasters = items.map(x => x.value);		
		this.context.updateselectedIndepthSet(selectedIndepthSet);       
	}

	onRemoveBroadcaster(item)  {
		var selectedIndepthSet = this.context.selectedIndepthSet;        
		selectedIndepthSet.programSelection.broadcasters = selectedIndepthSet.programSelection.broadcasters.filter(function(f) { return f !== item.value })
		this.context.updateselectedIndepthSet(selectedIndepthSet);        
	}

	addNewFreeText(newText) {
		var selectedIndepthSet = this.context.selectedIndepthSet;        
		selectedIndepthSet.programSelection.titles.unshift(newText);
		this.context.updateselectedIndepthSet(selectedIndepthSet);
	}

	removeText(index) {
		var selectedIndepthSet = this.context.selectedIndepthSet;        
		selectedIndepthSet.programSelection.titles.splice(index, 1);
		this.context.updateselectedIndepthSet(selectedIndepthSet);
	}

	isDisabled() {
		return !this.context.canChangeData || this.context.isNetQuestSurvey
	}

    render() {
        return (
            <div className="TabProgramSelectionComponent content-box">
                <Card>
					<div className="tab-program-selection-form-row">
						<div className="tab-program-selection-form-row-left">
							<div className="field-option-order">
								<Label tooltip="Select when to show this set of questions; for all titles that match the below criteria OR for all titles that do not match below criteria">
									Show set
								</Label>
								<Select isDisabled={this.isDisabled()} name="optionShowSet" isSearchable={false}
									defaultValue={this.OptionsShowSet[0]}
									value={this.getSelectedOptionShowSet()}
									options={this.OptionsShowSet}
									onChange={val=>{
										this.changeOptionShowSet(val)                                
									}}/>
							</div>						    
							<div className="program-titles">
								<Label tooltip="Fill in the exact program titles that apply for this set of questions">Program Titles</Label>								
								<MultiFreeText disabled={this.isDisabled()}
									values={this.context.selectedIndepthSet.programSelection.titles}
									addNewTextHandler={this.addNewFreeText.bind(this)}
									removeTextHandler={this.removeText.bind(this)}
								></MultiFreeText>
							</div>			

						</div>
						<div className="tab-program-selection-form-row-right">
							<div className='genre-selection'>
								<Label tooltip="Select the genre(s) that apply for this set of questions">Genres</Label>							
								<MultiSelect isDisabled={this.isDisabled()}
									selectedOptions={this.getSelectedOptionsMultiSelect(this.props.metadata.genres, this.context.selectedIndepthSet.programSelection.genres)} 
									onChange={this.onChangeGenre.bind(this)} 
									onRemove={this.onRemoveGenre.bind(this)} 
									options={this.props.metadata.genres}>									
								</MultiSelect>
							</div>
							<div className='channels-selection'>
								<Label tooltip="Select the channel(s) that apply for this set of questions">Channels</Label>							
								<MultiSelect isDisabled={this.isDisabled()}
									selectedOptions={this.getSelectedOptionsMultiSelect(this.getChannels(true), this.context.selectedIndepthSet.programSelection.channels)} 
									onChange={this.onChangeChannel.bind(this)} 
									onRemove={this.onRemoveChannel.bind(this)} 
									options={this.getChannels()}>
								</MultiSelect>
							</div>
							<div className='broadcasters-selection'>
								<Label tooltip="Select the broadcaster(s) that apply for this set of questions">Broadcasters</Label>							
								<MultiSelect isDisabled={this.isDisabled()}
									selectedOptions={this.getSelectedOptionsMultiSelect(this.props.metadata.broadcasters, this.context.selectedIndepthSet.programSelection.broadcasters)} 
									onChange={this.onChangeBroadcaster.bind(this)} 
									onRemove={this.onRemoveBroadcaster.bind(this)} 
									options={this.props.metadata.broadcasters}>									
								</MultiSelect>
							</div>
						</div>
                	</div>
                </Card>
            </div>			
        );
    }
}
TabProgramSelection.contextType = IndepthSetFormContext;
export default TabProgramSelection;