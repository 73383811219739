function ValidateRole(selectedRole) {
	var validationErrors = [];                
	if (!selectedRole.name || selectedRole.name === '') {                    
		addErrorMessage(validationErrors, 'name', 'Role name is empty');
	}
	return validationErrors;
}

function addErrorMessage(validationErrors, field, message) {      
	validationErrors.push({ field: field, errorMessage: message});      
}

export default ValidateRole;