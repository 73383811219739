import React, { Component } from "react";
import { IntroPage } from "app/components/IntroPage";

import './QuestionsHomeComponent.scss';

class QuestionsHome extends Component{
    render() {
        return (
            <IntroPage headerText="Welcome to Questions Homepage">                
                <b>Here you can</b>
                <ul className="introHomeItems">
					<li>Create new questions</li>
					<li>Search for existing questions</li>
					<li>Review and manage existing questions</li>
                </ul>
            </IntroPage>
        )
    }
}

export default QuestionsHome;