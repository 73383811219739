import { Component } from 'react';
import {Label, DatePickerCalendar, Switch} from 'app/components/Basics/';
import { Select } from '@ecosystem/ui-lib/core/components/Select';
import { Checkbox } from '@ecosystem/ui-lib/core/components/Checkbox';
import { Input as EcoInput } from '@ecosystem/ui-lib/core/components/Input';
import './GlobalFieldLineComponent.scss';
import {AdHocSurveyFormContext} from '../../AdHocSurveyContext';
import {adHocSurveyTypes} from 'app/constants/adhoc-survey-types'
import Upload from 'app/components/Upload';

class GlobalFieldLine extends Component {

    static contextType = AdHocSurveyFormContext;

    constructor(props){
        super(props);        
    }

	setStartDate(date) {		
		var selectedAdHocSurvey = this.context.selectedAdHocSurvey;
		selectedAdHocSurvey.startDate = date;
		if(selectedAdHocSurvey.adHocSurveyTypeId === adHocSurveyTypes.Generics){
			selectedAdHocSurvey.endDate = date;
		}
		this.context.updateSelectedAdHocSurvey(selectedAdHocSurvey);
	}

	setEndDate(date) {
		var selectedAdHocSurvey = this.context.selectedAdHocSurvey;
		selectedAdHocSurvey.endDate = date;
		this.context.updateSelectedAdHocSurvey(selectedAdHocSurvey);
	}

	getSelectedFolderOption() {		
        return this.getFolders()
			.find(t=> t.value === this.context.selectedAdHocSurvey.folderId);
    }

	getSelectedWeightingMethodOption() {
        return this.props.metadata.weightingMethods
			.find(t=> parseInt(t.value) === this.context.selectedAdHocSurvey.weightingMethod);
    }
	
	getSelectedDeliveryMethodOption() {
        return this.props.metadata.deliveryMethods
			.find(t=> parseInt(t.value) === this.context.selectedAdHocSurvey.deliveryMethod);
    }	

	getFolders() {		
		return this.props.folders.map(function (obj) {			
			return {value: obj.id, label: obj.name === 'default'? "" : obj.name}
		});
	}

	changeFolderHandler(selectedItem) {
		var selectedAdHocSurvey = this.context.selectedAdHocSurvey;
		selectedAdHocSurvey.folderId = selectedItem.value;
		this.context.updateSelectedAdHocSurvey(selectedAdHocSurvey);
    }

	enabledSwitchHandler() {
		var selectedAdHocSurvey = this.context.selectedAdHocSurvey;
		selectedAdHocSurvey.enabled = this.context.selectedAdHocSurvey.enabled === 1 ? 0 : 1;
		this.context.updateSelectedAdHocSurvey(selectedAdHocSurvey);
	}

	netQuestSurveyIdHandler = event => {		
		var selectedAdHocSurvey = this.context.selectedAdHocSurvey;
		selectedAdHocSurvey.netQuestSurveyId = event.target.value ? parseInt(event.target.value) : undefined;
		this.context.updateSelectedAdHocSurvey(selectedAdHocSurvey);
    }

	sampleIdHandler = event => {		
		var selectedAdHocSurvey = this.context.selectedAdHocSurvey;
		selectedAdHocSurvey.sampleId = event.target.value ? parseInt(event.target.value) : undefined;
		this.context.updateSelectedAdHocSurvey(selectedAdHocSurvey);
    }

	changeWeightMethodHandler(selectedItem) {
		var selectedAdHocSurvey = this.context.selectedAdHocSurvey;
		selectedAdHocSurvey.weightingMethod = parseInt(selectedItem.value);
		this.context.updateSelectedAdHocSurvey(selectedAdHocSurvey);
    }

	changeDeliveryMethodHandler(selectedItem) {
		var selectedAdHocSurvey = this.context.selectedAdHocSurvey;
		selectedAdHocSurvey.deliveryMethod = parseInt(selectedItem.value);
		this.context.updateSelectedAdHocSurvey(selectedAdHocSurvey);
    }

	removeRoutingSwitchHandler() {
		var selectedAdHocSurvey = this.context.selectedAdHocSurvey;
		selectedAdHocSurvey.excludeFromRouting = this.context.selectedAdHocSurvey.excludeFromRouting === 1 ? 0 : 1;
		this.context.updateSelectedAdHocSurvey(selectedAdHocSurvey);
	}

	userCannotChangeData() {
		return !this.props.canChangeData;
	}

	netQuestSchemaUploadHandler = (file) => {
		var selectedAdHocSurvey = this.context.selectedAdHocSurvey;
		selectedAdHocSurvey.schemaFile = file;
		selectedAdHocSurvey.uploadedSchemaFileName = file.name;
		this.context.updateSelectedAdHocSurvey(selectedAdHocSurvey);
	}

	isForceAdhocSurveyDisabled(){
		var forceDisabled = this.context.selectedAdHocSurvey.adHocSurveyTypeId === adHocSurveyTypes.FiveMinex &&
		! this.context.selectedAdHocSurvey.sampleId > 0;
		if(forceDisabled){
			this.context.selectedAdHocSurvey.enabled = 0;
		}
		return forceDisabled;
	}

	forceSchemaQuestionUpdateHandler(event) {		
		var selectedAdHocSurvey = this.context.selectedAdHocSurvey;
		selectedAdHocSurvey.forceSchemaQuestionUpdate = event.target.checked;
		this.context.updateSelectedAdHocSurvey(selectedAdHocSurvey);	
	}

    render() {
        return (
			<div>
				<div className="GlobalFieldLineComponent form-row">
					<div className="form-row-left">
						<div className="field-survey-from">
							<Label>Survey From</Label>
							<DatePickerCalendar disabled={this.userCannotChangeData()} value={this.context.selectedAdHocSurvey.startDate} maxDate={this.context.selectedAdHocSurvey.adHocSurveyTypeId === adHocSurveyTypes.Generics ? null : this.context.selectedAdHocSurvey.endDate} onChange={this.setStartDate.bind(this)} />
						</div>
						<div className="field-survey-to">
							<Label>Survey To</Label>
							<DatePickerCalendar disabled={this.userCannotChangeData() || this.context.selectedAdHocSurvey.adHocSurveyTypeId === adHocSurveyTypes.Generics} value={this.context.selectedAdHocSurvey.adHocSurveyTypeId === adHocSurveyTypes.Generics ? this.context.selectedAdHocSurvey.startDate : this.context.selectedAdHocSurvey.endDate} minDate={this.context.selectedAdHocSurvey.startDate} onChange={this.setEndDate.bind(this)} />
						</div>
						<div className="field-question-enabled">
							<Label>Enabled</Label>
							<div className="default-height centered"><Switch disabled={this.userCannotChangeData() || this.isForceAdhocSurveyDisabled()} name="enabled" setSwitchValue={this.enabledSwitchHandler.bind(this)} checked={this.context.selectedAdHocSurvey.enabled === 1 && ! this.isForceAdhocSurveyDisabled()}/></div>
						</div>
						<div className="field-folder">
							<Label>Folder</Label>
							<Select name="qsetfolder" isSearchable={false}
								styles={{ option: styles => ({ minHeight: 40, ...styles }) }}                            
								onChange={val=>{
									this.changeFolderHandler(val)                                
								}}							
								options={this.getFolders()}
								value={this.getSelectedFolderOption()}
								isDisabled={this.userCannotChangeData()}
								>							
							</Select>
						</div>	
						<div className="field-netquest-id">
							<Label tooltip="">NetQuest Survey Id</Label>
							<EcoInput disabled={this.userCannotChangeData() || ! this.context.selectedAdHocSurvey.questions.every(q => q.question.isSys === 3)} 
								type="number"
								value={this.context.selectedAdHocSurvey.netQuestSurveyId} 
								onChange={this.netQuestSurveyIdHandler}
								key={this.context.selectedAdHocSurvey.id}
								id="netquest-survey-id"
								>							
							</EcoInput>						
						</div>
										
						{/* <div className="field-question-archive">
							<Label>Auto-Archive</Label>
							<div className="default-height centered"><Checkbox checked={true}/></div>
						</div> */}
					</div>
				</div>
				<div className="GlobalFieldLineComponent form-row">
					<div className="form-row-left">
						<div className="field-netquest-schema">
							<Upload buttonLabel="Upload Netquest Schema"
								buttonDisabled={this.userCannotChangeData() || ! this.context.selectedAdHocSurvey.netQuestSurveyId } 
								acceptTypes="application/json"
								onFileSelected={this.netQuestSchemaUploadHandler}
								existingFile={this.context.selectedAdHocSurvey.uploadedSchemaFileName}
								id="netquest-schema-file"
								>							
							</Upload>						
						</div>
						<div className="field-netquest-schema">
						<Checkbox disabled={this.userCannotChangeData() || ! this.context.selectedAdHocSurvey.netQuestSurveyId} label="Force update" checked={this.context.selectedAdHocSurvey.forceSchemaQuestionUpdate === true} onChange={this.forceSchemaQuestionUpdateHandler.bind(this)} />
							<Label tooltip="Update any existing question(s) with the question(s) from this schema file and re-use them here. Effectively, all other questionnaires using these question(s) will be updated." />
						</div>						
					</div>
				</div>
				{this.context.selectedAdHocSurvey.adHocSurveyTypeId === adHocSurveyTypes.FiveMinex && 
				(<div className="GlobalFieldLineComponent form-row">
					<div className="form-row-left">												
					<div className="field-sample-id">
							<Label tooltip="">Sample Id</Label>
							<EcoInput disabled={this.userCannotChangeData()}
								type="number"
								value={this.context.selectedAdHocSurvey.sampleId} 
								onChange={this.sampleIdHandler}
								key={this.context.selectedAdHocSurvey.id}
								id="sample-id"
								>							
							</EcoInput>						
						</div>		
						<div className="field-weightmethod">
							<Label>Weight Method</Label>
							<Select isDisabled={this.userCannotChangeData()} name="selectweightmethod" isSearchable={false}
								styles={{ option: styles => ({ minHeight: 40, ...styles }) }}                            
								onChange={val=>{
									this.changeWeightMethodHandler(val)                                
								}}							
								options={this.props.metadata.weightingMethods} 							
								value={this.getSelectedWeightingMethodOption()}>							
							</Select>
						</div>	
						<div className="field-deliverymethod">
							<Label>Delivery Method</Label>
							<Select isDisabled={this.userCannotChangeData()} name="selectdeliverytmethod" isSearchable={false}
								styles={{ option: styles => ({ minHeight: 40, ...styles }) }}                            
								onChange={val=>{
									this.changeDeliveryMethodHandler(val)                                
								}}							
								options={this.props.metadata.deliveryMethods} 							
								value={this.getSelectedDeliveryMethodOption()}>							
							</Select>
						</div>
						<div className="field-exclude-routing">
							<Label>Exclude From Routing</Label>
							<div className="default-height"><Switch disabled={this.userCannotChangeData()} name="enabled" setSwitchValue={this.removeRoutingSwitchHandler.bind(this)} checked={this.context.selectedAdHocSurvey.excludeFromRouting === 1}/></div>
						</div>	
									
					</div>
				</div>)}
				
			</div>
        );
    }
}

GlobalFieldLine.contextType = AdHocSurveyFormContext;

export default GlobalFieldLine;