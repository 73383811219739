function ConvertToAdHocSurveyMenuItem(adHocSurvey) {
    return {
        id: adHocSurvey.id,       
        name: adHocSurvey.name,
		startDate: adHocSurvey.startDate,
		endDate: adHocSurvey.endDate,
		folderId: adHocSurvey.folderId,
		questionsCount: adHocSurvey.questions.length		
      }
}
export default ConvertToAdHocSurveyMenuItem;