import React, { Component } from 'react';
import Select from 'react-select'
import './overviewFilters.scss';
import { Input as EcoInput } from '@ecosystem/ui-lib/core/components/Input';
import { Label, Button, ButtonGroup, DatePickerCalendar } from 'app/components/Basics';
import { Icons } from '@ecosystem/ui-lib/core/components/Icon';
import { ReactComponent as FilterIcon } from 'app/assets/icons/filter.svg';
import RemoveTimezone from "app/helpers/remove-timezone";
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';

class OverviewFilters extends Component {
	dateFilterTypes = {
        Today: 0,        
        Tomorrow: 1,       
        All: 2,
        Other: 3
    }

    filters = {
        dateFilterType: this.dateFilterTypes.Today,
        dateFilterValue: '',
        filterText: ''
    }

    constructor(props){
        super(props);

        this.filters.dateFilterValue = this.props.todayDate;
        this.state = {
            currentFilters: cloneDeep(this.filters),
            previousFilters: cloneDeep(this.filters),

			dateFilterType: this.dateFilterTypes.Today,
            dateFilterValue: this.props.todayDate,
            filterText: ''
        }
    }

	getDate(dateType) {
        let currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        if (dateType === this.dateFilterTypes.Tomorrow) {
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return RemoveTimezone(currentDate); 
    }

    onClickDateFilter(dateType) {
        var dateFilterValue = null;        
        if (dateType === this.dateFilterTypes.All) {
            dateFilterValue = null;
        }
        else {
            dateFilterValue = this.getDate(dateType);
        }       

        var currentFilters = this.state.currentFilters;
        currentFilters.dateFilterType = dateType;
        currentFilters.dateFilterValue = dateFilterValue;
        this.setState({currentFilters: currentFilters });        
    }

    getDateFilterType(selectedDate) {
        const today = this.props.todayDate;
        let tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow = RemoveTimezone(tomorrow);
        const date = RemoveTimezone(new Date(selectedDate));

        if (date === today) {
            return this.dateFilterTypes.Today;
        } 
        else if (date === tomorrow) {
            return this.dateFilterTypes.Tomorrow
        } 
        else {
            return this.dateFilterTypes.Other;
        }
      }

    setDateFilterValue(date) {
        var currentFilters = this.state.currentFilters;
        currentFilters.dateFilterValue = date;
        this.setState({ currentFilters: currentFilters });
        if (date === null) {
            currentFilters.dateFilterType = this.dateFilterTypes.All;            
        }
        else {
            currentFilters.dateFilterType = this.getDateFilterType(date);
        }
        this.setState({ currentFilters: currentFilters });            
	}

    onFilterTextChange = event => {
        var currentFilters = this.state.currentFilters;
        currentFilters.filterText = event.target.value;
        this.setState({currentFilters: currentFilters });
    }

    filterData() {
        this.props.filterOverview(this.state.currentFilters.filterText, this.state.currentFilters.dateFilterValue);
        this.setState({previousFilters: cloneDeep(this.state.currentFilters) });
    }

    isFilterUnChanged() {        
        return isEqual(this.state.currentFilters, this.state.previousFilters);
    }

    render() {
        return (
            <div className="overview-filters-component">				
				<div className="form-row">
                    <div className="form-row-left">
                    <div className="field-overview-date-picker">
                            <Label>Broadcast Date:</Label>
                            <DatePickerCalendar value={this.state.currentFilters.dateFilterValue} onChange={this.setDateFilterValue.bind(this)} />
                        </div>
                        <div className="field-overview-date">                            
                            <ButtonGroup>                                
                                <Button selected={this.state.currentFilters.dateFilterType === this.dateFilterTypes.Today} onClick={this.onClickDateFilter.bind(this, this.dateFilterTypes.Today)} key="date-today-filter-button">Today</Button>
                                <Button selected={this.state.currentFilters.dateFilterType === this.dateFilterTypes.Tomorrow} onClick={this.onClickDateFilter.bind(this, this.dateFilterTypes.Tomorrow)} key="date-tomorrow-filter-button">Tomorrow</Button>
                                <Button selected={this.state.currentFilters.dateFilterType === this.dateFilterTypes.All} onClick={this.onClickDateFilter.bind(this, this.dateFilterTypes.All)} key="date-all-filter-button">All</Button>
                            </ButtonGroup>
                        </div>
                        <div className="field-overview-filter">                            
                            <EcoInput placeholder={this.props.filterPlaceholders} id="search-indepth-overview" className="search-indepth-overview" leftIcon={Icons.Search} onChange={this.onFilterTextChange} value={this.state.currentFilters.filterText}  /> 
                        </div>                    
                        
                       
                    </div>
                    <div className="form-row-right">
                        <div className="field-overview-filter-button">							
							<Button icon={<FilterIcon/>} disabled={this.isFilterUnChanged()} title="Filter" onClick={this.filterData.bind(this)}>Filter</Button>
						</div>

                    </div>
                </div>
            </div>
        );
    }
}
export default OverviewFilters;