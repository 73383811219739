import React, { Component } from 'react';
import './tableDraggableRows.scss';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

class TableDraggableRows extends Component {
    constructor(props){
        super(props);          
    }
	
	getTableHeaders() {
		return this.props.tableHeaders.map((tableHeader, i) => this.renderTableHeader(tableHeader, i))
	}

	renderTableHeader(tableHeader) {
		return (
			<th key={tableHeader.id} className={`Table__ColumnHeader AppFormTableHeader ${tableHeader.id}`} data-test-id={`col-${tableHeader.id}`}>
				<div className="Table__ColumnHeader__content">
					<div className="Table__ColumnHeader__label">{tableHeader.label}</div>
				</div>
			</th>
		)
	}

	getTableRows() {
		return this.props.tableRows.map((tableRows, i) => this.renderTableRow(tableRows, i))
	}

	renderTableRow(tableRow, index) {		
		return (
			<Draggable isDragDisabled={this.props.disabled} key={tableRow.itemId.toString()} draggableId={tableRow.itemId.toString()} index={index}>
				{provided => (
					<tr className="" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
						{this.getRowCells(tableRow)}
					</tr>
				)}
			</Draggable>
		)
	}

	getRowCells(tableRow) {		
		return tableRow.cells.map((cell, i) => this.getRowCell(cell, i))
	}

	getRowCell(cell, i) {
		return (<td key={i}>{cell}</td>);
	}

	onDragEnd = e => {		
		if (!e.destination) {			
		  	return;
		}
		let items = this.props.tableRows.flatMap(a => a.item);
		let validationResult = this.props.validateNewOrderedList(e.source.index, e.destination.index);
		if (validationResult === false) {
			return;
		}
		const newSortedList = this.reorderItems(items, e.source.index, e.destination.index);		
		this.props.updateNewOrderedList(newSortedList);
	};

	reorderItems(list, sourceIndex, destinationIndex)  {
		const arr = Array.from(list);
		const [removed] = arr.splice(sourceIndex, 1);
		arr.splice(destinationIndex, 0, removed);	
		return arr;
	};
	

    render() {
        return (
			<DragDropContext onDragEnd={this.onDragEnd}>
				<Droppable droppableId="Table">
				{ provided => (
					<table {...provided.droppableProps} ref={provided.innerRef} className="Table Draggable QuestionList">
						<thead>
							<tr>
								{this.getTableHeaders()}
							</tr>
						</thead>
						<tbody className="Table__Body">
							{this.getTableRows()}
							{provided.placeholder}
						</tbody>
					</table>
				)}
				</Droppable>
			</DragDropContext>            
        );
    }
}
export default TableDraggableRows;