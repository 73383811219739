import authHeader from './auth-header';
import Api from './api';

const API_URL = process.env.REACT_APP_AQUA_API_ENDPOINT

class ChannelService {
	
  async getChannelById(channelId: string) {
	return await Api().get(`${API_URL}/channel/${channelId}`, { headers:  authHeader() as any });      
  }
  async find(term: string) {
    return await Api().get(`${API_URL}/channel/find?text=${term}%`, { headers:  authHeader() as any });      
  }

  async updateChannel(channel: any) {
    return await Api().put(`${API_URL}/channel/${channel.id}`, channel, 
    { method: 'PUT',
      headers:  authHeader() as any });      
  }

  async createChannel(channel: any) {
    return await Api().post(`${API_URL}/channel/`, channel, 
    { method: 'POST',
      headers:  authHeader() as any });      
  }

  async deleteChannel(channel: any) {
    return await Api().delete(`${API_URL}/channel/${channel.id}`, 
    { method: 'DELETE',
      headers:  authHeader() as any });      
  }  

  async getMetadata() {
    return await Api().get(`${API_URL}/channel/metadata`, { headers:  authHeader() as any });      
  }
  
  async UploadImageToChannel(channelId: string, image: any) {
    const formData = new FormData();
    formData.append('targetEntity', "channel");
    if (channelId ?? "") {
      formData.append('targetEntityId', channelId);
    }  
    formData.append('file', image);
  
      return await Api().post(`${API_URL}/mediamanager/UploadImage`, formData, 
      { method: 'POST',
        headers:  authHeader() as any });      
    }
  
    async DeleteImageFromChannel(deleteRequest: any) {
      return await Api().delete(`${API_URL}/mediamanager/DeleteImage`, 
      { method: 'DELETE',
        headers:  authHeader() as any,
        data: deleteRequest });      
    }
}

export default new ChannelService();