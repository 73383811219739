import React, { Component } from "react";
import { IntroPage } from "app/components/IntroPage";


import './DemoSetHomeComponent.scss';

class DemoSetHome extends Component{
    render() {		
        return (
            <IntroPage headerText="Demographic Sets">                
                <b>Here you can</b>
                <ul className="introHomeItems">
					<li>Create new Demographic sets</li>
					<li>Add questions to existing sets</li>
					<li>Search for existing Demographic sets</li>
					<li>Review and manage existing Demographic sets</li>
                </ul>
            </IntroPage>
        )
    }
}

export default DemoSetHome;