import React, { Component } from 'react';
import {Spacer, Button} from 'app/components/Basics/'
import { Input  } from '@ecosystem/ui-lib/core/components/Input';
import { Icons } from '@ecosystem/ui-lib/core/components/Icon';
import './InlineTextEditorComponent.scss';


class InlineTextEditor extends Component {

    constructor(props){
        super(props);

        this.state = {
            value: this.props.value
        }        
    }

	componentWillReceiveProps(nextProps) {		
		this.setState({value: nextProps.value});
	}

	setNewValue(e) {
		this.setState({value: e.target.value});		
	}	

    render() {
		return (
			<div className="InlineTextEditorComponent">
				<div className={`inline-text-editor-container`}>
					<Input className='inline-text-input' label="" value={this.state.value} onChange={(e)=> this.setNewValue(e)} />
					<Spacer width={10}/>
					<div className='inline-text-buttons'>
						<Button title="Save" onClick={()=> this.props.saveHandler(this.state.value)} simple small icon={Icons.Check}></Button>
						<Button title="Cancel" onClick={()=> this.props.cancelHandler()} simple small icon={Icons.Close}></Button>
					</div>
				</div>
			</div>
        );
    }
}

export default InlineTextEditor;