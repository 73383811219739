import React, { Component } from "react";
import { IntroPage } from "app/components/IntroPage";
import './RoleHomeComponent.scss';

class RoleHome extends Component{
    render() {
        return (
            <IntroPage headerText="Welcome to Roles Homepage">                
                <b>Here you can</b>
                <ul className="introHomeItems">
					<li>Create new roles</li>
					<li>Add permission to roles</li>					
                </ul>
            </IntroPage>
        )
    }
}

export default RoleHome;