import React, { Component } from 'react';
import 'app/components/SideBar/SideBarComponent.scss';
import './QuestionSearchComponent.scss';
import { Table, GenericCell } from '@ecosystem/ui-lib/core/components/Table';
import QuestionService from "app/services/question.service";
import  GetErrorMessage  from 'app/helpers/error-message'
import {questionTypes } from 'app/constants/question-types'
import { ButtonIcon } from '@ecosystem/ui-lib/core/components/ButtonIcon';
import { Icons } from '@ecosystem/ui-lib/core/components/Icon';
import {StatusBadge} from 'app/components/Basics/'
import { Input as EcoInput } from '@ecosystem/ui-lib/core/components/Input';
import { Loading } from '@ecosystem/ui-lib/core/components/Loading';
import InfiniteScroll from "react-infinite-scroll-component";

class QuestionSearch extends Component {
    constructor(props){
        super(props);

        this.state = {
			loading: false,
            search: "",
			searchedTerm: "",
			infiniteScrollPage: 1,
			infiniteScrollHasMore: true,			
			questions: [],
			typingTimeout: 0,  
        }
    }

	componentDidUpdate(prevProps, prevState) {
		if (this.state.search !== prevState.search) {		  
		  this.queryQuestions(this.state.search);
		}		
	}

	componentDidMount() {
		this.queryQuestions('')
	}

	setSearchState(event) {    
		var value = event.target.value;    
		this.setState({search: value});
	}

	getPossibleParentQuestionIds()
	{
		if (!this.props.possibleParentQuestions) return '';
		var questionIds = [];
		this.props.possibleParentQuestions.forEach(surveyQuestion=>
			questionIds.push(surveyQuestion.question.id)
		);
		return questionIds.join(",");
	}

	queryQuestions(term) {		
		if (this.state.loading) return;
		var page = this.state.infiniteScrollPage;
		this.setState({ loading: true });
		if (term !== this.state.searchedTerm) {
			page = 1;
			this.setState({ questions: [], infiniteScrollPage: page });
		}

		var onlyAdHocParentQuestions = this.props.onlyAdHocParentQuestions ? this.props.onlyAdHocParentQuestions : false;
		var adHocParentQuestionsIds = this.getPossibleParentQuestionIds();
		var excludeDemoQuestions = this.props.excludeDemoQuestions ? this.props.excludeDemoQuestions : false;
		var onlyDemoQuestions = this.props.onlyDemoQuestions ? this.props.onlyDemoQuestions : false;

		QuestionService.findQuestionsPaged(term, onlyAdHocParentQuestions, adHocParentQuestionsIds, true, page, excludeDemoQuestions, onlyDemoQuestions)
		  .then(response =>{
				var questions = this.state.questions;
				questions.push(...response.data.questions);
				this.setState({ questions: questions,
						infiniteScrollPage: page + 1,
						infiniteScrollHasMore: response.data.hasMoreQuestions,	
						loading: false,
						searchedTerm: term
				});
			  return response;          
			  })
			  .catch(error => {			  
				let errorMessage = GetErrorMessage(error);
				console.log("errorMessage on catch");
				console.log(errorMessage);
				this.setState({ loading: false, errorMessage: errorMessage});            
			  })
	}
	
	getQuestionTypeLabel(questionTypeCode) {
		let questionType = questionTypes.find(q=>q.value === questionTypeCode);
		return questionType == null ? "" : questionType.label;
	}	

	isAlreadyAdded(questionId) {		
		if (!this.props.selectedQuestions) {
			return false;
		}
		var question = this.props.selectedQuestions.find(q=>q.id === questionId);
		return question !== undefined
	}

	addQuestionHandler(question) {
		if (!this.isAlreadyAdded(question.id)) {
			this.props.addQuestionHandler(question);
		}
	}

	removeQuestionHandler(question) {		
		this.props.removeQuestionHandler(question.id);		
	}

	addRemoveQuestionHandler(question) {
		if (this.isAlreadyAdded(question.id)) {
			this.props.removeQuestionHandler(question.id);
		}
		else {
			this.props.addQuestionHandler(question);
		}
	}

	addQuestionRow(question) {        
        const cols = [];
        cols.push(<div className='QuestionItemColumn' onClick={()=> this.addRemoveQuestionHandler(question)}><GenericCell className="column-code" primary={question.code}/></div>);
        cols.push(<div className='QuestionItemColumn' onClick={()=> this.addRemoveQuestionHandler(question)}><GenericCell className="column-type" primary={<StatusBadge>{this.getQuestionTypeLabel(question.questionType)}</StatusBadge>}/></div>);
		cols.push(<div className='QuestionItemColumn' onClick={()=> this.addRemoveQuestionHandler(question)}><GenericCell className="column-question-text" primary={question.text}/></div>);
        cols.push(<GenericCell className="column-add" primary={
			<div>
				{!this.isAlreadyAdded(question.id) && <ButtonIcon onClick={() => this.addQuestionHandler(question)}  icon={Icons.AddPlus}/>}
				{this.isAlreadyAdded(question.id) && <ButtonIcon onClick={() => this.removeQuestionHandler(question)} icon={Icons.Remove}/>}
			</div>
		}/>);

        return cols;
    }

	onSearchChange = (e) => {        
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
         }
         let self = this;
         this.setState({           
            typingTimeout: setTimeout(function () {
                self.setState({search: e.target.value});
              }, 500)
         });
		
    };
    
    render() {

		const rows = [];       
		const selectedRows = [];
		var rowsIndex = 1;
        this.state.questions.forEach((question) => {
            rows.push(this.addQuestionRow(question));
			if (this.isAlreadyAdded(question.id)) {
				selectedRows.push(rowsIndex);
			}
			rowsIndex++
        });
        return (
            <div className="QuestionSearchComponent content-box">
				{this.props.title && (<div className='title'>{this.props.title}</div>)}
                <div className='QuestionFilterContainer'>
					<EcoInput id="list-search" className="search-field" leftIcon={Icons.Search} onChange={this.onSearchChange} />
				</div>
				<div className='QuestionListContainerScrollbox'>
					<div id='QuestionListContainer' className='QuestionListContainer'>
						<InfiniteScroll
							dataLength={this.state.questions.length}
							next={()=> {this.queryQuestions(this.state.search)} }
							hasMore={this.state.infiniteScrollHasMore}
							loader={<Loading active={true}></Loading>}
							scrollableTarget="QuestionListContainer">
							<Table
								className="QuestionListIndepth"
								columns={[
									{   headerKey: `column-code`,
										className: "AppFormTableHeader column-code",
										name: `Code`
									},
									{
										headerKey: `column-type`,
										className: "AppFormTableHeader column-type",
										name: `Type`
									},
									{
										headerKey: `column-question-text`,
										className: "AppFormTableHeader column-question-text",
										name: `Question`
									},							
									{
										headerKey: `column-add`,
										className: "AppFormTableHeader column-add",
										name: `Add/Remove`
									}
								]}
								highlightedRows={selectedRows}
								rows= {rows}                
								sortingOptions={{headerKey: null}}
								isHeaderSticky={false}
								isFirstColumnSticky={false}
							/>
						</InfiniteScroll>						
					</div>	
                </div>
            </div>
        );
    }
}

export default QuestionSearch;