import React, { Component } from 'react';
import { ButtonIcon } from '@ecosystem/ui-lib/core/components/ButtonIcon';
import { MemoryRouter,Switch,Route,Redirect,withRouter} from "react-router-dom";
import {TabBar} from 'app/components/Basics';
import { ReactComponent as DuplicateIcon } from 'app/assets/icons/Duplicate.svg';
import GlobalControlLine from './components/GlobalControlLine/GlobalControlLineComponent';
import {RoleFormContext} from './RoleContext'
import RoleService from "../../../services/role.service";
import  GetErrorMessage  from '../../../helpers/error-message'
import {ShowError, ShowSuccess} from "../../../components/toast"
import NewRole from './RoleModel';
import Modal from '../../../components/Modal';
import cloneDeep from 'lodash/cloneDeep';
import TabPermission from './components/TabPermission/TabPermissionComponent';
import isEqual from 'lodash/isEqual';
import ValidateRole from './RoleValidation'

class RoleForm extends Component {
    tabs = [];
    constructor(props){
        super(props);
        this.state = {           
            activeTab:'permissions',
            selectedRole: NewRole(),
            errors: []           
        }        
    }    

	getTabs(){        
        return [
            { id:"permissions", label: 'Permissions', url: 'permissions', disabled:false, error:false}
        ];
    }
	
	componentWillReceiveProps(nextProps) {
		if (nextProps.selectedRole != null) {
			this.setState({ 
				selectedRole: nextProps.selectedRole,
				activeTab: "permissions"
			});
		}	

        if (!isEqual(this.props.selectedRole, nextProps.selectedRole)) {
            this.setState({errors: []});            
        }
    }	

    updateSelectedRole(newSelectedRole) {
		this.setState({selectedRole: newSelectedRole})
    }

    addErrorMessage(field, message) {
        var errors = this.state.errors;
        errors.push({ field: field, errorMessage: message});
        this.setState({errors: errors})
    }	

    SaveRole(role) {                
        this.props.toggleUiBlock(true);
        if (role.id) {
            RoleService.updateRole(role)
                .then(response =>{                    
                    this.props.toggleUiBlock(false);
                    this.setState({successMessage: 'The role was saved successfully' });                    
					this.props.getRoleById(role.id);
                })
                .catch(error => {
					let errorMessage = GetErrorMessage(error);
					console.log("errorMessage on catch");
					console.log(errorMessage);
					this.props.toggleUiBlock(false);
					this.setState({ errorMessage: errorMessage});
                });
        }
        else {
            RoleService.createRole(role)
                .then(response => {					
                    const newRole = response.data;                    
                    this.props.toggleUiBlock(false);
                    this.setState({successMessage: 'The role was created successfully' });                                        
                    this.setState({selectedRole: newRole});					
					this.props.getRoleById(newRole.id);
					
                })
                .catch(error => {
					let errorMessage = GetErrorMessage(error);
					console.log("errorMessage on catch");
					console.log(errorMessage);
					this.props.toggleUiBlock(false);
					this.setState({ errorMessage: errorMessage});
                });
        }

    }
    deleteRole = event => {
		if (this.props.canChangeData) {    
        	this.setState({ deleteModalText: `Are you sure you want to delete the role with name '${this.state.selectedRole.name}'?`});
		}
    }

    duplicateRole = event => {		
        let clonedRole = cloneDeep(this.state.selectedRole);		
        clonedRole.id = null;
        clonedRole.name = `${clonedRole.name}_copy`;
		this.props.updateSelectedRole(clonedRole);
    }

    confirmDelete() {        
        this.hideDeleteModal();
        this.props.toggleUiBlock(true);
        const roleId = this.state.selectedRole.id;
        RoleService.deleteRole(this.state.selectedRole)
        .then(response =>{            
            this.setState({successMessage: 'The role was deleted successfully' });            
            this.props.removeDeletedRole(roleId);
			setTimeout(() => {
				this.props.toggleUiBlock(false);
				this.props.closeEditor();				
			}, 2000);

            
        })
        .catch(error => {
            let errorMessage = GetErrorMessage(error);
            console.log("errorMessage on catch");
            console.log(errorMessage);
            this.props.toggleUiBlock(false);
            this.setState({ errorMessage: errorMessage});
        });
    }

    hideDeleteModal() {
        this.setState({deleteModalText: ''})
    }    

    handleSubmitAction = event => { 
		if (this.props.canChangeData) {
			var validationErrors = ValidateRole(this.state.selectedRole);
			this.setState({errors: validationErrors});
			if (validationErrors && validationErrors.length === 0) {                    
				this.SaveRole(this.state.selectedRole);
			}        
		}
        return;
    }

    render() {

        this.tabs = this.getTabs(this.state.selectedRole);
        return (        
               
            <div className="AppFormComponent RoleForm">                 
                <RoleFormContext.Provider value={{
					updateSelectedRole: this.updateSelectedRole.bind(this), 
					selectedRole: this.state.selectedRole,
					canChangeData: this.props.canChangeData}}>                
                    <form ref={ form => this.formEl = form } onSubmit={ this.handleSubmitAction }>
                        <div className="form-head">
                            <GlobalControlLine errors={this.state.errors} 
                                handleSubmitAction={this.handleSubmitAction.bind(this)} 
                                deleteRole={this.deleteRole.bind(this)}
                                duplicateRole={this.duplicateRole.bind(this)}
                                closeEditorButtonOnClick={this.props.closeEditorButtonOnClick}
								didSelectedRoleChange={this.props.didSelectedRoleChange}/>                            
                        </div>
                        <div className="form-sections">
                            <MemoryRouter>
                                <div className="tab-bar">
                                    <TabBar key="RoleForm" activeTab={this.state.activeTab} tabs={this.tabs} onTabActivate={item=>this.setState({activeTab:item.id})}/>                                    
                                </div>
                                <div className="section-content">
                                    <Switch>
                                        <Route exact path="/"><Redirect to={this.state.activeTab}/></Route>
                                        <Route exact path="/permissions"><TabPermission key={this.state.selectedRole?.id} metadata={this.props.metadata} errors={this.state.errors} /></Route>                                        
                                    </Switch>
                                    <Redirect to={this.state.activeTab}/>
                                </div>
                            </MemoryRouter>
                        </div>
                    </form>                    
                </RoleFormContext.Provider>

                {this.state.errorMessage && ( <ShowError clearErrorMessage={() => this.setState({errorMessage: ''})} errorMessage={this.state.errorMessage} /> )}
                {this.state.successMessage && ( <ShowSuccess clearMessage={() => this.setState({successMessage: ''})} message={this.state.successMessage} /> )}
                {this.state.deleteModalText && ( <Modal content={this.state.deleteModalText} size="small" confirmModalHandler={this.confirmDelete.bind(this)} cancelModalHandler={this.hideDeleteModal.bind(this)} />)}
            </div>            
        );
    }
}
export default withRouter(RoleForm); 