import React, { Component } from 'react';
import { Button, Label} from 'app/components/Basics';
import  VideoPlayer  from 'app/components/VideoPlayer';
import  ImagePreview  from 'app/components/ImagePreview';
import Modal from 'app/components/Modal';
import Upload from 'app/components/Upload';
import QuestionService from "app/services/question.service";
import  GetErrorMessage  from 'app/helpers/error-message'
import { QuestionFormContext } from 'app/pages/question/QuestionContext';
import { Loading } from '@ecosystem/ui-lib/core/components/Loading';
import {ShowError, ShowSuccess} from "../../../components/toast"
import './mediaurlhandler.scss';

class MediaUrlHandler extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileUploaded: false,
			tempUrl: this.props.url,	
			loading: false,
			mediaUrlModal: false,
			deleteModal: false,
			fileToUpload: null,
			successMessage: "",
        };
    }

	showMediaUrlModal() {
		this.setState({mediaUrlModal: true});
	}

	hideMediaUrlModal() {
		this.setState({mediaUrlModal: false, fileToUpload: null});	
	}
	
	showDeleteModal() {
		this.setState({deleteModal: true});
	}

	hideDeleteModal() {
		this.setState({deleteModal: false});	
	}

	getModalTitle() {
		let titleComplement = "";
		if (this.props.entityType === "option") {
			titleComplement = " for option with code " + this.props.id;
		}
		else {
			titleComplement = " for question"
		}
		return "Upload " + this.props.mediaType.charAt(0).toUpperCase() + this.props.mediaType.slice(1) + titleComplement;
	}

	onFileSelected = (file) => {		
		this.setState({ fileToUpload: file });
	};

	handleVideoUpload(file, optionValue) {		
		this.disableModalButtons(true);
		var selectedQuestion = this.context.selectedQuestion;
		var fileName = file.name;
		QuestionService.UploadVideoToQuestion(selectedQuestion.id, optionValue, file)
			.then(response =>{					
				if (this.props.entityType === "option" && optionValue != null) {
					var option = selectedQuestion.options.find(o => o.value === optionValue);
					option.videoUrl = response.data.videoUrl;
					option.videoThumbnailUrl = response.data.videoThumbnailUrl;
				}
				else {
					selectedQuestion.videoUrl = response.data.videoUrl;
					selectedQuestion.videoThumbnailUrl = response.data.videoThumbnailUrl;
				}
				this.context.updateSelectedQuestion(selectedQuestion);
				this.setState({ successMessage: `Video file ${fileName} was added successfully to question ${selectedQuestion.code}`});
				this.disableModalButtons(false);
				this.hideMediaUrlModal();
				
			return response;          
			})
			.catch(error => {			
				this.disableModalButtons(false);  
			  	let errorMessage = GetErrorMessage(error);
				this.setState({ errorMessage: errorMessage});
			  	console.log("errorMessage on catch");
			  	console.log(errorMessage);			  
			});
	}

	handleImageUpload(file, optionValue) {		
		this.disableModalButtons(true);
		var selectedQuestion = this.context.selectedQuestion;
		var fileName = file.name;
		QuestionService.UploadImageToQuestion(selectedQuestion.id, optionValue, file)
			.then(response =>{				
				if (this.props.entityType === "option" && optionValue != null) {
					var option = selectedQuestion.options.find(o => o.value === optionValue);
					option.imageUrl = response.data;
				}
				else {
					selectedQuestion.imageUrl = response.data;
				}
				this.context.updateSelectedQuestion(selectedQuestion);
				this.setState({ successMessage: `Image file ${fileName} was added successfully to question ${selectedQuestion.code}`});
				this.disableModalButtons(false);
				this.hideMediaUrlModal();
				
			return response;          
			})
			.catch(error => {			
				this.disableModalButtons(false);  
			  	let errorMessage = GetErrorMessage(error);
				this.setState({ errorMessage: errorMessage});
			  	console.log("errorMessage on catch");
			  	console.log(errorMessage);			  
			});
	}

	handleDelete() {
		if (this.props.mediaType === "video") {
			this.handleDeleteVideo();
		}
		else if (this.props.mediaType === "image") {
			this.handleDeleteImage();
		}
	}

	handleDeleteVideo() {
		this.disableModalButtons(true);
		var selectedQuestion = this.context.selectedQuestion;
		var deleteRequest = {
			targetEntityId: selectedQuestion.id,
			targetEntity: "question",
			answerOption: this.props.optionValue != null ? this.props.optionValue.toString() : null,
			mediaUrl: this.props.url
		}
		QuestionService.DeleteVideoFromQuestion(deleteRequest)
			.then(response =>{
				if (this.props.entityType === "option" && this.props.optionValue != null) {
					var option = selectedQuestion.options.find(o => o.value === this.props.optionValue);
					option.videoUrl = null;
				}
				else {
					selectedQuestion.videoUrl = null;
				}
				this.setState({ successMessage: `The Video file was deleted successfully from the question ${selectedQuestion.code}`});
				this.context.updateSelectedQuestion(selectedQuestion);				
				this.disableModalButtons(false);
				this.hideDeleteModal();
				
			return response;          
			})
			.catch(error => {			
				this.disableModalButtons(false);  
			  	let errorMessage = GetErrorMessage(error);
				this.setState({ errorMessage: errorMessage});
			  	console.log("errorMessage on catch");
			  	console.log(errorMessage);			  
			});
	}

	handleDeleteImage() {
		this.disableModalButtons(true);	
		const url = new URL(this.props.url);		
		var selectedQuestion = this.context.selectedQuestion;
		let fileName = url.pathname.substring(1);
		var deleteRequest = {
			targetEntityId: selectedQuestion.id,
			targetEntity: "question",
			answerOption: this.props.optionValue != null ? this.props.optionValue.toString() : null,
			mediaUrl: fileName
		}
		QuestionService.DeleteImageFromQuestion(deleteRequest)
			.then(response =>{
				if (this.props.entityType === "option" && this.props.optionValue != null) {
					var option = selectedQuestion.options.find(o => o.value === this.props.optionValue);
					option.imageUrl = null;
				}
				else {
					selectedQuestion.imageUrl = null;
				}
				this.context.updateSelectedQuestion(selectedQuestion);
				this.setState({ successMessage: `The image file was deleted successfully from the question ${selectedQuestion.code}`});
				this.disableModalButtons(false);
				this.hideDeleteModal();
				
			return response;          
			})
			.catch(error => {			
				this.disableModalButtons(false);  
			  	let errorMessage = GetErrorMessage(error);
				this.setState({ errorMessage: errorMessage});
			  	console.log("errorMessage on catch");
			  	console.log(errorMessage);			  
			});
	}

	disableModalButtons(newValue) {
		this.setState({ 
			disableConfirmButton: newValue, 
			disableCancelButton: newValue,
			disableBrowseButton: newValue,
			loading: newValue
		});
	}

	handleUpload = () => {
		if (this.props.mediaType === "video") {
			this.handleVideoUpload(this.state.fileToUpload, this.props.optionValue);
		}
		else if (this.props.mediaType === "image") {
			this.handleImageUpload(this.state.fileToUpload, this.props.optionValue);
		}
	};

	disableUploadButton() {
		if (this.state.disableConfirmButton) {
			return true;
		}
		if (this.state.fileToUpload == null) {
			return true;
		}
		return false;
	}

	getUploadModalContent() {
		return (
			<div>
				<div className="modal-title">
					{this.getModalTitle()}
				</div>				
				<div className="modal-input">					
					{this.props.mediaType === "video" && <Upload disableButton={this.state.disableBrowseButton} buttonLabel="Select Video" acceptTypes="video/*" onFileSelected={this.onFileSelected}></Upload>}
					{this.props.mediaType === "image" && <Upload disableButton={this.state.disableBrowseButton} buttonLabel="Select Image" acceptTypes=".jpg, .jpeg, .gif, .png" onFileSelected={this.onFileSelected}></Upload>}
					<Loading active={this.state.loading}></Loading>
				</div>				
			</div>
		)
	}

	getDeleteModalContent() {
		return (
			<div>
				<div className="modal-title">
					Are you sure you want to delete this {this.props.mediaType}?
				</div>				
				<div className="modal-input">
					{this.props.url && this.props.mediaType === "image" && <ImagePreview url={this.props.url} width={70} height={50}></ImagePreview>}
					{this.props.url && this.props.mediaType === "video" && <VideoPlayer url={this.props.url} thumbnailUrl={this.props.thumbnailUrl} width={70} height={50}></VideoPlayer>}		
					<Loading active={this.state.loading}></Loading>
				</div>				
			</div>
		)
	}

    render() {
        return(
            <div className={`MediaUrlHandlerComponent ${this.props.className} ${this.props.disabled ? "disabled" : ""} ${this.props.error ? "error" : ""}`}>
                <div className="file-preview-area">
                    {!this.props.url && <div className="instruction-label">No {this.props.mediaType} yet</div>}
					{this.props.url && this.props.mediaType === "image" && <ImagePreview url={this.props.url} width={70} height={50}></ImagePreview>}
					{this.props.url && this.props.mediaType === "video" && <VideoPlayer url={this.props.url} thumbnailUrl={this.props.thumbnailUrl} width={70} height={50}></VideoPlayer>}
                    <div>
						<Button className="button-upload-actions" onClick={()=> this.showMediaUrlModal()} disabled={this.props.disabled} small secondary>{this.props.url ? "Change" : "Add"}</Button>
						{this.props.url && (<Button className="button-upload-actions" onClick={()=> this.showDeleteModal()} disabled={this.props.disabled} small secondary>Delete</Button>)}
					</div>
                </div>
				{this.state.mediaUrlModal && ( <Modal disableCancelButton={this.state.disableCancelButton} disableConfirmButton={this.disableUploadButton()} content={this.getUploadModalContent()} size="small" confirmButtonLabel="Upload" confirmModalHandler={this.handleUpload.bind(this)} cancelModalHandler={this.hideMediaUrlModal.bind(this)} />)}
				{this.state.deleteModal && ( <Modal disableCancelButton={this.state.disableCancelButton} disableConfirmButton={this.state.disableConfirmButton} content={this.getDeleteModalContent()} size="small" confirmButtonLabel="Delete" confirmModalHandler={this.handleDelete.bind(this)} cancelModalHandler={this.hideDeleteModal.bind(this)} />)}
				{this.state.errorMessage && ( <ShowError clearErrorMessage={() => this.setState({errorMessage: ''})} errorMessage={this.state.errorMessage} /> )}
				{this.state.successMessage && ( <ShowSuccess clearMessage={() => this.setState({successMessage: ''})} message={this.state.successMessage} /> )}
            </div>           
        );
    }
}

MediaUrlHandler.contextType = QuestionFormContext;
export default MediaUrlHandler;