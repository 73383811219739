import authHeader from './auth-header';
import Api from './api';

const API_URL = process.env.REACT_APP_AQUA_API_ENDPOINT

class UserService {
	
  async getUserById(userId: string) {
	return await Api().get(`${API_URL}/user/${userId}`, { headers:  authHeader() as any });      
  }
  async find(term: string) {
    return await Api().get(`${API_URL}/user/find?text=${term}%`, { headers:  authHeader() as any });      
  }

  async updateUser(user: any) {
    return await Api().put(`${API_URL}/user/${user.id}`, user, 
    { method: 'PUT',
      headers:  authHeader() as any });      
  }

  async createUser(user: any) {
    return await Api().post(`${API_URL}/user/`, user, 
    { method: 'POST',
      headers:  authHeader() as any });      
  }

  async deleteUser(user: any) {
    return await Api().delete(`${API_URL}/user/${user.id}`, 
    { method: 'DELETE',
      headers:  authHeader() as any });      
  }

  async changeLoggedUserPassword(newPassword: string) {
    return await Api().put(`${API_URL}/user/changeLoggedUserPassword`, {newPassword}, 
    { method: 'PUT',
      headers:  authHeader() as any });      
  }

  async resetUserPassword(userId: string, newPassword: string) {	
    return await Api().put(`${API_URL}/user/resetUserPassword`, {
        userId, newPassword  
	}, 
    { method: 'PUT',
      headers:  authHeader() as any });      
  }

  async getMetadata() {
    return await Api().get(`${API_URL}/user/metadata`, { headers:  authHeader() as any });      
  }  
}

export default new UserService();