import axios from "axios";

const API_URL = process.env.REACT_APP_AQUA_API_ENDPOINT

class AuthService {
  async login(username: string, password: string, reCaptchaResponseToken: string) {
    const response = await axios.post(`${API_URL}/login/authenticate`, {
        username,
        password,
		reCaptchaResponseToken
      });
    if (response.data.token) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }
    return response.data;
  }

  logout() {
    localStorage.removeItem("user");
  }

  getCurrentUser() {
    const userStr = localStorage.getItem("user");
    if (userStr) return JSON.parse(userStr);

    return null;
  }

  getLoggedUserName() {
    const user = this.getCurrentUser()
    if (user) return user.userName;
    return ""    
  }
}

export default new AuthService();