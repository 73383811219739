import React, { Component } from 'react';
import './SideListComponent.scss';

export const SideListContext = React.createContext(); 

class SideList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedItem: null
        };
        this.setSelectedItem = this.setSelectedItem.bind(this)
    }

    setSelectedItem (item) {
        this.setState({selectedItem: item});
    }

    render() {
        return (
            <ul className="SideListComponent">
                <SideListContext.Provider value={{setSelectedItem: this.setSelectedItem, selectedItem: this.state.selectedItem}}>
                    {this.props.children}
                </SideListContext.Provider>
            </ul>
        );
    }
}

export default SideList;