import authHeader from './auth-header';
import Api from './api';

const API_URL = process.env.REACT_APP_AQUA_API_ENDPOINT

class MediaTypeService {
	
  async getMediaTypeById(mediaTypeId: string) {
	return await Api().get(`${API_URL}/mediaType/${mediaTypeId}`, { headers:  authHeader() as any });      
  }
  async find(term: string) {
    return await Api().get(`${API_URL}/mediaType/find?text=${term}%`, { headers:  authHeader() as any });      
  }

  async updateMediaType(mediaType: any) {
    return await Api().put(`${API_URL}/mediaType/${mediaType.id}`, mediaType, 
    { method: 'PUT',
      headers:  authHeader() as any });      
  }

  async createMediaType(mediaType: any) {
    return await Api().post(`${API_URL}/mediaType/`, mediaType, 
    { method: 'POST',
      headers:  authHeader() as any });      
  }

  async deleteMediaType(mediaType: any) {
    return await Api().delete(`${API_URL}/mediaType/${mediaType.id}`, 
    { method: 'DELETE',
      headers:  authHeader() as any });      
  }
}

export default new MediaTypeService();