import React, { Component } from 'react';
import {Spacer, Button, InputInline} from 'app/components/Basics/'
import { Icons } from '@ecosystem/ui-lib/core/components/Icon';
import './GlobalControlLineComponent.scss';
import { ReactComponent as SaveIcon } from 'app/assets/icons/Save.svg';
import { ReactComponent as DuplicateIcon } from 'app/assets/icons/Duplicate-Plus.svg';
import { ReactComponent as PagesIcon } from 'app/assets/icons/pagepage.svg';
import { ReactComponent as ArchiveIcon } from 'app/assets/icons/Archive.svg';
import {AdHocSurveyFormContext} from '../../AdHocSurveyContext'
import ValidateAdHocSurvey from '../../AdHocSurveyValidation'
 
class GlobalControlLine extends Component {

    constructor(props){
        super(props);

        this.state = {
            selectedAdHocSurvey: { name : "New Question Set"},
            errorAdHocSurveyName: ''        
        }        
    }

    componentWillReceiveProps(nextProps) {        
        if (nextProps && nextProps.errors) {            
            var error = nextProps.errors.find(e => e.field === "name")
            if (error) {
                this.setState({errorAdHocSurveyName: error.errorMessage})
            }
        }
    }  

    getCurrentAdHocSurveyName() {		
        return this.context.selectedAdHocSurvey.name !== '' ? 
            this.context.selectedAdHocSurvey.name : "New Question Set";
    }

    getNewAdHocSurveyName(isEditorOpen, newAdHocSurveyName) {
        if (!isEditorOpen) {
            var selectedAdHocSurvey = this.context.selectedAdHocSurvey;
            selectedAdHocSurvey.name = newAdHocSurveyName;
            this.context.updateSelectedAdHocSurvey(selectedAdHocSurvey);
        }
    }
	
	isSaveDisabled() {
		let result = false;
		var errors = ValidateAdHocSurvey(this.context.selectedAdHocSurvey);		
		if (errors.length > 0) {
			result = true;
		}		
		if (!this.props.didSelectedAdHocSurveyChange()) {
			result = true;
		}
		return result;
	}

	isNewAdHocSurvey() {
		return this.context.selectedAdHocSurvey.id === null;
	}	

	userCannotChangeData() {
		return !this.props.canChangeData;
	}

    render() {
        return (
            <div className="GlobalControlLineComponent form-row">
                <InputInline disabled={this.userCannotChangeData()} enableOnBlur className="name-field" error={this.state.errorAdHocSurveyName} value={this.getCurrentAdHocSurveyName()} onModeChange={this.getNewAdHocSurveyName.bind(this)} />
                <div className="contol-buttons">                    
                    <Button disabled={this.userCannotChangeData() || this.isSaveDisabled()} title="Save all changes in the survey" icon={<SaveIcon/>} onClick={this.props.handleSubmitAction}>Save</Button>                    
                    <Button title="Close current editor and discard all unsaved changes" onClick={this.props.closeEditorButtonOnClick} simple icon={Icons.Close}>Close</Button>
                    <Button disabled={this.userCannotChangeData() || this.isNewAdHocSurvey()} title="Delete the survey is irreversible" small secondary icon={Icons.Delete} onClick={this.props.deleteAdHocSurvey}>Delete</Button>
                    <Button disabled={this.userCannotChangeData() || this.isNewAdHocSurvey()} title="Create a new survey as duplicate from this survey" small secondary icon={<DuplicateIcon/>} onClick={this.props.duplicateAdHocSurvey}>Duplicate</Button>
					<Button disabled={!this.isSaveDisabled() || this.isNewAdHocSurvey()} title="Create test links to test the question set on netquest" small secondary icon={<PagesIcon/>} onClick={this.props.createTestLinks}>Create Test Links</Button>
                    {/* <Button title="Move this question into the archive (irreversible)" small secondary icon={<ArchiveIcon/>}>Archive</Button> */}
                </div>
                
            </div>  
        );
    }
}
GlobalControlLine.contextType = AdHocSurveyFormContext;
export default GlobalControlLine;