import React, { Component } from "react";
import {Label, DatePickerCalendar, Button} from 'app/components/Basics/';
import SurveyHistoryService from "../../../../services/surveyHistory.service";
import GetErrorMessage from '../../../../helpers/error-message';
import {ShowError, ShowSuccess} from "../../../../components/toast"
import fileDownload from 'js-file-download';
import './SurveyHistoryFormComponent.scss';

class SurveyHistoryForm extends Component {
	constructor (props) {
	super(props);
		this.state = {
			startDate: null,
			endDate: null
		}		
	}
	
	setStartDate(date) {		
		this.setState({startDate: date});
		this.setEndDate(date);
	}

	setEndDate(date) {		
		this.setState({endDate: date});
	}

	downloadSurveys() {		
		this.props.toggleUiBlock(true);		
		var startDate = this.state.startDate;
		var endDate = this.state.endDate;
        SurveyHistoryService.download(startDate, endDate).then(response =>{			
			let name = response.headers['content-disposition'].split('filename=')[1].split('.')[0];
			let extension = response.headers['content-disposition'].split('.')[1].split(';')[0];		
			let fileName = `${name}.${extension}`;
			fileDownload(response.data, fileName);
			this.setState({ successMessage: `The zip file ${fileName} was downloaded successfully`});
			this.props.toggleUiBlock(false);			
		})
		.catch(error => {		
			let errorMessage = '';
			if (error?.response?.status && error.response.status === 404) {				
				errorMessage = "No files found for the provided dates!"
			}
			else {
				errorMessage = GetErrorMessage(error);
				console.log("errorMessage on catch");
				console.log(errorMessage);
			}
			this.props.toggleUiBlock(false);
			this.setState({ errorMessage: errorMessage});
		});	
    }	

    render() {
        return (
			<div className="AppFormComponent">
				<div className="form-row createSurveyHeader">
					Download Surveys
				</div>
				<div className="form-row">
					<Label>Note! Please select the broadcast date (that is one day earlier than the survey was/is in field).</Label>
				</div>
				<div className="form-row">
					<div className="form-row-left">
						<div className="field-survey-from">
							<Label>Start Date</Label>
							<DatePickerCalendar onChange={this.setStartDate.bind(this)} value={this.state.startDate} />
						</div>
						<div className="field-survey-from">
							<Label>End Date</Label>
							<DatePickerCalendar onChange={this.setEndDate.bind(this)} value={this.state.endDate} />
						</div>						
						<div className="field-survey-test-button">							
							<Button disabled={ !this.state.startDate || !this.state.endDate} title="Download surveys from specified dates" onClick={this.downloadSurveys.bind(this)}>Download</Button>
						</div>                 
					</div>
					<div className="form-row-right">
					</div>
				</div>
				{this.state.errorMessage && ( <ShowError clearErrorMessage={() => this.setState({errorMessage: ''})} errorMessage={this.state.errorMessage} /> )}				
				{this.state.successMessage && ( <ShowSuccess clearMessage={() => this.setState({successMessage: ''})} message={this.state.successMessage} /> )}
			</div>
        )
    }
}

export default SurveyHistoryForm;