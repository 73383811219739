import React, { Component } from 'react';
import { Button as EcoButton } from '@ecosystem/ui-lib/core/components/Button';
import './ButtonComponent.scss';

class ButtonGroup extends Component {
    constructor(props) {
        super(props);
        var activeButton = props.multiSelect ? [] : null

        this.props.children.forEach(child => {
            if(child?.props?.defaultActive)
                activeButton = this.props.multiSelect ? activeButton.concat(child) : child;
        });

        this.state = {
            activeButton: activeButton
        };
    }

    changeActivity (button, active){
        if(this.props.multiSelect){
            if(this.buttonIsActive(button)){
                if(button.props.onInactivate) button.props.onInactivate();
                this.setState({activeButton: this.state.activeButton.filter((item)=>item!==button)});
            }else{                
                this.setState({activeButton: this.state.activeButton.concat(button)});
                if(button.props.onActivate) button.props.onActivate();
            }
        }else{
            if(!this.buttonIsActive(button)){
                if(this.state.activeButton)
                    if(this.state.activeButton.props.onInactivate) this.state.activeButton.props.onInactivate();
                this.setState({activeButton:button});
                if(button.props.onActivate) button.props.onActivate();
            }
        }
    }

    buttonIsActive(button) {        
        return button.props.selected === true;
    }

    render() {       
        const childrenWithProps = React.Children.map(this.props.children, (child, index) => {
            if (child?.props) {
                return React.cloneElement(child, {
                    onClick: () => {if(child.props.onClick) child.props.onClick();this.changeActivity(child);},
                    index,
                    isActive: this.buttonIsActive(child),
                    className: this.buttonIsActive(child) ? `${child.props.className} isActive` : child.props.className
                });
            } else {
                return (null);
            }
        });

        return (
            <div className={`ButtonGroupComponent ${this.props.className}`}>
                {childrenWithProps}
            </div>
        );
    }
}

class Button extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sizeCls: props.large ? 'large' : 'small',
            fillCls: props.filled ? 'filled' : (props.unframed ? 'unframed' : 'framed'),
            //disabled: props.disabled ? true : false
        };
    }

    render() {		
        if (!this.props) {
            return (null);
        }
        if(this.props.icon){
            var { ['icon']: _, ...newProps } = this.props;

            if(React.isValidElement(this.props.icon)){
                return(
                    <div title={this.props.title} className={`ButtonComponent separated-icon ${this.props.className}`}>
                        <EcoButton disabled={this.props.disabled} {...newProps}>{this.props.children}</EcoButton>
                        {React.cloneElement(this.props.icon,{onClick:()=>{}})}
                    </div>
                );
            }else{
                return(
                    <div title={this.props.title} className={`ButtonComponent ${this.props.className}`}>
                        <EcoButton disabled={this.props.disabled} icon={this.props.icon} {...newProps}>{this.props.children}</EcoButton>
                    </div>
                );
            }
        }else{
            return (
                <div title={this.props.title} className={`ButtonComponent ${this.props.className}`}>
                    <EcoButton disabled={this.props.disabled} {...this.props}>{this.props.children}</EcoButton>
                </div>
            );
        }
    }
}

export default Button;
export {Button, ButtonGroup};