import React, { Component } from 'react';
import { Spacer, Button, InputInline} from 'app/components/Basics/'
import { Icons } from '@ecosystem/ui-lib/core/components/Icon';
import './GlobalControlLineComponent.scss';
import { ReactComponent as SaveIcon } from 'app/assets/icons/Save.svg';
import { ReactComponent as DuplicateIcon } from 'app/assets/icons/Duplicate-Plus.svg';
import {RegionFormContext} from '../../RegionContext'
import ValidateRegion from '../../../region/RegionValidation'
 
class GlobalControlLine extends Component {

    constructor(props){
        super(props);

        this.state = {           
        }        
    }

    componentWillReceiveProps(nextProps) {        
        if (nextProps && nextProps.errors) {            
            var error = nextProps.errors.find(e => e.field === "name")
            if (error) {
                this.setState({errorRegionName: error.errorMessage})
            }
        }
    }

	isSaveDisabled() {
		if (this.isChangeDisabled())
		{
			return true;
		}
		let result = false;
		var errors = ValidateRegion(this.context.selectedRegion);		
		if (errors.length > 0) {
			result = true;
		}		
		if (!this.props.didSelectedRegionChange()) {
			result = true;
		}
		return result;
	}

	isButtonDisabled() {	
		if (this.isChangeDisabled())
		{
			return true;
		}
		return this.context.selectedRegion.id === null;
	}

	getRegionName() {		
        return this.context.selectedRegion.name !== '' ? 
            this.context.selectedRegion.name : "New Region Name";
    }

    getRegionCode() {		
        return this.context.selectedRegion.id != null && this.context.selectedRegion.id !== '' ? 
            this.context.selectedRegion.id : "New Region Code";
    }

	regionNameChange(isEditorOpen, newName) {
        if (!isEditorOpen) {
            var selectedRegion = this.context.selectedRegion;
            selectedRegion.name = newName;
            this.context.updateSelectedRegion(selectedRegion);
        }
    }

    regionCodeChange(isEditorOpen, newCode) {
        if (!isEditorOpen) {
            var selectedRegion = this.context.selectedRegion;
            selectedRegion.id = newCode;
            this.context.updateSelectedRegion(selectedRegion);
        }
    }

    regionCodeHandler = event => {		
        var selectedRegion = this.context.selectedRegion;
		selectedRegion.id = event.target.value ? parseInt(event.target.value) : undefined;
		this.context.updateSelectedRegion(selectedRegion);
    }

	isChangeDisabled() {
		return !this.context.canChangeData || this.context.selectedRegion.isSysAdmin === 1;
	}

    render() {
        return (
            <div className="GlobalControlLineComponent form-row">
                <div/>
				{/* <InputInline disabled={this.isChangeDisabled()} id="region-code-field" enableOnBlur className="name-field" value={this.getRegionCode()} onModeChange={this.regionCodeChange.bind(this)} />
                <InputInline disabled={this.isChangeDisabled()} id="region-name-field" enableOnBlur className="name-field" value={this.getRegionName()} onModeChange={this.regionNameChange.bind(this)} /> */}                       
                <div className="contol-buttons">                    
                    <Button disabled={this.isSaveDisabled()} title="Save all changes in the region" icon={<SaveIcon/>} onClick={this.props.handleSubmitAction}>Save</Button>                    
                    <Button title="Close current editor and discard all unsaved changes" onClick={this.props.closeEditorButtonOnClick} simple icon={Icons.Close}>Close</Button>
                    <Spacer width={20}/>                   
                    <Button disabled={this.isButtonDisabled()} title="Delete the region is irreversible" small secondary icon={Icons.Delete} onClick={this.props.deleteRegion}>Delete</Button>
                    <Button disabled={this.isButtonDisabled()} title="Create a new region as duplicate from this region" small secondary icon={<DuplicateIcon/>} onClick={this.props.duplicateRegion}>Duplicate</Button>                   
                </div>
                
            </div>  
        );
    }
}
GlobalControlLine.contextType = RegionFormContext;
export default GlobalControlLine;