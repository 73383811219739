import React, { Component } from 'react';
import { Table, GenericCell } from '@ecosystem/ui-lib/core/components/Table';
import './SubSurveysTableComponent.scss';
import { DateTime } from "luxon";
import Modal from 'app/components/Modal';
import SubSurveyForm from '../SubSurveysForm/SubSurveyFormComponent'
import { ReactComponent as EditPen } from 'app/assets/icons/edit-pen.svg';
import {SubSurveyContext} from '../../SubSurveyContext'
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

class SubSurveysTable extends Component {

    constructor(props){
        super(props);
        this.state = {            
            showSubSurveyFormModal: false,
			selectedSubSurvey: null
        }        
    }	

	addSubSurveyRow(subSurvey) {        
        const cols = [];
		var formatedStartDate = "";
		var formatedEndDate = "";
		if (subSurvey.startDate) {
			const startDate = DateTime.fromISO(subSurvey.startDate);
			formatedStartDate = startDate.toFormat("dd/MM/yyyy");
		}
		if (subSurvey.endDate) {
			const endDate = DateTime.fromISO(subSurvey.endDate);
			formatedEndDate = endDate.toFormat("dd/MM/yyyy");
		}
		
		cols.push(<GenericCell className="column-edit" primary={<EditPen className='edit-icon' onClick={(e) => {this.openFormModal(e, subSurvey, true)}}/>}/>);
        cols.push(<GenericCell className="column-name" primary={<div className="subSurvey-name">{subSurvey.name}</div>}/>);
        cols.push(<GenericCell className="column-nqtemplate-id" primary={subSurvey.nqTemplateId}/>);
		cols.push(<GenericCell alignment="left" className="column-version" primary={subSurvey.nqTemplateVersion}/>);        
		cols.push(<GenericCell className="column-start-date" primary={formatedStartDate}/>);
		cols.push(<GenericCell className="column-end-date" primary={formatedEndDate}/>);		
        return cols;
    }
	
	getSelectedSurveyPhaseOption(surveyPhaseId) {		
        return this.props.metadata.surveyPhases.find(t=> parseInt(t.value) === surveyPhaseId);
    }

	closeFormModal() {
		this.setState({
			showSubSurveyFormModal: false,
			selectedSubSurvey: null,
			originalSelectedSubSurvey: null,
			editExistingSubSurvey: null
		});
	}

	openFormModal(event, subSurvey, editExistingSubSurvey) {		
		let originalSubSurvey = cloneDeep(subSurvey);
		this.setState({
			showSubSurveyFormModal: true,
			editExistingSubSurvey: editExistingSubSurvey,
			selectedSubSurvey: cloneDeep(subSurvey),
			originalSelectedSubSurvey: originalSubSurvey
		});
	}

	saveSubSurvey() {		
		let action = this.state.editExistingSubSurvey === true ? "update" : "create"
		let result = this.context.saveSubSurvey(this.state.selectedSubSurvey, action);		
		result.then(success => {
			if (success) {
				this.closeFormModal();
			}
		});
	}

	disableSave() {
		if (!this.context.canChangeData) return true;		
		return isEqual(this.state.selectedSubSurvey, this.state.originalSelectedSubSurvey);		
	}

    render() {
		const rows = []; 
		if (this.props.subSurveys) {   
			this.props.subSurveys.forEach((subSurvey) => {
				rows.push(this.addSubSurveyRow(subSurvey));
			});
		}

		return (
            <div className="AppFormComponent SubSurveyTable">				
				<Table
					className="SubSurveyList"
					columns={[
						{
							headerKey: `column-edit`,
							className: "AppFormTableHeader column-edit",
							name: ``
						},
						{   headerKey: `column-name`,
							className: "AppFormTableHeader column-name",
							name: `Name`
						},
						{
							headerKey: `column-nqtemplate-id`,
							className: "AppFormTableHeader column-nqtemplate-id",
							name: `NQ Template Id`
						},
						{
							headerKey: `column-version`,
							className: "AppFormTableHeader column-version",
							name: `Nq Template Version`
						},
						{
							headerKey: `column-start-date`,
							className: "AppFormTableHeader column-start-date",
							name: `Start Date`
						},									
						{
							headerKey: `column-end-date`,
							className: "AppFormTableHeader column-end-date",
							name: `End Date`
						}
					]}
					rows= {rows}                
					sortingOptions={{headerKey: null}}
					isHeaderSticky={false}
					isFirstColumnSticky={false}
				/>
				{this.state.showSubSurveyFormModal && ( <Modal size="fit-content" content={
						<SubSurveyForm subSurvey={this.state.selectedSubSurvey} />
					}
					confirmButtonLabel="Save"
					disableConfirmButton={this.disableSave()}				
					confirmModalHandler={this.saveSubSurvey.bind(this)}				
					cancelButtonLabel="Cancel"
					cancelModalHandler={this.closeFormModal.bind(this)} 					
					/>)
				}							
			</div>
        );
    }
}
SubSurveysTable.contextType = SubSurveyContext;
export default SubSurveysTable;