import axios from 'axios';
import { history } from '../helpers/history'
import  GetErrorMessage from '../helpers/error-message'

function Api() {
  // axios instance for making requests
  const axiosInstance = axios.create();
  
  axiosInstance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error && error.response && error.response.status &&
        error.response.status === 401) {        
        localStorage.removeItem("user");        
        history.push('/login');
      }
      else if (error) {
        let errorMessage = GetErrorMessage(error)          
        console.log(`error: ${errorMessage}`)
        throw error;        
      }      
    }
  );

  return axiosInstance;
}

export default Api;