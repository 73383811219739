
import React, { Component } from 'react';
import { SideBar, SideBarHead, SideList, SideListFolder, SideListItemAdHocSurvey } from 'app/components/SideBar/';
import AdHocSurveyForm from './AdHocSurveyForm';
import NewAdHocSurvey from './models/AdHocSurveyModel';
import ConvertToAdHocSurveyMenuItem from './models/AdHocSurveyMenuItemModel';
import AdHocSurveyHome from './components/AdHocSurveyHome/AdHocSurveyHomeComponent';
import AdHocSurveyService from "../../services/adHocSurvey.service";
import AdHocSurveyFolderService from "../../services/adHocSurveyFolder.service";
import GetErrorMessage from '../../helpers/error-message'
import { ShowError, ShowSuccess } from "../../components/toast"
import { Loading } from '@ecosystem/ui-lib/core/components/Loading';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import Modal from '../../components/Modal';
import { adHocSurveyTypes } from 'app/constants/adhoc-survey-types'
import NewFolderCreator from './components/NewFolderCreator/NewFolderCreatorComponent';
import sortBy from 'array-sort-by';
import RemoveTimezone from "app/helpers/remove-timezone";
import './AdHocSurveyComponent.scss';

class AdHocSurvey extends Component {
	_adHocSurveyService = {};
	_adHocSurveyFolderService = {};
	constructor(props) {
		super(props);
		this.state = {
			search: '',
			allData: [],
			filteredData: [],
			adHocSurveyOverview: [],
			folders: [],
			loading: false,
			errorMessage: null,
			selectedAdHocSurvey: null,
			originalSelectedAdHocSurvey: null,
			uiBlocked: false,
			editorOpen: false,
			metadata: {
				weightingMethods: []
			},
			canChangeData: this.setCanChangeData(this.props.surveyType)
		}
		this._adHocSurveyService = new AdHocSurveyService(this.props.surveyType);
		this._adHocSurveyFolderService = new AdHocSurveyFolderService(this.props.surveyType);
	}

	setCanChangeData(surveyType) {
		if (surveyType == null) return false;
		var result = false;
		if (surveyType === adHocSurveyTypes.Generics) {
			result = this.props.userPermissions?.CanChangeGenerics
		} else if (surveyType === adHocSurveyTypes.FiveMinex) {
			result = this.props.userPermissions?.CanChangeFiveMinex
		}
		return result;
	}

	setSearchState(event) {
		var value = event.target.value;
		this.setState({ search: value });
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.search !== prevState.search) {
			this.filterAdHocSurveys(this.state.search);
		}

		if (this.state.editorOpen !== prevState.editorOpen) {
			if (this.state.editorOpen) {
				//retrigger selected question if editor was closed        
				this.setState({ selectedAdHocSurvey: this.state.selectedAdHocSurvey });
			}
		}
	}

	filterAdHocSurveys(term) {
		if (term !== '') {
			const filteredData = this.state.allData
				.filter(i => (i.name && i.name.toLowerCase().includes(term.toLowerCase())));
			let newFilteredData = [...filteredData];
			this.setState({ filteredData: newFilteredData });
		}
		else {
			let newFilteredData = [...this.state.allData];
			this.setState({ filteredData: newFilteredData });
		}
	}

	componentDidMount() {

		let date = new Date();
		let todayDate = RemoveTimezone(new Date(date.getFullYear(), date.getMonth(), date.getDate()));
		this.setState({todayDate: todayDate});
		this.queryAdHocSurveysOverview('', todayDate);		
		this.queryAdHocSurveyFolders('');
		this.queryAdHocSurveys('');
		this.queryMetadata();
	}

	openEditor() {
		this.setState({ editorOpen: true });
	}

	closeEditorButtonOnClick() {
		if (this.shouldLosePossibleChangesOnEditor(this.closeEditor)) {
			this.closeEditor();
		}
	}

	closeEditor() {
		this.setState({ editorOpen: false });
		this.setSelectedItem(null);
	}

	queryMetadata() {
		this._adHocSurveyService.getMetadata()
			.then(response => {
				this.setState({
					metadata: response.data
				});
				return response;
			})
			.catch(error => {
				let errorMessage = GetErrorMessage(error);
				console.log("errorMessage on catch");
				console.log(errorMessage);
				this.setState({ loading: false, errorMessage: errorMessage });
			})
	}

	queryAdHocSurveysOverview(term, date) {
		this.setState({ loading: true });
		const _this = this;
		_this._adHocSurveyService.find(this.props.surveyType, term, date, true)
			.then(response =>{				
				this.setState({ 
					adHocSurveyOverview: _this.orderOverview(response.data),
					loading: false				
				});
				return response;          
			})
			.catch(error => {			  
				let errorMessage = GetErrorMessage(error);
				console.log("errorMessage on catch");
				console.log(errorMessage);
				this.setState({ loading: false, errorMessage: errorMessage});            
			})
	}

	orderOverview(adhocSurveys) {		
		sortBy(adhocSurveys, item => [item.startDate]);		
		return adhocSurveys.reverse();		
	}

	queryAdHocSurveyFolders(term) {
		this.setState({ loading: true, filteredData: [] });
		this._adHocSurveyFolderService.find(term, this.props.surveyType)
			.then(response => {
				this.setState({
					folders: response.data,
					foldersOriginal: cloneDeep(response.data),
					defaultFolder: response.data.find(f => f.name === 'default'),
					loading: false
				});
				return response;
			})
			.catch(error => {
				let errorMessage = GetErrorMessage(error);
				console.log("errorMessage on catch");
				console.log(errorMessage);
				this.setState({ loading: false, errorMessage: errorMessage });
			})
	}

	queryAdHocSurveys(term) {
		this.setState({ loading: true, filteredData: [] });
		this._adHocSurveyService.find(this.props.surveyType, term)
			.then(response =>{				
				this.setState({ filteredData: response.data,
					allData:  response.data,					
					loading: false				
				}, function() { this.filterAdHocSurveys(this.state.search); });
				return response;          
			})
			.catch(error => {
				let errorMessage = GetErrorMessage(error);
				console.log("errorMessage on catch");
				console.log(errorMessage);
				this.setState({ loading: false, errorMessage: errorMessage });
			})
	}

	getAdHocSurveyById(id) {
		this.toggleUiBlock(true)
		this._adHocSurveyService.getAdHocSurveyById(id)
			.then(response => {				
				this.updateSelectedAdHocSurvey(response.data);
				this.updateSavedAdHocSurveyOnList(response.data);
				this.setState(
					{ originalSelectedAdHocSurvey: cloneDeep(response.data) }
				);
				this.toggleUiBlock(false);
				return response;
			})
			.catch(error => {
				let errorMessage = GetErrorMessage(error);
				console.log("errorMessage on catch");
				console.log(errorMessage);
				this.setState({ errorMessage: errorMessage });
				this.toggleUiBlock(false)
			})
	}

	createFolder(folder) {
		this.toggleUiBlock(true)
		this._adHocSurveyFolderService.createFolder(folder)
			.then(response => {
				const newFolder = response.data;
				var folders = this.state.folders;
				folders.push(newFolder)
				this.setState({ folders: folders })
				this.toggleUiBlock(false)
				this.setState({ successMessageFolder: `The folder ${folder.name} was created successfully` });

			})
			.catch(error => {
				let errorMessage = GetErrorMessage(error);
				console.log("errorMessage on catch");
				console.log(errorMessage);
				this.setState({ errorMessage: errorMessage });
				this.toggleUiBlock(false)
			});
	}

	updateFolder(folder) {
		this.toggleUiBlock(true)
		this._adHocSurveyFolderService.updateFolder(folder)
			.then(response => {
				this.toggleUiBlock(false);
				this.setState({ successMessageFolder: `The folder ${folder.name} was saved successfully` });
			})
			.catch(error => {
				this.rollbackFolderNameChange(folder);
				let errorMessage = GetErrorMessage(error);
				console.log("errorMessage on catch");
				console.log(errorMessage);
				this.setState({ errorMessage: errorMessage });
				this.toggleUiBlock(false)
			});
	}

	rollbackFolderNameChange(folder) {
		var originalFolder = cloneDeep(this.state.foldersOriginal.find(f => f.id === folder.id));
		var folders = this.state.folders;
		var index = this.state.folders.findIndex(({ id }) => id === folder.id);
		folders[index].name = originalFolder.name;
	}

	deleteFolder(folder) {
		if (this.folderContainQuestionSets(folder)) {
			this.setState({ errorMessage: `The folder '${folder.name}' cannot be deleted because it contains question sets` })
			return;
		}
		this.setState({
			deleteFolderModalText: `Are you sure you want to delete the folder '${folder.name}'?`,
			folderToDelete: folder
		});
	}

	folderContainQuestionSets(folder) {
		var result = this.state.allData.find(i => i.folderId === folder.id);
		return result !== undefined;
	}

	confirmDeleteFolder() {
		this.hideDeleteFolderModal();
		this.toggleUiBlock(true);
		let _this = this;
		this._adHocSurveyFolderService.deleteFolder(this.state.folderToDelete)
			.then(response => {
				this.toggleUiBlock(false);
				this.setState({ successMessageFolder: 'The folder was deleted successfully' });
				let folders = this.state.folders;
				folders = folders.filter(function (folder) {
					return folder.id !== _this.state.folderToDelete.id;
				});
				this.setState({ folders: folders });
			})
			.catch(error => {
				let errorMessage = GetErrorMessage(error);
				console.log("errorMessage on catch");
				console.log(errorMessage);
				this.toggleUiBlock(false);
				this.setState({ errorMessage: errorMessage });
			})
			.finally(function () {
				_this.setState({ folderToDelete: null })
			});
	}

	hideDeleteFolderModal() {
		this.setState({ deleteFolderModalText: '' })
	}

	getSelectedItem(adHocSurveyid) {
		this.setState({ clickedAdHocSurveyid: adHocSurveyid });
		this.openEditor();
		if (this.shouldLosePossibleChangesOnEditor(() => this.getAdHocSurveyById(adHocSurveyid))) {
			this.getAdHocSurveyById(adHocSurveyid)
		}
	}

	setSelectedItem(selectedAdHocSurvey) {
		this.updateSelectedAdHocSurvey(selectedAdHocSurvey);
	}

	shouldLosePossibleChangesOnEditor(callbackAfterLoseChangesConfirmation) {
		if (this.didSelectedAdHocSurveyChange()) {
			this.showLoseChangeConfirmationModal(callbackAfterLoseChangesConfirmation);
			return false;
		}
		else {
			return true;
		}
	}

	didSelectedAdHocSurveyChange() {
		let selectedAdHocSurvey = this.state.selectedAdHocSurvey;
		if (!selectedAdHocSurvey) return false;
		let questionsAreEquals = isEqual(selectedAdHocSurvey, this.state.originalSelectedAdHocSurvey);
		return !questionsAreEquals;
	}

	showLoseChangeConfirmationModal(callbackAfterLoseChangesConfirmation) {
		this.setState({
			loseChangesModalText: `There are unsaved changes to the survey '${this.state.selectedAdHocSurvey.name}'. Are you sure do you want to leave without saving?`,
			callbackAfterLoseChangesConfirmation: callbackAfterLoseChangesConfirmation
		});
	}

	confirmLoseChangesModalHandler() {
		let filteredData = this.rollbackToOriginalAdHocSurvey(this.state.filteredData, this.state.originalSelectedAdHocSurvey);
		let allData = this.rollbackToOriginalAdHocSurvey(this.state.allData, this.state.originalSelectedAdHocSurvey);

		this.setState({
			allData: allData, filteredData: filteredData
		}, this.state.callbackAfterLoseChangesConfirmation);
		this.setState({ clickedAdHocSurveyid: null });
		this.hideLoseChangesModal();
	}

	rollbackToOriginalAdHocSurvey(list, originalSelectedItem) {
		const index = list.findIndex(item => item.id === this.state.selectedAdHocSurvey.id);
		list[index] = originalSelectedItem;
		return list;
	}

	hideLoseChangesModal() {
		this.setState({ loseChangesModalText: '' })
	}

	isItemSelected(adHocSurveyId) {
		if (this.state && this.state.selectedAdHocSurvey &&
			this.state.selectedAdHocSurvey.id === adHocSurveyId) {
			return true;
		} else {
			return false;
		}
	}

	toggleUiBlock(blockUi) {
		this.setState({ uiBlocked: blockUi });
	}

	addNewAdHocSurveyHandler = event => {
		if (this.shouldLosePossibleChangesOnEditor(() => this.addNewAdHocSurvey())) {
			this.addNewAdHocSurvey()
		}
		event.preventDefault();
	}

	addNewAdHocSurvey() {
		const defaultweightingMethod = this.state.metadata.weightingMethods && this.state.metadata.weightingMethods.length > 0 ?
			parseInt(this.state.metadata.weightingMethods[0].value) : 0;
		const newAdHocSurvey = NewAdHocSurvey(defaultweightingMethod);
		newAdHocSurvey.adHocSurveyTypeId = this.props.surveyType;
		this.setSelectedItem(null);
		this.setSelectedItem(newAdHocSurvey);
		this.openEditor();
	}

	removeDeletedAdHocSurvey(adHocSurveyId) {
		const newFilteredData = this.state.filteredData.filter(i => i.id !== adHocSurveyId);
		const newAllData = this.state.allData.filter(i => i.id !== adHocSurveyId);
		this.setState({
			filteredData: newFilteredData,
			allData: newAllData
		});
	}

	updateSavedAdHocSurveyOnList(selectedAdHocSurvey) {
		let newFilteredData = [...this.updateAdHocSurveyList(this.state.filteredData, selectedAdHocSurvey)];
		let newAllData = [...this.updateAdHocSurveyList(this.state.allData, selectedAdHocSurvey)];
		this.setState({ filteredData: [] });
		this.setState({
			filteredData: newFilteredData,
			allData: newAllData
		});
	}

	updateAdHocSurveyList(list, selectedAdHocSurvey) {
		let adHocSurveyMenuItem = ConvertToAdHocSurveyMenuItem(selectedAdHocSurvey);
		var index = list.findIndex(q => q.id === selectedAdHocSurvey.id);
		if (index === -1) {
			list.push(adHocSurveyMenuItem);
		}
		else {
			list[index] = adHocSurveyMenuItem;
		}
		return list;
	}

	saveNewFolder(newFolderText) {
		var folder = {
			id: null,
			name: newFolderText,
			sortOrder: 0,
			surveyType: this.props.surveyType
		}
		this.createFolder(folder);
	}

	getListItems(adHocSurveyFolderId) {
		return this.state.filteredData.filter(i => i.folderId === adHocSurveyFolderId).map(adHocSurvey =>
			<div key={adHocSurvey.id}>
				<SideListItemAdHocSurvey key={adHocSurvey.id}
					adHocSurveyId={adHocSurvey.id}
					questions={adHocSurvey.questionsCount}
					selector=""
					start={adHocSurvey.startDate}
					end={adHocSurvey.endDate}
					state="Ended"
					getSelectedItem={this.getSelectedItem.bind(this)}
					isItemSelected={this.isItemSelected(adHocSurvey.id)}
				>
					{adHocSurvey.name}
				</SideListItemAdHocSurvey>
			</div>
		)
	}

	getFolder(adHocSurveyFolder, folderItems) {
		//dont show folder if user's search resulted in a empty folder
		if (folderItems.length === 0 & this.state.search !== '') {
			return null
		}
		return (<SideListFolder canChangeData={this.state.canChangeData} folder={adHocSurveyFolder} updateFolder={this.updateFolder.bind(this)} deleteFolder={this.deleteFolder.bind(this)}>
			{folderItems}
		</SideListFolder>)
	}

	updateSelectedAdHocSurvey(selectedAdHocSurvey) {
		this.setState({ selectedAdHocSurvey: selectedAdHocSurvey })
	}


	render() {
		return (
			<BlockUi tag="div" blocking={this.state.uiBlocked}>
				<main className="app-body">
					<SideBar>
						<SideBarHead canChangeData={this.state.canChangeData} tagName="SideBarHead" headline={this.props.surveyType === adHocSurveyTypes.Generics ? 'Generics' : '5minEx'} addButton addButtonLabel="Set" addButtonCallBack={this.addNewAdHocSurveyHandler.bind(this)}
							toolbar={(
								<div>
									{/* <IntroTextIcon className="icon orange clickable" title="Intro Texts"/>
							<SettingsIcon className="icon orange clickable" title="Settings"/> */}
								</div>
							)} onChange={this.setSearchState.bind(this)}
						/>
						<SideList tagName="SideList">
							<Loading active={this.state.loading}></Loading>
							{<div key="noFolderItems">
								{this.getListItems(null)}
							</div>}
							{this.state.folders.map(adHocSurveyFolder =>
								<div key={adHocSurveyFolder.id}>
									{<div key={adHocSurveyFolder.id}>
										{this.getFolder(adHocSurveyFolder, this.getListItems(adHocSurveyFolder.id))}
									</div>}
								</div>
							)}

							<NewFolderCreator canChangeData={this.state.canChangeData} saveNewFolderHandler={this.saveNewFolder.bind(this)} />
						</SideList>
					</SideBar>

					<div className="app-content scrollbox">
						{this.state.editorOpen && (<AdHocSurveyForm openEditor={this.openEditor.bind(this)}
							didSelectedAdHocSurveyChange={this.didSelectedAdHocSurveyChange.bind(this)}
							closeEditorButtonOnClick={this.closeEditorButtonOnClick.bind(this)}
							closeEditor={this.closeEditor.bind(this)}
							removeDeletedAdHocSurvey={this.removeDeletedAdHocSurvey.bind(this)}
							toggleUiBlock={this.toggleUiBlock.bind(this)}
							selectedAdHocSurvey={this.state.selectedAdHocSurvey}
							updateSelectedAdHocSurvey={this.updateSelectedAdHocSurvey.bind(this)}
							updateSavedAdHocSurveyOnList={this.updateSavedAdHocSurveyOnList.bind(this)}
							getAdHocSurveyById={this.getAdHocSurveyById.bind(this)}
							folders={this.state.folders}
							surveyType={this.props.surveyType}
							metadata={this.state.metadata}
							canChangeData={this.state.canChangeData}
							/> )}
					{!this.state.editorOpen && this.state.todayDate &&( <AdHocSurveyHome surveyType={this.props.surveyType} todayDate={this.state.todayDate} overview={this.state.adHocSurveyOverview} filterOverview={this.queryAdHocSurveysOverview.bind(this)} openSelectedItem={this.getSelectedItem.bind(this)}/>)}
				</div>
				
				{this.state.deleteFolderModalText && ( <Modal size="small" content={this.state.deleteFolderModalText} confirmModalHandler={this.confirmDeleteFolder.bind(this)} cancelModalHandler={this.hideDeleteFolderModal.bind(this)} />)}
				{this.state.successMessageFolder && ( <ShowSuccess clearMessage={() => this.setState({successMessageFolder: ''})} message={this.state.successMessageFolder} /> )}
				{this.state.errorMessage && ( <ShowError clearErrorMessage={() => this.setState({errorMessage: ''})} errorMessage={this.state.errorMessage} /> )}
				{this.state.loseChangesModalText && ( <Modal size="small" content={this.state.loseChangesModalText} confirmModalHandler={this.confirmLoseChangesModalHandler.bind(this)} cancelModalHandler={this.hideLoseChangesModal.bind(this)} />)}
			</main>
			</BlockUi>
		);
	}
}

export default AdHocSurvey;