import React, { Component } from "react";
import {Label, DatePickerCalendar, Button, CSVDownloader} from 'app/components/Basics/';
import { Card} from '@ecosystem/ui-lib/core/components/Card';
import { Select } from '@ecosystem/ui-lib/core/components/Select';
import InDepthService from "../../../../services/indepth.service";
import GetErrorMessage from '../../../../helpers/error-message';
import ExtractTemplateIdFromLogs from '../../../../helpers/extract-template-id';
import Modal from '../../../../components/Modal';
import { CSVLink } from 'react-csv'
import NewInDepthTestLinks from '../../InDepthTestLinksModel';
import InDepthTestSurveyDemoSet from '../InDepthTestSurveyDemoSet/index'
import { ReactComponent as PlusIcon } from 'app/assets/icons/Plus.svg';
import { Icons } from '@ecosystem/ui-lib/core/components/Icon';
import './InDepthTestSurveyComponent.scss';

class InDepthTestSurvey extends Component {
	constructor (props) {
	super(props);
		this.state = {
			surveyDate: null,
			programsDate: null,
			surveyType: null,
			usersToCreate: 10,
			metadata: null,
			demoSets: [],
			selectedDemos: [],
			uniqueDemoSetId:  0
		}		
	}

	componentDidMount() {		
		this.queryMetadata();
		
	}
	
	queryMetadata() {		
		InDepthService.getTestLinksMetadata()
				.then(response =>{					
					this.setState({
						metadata: response.data					
					});					
					return response;          
				})
				.catch(error => {			  
					let errorMessage = GetErrorMessage(error);
					console.log("errorMessage on catch");
					console.log(errorMessage);
					this.setState({ loading: false, errorMessage: errorMessage});            
			})
	}

	setSurveyDate(date) {		
		this.setState({surveyDate: date});
		this.setProgramsDate(date);
	}

	setProgramsDate(date) {		
		this.setState({programsDate: date});
	}

	setSurveyType(selectedItem) {
		this.setState({surveyType: parseInt(selectedItem.value)});
    }

	getSelectedSurveyTypeOption() {
        return this.props.surveyTypes
			.find(t=> parseInt(t.value) === this.state.surveyType);
    }

	isTestValid() {
		return this.state.surveyDate && this.state.programsDate && this.state.surveyType;
	}

	createTestLinks() {		
		this.props.toggleUiBlock(true);		
		var surveyDate = this.state.surveyDate;
		var programsDate = this.state.programsDate;
		var indepthTestLinksParams = new NewInDepthTestLinks();		
		indepthTestLinksParams.surveyDate = surveyDate;
		indepthTestLinksParams.programsDate = programsDate;
		indepthTestLinksParams.surveyType = this.state.surveyType;
		indepthTestLinksParams.usersToCreate = parseInt(this.state.usersToCreate);
		indepthTestLinksParams.demoSets = this.state.demoSets;
        InDepthService.createInDepthTestLinks(indepthTestLinksParams).then(response =>{                    
			this.props.toggleUiBlock(false);
			this.createModalContent(response, surveyDate);
		})
		.catch(error => {
			let errorMessage = GetErrorMessage(error);
			console.log("errorMessage on catch");
			console.log(errorMessage);
			this.props.toggleUiBlock(false);
			this.setState({ errorMessage: errorMessage});
		});	
    }

	createModalContent(response, surveyDate) {
		let modalContent = [];
		{Object.entries(response.data.testLinks).map(([key, value]) => (
			modalContent.push(this.getDemoSetLinksHtml(key, value))			
		))}		
		
		// Join the lists of Urls with the dictionary key (demo set name) included
		let flatUrlList = Object.entries(response.data.testLinks)
			.reduce((accumulator, [key, value]) => {
				const joinedValues = [key, ...value];
				return accumulator.concat(joinedValues);
		}, []);

		//remove demoset = 0 (no demosets)
		flatUrlList = flatUrlList.filter(e => e !== "0");		

		let linksToClipBoard = flatUrlList.map(url => `${url} \n`).join('');
		let logsToDownload = response.data.logs.map(log => `${log} \n`).join('')
		let surveyType = this.getSelectedSurveyTypeOption();
		var templateId = ExtractTemplateIdFromLogs(response.data.logs);
		modalContent.unshift((<div><strong>Test links using TemplateId <span className='orange'>{templateId}</span> for survey on <span className='orange'>{new Date(this.state.surveyDate).toLocaleDateString('en-GB') }</span>:</strong></div>));
		modalContent.push(<div style={{ marginTop: '20px' }}></div>);
		modalContent.push((<CSVLink filename={`testlinks_indepth_surveyType_${surveyType.label}_surveyDate_${surveyDate}.csv`} data={linksToClipBoard}>Download Links</CSVLink>));
		modalContent.push(<div></div>);
		modalContent.push((<CSVLink filename={`logs_for_testlinks_indepth_surveyType_${surveyType.label}_surveyDate_${surveyDate}.csv`} data={logsToDownload}>Download Logs</CSVLink>));
		
		this.setState({ 
			testLinksModalContent: modalContent,
			linksToClipBoard: linksToClipBoard
		});
	}	

	getDemoSetLinksHtml(key, links) {
		return (
			<div key={key}>
				<div style={{ marginTop: '20px' }}></div>
				{key !== "0" && (<div><Label>{key}</Label></div>)}
				<div>
					{links.map((url,index) => (<div key={index}>Respondent {index+1} - <a rel="noreferrer" href={url} target="_blank">{url}</a></div>))}
				</div>				
			</div>
		)
	}

	hideTestLinksModal() {
		this.setState({ 
			testLinksModalContent: '',
			linksToClipBoard: ''
		});		
	}

	copyLinksToClipboard() {
		navigator.clipboard.writeText(this.state.linksToClipBoard);
	}

	mapDemosToSelectBox(demos) {
		return demos.map(function (obj) {			
			return {value: obj.demoCode, label: obj.demoText}
		});
	}

	onChangeDemo(demoSetName, items)  {		
		var demoSets = this.state.demoSets;
		var demoSet = demoSets.find(x=>x.demoSetName === demoSetName);		
		demoSet.selectedDemos = [];
		demoSet.selectedDemos = items.map(x => x);
		this.setState({demoSets: demoSets});		
	}

	onRemoveDemo(item)  {		
		var selectedDemos = this.state.selectedDemos;		
		selectedDemos = selectedDemos.filter(function(f) { return f !== item.value });
		this.setState({selectedDemos: selectedDemos});		
	}

	createDemoSet(index) {
		return {
			demoSetName: "DemoSet" + index,
			demoValues: [],
			selectedDemos: []
		}
	}

	addDemoSet() {
		var demoSets = this.state.demoSets;
		var uniqueDemoSetId = this.state.uniqueDemoSetId;
		uniqueDemoSetId++
		this.setState({uniqueDemoSetId: uniqueDemoSetId});
		demoSets.push(this.createDemoSet(uniqueDemoSetId));
		this.setState({demoSets: demoSets });
	}

	onChangeDemoSelectedValue(demoSetName, demoCode, selectedDemoValue) {		
		var demoSets = this.state.demoSets;
		var demoSet = demoSets.find(x=>x.demoSetName === demoSetName);	
		const existingIndex = demoSet.demoValues.findIndex((item) => item.demoCode === demoCode);		
		if (existingIndex !== -1) {		  
			demoSet.demoValues[existingIndex].demoValue = selectedDemoValue;
		} else {		  
			demoSet.demoValues.push({ demoCode: demoCode, demoValue: selectedDemoValue });
		}
		this.setState({demoSets: demoSets });
	}

	removeDemoSet(index) {
		var demoSets = this.state.demoSets;
		demoSets.splice(index, 1)
		this.setState({demoSets: demoSets });
	}

	removeDemoFromSet(demoSetName, index) {		
		var demoSets = this.state.demoSets;
		var demoSet = demoSets.find(x=>x.demoSetName === demoSetName);		
		demoSet.selectedDemos.splice(index, 1);		
		this.setState({demoSets: demoSets });
	}

	getUsersToCreateOptions() {
		var numbers = ["5", "10", "20"];
		return numbers.map(function (num) {			
			return {value: num, label: num}
		});
	}

	getDefaultUsersToCreate() {
		var options = this.getUsersToCreateOptions();
		if (options) {
			return options[1];
		}
	}

	setUsersToCreate(usersToCreate) {		
		this.setState({usersToCreate: usersToCreate.value});
    }

    render() {
        return (
			<div className="AppFormComponent TestSurveyComponent">
				<div className="form-row createSurveyHeader">
					Create Daily Survey Test Links
				</div>
				<div className="form-row">
					<div className="form-row-left">
						<div className="field-survey-from">
							<Label>Survey Date</Label>
							<DatePickerCalendar onChange={this.setSurveyDate.bind(this)} value={this.state.surveyDate} />
						</div>
						<div className="field-survey-from">
							<Label>Programs Date</Label>
							<DatePickerCalendar onChange={this.setProgramsDate.bind(this)} value={this.state.programsDate} />
						</div>					
						<div className="field-surveytype">
							<Label>Survey Type</Label>
							<Select name="qsetsurveytype" isSearchable={false}
								styles={{ option: styles => ({ minHeight: 40, ...styles }) }}								
								onChange={val=>{
									this.setSurveyType(val)                                
								}}
								options={this.props.surveyTypes} 
								value={this.getSelectedSurveyTypeOption()}>
							</Select>
						</div>
						<div className="field-surveytype">
							<Label>Number of Links</Label>
							<Select name="qnumbersoflink" isSearchable={false}
								styles={{ option: styles => ({ minHeight: 40, ...styles }) }}								
								onChange={val=>{
									this.setUsersToCreate(val)                                
								}}
								options={this.getUsersToCreateOptions()} 
								defaultValue={this.getDefaultUsersToCreate()}
								>
							</Select>
						</div>
						<div className="field-survey-test-button">							
							<Button disabled={!this.isTestValid()} title="Create test links to test the survey on netquest" onClick={this.createTestLinks.bind(this)}>Create</Button>
						</div>                 
					</div>
					<div className="form-row-right">
					</div>
				</div>
				<div className="form-row">
						<div className="form-row-left">
							
						</div>
						<div className="form-row-right">
							<Button secondary icon={<PlusIcon/>} onClick={() => this.addDemoSet()}>Demographics Set</Button>
						</div>
                </div>
				<div className="form-row demo-sets-wrapper">
						<div className="form-row-left align-top">
						{this.state.demoSets && this.state.demoSets.map((demoSet, index) => (
								<div key={demoSet.demoSetName}>
									<Button className="float-right" disabled={this.props.isDisabled} title="remove" onClick={()=> this.removeDemoSet(index)} simple small icon={Icons.Close} />
									<Label>{demoSet.demoSetName} Variables</Label>								
									{this.state.metadata && this.state.metadata.demographics && (<InDepthTestSurveyDemoSet key={demoSet.demoSetName} id={demoSet.demoSetName}
										selectedOptions={demoSet.selectedDemos} 
										onChange={this.onChangeDemo.bind(this, demoSet.demoSetName)} 
										onRemove={this.onRemoveDemo.bind(this)} 
										options={this.mapDemosToSelectBox(this.state.metadata.demographics)}
										metadata={this.state.metadata.demographics}
										onChangeDemoSelectedValue={this.onChangeDemoSelectedValue.bind(this)}
										removeDemoFromSet={this.removeDemoFromSet.bind(this)}										
										demoSet={demoSet}
										>									
									</InDepthTestSurveyDemoSet>)}
							</div>		
						))}
							
						</div>
						<div className="form-row-right">
							
						</div>
                </div>
				{this.state.testLinksModalContent && ( <Modal hideConfirmButton content={this.state.testLinksModalContent} cancelButtonLabel="Close" confirmButtonLabel="Copy Test Links to Clipboard" cancelModalHandler={this.hideTestLinksModal.bind(this)} confirmModalHandler={this.copyLinksToClipboard.bind(this)}/>)}
			</div>
        )
    }
}

export default InDepthTestSurvey;