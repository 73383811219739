import React, { Component } from 'react';
import 'app/components/SideBar/SideBarComponent.scss';
import './ConditionLogicsComponent.scss';
import { Table, GenericCell } from '@ecosystem/ui-lib/core/components/Table';
import { ButtonIcon } from '@ecosystem/ui-lib/core/components/ButtonIcon';
import { Checkbox } from '@ecosystem/ui-lib/core/components/Checkbox';
import { Icons } from '@ecosystem/ui-lib/core/components/Icon';
import {AdHocSurveyFormContext} from '../../AdHocSurveyContext'
import { Label, Button, ButtonGroup } from 'app/components/Basics';
import { ReactComponent as PlusIcon } from 'app/assets/icons/Plus.svg';
import MultiSelect from 'app/components/MultiSelect'
import Modal from 'app/components/Modal';
import { QuestionSearch } from "app/components/QuestionSearch";
import NewAdHocCondition from "../../models/AdHocConditionModel"

class ConditionLogics extends Component {
    constructor(props){
        super(props);

        this.state = {
			showQuestionModal: false,
        }
    }

	getQuestionInfo(question) {
		return (<div>
			<div><h4>{question.textPlain}</h4></div>
			<div>{question.code}</div>
		</div>);
	}

	getValidAnswers(condition) {		
		return (<MultiSelect key={this.props.selectedAnswerOptionValue}
			isDisabled={!this.props.canChangeData}
			selectedOptions={this.mapOptionsToSelectBox(condition.validAnswerValues)} 
			onChange={this.onChangeValidAnswer.bind(this, condition)} 
			onRemove={this.onRemoveValidAnswer.bind(this, condition)} 
			options={this.mapOptionsToSelectBox(condition.questionToCheck.options)}>									
		</MultiSelect>);
	}	

	onChangeValidAnswer(condition, items)  {		
		var options = this.mapSelectBoxToOptions(items);
		this.props.validAnswersHandler(condition, options);
	}

	onRemoveValidAnswer(condition, item)  {		
		var values = condition.validAnswerValues.filter(a=> { return a.value !== item.value });
		this.props.validAnswersHandler(condition, values);
	}
	
	
	mapOptionsToSelectBox(options) {
		return options.map(function (obj) {			
			return {value: obj.value, label: obj.text}
		});
	}

	mapSelectBoxToOptions(selectBoxItems) {
		return selectBoxItems.map(function (obj) {			
			return {value: obj.value, text: obj.label}
		});
	}	

	removeQuestionConditionHandler(index) {
		this.props.removeConditionHandler(index);
	}	

	negateCheckHandler(index, event) {		
		this.props.conditionNegateHandler(index, event.target.checked);	
	}
	
    addQuestionRow(condition, index) {        
        const cols = [];
        cols.push(<GenericCell className="column-question" primary={this.getQuestionInfo(condition.questionToCheck)}/>);
        cols.push(<GenericCell className="column-valid-answer" primary={this.getValidAnswers(condition)}/>);
		cols.push(<GenericCell className="column-negate" primary={<Checkbox disabled={!this.props.canChangeData} label="" checked={condition.negate === 1} onChange={this.negateCheckHandler.bind(this, index)} />}/>);
        cols.push(<GenericCell className="column-remove" primary={<div><ButtonIcon isDisabled={!this.props.canChangeData} onClick={() => this.removeQuestionConditionHandler(index)}  icon={Icons.Close}/></div>}/>);        
        return cols;
    }

	addQuestionHandler(question) {		
		let condition = NewAdHocCondition();
		condition.questionToCheck = question;
		this.props.addConditionHandler(condition);
		this.closeQuestionModal();
	}

	removeQuestionHandler() {

	}

	openAddQuestionModal() {
		this.setState({showQuestionModal: true});
	}

	closeQuestionModal() {		
		this.setState({
			showQuestionModal: false,
			selectedQuestionToAdd: null
		});
	}	

	onClickLogicOperator(conditionOperator) {
		this.props.logicOperatorHandler(conditionOperator);
    }

	getPossibleQuestions() {		
		let possibleQuestions = this.context.selectedAdHocSurvey.questions.filter(q=>q.question.id !== this.props.question.id);
		possibleQuestions = possibleQuestions.filter(q=>q.question.questionType !== "GGROUP");
		return possibleQuestions;
	}

    render() {
		const rows = [];
		var count = 0;
        this.props.conditions.forEach((condition) => {
            rows.push(this.addQuestionRow(condition, count));
			count++;
        });

		const selectedQuestions =  this.props.getSelectedQuestions();
        return (
            <div className="ConditionLogicsComponent content-box">
				<Label>Logic</Label>
				<div className="form-row">				
					<div className="form-row-left">
						<ButtonGroup>
							<Button disabled={!this.props.canChangeData} selected={this.props.conditionOperator === 0} onClick={this.onClickLogicOperator.bind(this, 0)} key="matchall" defaultActive >Match All</Button>                                    
							<Button disabled={!this.props.canChangeData} selected={this.props.conditionOperator === 1} onClick={this.onClickLogicOperator.bind(this, 1)} key="matchany" >Match Any</Button>
						</ButtonGroup>						
					</div>
					<div className="form-row-right">
						<Button disabled={!this.props.canChangeData} secondary icon={<PlusIcon/>} onClick={() => this.openAddQuestionModal()}>Condition Statement</Button>
					</div>
				</div>
					<Table
						className="ConditionQuestionList"
						columns={[
							{   headerKey: `column-question-condition`,
								className: "column-question-condition",
								name: `Questions`
							},
							{
								headerKey: `column-valid-answer-condition`,
								className: "column-valid-answer-condition",
								name: `Valid Answers`
							},
							{
								headerKey: `column-negate-condition`,
								className: "column-negate-condition",
								name: `Negate`
							},
							{
								headerKey: `column-remove-condition`,
								className: "column-remove-condition",
								name: `Remove`
							}
						]}
						rows= {rows}                
						sortingOptions={{headerKey: null}}
						isHeaderSticky={false}
						isFirstColumnSticky={false}
					/>
					<div style={{display: rows && rows.length > 0 ? 'none' : 'block' }}>
						<p className='EmptyQuestionListInfoTitle'>Question list is empty</p>
						<p className='EmptyQuestionListInfoText'>Please, add questions via <span className='span-box'>+Question</span></p>
					</div>
					
					{this.state.showQuestionModal && (
						<Modal content={
							<QuestionSearch selectedQuestions={selectedQuestions}
								onlyAdHocParentQuestions={true}
								possibleParentQuestions={this.getPossibleQuestions()}
								addQuestionHandler={this.addQuestionHandler.bind(this)} 
								removeQuestionHandler={this.removeQuestionHandler.bind(this)}
								title={["Select questions to be used as condition statements for question ", <span className='orange'>{this.props.question.code}</span>]}							
							/>
							} 
							disableConfirmButton={this.state.selectedQuestionToAdd == null}
							hideConfirmButton
							cancelButtonLabel="Close"
							cancelModalHandler={this.closeQuestionModal.bind(this)} 					
						/>						
					)}

			
            </div>
        );
    }
}

ConditionLogics.contextType = AdHocSurveyFormContext;
export default ConditionLogics;