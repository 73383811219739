import React, { Component } from 'react';
import {Label, Spacer, Button, InputInline} from 'app/components/Basics/'
import { Icons } from '@ecosystem/ui-lib/core/components/Icon';
import './GlobalControlLineComponent.scss';
import { ReactComponent as SaveIcon } from 'app/assets/icons/Save.svg';
import { ReactComponent as DuplicateIcon } from 'app/assets/icons/Duplicate-Plus.svg';
import { ReactComponent as ArchiveIcon } from 'app/assets/icons/Archive.svg';
import {MediaTypeFormContext} from '../../MediaTypeContext'
import ValidateMediaType from '../../MediaTypeValidation'
import { Input as EcoInput } from '@ecosystem/ui-lib/core/components/Input';


 
class GlobalControlLine extends Component {

    constructor(props){
        super(props);

        this.state = {           
        }        
    }

    componentWillReceiveProps(nextProps) {        
        if (nextProps && nextProps.errors) {            
            var error = nextProps.errors.find(e => e.field === "name")
            if (error) {
                this.setState({errorMediaTypeName: error.errorMessage})
            }
        }
    }

	isSaveDisabled() {
		if (this.isChangeDisabled())
		{
			return true;
		}
		let result = false;
		var errors = ValidateMediaType(this.context.selectedMediaType);		
		if (errors.length > 0) {
			result = true;
		}		
		if (!this.props.didSelectedMediaTypeChange()) {
			result = true;
		}
		return result;
	}

	isButtonDisabled() {	
		if (this.isChangeDisabled())
		{
			return true;
		}
		return this.context.selectedMediaType.id === null;
	}

	getMediaTypeName() {		
        return this.context.selectedMediaType.name !== '' ? 
            this.context.selectedMediaType.name : "New Media Type Name";
    }

    getMediaTypeCode() {		
        return this.context.selectedMediaType.id != null && this.context.selectedMediaType.id !== '' ? 
            this.context.selectedMediaType.id : "New Media Type Code";
    }

	mediaTypeNameChange(isEditorOpen, newName) {
        if (!isEditorOpen) {
            var selectedMediaType = this.context.selectedMediaType;
            selectedMediaType.name = newName;
            this.context.updateSelectedMediaType(selectedMediaType);
        }
    }

    mediaTypeCodeChange(isEditorOpen, newCode) {
        if (!isEditorOpen) {
            var selectedMediaType = this.context.selectedMediaType;
            selectedMediaType.id = newCode;
            this.context.updateSelectedMediaType(selectedMediaType);
        }
    }

    mediaTypeCodeHandler = event => {		
        var selectedMediaType = this.context.selectedMediaType;
		selectedMediaType.id = event.target.value ? parseInt(event.target.value) : undefined;
		this.context.updateSelectedMediaType(selectedMediaType);
    }

	isChangeDisabled() {
		return !this.context.canChangeData || this.context.selectedMediaType.isSysAdmin === 1;
	}

    render() {
        return (
            <div className="GlobalControlLineComponent form-row">
                <div/>
				{/* <InputInline disabled={this.isChangeDisabled()} id="mediaType-code-field" enableOnBlur className="name-field" value={this.getMediaTypeCode()} onModeChange={this.mediaTypeCodeChange.bind(this)} />
                <InputInline disabled={this.isChangeDisabled()} id="mediaType-name-field" enableOnBlur className="name-field" value={this.getMediaTypeName()} onModeChange={this.mediaTypeNameChange.bind(this)} /> */}                       
                <div className="contol-buttons">                    
                    <Button disabled={this.isSaveDisabled()} title="Save all changes in the Media Type" icon={<SaveIcon/>} onClick={this.props.handleSubmitAction}>Save</Button>                    
                    <Button title="Close current editor and discard all unsaved changes" onClick={this.props.closeEditorButtonOnClick} simple icon={Icons.Close}>Close</Button>
                    <Spacer width={20}/>                   
                    <Button disabled={this.isButtonDisabled()} title="Delete the Media Type is irreversible" small secondary icon={Icons.Delete} onClick={this.props.deleteMediaType}>Delete</Button>
                    <Button disabled={this.isButtonDisabled()} title="Create a new Media Type as duplicate from this Media Type" small secondary icon={<DuplicateIcon/>} onClick={this.props.duplicateMediaType}>Duplicate</Button>                   
                </div>
                
            </div>  
        );
    }
}
GlobalControlLine.contextType = MediaTypeFormContext;
export default GlobalControlLine;