import React, { Component } from 'react';
import {Label, DatePickerCalendar, StatusBadge, Switch} from 'app/components/Basics/';
import { Select } from '@ecosystem/ui-lib/core/components/Select';
import { Checkbox } from '@ecosystem/ui-lib/core/components/Checkbox';
import { Input as EcoInput } from '@ecosystem/ui-lib/core/components/Input';
import './GlobalFieldLineComponent.scss';
import {DemoSetFormContext} from '../../DemoSetContext';

class GlobalFieldLine extends Component {

    static contextType = DemoSetFormContext;

    constructor(props){
        super(props);        
    }

	setStartDate(date) {		
		var selectedDemoSet = this.context.selectedDemoSet;
		selectedDemoSet.startDate = date;		
		this.context.updateSelectedDemoSet(selectedDemoSet);
	}

	setEndDate(date) {
		var selectedDemoSet = this.context.selectedDemoSet;
		selectedDemoSet.endDate = date;
		this.context.updateSelectedDemoSet(selectedDemoSet);
	}	

	enabledSwitchHandler() {
		var selectedDemoSet = this.context.selectedDemoSet;
		selectedDemoSet.enabled = this.context.selectedDemoSet.enabled === 1 ? 0 : 1;
		this.context.updateSelectedDemoSet(selectedDemoSet);
	}	

	userCannotChangeData() {
		return !this.props.canChangeData;
	}

    render() {
        return (
			<div>
				<div className="GlobalFieldLineComponent form-row">
					<div className="form-row-left">
						<div className="field-survey-from">
							<Label>Start Date</Label>
							<DatePickerCalendar disabled={this.userCannotChangeData()} value={this.context.selectedDemoSet.startDate}  onChange={this.setStartDate.bind(this)} />
						</div>
						{/* <div className="field-survey-to">
							<Label>Survey To</Label>
							<DatePickerCalendar disabled={this.userCannotChangeData()} value={this.context.selectedDemoSet.endDate} minDate={this.context.selectedDemoSet.startDate} onChange={this.setEndDate.bind(this)} />
						</div> */}
						<div className="field-enabled">
							<Label>Enabled</Label>
							<div className="default-height centered"><Switch disabled={this.userCannotChangeData()} name="enabled" setSwitchValue={this.enabledSwitchHandler.bind(this)} checked={this.context.selectedDemoSet.enabled === 1}/></div>
						</div>						
					</div>
				</div>
			</div>
        );
    }
}

GlobalFieldLine.contextType = DemoSetFormContext;

export default GlobalFieldLine;