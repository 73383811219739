import React, { Component } from 'react';
import {Button} from 'app/components/Basics/';
import { Input as EcoInput } from '@ecosystem/ui-lib/core/components/Input';
import { Icons } from '@ecosystem/ui-lib/core/components/Icon';
import { ReactComponent as ClosePanel } from 'app/assets/icons/close_panel.svg';
import { ReactComponent as OpenPanel } from 'app/assets/icons/open_panel.svg';
import { ReactComponent as Plus } from 'app/assets/icons/Plus.svg';
import './SideBarComponent.scss';

class SideBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: true,
            hasArchive: props.archive,
            archiveOpened: false
        }

        this.openArchive = this.openArchive.bind(this)
        this.closeArchive = this.closeArchive.bind(this)

    }

    toggle(){
        this.setState({expanded: !this.state.expanded});
    }

    openArchive(){
        this.setState({archiveOpened:true});
    }

    closeArchive(){
        this.setState({archiveOpened:false});
    }


    render() {
    

        var toggle = (<ClosePanel onClick={(e) => {this.toggle()}} title="Close Sidebar" className="sidebar-toggle"/>);
        var cls1 = "expanded";
        var cls2 = "";
        var footerContent = "";

        if(!this.state.expanded){
            toggle = (<OpenPanel onClick={(e) => {this.toggle()}} title="Open Sidebar" className="sidebar-toggle"/>);
            cls1 = "collapsed";
        }
        
        if(this.state.hasArchive){
            if(this.state.archiveOpened){
                footerContent = (<Button className="archive-switch" simple icon={Icons.ChevronLeft} onClick={this.closeArchive}>Leave Archive</Button>)
                cls2="isArchive";
            }else{
                footerContent = (<Button className="archive-switch" simple  icon={Icons.ChevronRight} onClick={this.openArchive}>Go to Archive</Button>)
            }
        }

        return (
            <div className={`SideBarComponent ${cls1} ${cls2}`}>
                {React.Children.toArray(this.props.children).filter(child => child?.props?.tagName==="SideBarHead")}
                <div id="SideBarScrollableDiv" className="main scrollbox">
                    {React.Children.toArray(this.props.children).filter(child => child?.props?.tagName==="SideList")}
                </div>
                <div className="foot">
                    {toggle}
                    {/* <div className="footer-content">
                        {footerContent}
                    </div> */}
					<div className="footer-content">
                        {this.props.footerContent}
                    </div>
                </div>
            </div>
        );
        
    }
}

class SideBarHead extends Component {

    constructor(props) {
        super(props);

        this.state = {
            title: props.headline || "Title",
            addButton: props.addButton,
            addButtonLabel: props.addButtonLabel || "Item",            
            toolbar: props.toolbar,           
            typingTimeout: 0,            
        }
    }
        
    onChange = (e) => {        
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
         }
         let self = this;
         this.setState({           
            typingTimeout: setTimeout(function () {
                self.props.onChange(e)
              }, 500)
         });
    };

    onSubmit = (e) => {        
        e.preventDefault();
    };



    render() {
        return (
            <form action="/" method="get" autoComplete="off" onSubmit={this.onSubmit}>
                <div className="SideBarHeadComponent">
                    <div className="title">
                        {this.state.title}
                        {this.state.addButton ? (<Button disabled={!this.props.canChangeData} onClick={this.props.addButtonCallBack} small secondary icon={<Plus/>} >{this.state.addButtonLabel}</Button>) : ""}
                    </div>
                    {
					this.props.toolbar && (<div className="toolbar">
                        {this.state.toolbar}
                    </div>)
					}
					{
                    this.props.onChange &&
					(<div className="search">
                        <EcoInput id="list-search" className="search-field" leftIcon={Icons.Search} onChange={this.onChange} />                        
                    </div>)
					}
                </div>
            </form>
        );
    }
}


export default SideBar;
export {SideBar, SideBarHead};