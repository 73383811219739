import { Component } from 'react';
import  DatePicker, { registerLocale } from "react-datepicker";
import nl from "date-fns/locale/nl"
import "react-datepicker/dist/react-datepicker.css";
import './DatePickerCalendarComponent.scss';
import { DateTime } from "luxon";
import RemoveTimezone from "app/helpers/remove-timezone";
registerLocale("nl", nl);

class DatePickerCalendar extends Component {
    constructor(props) {
        super(props);
        this.state = {            
            //locale: props.locale ? props.locale : "nl",
			dateFormat: props.dateFormat ? props.dateFormat : "dd/MM/yyyy"
        };		
    }

	getSelectedValue() {
		return this.props.value ? new Date(this.props.value) : null;
	}

	setSelectedValue(date) {		
		if (date == null || date === '') {
			this.props.onChange(null);
			return;
		}	
		// let day = date.getDate();
		// let month = date.getMonth();
		// let year = date.getFullYear();		
		// let newDate = new Date(Date.UTC(year, month, day));		
		this.props.onChange(RemoveTimezone(date));
	}
	
	getMinDate() {				
		if (this.props.minDate) {			
			return new Date(this.props.minDate);
		}
		return null;
	}
	
	getMaxDate() {
		if (this.props.maxDate) {			
			return new Date(this.props.maxDate);
		}
		return null;
	}

    render() {
        if (!this.props) {
            return (null);
        }        
		return (
			<div className={`DatePickerComponent ${this.props.className}`}>
				<DatePicker disabled={this.props.disabled}					
					selected={this.getSelectedValue()} 
					onChange={(date) =>  this.setSelectedValue(date) } 
					locale={this.state.locale}
					dateFormat={this.state.dateFormat}
					minDate={this.getMinDate()}	
					maxDate={this.getMaxDate()}	
					isClearable={true}						
					/>
			</div>			
		);      
    }
}

export default DatePickerCalendar;
export {DatePickerCalendar};