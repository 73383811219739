import React, { Component } from 'react';
import Spacer, { Label, RichTextInput, NumericInput} from 'app/components/Basics';
import { Card } from '@ecosystem/ui-lib/core/components/Card';
import { Input as EcoInput } from '@ecosystem/ui-lib/core/components/Input';
import { CardOptionsAlternative } from '../TabOptions/TabOptionsComponent';
import './TabInputComponent.scss';
import {QuestionFormContext} from '../../QuestionContext'
import { Checkbox } from '@ecosystem/ui-lib/core/components/Checkbox';
import { GenericCell } from '@ecosystem/ui-lib/core/components/Table';
import { ButtonIcon } from '@ecosystem/ui-lib/core/components/ButtonIcon';
import { Icons } from '@ecosystem/ui-lib/core/components/Icon';

class TabInputText extends Component {

    constructor(props){
        super(props);

        this.state = {
            typeSetting:"LINE",
            fieldSetting: "SINGLE"
        }
    }

    handleMaxLengthInput(event) {
        var selectedQuestion = this.context.selectedQuestion;
        selectedQuestion.openMax = parseInt(event.target.value);
        this.context.updateSelectedQuestion(selectedQuestion);
    }

    isOpenTextOptional() {
        return this.context.selectedQuestion.openOptional === 1;
    }

    openTextOptionalHandler = event => {    
        var selectedQuestion = this.context.selectedQuestion;
        selectedQuestion.openOptional = event.target.checked ? 1 : 0;
        this.context.updateSelectedQuestion(selectedQuestion);
    }

    OptionalTextHandler = event => {    
        var selectedQuestion = this.context.selectedQuestion;
        selectedQuestion.openOptionalText = event.target.value;
        this.context.updateSelectedQuestion(selectedQuestion);
    }

    
    
    render() {
        return (
            <div className="TabInputComponent TabInputTextComponent content-box">
                <Card>
                    <div className="form-row">
                        <div className="optionalTextRow form-row-left">
                            <Checkbox disabled={!this.context.canChangeData} label="Optional" checked={this.isOpenTextOptional()} onChange={this.openTextOptionalHandler} />
                            <Spacer width={50} />
                            {/* <Label tooltip={(<div>Description of <br/>optional text</div>)}>Optional text</Label> */}
                            <EcoInput disabled={!this.context.canChangeData} label="Optional text" id="optionalText" name="optionalText" placeholder="- optinal text -" 
                            value={this.context.selectedQuestion.openOptionalText} 
                            onChange={this.OptionalTextHandler}                            
                            ></EcoInput>
                        </div>

                        
                    </div>

                    {/* <div className="form-row">
                        <div className="form-row-left">
                            <div className="buttons-inputtype">
                                <Label>Type</Label>
                                <ButtonGroup>
                                    <Button key="text-line" onActivate={()=>{this.setState({typeSetting:"LINE"})}} defaultActive={this.state.typeSetting==="LINE"} icon={<LineIcon/>}>Text Line</Button>
                                    <Button key="text-area" onActivate={()=>{this.setState({typeSetting:"AREA"})}} defaultActive={this.state.typeSetting==="AREA"} icon={<AreaIcon/>}>Text Area</Button>
                                </ButtonGroup>
                            </div>
                            <div className="field-maxlength">
                                <Label>Max. Length</Label>
                                <EcoInput id="maxlength" name="maxlength" value={this.context.selectedQuestion.openMax} onChange={this.handleMaxLengthInput.bind(this)}></EcoInput>
                            </div>
                            <div className="field-placeholder">
                                <Label>Placeholder</Label>
                                <EcoInput id="placeholder" name="placeholder"></EcoInput>
                            </div>
                        </div>
                        <div className="form-row-right">
                            
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-row-left">
                            <div className="field-fieldprep">
                                <Label tooltip={(<div>Description of <br/>Fields</div>)}>Fields</Label>
                                <Select name="qtype" isSearchable={false}
                                    isDisabled={this.state.typeSetting!=="LINE"}
                                    defaultValue={{value:"SINGLE", label: "Single Line"}}
                                    onChange={val=>this.setState({fieldSetting:val.value})}
                                    options={[
                                        {value:"SINGLE", label: "Single Line"},
                                        {value:"PREPARED", label: "Prepared Lines"},
                                        {value:"CUSTOM", label: "User-defined Lines"}
                                    ]}
                                />
                            </div>
                            <div className={`field-lineslider field-setting-${this.state.fieldSetting}`}>
                                <RangeSlider 
                                    disabled={this.state.fieldSetting==="SINGLE" || this.state.typeSetting!=="LINE"}
                                    name="field-amount"
                                    className="field-amount"
                                    startValue={false} 
                                    endValue={1} 
                                    min={1} 
                                    max={10}  
                                    markDistance={1} 
                                    markLabelDistance={1}
                                    label={<Label tooltip={(<div>Description of <br/>Slider</div>)}>Amount of Text Lines</Label>}
                                />
                                <RangeSlider 
                                    disabled={this.state.typeSetting!=="LINE"}
                                    name="field-min-max"
                                    className="field-min-max"
                                    startValue={1} 
                                    endValue={2} 
                                    min={1} 
                                    max={10}  
                                    markDistance={1} 
                                    markLabelDistance={1}
                                    valueFormat={(vals) => Array.isArray(vals) ? vals.join(" / ") : vals}
                                    label={<Label tooltip={(<div>Description of <br/>Slider</div>)}>Min. Required / Max. Available Text Lines</Label>}
                                />
                            </div>
                        </div>
                    </div> */}
                </Card>
                {/* <CardOptionsAlternative/> */}
            </div>
        );
    }
}

class TabInputNumeric extends Component {

    constructor(props){
        super(props);
    }

	getValidationError(field) { 		
		if (!this.props.errors || this.props.errors.length === 0) {			
			return "";
		}		
		var errorText = this.props.errors.find(e => e.field === field);		
		return errorText ? errorText.errorMessage : "";
	}

	numericMinHandler = event => {
        var selectedQuestion = this.context.selectedQuestion;
        selectedQuestion.numericMin = event.target.value !== '' ? parseInt(event.target.value) : '';
        this.context.updateSelectedQuestion(selectedQuestion);
    }
	
	numericMaxHandler = event => {		
        var selectedQuestion = this.context.selectedQuestion;
        selectedQuestion.numericMax = event.target.value !== '' ? parseInt(event.target.value) : '';
        this.context.updateSelectedQuestion(selectedQuestion);
    }
	
	numericDecimalMaxHandler = event => {		
        var selectedQuestion = this.context.selectedQuestion;
        selectedQuestion.numericDecimalMax = event.target.value !== '' ? parseInt(event.target.value) : null;
        this.context.updateSelectedQuestion(selectedQuestion);
    }

	numericSuffixHandler = event => {		
        var selectedQuestion = this.context.selectedQuestion;
        selectedQuestion.numericSuffix = event.target.value;
        this.context.updateSelectedQuestion(selectedQuestion);
    }

    render() {
	
        return (
            <div className="TabInputComponent TabInputNumericComponent content-box">
                <Card>
                    <div className="form-row">
                        <div className="form-row-left">
                            <div className={`field-min-value ${this.getValidationError("numericMin") ? "error" : ""}`}>
                                <Label>Min. Value</Label>
                                <NumericInput disabled={!this.context.canChangeData} value={this.context.selectedQuestion.numericMin} onChange={this.numericMinHandler.bind(this)}></NumericInput>
								<span className={`error-container`}>{this.getValidationError("numericMin")}</span>
                            </div>
                            <div className={`field-max-value ${this.getValidationError("numericMax") ? "error" : ""}`}>
                                <Label>Max. Value</Label>
                                <NumericInput disabled={!this.context.canChangeData} value={this.context.selectedQuestion.numericMax} onChange={this.numericMaxHandler.bind(this)}></NumericInput>
								<span className={`error-container`}>{this.getValidationError("numericMax")}</span>
                            </div>

							<div className={`field-max-decimal-digits`}>
                                <Label>Max. Decimal Digits</Label>
                                <NumericInput disabled={!this.context.canChangeData} value={this.context.selectedQuestion.numericDecimalMax} onChange={this.numericDecimalMaxHandler.bind(this)}></NumericInput>								
                            </div>

							<div className={`field-sufix`}>
                                <Label tooltip="A suffix text that will show up next to the numeric text box. Ex.: '%' for a percentage number">Suffix</Label>								
                                <EcoInput disabled={!this.context.canChangeData} id="suffixText" name="suffixText" value={this.context.selectedQuestion.numericSuffix} onChange={this.numericSuffixHandler.bind(this)}></EcoInput>								
                            </div>
							
                            {/* <div className="field-placeholder">
                                <Label>Placeholder</Label>
                                <EcoInput id="placeholder" name="placeholder"></EcoInput>
                            </div> */}
                        </div>
                    </div>
                </Card>
                <CardOptionsAlternative errors={this.props.errors}/>
            </div>
        );
    }
}
TabInputText.contextType = QuestionFormContext;
TabInputNumeric.contextType = QuestionFormContext;
export default TabInputText;
export {TabInputText, TabInputNumeric}