import React, { Component } from 'react';
import {Spacer, Button, InputInline} from 'app/components/Basics/'
import { Icons } from '@ecosystem/ui-lib/core/components/Icon';
import './GlobalControlLineComponent.scss';
import { ReactComponent as SaveIcon } from 'app/assets/icons/Save.svg';
import { ReactComponent as DuplicateIcon } from 'app/assets/icons/Duplicate-Plus.svg';
import { ReactComponent as ArchiveIcon } from 'app/assets/icons/Archive.svg';
import { questionCategories } from '../../../../constants/question-categories'
import {QuestionFormContext} from '../../QuestionContext'

class GlobalControlLine extends Component {

    constructor(props){
        super(props);

        this.state = {
            selectedQuestion: { code : "My Question!"},
            errorQuestionCode: ''        
        }        
    }

    componentWillReceiveProps(nextProps) {  
        if (nextProps && nextProps.errors && nextProps.errors !== this.props.errors) {            
            var error = nextProps.errors.find(e => e.field === "code")
            if (error) {
                this.setState({errorQuestionCode: error.errorMessage})
            }
        }
		else {
			this.setState({errorQuestionCode: ''})
		}
    }  

    getCurrentQuestionCode() {
        return this.context.selectedQuestion.code !== '' ? 
            this.context.selectedQuestion.code : "MyQuestion";
    }

    getNewQuestionCode(isEditorOpen, newQuestionCode) {
        if (!isEditorOpen) {
            var selectedQuestion = this.context.selectedQuestion;
            selectedQuestion.code = newQuestionCode;
            this.context.updateSelectedQuestion(selectedQuestion);
        }
    }    

    isButtonDisabled() {
        return !this.context.canChangeData || 
            (this.context.selectedQuestion.isSys === questionCategories.Demographics && !this.context.canChangeDemoQuestion);
    }

    render() {
        return (
            <div className="GlobalControlLineComponent form-row">    
             {/* <input type="text" value={this.context.selectedQuestion.code} onChange={this.handleInput.bind(this)} /> */}
                <InputInline disabled={this.isButtonDisabled()} enableOnBlur className="name-field" error={this.state.errorQuestionCode} value={this.getCurrentQuestionCode()} onModeChange={this.getNewQuestionCode.bind(this)} />
                <div className="contol-buttons">                    
                    <Button disabled={this.isButtonDisabled()} title="Save all changes in question" icon={<SaveIcon/>} onClick={this.props.handleSubmitAction}>Save</Button>                    
                    <Button title="Close current editor and discard all unsaved changes" onClick={this.props.closeEditorButtonOnClick} simple icon={Icons.Close}>Close</Button>
                    <Spacer width={20}/>
                   
                    <Button disabled={this.isButtonDisabled()} title="Delete question irreversible" small secondary icon={Icons.Delete} onClick={this.props.deleteQuestion}>Delete</Button>
                    <Button disabled={this.isButtonDisabled()} title="Create a new question as duplicate from this question" small secondary icon={<DuplicateIcon/>} onClick={this.props.duplicateQuestion}>Duplicate</Button>
                    {/* <Button title="Move this question into the archive (irreversible)" small secondary icon={<ArchiveIcon/>}>Archive</Button> */}
                </div>
                
            </div>  
        );
    }
}
GlobalControlLine.contextType = QuestionFormContext;
export default GlobalControlLine;