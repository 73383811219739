import authHeader from './auth-header';
import Api from './api';

const API_URL = process.env.REACT_APP_AQUA_API_ENDPOINT

class DemoSetService {
	
  async getDemoSetById(demoSetId: string) {
	return await Api().get(`${API_URL}/demographicSet/${demoSetId}`, { headers:  authHeader() as any });      
  }
  async find(term: string) {
    return await Api().get(`${API_URL}/demographicSet/find?text=${term}%`, { headers:  authHeader() as any });      
  }

  async updateDemoSet(demoSet: any) {
    return await Api().put(`${API_URL}/demographicSet/${demoSet.id}`, demoSet, 
    { method: 'PUT',
      headers:  authHeader() as any });      
  }

  async createDemoSet(demoSet: any) {
    return await Api().post(`${API_URL}/demographicSet/`, demoSet, 
    { method: 'POST',
      headers:  authHeader() as any });      
  }

  async deleteDemoSet(demoSet: any) {
    return await Api().delete(`${API_URL}/demographicSet/${demoSet.id}`, 
    { method: 'DELETE',
      headers:  authHeader() as any });      
  }  

  async getMetadata() {
    return await Api().get(`${API_URL}/demographicSet/metadata`, { headers:  authHeader() as any });      
  }
}

export default new DemoSetService();