function NewQuestion() {
    return {
        code: "newQuestionCode",
        groupQuestionId: null,
        id: null,
        inArchive: 0,
        introductoryText: "",
        isSys: 0,
        layoutType: 6,
        mediaTooltip: "",
        mediaUrl: null,
        openColumns: 40,
        openMax: 4000,
        openOptional: 0,
        openOptionalText: "",
        openRows: 3,
        optionSortOrder: 1,
        options: [],
        questionType: "SS",
        remarks: "",
        text: "",
		numericMin: null,
		numericMax: null,
		gridQuestions: [],
		imageUrl: null,
		videoUrl: null
      }
}
export default NewQuestion;