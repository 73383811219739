function ValidateDemoSet(selectedDemoSet) {
	var validationErrors = [];                
	if (!selectedDemoSet.name || selectedDemoSet.name === '') {                    
		addErrorMessage(validationErrors, 'name', 'Demographic set name is empty');
	}
	if (!selectedDemoSet.startDate || selectedDemoSet.startDate === '') {
		addErrorMessage(validationErrors, 'startDate', 'Demographic set start date is empty');
	}
	
	return validationErrors;
}

function addErrorMessage(validationErrors, field, message) {      
	validationErrors.push({ field: field, errorMessage: message});      
}

export default ValidateDemoSet;