import authHeader from './auth-header';
import Api from './api';

const API_URL = process.env.REACT_APP_AQUA_API_ENDPOINT

class InDepthFolderService {
	
	async find(term: string) {
		return await Api().get(`${API_URL}/inDepthFolder/find?text=${term}%`, { headers:  authHeader() as any });      
	}

	async createFolder(folder: any) {
		return await Api().post(`${API_URL}/inDepthFolder/`, folder, 
		{ method: 'POST',
		headers:  authHeader() as any });      
	}

	async updateFolder(folder: any) {
		return await Api().put(`${API_URL}/inDepthFolder/${folder.id}`, folder, 
		{ method: 'PUT',
		headers:  authHeader() as any });      
  	}

	async deleteFolder(folder: any) {
		return await Api().delete(`${API_URL}/inDepthFolder/${folder.id}`, 
		{ method: 'DELETE',
		headers:  authHeader() as any });      
	}
}

export default new InDepthFolderService();