import React, { Component } from 'react';
import { withRouter} from "react-router-dom";
import GlobalControlLine from './components/GlobalControlLine/GlobalControlLineComponent';
import {MediaTypeFormContext} from './MediaTypeContext'
import MediaTypeService from "../../../services/mediaType.service";
import  GetErrorMessage  from '../../../helpers/error-message'
import {ShowError, ShowSuccess} from "../../../components/toast"
import NewMediaType from './MediaTypeModel';
import Modal from '../../../components/Modal';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import ValidateMediaType from './MediaTypeValidation'
import GlobalFieldLine from './components/GlobalFieldLine/GlobalFieldLineComponent';

class MediaTypeForm extends Component {
    tabs = [];
    constructor(props){
        super(props);
        this.state = {           
            activeTab:'mediaTypes',
            selectedMediaType: NewMediaType(),
            errors: []           
        }        
    }    

	getTabs(){        
        return [
            { id:"mediaTypes", label: 'MediaTypes', url: 'mediaTypes', disabled:false, error:false}
        ];
    }
	
	componentWillReceiveProps(nextProps) {
		if (nextProps.selectedMediaType != null) {
			this.setState({ 
				selectedMediaType: nextProps.selectedMediaType,
				activeTab: "mediaTypes"
			});
		}	

        if (!isEqual(this.props.selectedMediaType, nextProps.selectedMediaType)) {
            this.setState({errors: []});            
        }
    }	

    updateSelectedMediaType(newSelectedMediaType) {
		this.setState({selectedMediaType: newSelectedMediaType})
    }

    addErrorMessage(field, message) {
        var errors = this.state.errors;
        errors.push({ field: field, errorMessage: message});
        this.setState({errors: errors})
    }	

    SaveMediaType(mediaType) {                
        this.props.toggleUiBlock(true);
        if (!mediaType._isNew) {
            MediaTypeService.updateMediaType(mediaType)
                .then(response =>{                    
                    this.props.toggleUiBlock(false);
                    this.setState({successMessage: 'The Media Type was saved successfully' });                    
					this.props.getMediaTypeById(mediaType.id);
                })
                .catch(error => {
					let errorMessage = GetErrorMessage(error);
					console.log("errorMessage on catch");
					console.log(errorMessage);
					this.props.toggleUiBlock(false);
					this.setState({ errorMessage: errorMessage});
                });
        }
        else {
            MediaTypeService.createMediaType(mediaType)
                .then(response => {					
                    const newMediaType = response.data;                    
                    this.props.toggleUiBlock(false);
                    this.setState({successMessage: 'The Media Type was created successfully' });                                        
                    this.setState({selectedMediaType: newMediaType});					
					this.props.getMediaTypeById(newMediaType.id);
					
                })
                .catch(error => {
					let errorMessage = GetErrorMessage(error);
					console.log("errorMessage on catch");
					console.log(errorMessage);
					this.props.toggleUiBlock(false);
					this.setState({ errorMessage: errorMessage});
                });
        }

    }
    deleteMediaType = event => {
		if (this.props.canChangeData) {    
        	this.setState({ deleteModalText: `Are you sure you want to delete the Media Type with name '${this.state.selectedMediaType.name}'?`});
		}
    }

    duplicateMediaType = event => {		
        let clonedMediaType = cloneDeep(this.state.selectedMediaType);		
        clonedMediaType.id = null;
        clonedMediaType._isNew = true;
        clonedMediaType.name = `${clonedMediaType.name}_copy`;
		this.props.updateSelectedMediaType(clonedMediaType);
    }

    confirmDelete() {        
        this.hideDeleteModal();
        this.props.toggleUiBlock(true);
        const mediaTypeId = this.state.selectedMediaType.id;
        MediaTypeService.deleteMediaType(this.state.selectedMediaType)
        .then(response =>{            
            this.setState({successMessage: 'The Media Type was deleted successfully' });            
            this.props.removeDeletedMediaType(mediaTypeId);
			setTimeout(() => {
				this.props.toggleUiBlock(false);
				this.props.closeEditor();				
			}, 2000);

            
        })
        .catch(error => {
            let errorMessage = GetErrorMessage(error);
            console.log("errorMessage on catch");
            console.log(errorMessage);
            this.props.toggleUiBlock(false);
            this.setState({ errorMessage: errorMessage});
        });
    }

    hideDeleteModal() {
        this.setState({deleteModalText: ''})
    }    

    handleSubmitAction = event => { 
		if (this.props.canChangeData) {
			var validationErrors = ValidateMediaType(this.state.selectedMediaType);
			this.setState({errors: validationErrors});
			if (validationErrors && validationErrors.length === 0) {                    
				this.SaveMediaType(this.state.selectedMediaType);
			}        
		}
        return;
    }

    getGlobalFieldLineKey() {
        return this.state.selectedMediaType._isNew ? this.state.selectedMediaType_?.id : "new";
    }

    render() {

        this.tabs = this.getTabs(this.state.selectedMediaType);
        return (        
               
            <div className="AppFormComponent MediaTypeForm">                 
                <MediaTypeFormContext.Provider value={{
					updateSelectedMediaType: this.updateSelectedMediaType.bind(this), 
					selectedMediaType: this.state.selectedMediaType,
					canChangeData: this.props.canChangeData}}>                
                    <form ref={ form => this.formEl = form } onSubmit={ this.handleSubmitAction }>
                        <div className="form-head">
                            <GlobalControlLine errors={this.state.errors} 
                                handleSubmitAction={this.handleSubmitAction.bind(this)} 
                                deleteMediaType={this.deleteMediaType.bind(this)}
                                duplicateMediaType={this.duplicateMediaType.bind(this)}
                                closeEditorButtonOnClick={this.props.closeEditorButtonOnClick}
								didSelectedMediaTypeChange={this.props.didSelectedMediaTypeChange}/>    
                            <GlobalFieldLine metadata={this.props.metadata} canChangeData={this.props.canChangeData} key={this.getGlobalFieldLineKey()} />                        
                        </div>                        
                    </form>                    
                </MediaTypeFormContext.Provider>

                {this.state.errorMessage && ( <ShowError clearErrorMessage={() => this.setState({errorMessage: ''})} errorMessage={this.state.errorMessage} /> )}
                {this.state.successMessage && ( <ShowSuccess clearMessage={() => this.setState({successMessage: ''})} message={this.state.successMessage} /> )}
                {this.state.deleteModalText && ( <Modal content={this.state.deleteModalText} size="small" confirmModalHandler={this.confirmDelete.bind(this)} cancelModalHandler={this.hideDeleteModal.bind(this)} />)}
            </div>            
        );
    }
}
export default withRouter(MediaTypeForm); 