import React, { Component } from 'react';
import { Button, Label} from 'app/components/Basics';
import  ImagePreview  from 'app/components/ImagePreview';
import Modal from 'app/components/Modal';
import Upload from 'app/components/Upload';
import ChannelService from "app/services/channel.service";
import  GetErrorMessage  from 'app/helpers/error-message'
import {ChannelFormContext} from '../../ChannelContext'
import { Loading } from '@ecosystem/ui-lib/core/components/Loading';
import {ShowError, ShowSuccess} from "app/components/toast"
import './imageurlhandler.scss';

class ImageUrlHandler extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileUploaded: false,
			tempUrl: this.props.url,	
			loading: false,
			imageUrlModal: false,
			deleteModal: false
        };
    }

	showImageUrlModal() {
		this.setState({imageUrlModal: true});
	}

	hideImageUrlModal() {
		this.setState({imageUrlModal: false, fileToUpload: null});	
	}
	
	showDeleteModal() {
		this.setState({deleteModal: true});
	}

	hideDeleteModal() {
		this.setState({deleteModal: false});	
	}

	onFileSelected = (file) => {		
		this.setState({ fileToUpload: file });
	};

	handleImageUpload(file) {		
		this.disableModalButtons(true);
		var selectedChannel = this.context.selectedChannel;
		var fileName = file.name;
		ChannelService.UploadImageToChannel(selectedChannel.id, file)
			.then(response =>{				
				selectedChannel.imageUrl = response.data;
				selectedChannel.imageHash = Date.now();
				this.context.updateSelectedChannel(selectedChannel);
				this.setState({ successMessage: `Image file ${fileName} was added successfully to channel ${selectedChannel.name}`});
				this.disableModalButtons(false);
				this.hideImageUrlModal();
				
			return response;          
			})
			.catch(error => {			
				this.disableModalButtons(false);  
			  	let errorMessage = GetErrorMessage(error);
				this.setState({ errorMessage: errorMessage});
			  	console.log("errorMessage on catch");
			  	console.log(errorMessage);			  
			});
	}

	handleDelete() {
		this.disableModalButtons(true);	
		const url = new URL(this.props.url);		
		var selectedChannel = this.context.selectedChannel;
		let fileName = url.pathname.substring(1);	
		var deleteRequest = {
			targetEntityId: selectedChannel.id,
			targetEntity: "channel",
			answerOption: null,
			mediaUrl: fileName
		}
		ChannelService.DeleteImageFromChannel(deleteRequest)
			.then(response =>{				
				selectedChannel.imageUrl = null;				
				this.context.updateSelectedChannel(selectedChannel);
				this.setState({ successMessage: `Image file was deleted successfully from channel ${selectedChannel.name}`});
				this.disableModalButtons(false);
				this.hideDeleteModal();
				
			return response;          
			})
			.catch(error => {			
				this.disableModalButtons(false);  
			  	let errorMessage = GetErrorMessage(error);
				this.setState({ errorMessage: errorMessage});
			  	console.log("errorMessage on catch");
			  	console.log(errorMessage);			  
			});
	}

	disableModalButtons(newValue) {
		this.setState({ 
			disableConfirmButton: newValue, 
			disableCancelButton: newValue,
			disableBrowseButton: newValue,
			loading: newValue
		});
	}

	handleUpload = () => {
		this.handleImageUpload(this.state.fileToUpload);				
	};

	disableUploadButton() {
		if (this.state.disableConfirmButton) {
			return true;
		}
		if (this.state.fileToUpload == null) {
			return true;
		}
		return false;
	}

	getUploadModalContent() {
		return (
			<div>
				<div className="modal-title">
					Upload image to channel
				</div>				
				<div className="modal-input">
					<Upload disableButton={this.state.disableBrowseButton} buttonLabel="Select Image" acceptTypes=".jpg, .jpeg, .gif, .png" onFileSelected={this.onFileSelected}></Upload>
					<Loading active={this.state.loading}></Loading>
				</div>				
			</div>
		)
	}

	getDeleteModalContent() {
		return (
			<div>
				<div className="modal-title">
					Are you sure you want to delete this image?
				</div>				
				<div className="modal-input">		
					<ImagePreview url={this.props.url} width={70} height={50}></ImagePreview>			
					<Loading active={this.state.loading}></Loading>
				</div>				
			</div>
		)
	}

    render() {
        return(
            <div className={`ImageUrlHandlerComponent ${this.props.className} ${this.props.disabled ? "disabled" : ""} ${this.props.error ? "error" : ""}`}>
                <div className="file-preview-area">
                    {!this.props.url && <div className="instruction-label">No image yet</div>}
					{this.props.url && <ImagePreview url={this.props.url + "?" + this.context.selectedChannel.imageHash} width={70} height={50}></ImagePreview>}
					
                    <div>
						<Button className="button-upload-actions" onClick={()=> this.showImageUrlModal()} disabled={this.props.disabled} small secondary>{this.props.url ? "Change" : "Add"}</Button>
						{this.props.url && (<Button className="button-upload-actions" onClick={()=> this.showDeleteModal()} disabled={this.props.disabled} small secondary>Delete</Button>)}
					</div>
                </div>
				{this.state.imageUrlModal && ( <Modal disableCancelButton={this.state.disableCancelButton} disableConfirmButton={this.disableUploadButton()} content={this.getUploadModalContent()} size="small" confirmButtonLabel="Upload" confirmModalHandler={this.handleUpload.bind(this)} cancelModalHandler={this.hideImageUrlModal.bind(this)} />)}
				{this.state.deleteModal && ( <Modal disableCancelButton={this.state.disableCancelButton} disableConfirmButton={this.state.disableConfirmButton} content={this.getDeleteModalContent()} size="small" confirmButtonLabel="Delete" confirmModalHandler={this.handleDelete.bind(this)} cancelModalHandler={this.hideDeleteModal.bind(this)} />)}
				{this.state.errorMessage && ( <ShowError clearErrorMessage={() => this.setState({errorMessage: ''})} errorMessage={this.state.errorMessage} /> )}
				{this.state.successMessage && ( <ShowSuccess clearMessage={() => this.setState({successMessage: ''})} message={this.state.successMessage} /> )}
            </div>           
        );
    }
}

ImageUrlHandler.contextType = ChannelFormContext;
export default ImageUrlHandler;