import React, { Component } from 'react';
import { Label, NumericInput} from 'app/components/Basics';
import { Card } from '@ecosystem/ui-lib/core/components/Card';
import { Input as EcoInput } from '@ecosystem/ui-lib/core/components/Input';
import { Select } from '@ecosystem/ui-lib/core/components/Select';
import { CardOptionsAlternative } from '../TabOptions/TabOptionsComponent';
import './TabRatingComponent.scss';
import {QuestionFormContext} from '../../QuestionContext'

class TabRating extends Component {

    constructor(props){
        super(props);
    }

	ratingIcons = [
		{value:"smile", label: "Smile"},
		{value:"thumb", label: "Thumb"},
	  ];

	getValidationError(field) { 		
		if (!this.props.errors || this.props.errors.length === 0) {			
			return "";
		}		
		var errorText = this.props.errors.find(e => e.field === field);		
		return errorText ? errorText.errorMessage : "";
	}

	numericMinHandler = event => {
        var selectedQuestion = this.context.selectedQuestion;
        selectedQuestion.numericMin = event.target.value !== '' ? parseInt(event.target.value) : '';
        this.context.updateSelectedQuestion(selectedQuestion);
    }
	
	numericMaxHandler = event => {		
        var selectedQuestion = this.context.selectedQuestion;
        selectedQuestion.numericMax = event.target.value !== '' ? parseInt(event.target.value) : '';
        this.context.updateSelectedQuestion(selectedQuestion);
    }

	numericSuffixHandler = event => {		
        var selectedQuestion = this.context.selectedQuestion;
        selectedQuestion.numericSuffix = event.target.value;
        this.context.updateSelectedQuestion(selectedQuestion);
    }

	getSelectedIconOption() {
        return this.ratingIcons.find(t=> t.value === this.context.selectedQuestion.ratingIcon)        
    }

	changeIconHandler(iconValue) {
		var selectedQuestion = this.context.selectedQuestion;
        selectedQuestion.ratingIcon = iconValue;        
        this.context.updateSelectedQuestion(selectedQuestion);        
    }

    render() {
	
        return (
            <div className="TabRatingComponent content-box">
                <Card>
                    <div className="form-row">
                        <div className="form-row-left">
                            <div className={`field-min-value ${this.getValidationError("numericMin") ? "error" : ""}`}>
                                <Label>Min. Value</Label>
                                <NumericInput value={this.context.selectedQuestion.numericMin} onChange={this.numericMinHandler.bind(this)}></NumericInput>
								<span className={`error-container`}>{this.getValidationError("numericMin")}</span>
                            </div>
                            <div className={`field-max-value ${this.getValidationError("numericMax") ? "error" : ""}`}>
                                <Label>Max. Value</Label>
                                <NumericInput value={this.context.selectedQuestion.numericMax} onChange={this.numericMaxHandler.bind(this)}></NumericInput>
								<span className={`error-container`}>{this.getValidationError("numericMax")}</span>
                            </div>
                        </div>
                    </div>
					{/* <div className="form-row nomargin">
						<div className="form-row-left">
							<div className="field-icon">
							<Label>Extrema Labels / Icons</Label>
							</div>
						</div>
					</div> */}
					<div className="form-row">
						<div className="form-row-left">							
							<div className="field-label-min">
                                <Label>Minimum Label</Label>								
                                <EcoInput id="minimumLabelText" name="minimumLabelText" value={this.context.selectedQuestion.numericSuffix} onChange={this.numericSuffixHandler.bind(this)}></EcoInput>								
                            </div>
							<div className="field-label-min">
                                <Label>Maximum Label</Label>								
                                <EcoInput id="maximumLabelText" name="maximumLabelText" value={this.context.selectedQuestion.numericSuffix} onChange={this.numericSuffixHandler.bind(this)}></EcoInput>								
                            </div>
							<div className="field-icon">
								<Label>Extrema Icons</Label>
								<Select name="ratingIcon" isSearchable={false}
									styles={{ option: styles => ({ minHeight: 40, ...styles }) }}									
									onChange={val=>{
										this.changeIconHandler(val)                                
									}}
									options={this.ratingIcons} 
									value={this.getSelectedIconOption()}>
								</Select>
							</div>
						</div>
					</div>
                </Card>
                <CardOptionsAlternative errors={this.props.errors}/>
            </div>
        );
    }
}

TabRating.contextType = QuestionFormContext;
export default TabRating;
export {TabRating}