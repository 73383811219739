import React, { Component } from 'react';
import './imagepreview.scss';
import IsValidUrl from 'app/helpers/valid-url'
import Modal from 'app/components/Modal';

class ImagePreview extends Component {
  constructor(props) {
    super(props);

    this.state = {
		videoImageModal: ""
    };
  }

	openImagePlayerModal() {
		this.setState({videoImageModal: 		
			<div className='modal-image-wrapper'>
				<img src={this.props.url} className="modal-image" alt={this.props.url} />
			</div>
		}); 
	}

	closeImagePlayerModal = () => {
		this.setState({videoImageModal: ""}); 
	}

  	render() {
		const { url, width = 280, height = 158 } = this.props;

		if (url == null || url === "") {
			return(<div></div>);
		} else if (IsValidUrl(url)) {
			return (
				<div>
					<div title={url}><img src={url} onClick={this.openImagePlayerModal.bind(this)} width={70} height={50} style={{cursor: 'pointer', maxWidth: width, maxHeight : height}} alt={url} className="image-preview"></img></div>
					{this.state.videoImageModal && ( <Modal size="image-preview" content={this.state.videoImageModal} hideConfirmButton cancelButtonLabel="Close" cancelModalHandler={this.closeImagePlayerModal.bind(this)} />)}
				</div>
			);
		} else {
			return (    
				<svg className='image-preview' width={width} height={height} viewBox="0 0 100">
					<rect width="100%" height="100%" fill="#ebecf0" />
					<text x="50%" y="40%" dominantBaseline="middle" textAnchor="middle" fill="#ff0000" fontSize="36px" fontWeight="bold">
						&#x2716;
					</text>
					<text x="50%" y="70%" dominantBaseline="middle" textAnchor="middle" fill="#333" fontSize="24px"fontWeight="bold">
						Invalid URL
					</text>
				</svg>
			);
		}
	};
}
export default ImagePreview;