import authHeader from './auth-header';
import Api from './api';

const API_URL = process.env.REACT_APP_AQUA_API_ENDPOINT

class SubSurveyService {
	
  async getSubSurveyById(subSurveyId: string) {
	return await Api().get(`${API_URL}/subsurvey/${subSurveyId}`, { headers:  authHeader() as any });      
  }
  async find(term: string) {
    return await Api().get(`${API_URL}/subsurvey/find?text=${term}%`, { headers:  authHeader() as any });      
  }

  async updateSubSurvey(subSurvey: any) {
    return await Api().put(`${API_URL}/subsurvey/${subSurvey.id}`, subSurvey, 
    { method: 'PUT',
      headers:  authHeader() as any });      
  }  
}

export default new SubSurveyService();