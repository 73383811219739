import React, { Component } from 'react';
import './multiFreeText.scss';
import {Button} from 'app/components/Basics/'
import { Icons } from '@ecosystem/ui-lib/core/components/Icon';

class MultiFreeText extends Component {
    constructor(props){
        super(props);
        this.state = {
			textValue: ""
        }        
    }

	setNewValue(event) {		
		this.setState({textValue: event.target.value});
	}

	removeSelectedText(index) {		
		this.props.removeTextHandler(index);
	}

	freeTextItemContent(text, index) {
		return (
			<div key={index} className='freetext-item'>
				<div>{text}</div>
				<div className='freetext-item-remove'>
					<Button title="remove" onClick={()=> this.removeSelectedText(index)} simple small icon={Icons.Close} />
				</div>
			</div>
		)
	}

	onBlur(event) {		
		this.returnNewValue();
	}

	handleKeyDown(event) {
		if (event.key === 'Enter') {
			this.returnNewValue();
		}
	}

	returnNewValue() {
		if (this.state.textValue != null && this.state.textValue !== '') {
			if (this.props.values.indexOf(this.state.textValue) === -1) {
				this.props.addNewTextHandler(this.state.textValue)
			}
		}
		this.setState({textValue: ''})
	}
	
    render() {
        return (
            <div className="multi-freetext-component">				
				<div className={`Input__wrapper ${this.props.disabled ? "Input__wrapper--disabled" : ""}`}>
					<div className="Input__container">
						<input value={this.state.textValue} 
							onChange={(e)=> this.setNewValue(e)}
							onBlur={(e)=> this.onBlur(e)}
							onKeyDown={(e)=> this.handleKeyDown(e)}
							type="text" className="Input__field"
							placeholder="new program name" maxLength={45}
							disabled={this.props.disabled}  />
					</div>
				</div>
				<div className='freetext-list'>
					{this.props.values && this.props.values.map((text, index) => {
						return this.freeTextItemContent(text, index)
					})}
				</div>
				
            </div>
        );
    }
}
export default MultiFreeText;