import React, { Component } from 'react';
import { Label } from 'app/components/Basics';
import { Card } from '@ecosystem/ui-lib/core/components/Card';
import {RoleFormContext} from '../../RoleContext'
import MultiSelect from 'app/components/MultiSelect'
import PermissionsToSelectBox from '../../helpers/PermissionsToSelectBox'
import SelectOptionsToPermissions from '../../helpers/SelectOptionsToPermissions'

import './TabPermissionComponent.scss';

class TabPermission extends Component {
    constructor(props){
        super(props);
        this.state = {
        }    
    }

	getSelectedOptionsMultiSelect( ) {
		return PermissionsToSelectBox(this.context.selectedRole.permissions);
	}

	onChangePermission(items)  {
		var selectedPermissions = SelectOptionsToPermissions(items);
		var selectedRole = this.context.selectedRole;
		selectedRole.permissions = [];
		selectedRole.permissions = selectedPermissions;
		this.context.updateSelectedRole(selectedRole);       
	}

	onRemovePermission(item)  {		
		var selectedRole = this.context.selectedRole;		
		selectedRole.permissions = selectedRole.permissions.filter(function(p) { return p.id !== parseInt(item.value) });
		this.context.updateSelectedRole(selectedRole);
	}

    render() {
        return (
            <div className="TabPermissionComponent content-box">
                <Card>
					<div className="form-row">
						<div className="form-row-left">
							<div className='permission-selection'>
								<Label>Permissions: { this.context.selectedRole.isSysAdmin === 1 && (<span className='admin-role-msg'>({this.context.selectedRole.name} role must have all permissions)</span>)}</Label>
								<MultiSelect key={this.context.selectedRole.id} isDisabled={!this.context.canChangeData || this.context.selectedRole.isSysAdmin}
									selectedOptions={this.getSelectedOptionsMultiSelect()} 
									onChange={this.onChangePermission.bind(this)} 
									onRemove={this.onRemovePermission.bind(this)} 
									options={this.props.metadata.permissions}>									
								</MultiSelect>
							</div>
						</div>						
                	</div>
                </Card>
            </div>			
        );
    }
}
TabPermission.contextType = RoleFormContext;
export default TabPermission;