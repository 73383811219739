import React, { Component } from 'react';
import {InputInline} from 'app/components/Basics/';
import { ReactComponent as ToggleCollapsed } from 'app/assets/icons/chevron_down.svg';
import { ReactComponent as ToggleExpanded } from 'app/assets/icons/chevron_up.svg';
import { ReactComponent as TrashBin } from 'app/assets/icons/bin.svg';
import Collapse from 'react-css-collapse';
import './SideListComponent.scss';

export const SideListContext = React.createContext(); 

class SideListFolder extends Component {
    constructor(props) {
        super(props);

        this.textInput = React.createRef();

        this.state = {
            folder: props.folder,
            editmode: false,
            expanded: false
        };

        this.changeEditMode = this.changeEditMode.bind(this);
    }

    toggle() {
        this.setState({expanded: !this.state.expanded});
    }
    
    changeEditMode(inEditMode, value) {
		if (this.props.canChangeData) {
			this.setState({editmode: inEditMode});
			if(value) {
				var folder = this.state.folder;
				folder.name = value;
				this.setState({folder: folder});
				this.props.updateFolder(folder);
			}
		}
    }

	saveFolderChangeOnClick(newFolderName) {
		if (this.props.canChangeData) {
			var folder = this.state.folder;
			folder.name = newFolderName;
			this.props.updateFolder(folder);
			this.changeEditMode(false);
		}
	}

	deleteFolder(folder) {		
		if (this.props.canChangeData) {
			this.props.deleteFolder(folder);
		}
	}	

    render() {		
        var toggle = this.state.expanded ? <ToggleExpanded title="Collapse" className="toggle" style={{cursor:'pointer'}}/> : <ToggleCollapsed title="Expand" className="toggle" style={{cursor:'pointer'}}/>;
        var cls1 = this.state.expanded ? "expanded" : "collapsed";
        var cls2 = this.state.editmode ? "editmode" : "";

        if(!this.state.editmode){   
            var bin = (<TrashBin onClick={() => this.deleteFolder(this.state.folder)} title="Delete" className={`bin-icon ${!this.props.canChangeData ? "disabled" : ""}`} />);
        }

        return (
            <div key={this.props.name} className={`SideListFolderComponent ${cls1} ${cls2}`}>
                <div className="folder-head" onClick={(e) => {this.toggle()}}>
                    {toggle}
                    <InputInline disabled={!this.props.canChangeData} editTooltip="Rename" closeTooltip="Discard" editOnHover onModeChange={this.changeEditMode} saveHandler={this.saveFolderChangeOnClick.bind(this)} cancelHandler={(e)=>{this.changeEditMode(false);}}>{this.state.folder.name}</InputInline>					
                    {bin}
                </div>
               
                <Collapse className="collapse-area" transition="height 300ms linear" isOpen={this.state.expanded}>
                    <ul className="folder-items">
                    <SideListContext.Provider value={{setSelectedItem: this.setSelectedItem, selectedItem: this.state.selectedItem}}>
                        {this.props.children}
                    </SideListContext.Provider>
                    </ul>
                </Collapse>
            </div>
        );
    }
}

export default SideListFolder;