
import React, { Component } from 'react';
import {SideBar, SideBarHead, SideList, SideListItemQuestion} from 'app/components/SideBar/';
import QuestionForm from './QuestionForm';
import NewQuestion from './QuestionModel';
import QuestionsHome from './components/QuestionsHome/QuestionsHomeComponent';
import QuestionService from "../../services/question.service";
import  GetErrorMessage  from '../../helpers/error-message'
import ShowError from "../../components/toast"
import { Loading } from '@ecosystem/ui-lib/core/components/Loading';
import {questionTypes } from '../../constants/question-types'
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import Modal from '../../components/Modal';
import InfiniteScroll from "react-infinite-scroll-component";

class Question extends Component {
	constructor (props) {
		super(props);
		this.state = {
			search: '',
			infiniteScrollPage: 1,
			infiniteScrollHasMore: true,
			questions: [],
			loading: false,
			errorMessage: null,
			selectedQuestion: null,
			originalSelectedQuestion: null,
			searchedTerm: '',
			uiBlocked: false,
			editorOpen: false
		}
	}

	setSearchState(event) {    
		var value = event.target.value;    
		this.setState({search: value});
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.search !== prevState.search) {			
			this.queryQuestions(this.state.search)
		}

		if (this.state.editorOpen !== prevState.editorOpen) {
			if (this.state.editorOpen) {
			 //retrigger selected question if editor was closed        
				this.setState({selectedQuestion: this.state.selectedQuestion});
			}
		}    
	}

	componentDidMount() {
		this.queryQuestions('')
	}

	openEditor() {
		this.setState({ editorOpen: true });
	}

	closeEditorButtonOnClick() {
		if (this.shouldLosePossibleChangesOnEditor(this.closeEditor)) {
				this.closeEditor();
		}
	}

	closeEditor() {
		this.setState({ editorOpen: false });
		this.setSelectedItem(null);
	}
		
	queryQuestions(term) {
		if (this.state.loading) return;		

		var page = this.state.infiniteScrollPage;
		this.setState({ loading: true });
		if (term !== this.state.searchedTerm) {
			page = 1;
			this.setState({ questions: [], infiniteScrollPage: page });
		}

		QuestionService.findQuestionsPaged(term, false, "", true, page)
			.then(response =>{          
					var questions = this.state.questions;
					questions.push(...response.data.questions);
					this.setState({ questions: questions,
						infiniteScrollPage: page + 1,
						infiniteScrollHasMore: response.data.hasMoreQuestions,
						loading: false,
						searchedTerm: term
					 });
					return response;          
					})
					.catch(error => {			  
						let errorMessage = GetErrorMessage(error);
						console.log("errorMessage on catch");
						console.log(errorMessage);
						this.setState({ loading: false, errorMessage: errorMessage});            
					})
	}	

	getQuestionById(id) {		
		this.toggleUiBlock(true)
		QuestionService.getQuestionById(id)
			.then(response =>{
				this.updateSelectedQuestion(response.data);
				this.updateSavedQuestionOnList(response.data);				
				this.toggleUiBlock(false)
				return response;          
			})
			.catch(error => {			  
				let errorMessage = GetErrorMessage(error);
				console.log("errorMessage on catch");
				console.log(errorMessage);
				this.setState({ errorMessage: errorMessage});    
				this.toggleUiBlock(false)        
			})
	}
	
	getQuestionType(questionTypeCode) {
		var questionType = questionTypes.find(q => q.value === questionTypeCode);
		if (questionType === undefined) return "invalid_question_type";
		return questionType.label;
	}

	getSelectedItem(selectedQuestionId) {
		this.openEditor();
		if (this.shouldLosePossibleChangesOnEditor(()=>this.getQuestionById(selectedQuestionId))) {			
			this.getQuestionById(selectedQuestionId)			
		}		
	}  

	setSelectedItem(selectedQuestion) {
		this.setState({selectedQuestion: selectedQuestion});
	}

	shouldLosePossibleChangesOnEditor(callbackAfterLoseChangesConfirmation) {		
		if (this.didSelectedQuestionChange()) {
			this.showLoseChangeConfirmationModal(callbackAfterLoseChangesConfirmation);
			return false;	
		}
		else {	
			return true;
		}	
	}

	didSelectedQuestionChange() {
		let selectedQuestion = this.state.selectedQuestion;
		if (!selectedQuestion) return false;
		let questionsAreEquals = isEqual(selectedQuestion, this.state.originalSelectedQuestion);
		return !questionsAreEquals;
	}

	showLoseChangeConfirmationModal(callbackAfterLoseChangesConfirmation) {
		this.setState({ loseChangesModalText: `There are unsaved changes to the question with code '${this.state.selectedQuestion.code}'. Are you sure do you want to leave without saving?`,
		callbackAfterLoseChangesConfirmation: callbackAfterLoseChangesConfirmation});
	}

	confirmLoseChangesModalHandler() {
		let questions = this.rollbackToOriginalQuestion(this.state.questions, this.state.originalSelectedQuestion);
		this.setState({questions: questions	}, this.state.callbackAfterLoseChangesConfirmation);
		this.hideLoseChangesModal();
	}

	rollbackToOriginalQuestion(list, originalSelectedItem) {
		const index = list.findIndex(item => item.id === this.state.selectedQuestion.id);	
		list[index] = originalSelectedItem;
		return list;
	}

	hideLoseChangesModal() {
		this.setState({loseChangesModalText: ''})
	}

	isItemSelected(questionId) {    
		if (this.state && this.state.selectedQuestion && 
			this.state.selectedQuestion.id === questionId) {
			return true;
		} else {
			return false;
		}
	}

	toggleUiBlock(blockUi) {
		this.setState ({uiBlocked: blockUi});
	} 

	addNewQuestionHandler = event => {
	if (this.shouldLosePossibleChangesOnEditor(this.addNewQuestion)) {
		this.addNewQuestion()
	}    
		event.preventDefault();
	}

	addNewQuestion() {
		const newQuestion = NewQuestion();
		this.setState({selectedQuestion: newQuestion});
		this.openEditor();
	}

	removeDeletedQuestion(questionId) {    
		const questions = this.state.questions.filter(q => q.id !== questionId);
		this.setState({ questions: questions });    
	}

	updateSavedQuestionOnList(selectedQuestion) {    
		let questions = [...this.updateQuestionsList(this.state.questions, selectedQuestion)];    
		this.setState ({questions: []});
		this.setState({questions: questions,	
			selectedQuestion: selectedQuestion, 
			originalSelectedQuestion: cloneDeep(selectedQuestion) 
		});    
	}

	updateQuestionsList(list, selectedQuestion) {
		var index = list.findIndex(q => q.id === selectedQuestion.id);    
		if (index === -1) {
			list.push(selectedQuestion);      
		}
		else {
			list[index] = selectedQuestion;      
		}
		return list;
	}

	updateSelectedQuestion(selectedQuestion) {
		this.setState({selectedQuestion: selectedQuestion})
	}

	render() {
			return (
				<BlockUi tag="div" blocking={this.state.uiBlocked}>  
				<main className="app-body">                   
						<SideBar>
							<SideBarHead canChangeData={this.props.userPermissions?.CanChangeQuestion} tagName="SideBarHead" 
									headline="Questions" addButton addButtonLabel="Question" addButtonCallBack={this.addNewQuestionHandler.bind(this)}
									onChange={this.setSearchState.bind(this)}
							/>                  
							<SideList tagName="SideList">																				
									<InfiniteScroll
										dataLength={this.state.questions.length}
										next={()=> {this.queryQuestions(this.state.search)} }
										hasMore={this.state.infiniteScrollHasMore}
										loader={<Loading active={true}></Loading>}
										scrollableTarget="SideBarScrollableDiv"
									>
										{this.state.questions.map(q => 
											<div key={q.code}>
												<SideListItemQuestion key={q.code}
													qid={q.id}
													qcode={q.code}
													qtype={this.getQuestionType(q.questionType)}
													itemStatus="In-Use"                          
													getSelectedItem={this.getSelectedItem.bind(this)}  
													isItemSelected={this.isItemSelected(q.id)}                   
													>
													{q.textPlain}                          
												</SideListItemQuestion>
											</div>
										)}                        
									</InfiniteScroll>
								
							</SideList>
						</SideBar>
						<div className="app-content scrollbox">
						{this.state.editorOpen && ( <QuestionForm openEditor={this.openEditor.bind(this)} 
									didSelectedQuestionChange={this.didSelectedQuestionChange.bind(this)} 
									closeEditorButtonOnClick={this.closeEditorButtonOnClick.bind(this)}					  
									closeEditor={this.closeEditor.bind(this)}
									removeDeletedQuestion={this.removeDeletedQuestion.bind(this)} 
									toggleUiBlock={this.toggleUiBlock.bind(this)} 
									selectedQuestion={this.state.selectedQuestion}
									updateSelectedQuestion={this.updateSelectedQuestion.bind(this)}
									updateSavedQuestionOnList={this.updateSavedQuestionOnList.bind(this)}
									canChangeData={this.props.userPermissions?.CanChangeQuestion}  
									canChangeDemoQuestion={this.props.userPermissions?.CanChangeDemoQuestion}  
									/> )}
						{!this.state.editorOpen && ( <QuestionsHome></QuestionsHome>)}
						</div>
					
						{this.state.errorMessage && ( <ShowError clearErrorMessage={() => this.setState({errorMessage: ''})} errorMessage={this.state.errorMessage} /> )}
						{this.state.loseChangesModalText && ( <Modal size="small" content={this.state.loseChangesModalText} confirmModalHandler={this.confirmLoseChangesModalHandler.bind(this)} cancelModalHandler={this.hideLoseChangesModal.bind(this)} />)}
				</main>
				</BlockUi>
			);
	}
}

export default Question;