import React, { Component } from 'react';
import { Button } from 'app/components/Basics';
import { ButtonIcon } from '@ecosystem/ui-lib/core/components/ButtonIcon';
import { Icons } from '@ecosystem/ui-lib/core/components/Icon';
import { ReactComponent as PlusIcon } from 'app/assets/icons/Plus.svg';
import { ReactComponent as ShareIcon } from 'app/assets/icons/share.svg';
import { Card } from '@ecosystem/ui-lib/core/components/Card';
import { Table, GenericCell } from '@ecosystem/ui-lib/core/components/Table';
import Modal from 'app/components/Modal';
import { QuestionSearch } from "app/components/QuestionSearch";
import {AdHocSurveyFormContext} from '../../AdHocSurveyContext'
import {StatusBadge} from 'app/components/Basics/'
import { questionTypes } from '../../../../constants/question-types'
import './TabQuestionsComponent.scss';
import Condition from '../Condition';
import NewQuestionWithCondition from '../../models/AdHocQuestionConditionModel'
import {Badge} from '@ecosystem/ui-lib/core/components/Badge';
import TableDraggableRows from 'app/components/TableDraggableRows'

class TabQuestions extends Component {
    constructor(props){
        super(props);
        this.state = {     
            errorQuestionText: '',
			showQuestionModal: false,
			showConditionModal: false,
			selectedQuestionToAdd: null
        }    
    }
		
	getQuestionTypeLabel(questionTypeCode) {
		let questionType = questionTypes.find(q=>q.value === questionTypeCode);
		return questionType == null ? "" : questionType.label;
	}

	getConditionsCounter(surveyQuestion) {		
		var counter = 0;
		if (surveyQuestion.questionConditionsDefinition != null) {
			counter = surveyQuestion.questionConditionsDefinition.conditions.length;
		}

		if (surveyQuestion.answerOptionsConditionsDefinitions != null) {
			surveyQuestion.answerOptionsConditionsDefinitions.forEach(answerOptionsConditions => {
				counter = counter + answerOptionsConditions.conditions.length;
			});
		}
		return counter;
	}

	addQuestionRow(questionCondition) {        
        const cols = [];
        cols.push(<GenericCell className="column-code" primary={questionCondition.question.code}/>);
        cols.push(<GenericCell className="column-type" primary={<StatusBadge>{this.getQuestionTypeLabel(questionCondition.question.questionType)}</StatusBadge>}/>);
		cols.push(<GenericCell className="column-question-text" primary={questionCondition.question.textPlain}/>);        
        cols.push(<GenericCell className="column-condition" primary={
			<div>
				<div>
					<ButtonIcon onClick={() => this.openAddConditionModal(questionCondition)}  icon={ShareIcon}/>
				</div>
				<div>
					<Badge key={questionCondition.id} text={this.getConditionsCounter(questionCondition)} className="condition-counter-badge"></Badge>
				</div>
			</div>}/>);
        cols.push(<GenericCell className="column-remove" primary={<div><ButtonIcon onClick={() => this.removeQuestionHandler(questionCondition.question.id)}  icon={Icons.Close}/></div>}/>);        
        return cols;
    }

	openAddQuestionModal() {
		this.setState({showQuestionModal: true});
	}

	openAddConditionModal(questionCondition) {		
		this.setState({
			showConditionModal: true,
			questionWithConditions: questionCondition
		});
	}

	closeQuestionModal() {		
		this.setState({
			showQuestionModal: false,
			selectedQuestionToAdd: null
		});
	}

	closeConditionModal() {		
		this.setState({
			showConditionModal: false,
			questionWithConditions: null
		});
	}

	addQuestionHandler(question) {		
		var selectedAdHocSurvey = this.context.selectedAdHocSurvey;

		let newQuestionWithCondition = NewQuestionWithCondition();
		newQuestionWithCondition.question = question;
		selectedAdHocSurvey.questions.push(newQuestionWithCondition);
		this.context.updateSelectedAdHocSurvey(selectedAdHocSurvey);		
	}

	setSelectedQuestion(selectedQuestion) {		
		this.setState({selectedQuestionToAdd: selectedQuestion});
	}

	removeQuestionHandler(questionId) {
        var selectedAdHocSurvey = this.context.selectedAdHocSurvey;
		if (selectedAdHocSurvey.questions) {
			let newQuestionList = selectedAdHocSurvey.questions.filter(function(f) { return f.question.id !== questionId });
			if (this.validateConditions(newQuestionList, "Question can not be removed") === false) {
				return;
			}
        
            selectedAdHocSurvey.questions = newQuestionList
            this.context.updateSelectedAdHocSurvey(selectedAdHocSurvey);        
        }
    }
	
	getSelectedQuestions() {
		return this.context.selectedAdHocSurvey.questions.map(function (surveyQuestion) {
			return surveyQuestion.question;
		});
		
	}

	getTableHeaders() {		
		return [
			{
				id: 'column-code',
				label: 'Code'
			},
			{
				id: 'column-type',
				label: 'Type'
			},
			{
				id: 'column-question-text',
				label: 'Question'
			},
			{
				id: 'column-question-condition',
				label: 'Conditions'
			},
			{
				id: 'column-remove',
				label: 'Remove'
			}
		];
	}

	getDraggableRows() {
		const draggableRows = [];       
        this.context.selectedAdHocSurvey.questions.forEach((adHocSurveyQuestion) => {
            draggableRows.push(this.getDraggableRowCells(adHocSurveyQuestion));
        });
		return draggableRows;
	}

	getDraggableRowCells(adHocSurveyQuestion) {
		var draggableRow = {};
		draggableRow.itemId = adHocSurveyQuestion.question.id;
		draggableRow.item = adHocSurveyQuestion;
		draggableRow.cells = this.getCells(adHocSurveyQuestion);
		return draggableRow;
	}

	getCells(questionCondition) {        
        const cols = [];
        cols.push(<GenericCell className="column-code" primary={questionCondition.question.code}/>);
        cols.push(<GenericCell className="column-type" primary={<StatusBadge>{this.getQuestionTypeLabel(questionCondition.question.questionType)}</StatusBadge>}/>);
		cols.push(<GenericCell className="column-question-text" primary={questionCondition.question.textPlain}/>);        
        cols.push(<GenericCell className="column-condition" primary={
			<div>
				<div>
					<ButtonIcon isDisabled={this.userCannotChangeData()} onClick={() => this.openAddConditionModal(questionCondition)}  icon={ShareIcon}/>
				</div>
				<div>
					<Badge key={questionCondition.id} text={this.getConditionsCounter(questionCondition)} className="condition-counter-badge"></Badge>
				</div>
			</div>}/>);
        cols.push(<GenericCell className="column-remove" primary={<div><ButtonIcon isDisabled={this.userCannotChangeData()} onClick={() => this.removeQuestionHandler(questionCondition.question.id)}  icon={Icons.Close}/></div>}/>);        
        return cols;
    }	
	
	isConditionValid(surveyQuestion, precedingSurveyQuestions, errorMessageTitle) {
		let questionsOnConditions = [];
		let isValid = true;
		if (surveyQuestion.questionConditionsDefinition) {
			surveyQuestion.questionConditionsDefinition.conditions.forEach(condition => {
				//dont check demo questions (isSys == 2)
				if (condition.questionToCheck.isSys !== 2) {
					questionsOnConditions.push(condition.questionToCheck);
				}
			});
		}
		if (surveyQuestion.answerOptionsConditionsDefinitions) {
			surveyQuestion.answerOptionsConditionsDefinitions.forEach(answerOptionsConditionsDefinition => {
				answerOptionsConditionsDefinition.conditions.forEach(condition => {
					//dont check demo questions (isSys == 2)
					if (condition.questionToCheck.isSys !== 2) {
						questionsOnConditions.push(condition.questionToCheck);
					}
				});
			});
		}

		for (let index = 0; index < questionsOnConditions.length; index++) {
			const questionOnCondition = questionsOnConditions[index];
			let foundOnPrecedingQuestions = false;
			for (let index = 0; index < precedingSurveyQuestions.length; index++) {
				const precedingSurveyQuestion = precedingSurveyQuestions[index];				
				if (precedingSurveyQuestion.question.code === questionOnCondition.code) {
					foundOnPrecedingQuestions = true;
				}				
			}
			if (foundOnPrecedingQuestions === false) {
				isValid = false;
				this.setState({ modalInvalidConditionText: `${errorMessageTitle}! Question '${surveyQuestion.question.code}' must be positioned after '${questionOnCondition.code}' because it has a condition that depends on the answer of this question. `});
				break;
			}
		}		
		return isValid;		
	}

	validateConditions(surveyQuestions, errorMessageTitle) {
		let isValid = true;
		for (let index = 0; index < surveyQuestions.length; index++) {
			const surveyQuestion = surveyQuestions[index];
			if (this.isConditionValid(surveyQuestion, [...surveyQuestions].slice(0,index), errorMessageTitle) === false)  {
				isValid = false;
				break;
			}
		}
		return isValid;
	}

	validateNewOrderedList(indexItemMovedFrom, indexItemMovedTo) {
		var newQuestionsOrder = [...this.context.selectedAdHocSurvey.questions];
		const questionToMove = newQuestionsOrder.splice(indexItemMovedFrom, 1)[0];		
		newQuestionsOrder.splice(indexItemMovedTo, 0, questionToMove);		
		let isValid = this.validateConditions(newQuestionsOrder, "Question can not be moved to this position");
		return isValid;		
	}

	hideInvalidDraggedQuestionModal() {
		this.setState({ modalInvalidConditionText: ''});
	}

	updateNewOrderedQuestionList(newOrderedList) {
		var selectedAdHocSurvey = this.context.selectedAdHocSurvey;
		selectedAdHocSurvey.questions = newOrderedList;
		this.context.updateSelectedAdHocSurvey(selectedAdHocSurvey);		
	}
	
	userCannotChangeData() {
		return !this.props.canChangeData  || this.context.selectedAdHocSurvey.netQuestSurveyId;
	}

    render() {		
		
		const draggableRows = this.getDraggableRows();
        return (
            <div className="TabQuestionsComponent content-box">
                <Card>
					<div className="form-row">
						<div className="form-row-left">
							
						</div>
						<div className="form-row-right">
							<Button disabled={this.userCannotChangeData()} secondary icon={<PlusIcon/>} onClick={() => this.openAddQuestionModal()}>Question</Button>
						</div>
                	</div>
					
					<TableDraggableRows 
						disabled={this.userCannotChangeData()} 
						tableHeaders={this.getTableHeaders()} 
						tableRows={draggableRows}
						validateNewOrderedList={this.validateNewOrderedList.bind(this)}
						updateNewOrderedList={this.updateNewOrderedQuestionList.bind(this)}
						>						
					</TableDraggableRows>

					<div style={{display: draggableRows && draggableRows.length > 0 ? 'none' : 'block' }}>
						<p className='EmptyQuestionListInfoTitle'>Question list is empty</p>
						<p className='EmptyQuestionListInfoText'>Please, add questions via <span className='span-box'>+Question</span></p>
					</div>
                </Card>			
				{this.state.showQuestionModal && ( <Modal content={
						<QuestionSearch selectedQuestions={this.getSelectedQuestions()} 
							addQuestionHandler={this.addQuestionHandler.bind(this)} 
							removeQuestionHandler={this.removeQuestionHandler.bind(this)}
							excludeDemoQuestions={true}
						/>
					} 
					disableConfirmButton={this.state.selectedQuestionToAdd == null}					
					hideConfirmButton
					cancelButtonLabel="Close"
					cancelModalHandler={this.closeQuestionModal.bind(this)} 					
					/>)
				}


				{this.state.showConditionModal && ( <Modal size="large" content={
						<Condition canChangeData={this.props.canChangeData} questionConditions={this.state.questionWithConditions} selectedQuestions={this.context.selectedAdHocSurvey.questions} 
							addQuestionHandler={this.addQuestionHandler.bind(this)} 
							removeQuestionHandler={this.removeQuestionHandler.bind(this)} 
						/>
					}

					hideConfirmButton					
					cancelButtonLabel="Close"
					cancelModalHandler={this.closeConditionModal.bind(this)} 					
					/>)
				}
				{this.state.modalInvalidConditionText && ( <Modal content={this.state.modalInvalidConditionText} size="small" hideConfirmButton cancelButtonLabel="Ok" cancelModalHandler={this.hideInvalidDraggedQuestionModal.bind(this)} />)}
            </div>			
        );
    }
}
TabQuestions.contextType = AdHocSurveyFormContext;
export default TabQuestions;