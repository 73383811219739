import React, { Component } from 'react';
import {SideBar, SideBarHead, SideList, SideListItemGeneric} from 'app/components/SideBar/';
import MediaTypeForm from './MediaTypeForm';
import NewMediaType from './MediaTypeModel';
import MediaTypeHome from './components/MediaTypeHome/MediaTypeHomeComponent';
import MediaTypeService from "../../../services/mediaType.service";
import  GetErrorMessage  from '../../../helpers/error-message'
import {ShowError, ShowSuccess} from "../../../components/toast"
import { Loading } from '@ecosystem/ui-lib/core/components/Loading';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import Modal from '../../../components/Modal';
import './MediaTypeComponent.scss';

class MediaType extends Component {
	constructor (props) {
	super(props);
		this.state = {
			search: '',			
			allData: [],
			filteredData: [],
			folders: [],
			loading: false,
			errorMessage: null,
			selectedMediaType: null,
			originalSelectedMediaType: null,
			uiBlocked: false,
			editorOpen: false
		}		
	}

	setSearchState(event) {    
		var value = event.target.value;    
		this.setState({search: value});
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.search !== prevState.search) {     
			this.filterMediaTypes(this.state.search);
		}

		if (this.state.editorOpen !== prevState.editorOpen) {
			if (this.state.editorOpen) {
			//retrigger selected question if editor was closed        
			this.setState({selectedMediaType: this.state.selectedMediaType});
			}
		}    
	}

	filterMediaTypes(term) {		
		if (term !== '') {
			const filteredData = this.state.allData
			.filter(i => (i.name  && i.name.toLowerCase().includes(term.toLowerCase())));
			let newFilteredData = [...filteredData];
			this.setState({ filteredData: newFilteredData});      
		}
		else {
			let newFilteredData = [...this.state.allData];
			this.setState({ filteredData: newFilteredData});     
		}
	}

	componentDidMount() {
		this.queryMediaTypes('');
	}	

	openEditor() {
		this.setState({editorOpen: true});		
	}	

	closeEditorButtonOnClick() {
		if (this.shouldLosePossibleChangesOnEditor(this.closeEditor)) {
			this.closeEditor();
		}
	}

	closeEditor() {
		this.setState({ editorOpen: false });
		this.setSelectedItem(null);
	}

	queryMediaTypes(term) {
		this.setState({ loading: true, filteredData: [] });
		MediaTypeService.find(term)
			.then(response =>{				
				this.setState({ filteredData: response.data,
					allData:  response.data,					
					loading: false				
				}, function() { this.filterMediaTypes(this.state.search); });
				return response;          
			})
			.catch(error => {			  
				let errorMessage = GetErrorMessage(error);
				console.log("errorMessage on catch");
				console.log(errorMessage);
				this.setState({ loading: false, errorMessage: errorMessage});            
			})
	}

	getMediaTypeById(id) {		
		this.toggleUiBlock(true)
		MediaTypeService.getMediaTypeById(id)
			.then(response => {				
				response.data.imageHash = Date.now();
				this.setState({originalSelectedMediaType: cloneDeep(response.data)});
				this.updateSelectedMediaType(response.data);
				this.updateSavedMediaTypeOnList(response.data);				
				this.toggleUiBlock(false)
				return response;          
			})
			.catch(error => {			  
				let errorMessage = GetErrorMessage(error);
				console.log("errorMessage on catch");
				console.log(errorMessage);
				this.setState({ errorMessage: errorMessage});    
				this.toggleUiBlock(false)        
			})
	}	

	getSelectedItem(mediaTypeId) {		
		this.setState({clickedMediaTypeId: mediaTypeId});		
		this.openEditor();
		if (this.shouldLosePossibleChangesOnEditor(()=>this.getMediaTypeById(mediaTypeId))) {			
			this.getMediaTypeById(mediaTypeId)			
		}	
	}

	setSelectedItem(selectedMediaType) {		
		this.updateSelectedMediaType(selectedMediaType);
	}	

	shouldLosePossibleChangesOnEditor(callbackAfterLoseChangesConfirmation) {		
		if (this.didSelectedMediaTypeChange()) {
			this.showLoseChangeConfirmationModal(callbackAfterLoseChangesConfirmation);
			return false;	
		}
		else {	
			return true;
		}	
	}

	didSelectedMediaTypeChange() {
		let selectedMediaType = this.state.selectedMediaType;
		if (!selectedMediaType) return false;
		return !isEqual(selectedMediaType, this.state.originalSelectedMediaType);		
	  }

	showLoseChangeConfirmationModal(callbackAfterLoseChangesConfirmation) {
		this.setState({ 
			loseChangesModalText: `There are unsaved changes to the Media Type '${this.state.selectedMediaType.name}'. Are you sure do you want to leave without saving?`,
			callbackAfterLoseChangesConfirmation: callbackAfterLoseChangesConfirmation
		});
	}

	confirmLoseChangesModalHandler() {
		let filteredData = this.rollbackToOriginalMediaType(this.state.filteredData, this.state.originalSelectedMediaType);
		let allData = this.rollbackToOriginalMediaType(this.state.allData, this.state.originalSelectedMediaType);	

		this.setState({allData: allData, filteredData: filteredData
		}, this.state.callbackAfterLoseChangesConfirmation);
		this.setState({clickedMediaTypeId: null});
		this.hideLoseChangesModal();
	}

	rollbackToOriginalMediaType(list, originalSelectedItem) {
		const index = list.findIndex(item => item.id === this.state.selectedMediaType.id);	
		list[index] = originalSelectedItem;
		return list;
	}

	hideLoseChangesModal() {
		this.setState({loseChangesModalText: ''})
	}

	isItemSelected(mediaTypeId) {    
		if (this.state && this.state.selectedMediaTypeSet && 
			this.state.selectedMediaType.id === mediaTypeId) {
			return true;
		} else {
			return false;
		}
	}

	toggleUiBlock(blockUi) {
		this.setState ({uiBlocked: blockUi});
	} 

	addNewMediaTypeHandler = event => {
		if (this.shouldLosePossibleChangesOnEditor(() => this.addNewMediaType())) {
			this.addNewMediaType()
		}    
		event.preventDefault();
	}

	addNewMediaType() {
		const newMediaType = NewMediaType();		
		this.setSelectedItem(null);
		this.setSelectedItem(newMediaType);
		this.openEditor();
	}

	removeDeletedMediaType(mediaTypeId) {    
		const newFilteredData = this.state.filteredData.filter(i => i.id !== mediaTypeId);
		const newAllData = this.state.allData.filter(i => i.id !== mediaTypeId);
		this.setState({ filteredData: newFilteredData,
			allData: newAllData
			});    
	}

	updateSavedMediaTypeOnList(selectedMediaType) {
		let newFilteredData = [...this.updateMediaTypeList(this.state.filteredData, selectedMediaType)];	
		let newAllData = [...this.updateMediaTypeList(this.state.allData, selectedMediaType)];
		this.setState({filteredData: []});
		this.setState({filteredData: newFilteredData, 
			allData: newAllData
		});
	}

	updateMediaTypeList(list, selectedMediaType) {		
		var index = list.findIndex(q => q.id === selectedMediaType.id);
		if (index === -1) {
			list.push(selectedMediaType);      
		}
		else {
			list[index] = selectedMediaType;      
		}
		return list;
	}
	
	getListItems() {
		return this.state.filteredData.map(mediaType => 
			<div key={mediaType.id}>
				<SideListItemGeneric key={mediaType.id}
					id={mediaType.id}					
					getSelectedItem={this.getSelectedItem.bind(this)}				
					isItemSelected={this.isItemSelected(mediaType.id)}
					menuItemId={mediaType.id}
				>
					{mediaType.name}
				</SideListItemGeneric>
		</div>
		)
	}

	updateSelectedMediaType(selectedMediaType) {		
		this.setState({selectedMediaType: selectedMediaType})
	}	

    render() {

        return (
			<BlockUi tag="div" blocking={this.state.uiBlocked}>  
			<main className="app-body">         
				<div className={`${this.props.isSurveyTest ? "survey-test-sidebar" : ""}`}>
					<SideBar>
						<SideBarHead  canChangeData={this.props.userPermissions?.CanChangeMediaType} tagName="SideBarHead" headline="Media Type" addButton addButtonLabel="Media Type" addButtonCallBack={this.addNewMediaTypeHandler.bind(this)}
							onChange={this.setSearchState.bind(this)}
						/>                  
						<SideList tagName="SideList">                  
							<Loading active={this.state.loading}></Loading>							
							<div>								
								{this.getListItems()}
							</div>
						</SideList>					
					</SideBar>
				</div>          

				<div className="app-content scrollbox">
					{this.state.editorOpen && ( <MediaTypeForm openEditor={this.openEditor.bind(this)} 
							didSelectedMediaTypeChange={this.didSelectedMediaTypeChange.bind(this)}
							closeEditorButtonOnClick={this.closeEditorButtonOnClick.bind(this)} 
							closeEditor={this.closeEditor.bind(this)}
							removeDeletedMediaType={this.removeDeletedMediaType.bind(this)} 
							toggleUiBlock={this.toggleUiBlock.bind(this)} 
							selectedMediaType={this.state.selectedMediaType}
							updateSelectedMediaType={this.updateSelectedMediaType.bind(this)}
							updateSavedMediaTypeOnList={this.updateSavedMediaTypeOnList.bind(this)}
							getMediaTypeById={this.getMediaTypeById.bind(this)}							
							canChangeData={this.props.userPermissions?.CanChangeMediaType}							
							/> )}
					{!this.state.editorOpen && ( <MediaTypeHome></MediaTypeHome>)}					
				</div>				
				
				{this.state.successMessageFolder && ( <ShowSuccess clearMessage={() => this.setState({successMessageFolder: ''})} message={this.state.successMessageFolder} /> )}
				{this.state.errorMessage && ( <ShowError clearErrorMessage={() => this.setState({errorMessage: ''})} errorMessage={this.state.errorMessage} /> )}
				{this.state.loseChangesModalText && ( <Modal size="small" content={this.state.loseChangesModalText} confirmModalHandler={this.confirmLoseChangesModalHandler.bind(this)} cancelModalHandler={this.hideLoseChangesModal.bind(this)} />)}
			</main>
			</BlockUi>
        );
    }
}

export default MediaType;