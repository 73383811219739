function NewMediaType() {
    return {
        id: null,       
        name: "",
        placeholder: "",
        sortOrder: undefined,
        startTime: '02:00:00',
        _isNew: true
      }
}
export default NewMediaType;