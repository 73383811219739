import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import AuthService from "../../services/auth.service";
import './login.scss';
import { Button } from '@ecosystem/ui-lib/core/components/Button';
import { Loading } from '@ecosystem/ui-lib/core/components/Loading';
import { InlineAlertType } from '@ecosystem/ui-lib/core/components/InlineAlert';
import { ToastMessage } from '@ecosystem/ui-lib/core/components/ToastMessage';
import ReCAPTCHA from "react-google-recaptcha";

interface RouterProps {
  history: string;
}

type Props = RouteComponentProps<RouterProps>;

type State = {
  username: string,
  password: string,
  loading: boolean,
  message: string,
  reCaptchaResponseToken: string  
};

export default class Login extends Component<Props, State> {
  recaptchaRef: React.RefObject<ReCAPTCHA>; 

  constructor(props: Props) {
	super(props);
	this.handleLogin = this.handleLogin.bind(this);
	this.onReCaptchaChange = this.onReCaptchaChange.bind(this);

	this.state = {
		username: "",
		password: "",
		loading: false,
		message: "",
		reCaptchaResponseToken: ""
	};

	this.recaptchaRef = React.createRef<ReCAPTCHA>();
  }

  getRecaptchaSiteKey() {	  
	return process.env.REACT_APP_RECAPTCHA_SITE_KEY ? process.env.REACT_APP_RECAPTCHA_SITE_KEY : "";
  }

  async handleLogin(formValue: { username: string; password: string }) {
    const { username, password } = formValue;
	
	const token = await this.recaptchaRef?.current?.executeAsync();
	this.recaptchaRef?.current?.reset();
	const reCaptchaResponseToken = token ? token : ''
    this.setState({
      message: "",
      loading: true,
    });

    AuthService.login(username, password, reCaptchaResponseToken).then(
      (response) => {		
		if (response.resetPassword) {
			this.props.history.push("/changepassword");
        	window.location.reload();
		}
		else {
        	this.props.history.push("/question");
        	window.location.reload();
		}
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        this.setState({
          loading: false,
          message: resMessage
        });
      }
    );
  }
  
  onReCaptchaChange(token: string | null) {
	this.setState({reCaptchaResponseToken: token ? token : ''});
  }

  render() {
    const { loading, message } = this.state;

    const initialValues = {
      username: "",
      password: "",
    };

    return (
<div className="loginWrapper">
  <div className="loginForm">
    <Formik initialValues={initialValues} onSubmit={this.handleLogin}>
          <Form>
            <div className="form-header">
                <h4>Welcome to GfK AQuA</h4>
            </div>
            <div className="form-group">                
              <Field className="form-input" placeholder="User name" name="username" type="text" />
            </div>
            <div className="form-group">                 
                <Field className="form-input" placeholder="Password" name="password" type="password"  />                            
            </div>           
                  
            <div className="form-group">
              <Button className="form-button" type="submit">Login</Button>                     
            </div>
			<div>
				<ReCAPTCHA ref={this.recaptchaRef}
				sitekey={this.getRecaptchaSiteKey()} 
				onChange={this.onReCaptchaChange} 
				size="invisible"/>
			</div>
            <Loading active={loading}></Loading>

              {message && (
                  <ToastMessage type={InlineAlertType.DataError} message={message} onDismiss={() =>
                        this.setState({message: ''})} timeout={100000} />
              )}
          </Form>
      </Formik>
    </div>              
</div>     
    );
  }
}
