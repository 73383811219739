import React, { Component } from 'react';
import Select from 'react-select'
import './InDepthTestSurveyDemoSet.scss';
import {Label, Button} from 'app/components/Basics/'
import { Icons } from '@ecosystem/ui-lib/core/components/Icon';
import { Card} from '@ecosystem/ui-lib/core/components/Card';

class InDepthTestSurveyDemoSet extends Component {
    constructor(props){
        super(props);        
    }

	handleDemoSetChange = (selectedOptions) => {		
		this.setState({ selectedValues: selectedOptions });
		this.props.onChange(selectedOptions);
	}

	getDemoOptions(demoCode) {		
		var selectedDemo = this.props.metadata.find(function(f) { return f.demoCode === demoCode });
		if (selectedDemo) {
			return selectedDemo.demoOptions;
		}
		else {
			return [];
		}		
	}	

	handleDemoSelectedValue = (demoCode, selectedOption) => {				
		this.props.onChangeDemoSelectedValue(this.props.demoSet.demoSetName, demoCode, selectedOption.value)
	}	

	getSelectedDemoValue(demoCode) {		
		var demoValue = this.props.demoSet.demoValues.find(function(f) { return f.demoCode === demoCode });		
		if (!demoValue) return null;
		var demoOptions = this.getDemoOptions(demoCode);		
		return demoOptions.find(function(f) { return f.value === demoValue.demoValue });
	}

	mapDemosToSelectBox(demos) {
		return demos.map(function (obj) {			
			return {value: obj.demoCode, label: obj.demoText}
		});
	}

	removeDemoFromSet(index) {
		this.props.removeDemoFromSet(this.props.demoSet.demoSetName, index);
	}

    render() {
        return (
            <div key={this.props.demoSet.demoSetName} className="InDepthTestSurveyDemoSetComponent">				
                <Select key={this.props.demoSet.demoSetName}				
				 	classNamePrefix='multi-select-box'
					closeMenuOnSelect={false}
					value={this.props.selectedOptions}
					onChange={this.handleDemoSetChange}
					options={this.props.options} 
					isMulti={true} 
					controlShouldRenderValue={false}
					isClearable={false}
					backspaceRemovesValue={false}
					isDisabled={this.props.isDisabled}
					>
				</Select>
				<div className='DropDownMultiSelectFilter-selected-list'>								
					<Card>
					{this.props.selectedOptions && this.props.selectedOptions.map((option, i) => {						
						return (<div className='demo-box' key={option.value}><Button className="float-right" disabled={this.props.isDisabled} title="remove" onClick={()=> this.removeDemoFromSet(i)} simple small icon={Icons.Close} /><Label>{option.label}</Label>
									<Select key={option.value} 
										text={option.label}
										isMulti={false}
										value={this.getSelectedDemoValue(option.value)}
										onChange={this.handleDemoSelectedValue.bind(this, option.value)}
										options={this.getDemoOptions(option.value)}>
									</Select>
								</div> )
					})}	
					</Card>				
				</div>
            </div>
        );
    }
}
export default InDepthTestSurveyDemoSet;