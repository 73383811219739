import React, { Component } from 'react';
import {Spacer, Button, InputInline} from 'app/components/Basics/'
import { Icons } from '@ecosystem/ui-lib/core/components/Icon';
import './GlobalControlLineComponent.scss';
import { ReactComponent as SaveIcon } from 'app/assets/icons/Save.svg';
import { ReactComponent as DuplicateIcon } from 'app/assets/icons/Duplicate-Plus.svg';
import { ReactComponent as ArchiveIcon } from 'app/assets/icons/Archive.svg';
import {IndepthSetFormContext} from '../../InDepthContext'
import ValidateInDepthSet from '../../InDepthSetValidation'
 
class GlobalControlLine extends Component {

    constructor(props){
        super(props);

        this.state = {
            selectedIndepthSet: { name : "New Question Set"},
            errorIndepthSetName: ''        
        }        
    }

    componentWillReceiveProps(nextProps) {        
        if (nextProps && nextProps.errors) {            
            var error = nextProps.errors.find(e => e.field === "name")
            if (error) {
                this.setState({errorIndepthSetName: error.errorMessage})
            }
        }
    }  

    getCurrentIndepthSetName() {		
        return this.context.selectedIndepthSet.name !== '' ? 
            this.context.selectedIndepthSet.name : "New Question Set";
    }

    getNewIndepthSetName(isEditorOpen, newIndepthSetName) {
        if (!isEditorOpen) {
            var selectedIndepthSet = this.context.selectedIndepthSet;
            selectedIndepthSet.name = newIndepthSetName;
            this.context.updateselectedIndepthSet(selectedIndepthSet);
        }
    }
	
	isSaveDisabled() {
		if (!this.context.canChangeData)
		{
			return true;
		}
		let result = false;
		var errors = ValidateInDepthSet(this.context.selectedIndepthSet);		
		if (errors.length > 0) {
			result = true;
		}		
		if (!this.props.didSelectedIndepthSetChange()) {
			result = true;
		}
		return result;
	}

	isButtonDisabled() {	
		if (!this.context.canChangeData)
		{
			return true;
		}
		return this.context.selectedIndepthSet.id === null;
	}

    render() {
        return (
            <div className="GlobalControlLineComponent form-row">
                <InputInline disabled={!this.context.canChangeData} enableOnBlur className="name-field" error={this.state.errorIndepthSetName} value={this.getCurrentIndepthSetName()} onModeChange={this.getNewIndepthSetName.bind(this)} />
                <div className="contol-buttons">                    
                    <Button disabled={this.isSaveDisabled()} title="Save all changes in the in-depth set" icon={<SaveIcon/>} onClick={this.props.handleSubmitAction}>Save</Button>                    
                    <Button title="Close current editor and discard all unsaved changes" onClick={this.props.closeEditorButtonOnClick} simple icon={Icons.Close}>Close</Button>
                    <Spacer width={20}/>                   
                    <Button disabled={this.isButtonDisabled()} title="Delete  the in-depth set is irreversible" small secondary icon={Icons.Delete} onClick={this.props.deleteIndepthSet}>Delete</Button>
                    <Button disabled={this.isButtonDisabled()} title="Create a new in-depth set as duplicate from this in-depth set" small secondary icon={<DuplicateIcon/>} onClick={this.props.duplicateIndepthSet}>Duplicate</Button>
                    {/* <Button title="Move this question into the archive (irreversible)" small secondary icon={<ArchiveIcon/>}>Archive</Button> */}
                </div>
                
            </div>  
        );
    }
}
GlobalControlLine.contextType = IndepthSetFormContext;
export default GlobalControlLine;