import React, { Component } from 'react';
import { RichTextInput, Label} from 'app/components/Basics';
import { Card, } from '@ecosystem/ui-lib/core/components/Card';
import {QuestionFormContext} from '../../QuestionContext'
import  MediaUrlHandler  from 'app/pages/question/MediaUrlHandler';

import './TabQuestionComponent.scss';

class TabQuestion extends Component {

    constructor(props){
        super(props);
        this.state = {
        }    
    }

	getValidationError() {
		if (!this.props.errors || this.props.errors.length === 0) {			
			return "";
		}		
		var errorText = this.props.errors.find(e => e.field === "text");
		return errorText ? errorText.errorMessage : "";
	}

    onQuestionTextChange(newText) {        
        var selectedQuestion = this.context.selectedQuestion;
        selectedQuestion.text = newText;
        this.context.updateSelectedQuestion(selectedQuestion); 
    }

    onInstructionsTextChange(newText) {    
		var selectedQuestion = this.context.selectedQuestion;
        selectedQuestion.introductoryText = newText;
        this.context.updateSelectedQuestion(selectedQuestion); 
    }

    onVideoIntroTextChange(newText) {    
		var selectedQuestion = this.context.selectedQuestion;
        selectedQuestion.videoIntroText = newText;
        this.context.updateSelectedQuestion(selectedQuestion); 
    }    

    render() {        
        return (
            <div className="TabQuestionComponent content-box">
                <Card>
                    <div className="form-row">
                        <div className="form-row-50">
                            <div className="field-question-text">
                                <RichTextInput
									key={this.context.selectedQuestion.id}
                                    value={this.context.selectedQuestion.text}
                                    onValueChange={this.onQuestionTextChange.bind(this)}
                                    label={<Label>Question Text</Label>}
                                    textAlign="left" 
                                    ctrlsPosition="top" 
                                    editorLines={5} maxLines={5}    
                                    error={this.getValidationError()}
									disabled={!this.context.canChangeData}                                
                                />
                            </div>
                            {/* <div className="field-image">
                                <Label>Image</Label>
                                <FileUpload></FileUpload>
                            </div> */}
							
                            <div className="field-instructions">
                                <RichTextInput		
									key={this.context.selectedQuestion.id}							
                                    value={this.context.selectedQuestion.introductoryText}
                                    onValueChange={this.onInstructionsTextChange.bind(this)}
                                    hideCtrls
                                    label={<Label>Instructions</Label>}
                                    textAlign="left" 
                                    editorLines={2} maxLines={2}
									disabled={!this.context.canChangeData}
                                />
                            </div>

							<div className="form-row-left">
								<div className="field-image">
									<Label>Image</Label>
									<MediaUrlHandler key={`image_${this.context.selectedQuestion.id}`} id={this.context.selectedQuestion.id} url={this.context.selectedQuestion.imageUrl}
										mediaType="image" entityType="question" />
								</div>
								<div className="field-video">
									<Label>Video</Label>
									<MediaUrlHandler key={`video_${this.context.selectedQuestion.id}`} id={this.context.selectedQuestion.id} url={this.context.selectedQuestion.videoUrl}
										thumbnailUrl={this.context.selectedQuestion.videoThumbnailUrl} mediaType="video" entityType="question" />                                    
								</div>
                                {this.context.selectedQuestion.videoUrl && <div className="field-video-desc">	                                    								
                                    <Label>Introduction Text For Video</Label> 
                                    <RichTextInput		
                                        key={this.context.selectedQuestion.id}							
                                        value={this.context.selectedQuestion.videoIntroText}
                                        onValueChange={this.onVideoIntroTextChange.bind(this)}
                                        hideCtrls                                    
                                        textAlign="left" 
                                        editorLines={2} maxLines={3}
                                        disabled={!this.context.canChangeData}
                                        exactHeight="78px"
                                    /> 
                                    
								</div>}
							</div>
                        </div>
                        <div className="form-row-50">
                            
                        </div>
                    </div>
                </Card>
            </div>
        );
    }
}
TabQuestion.contextType = QuestionFormContext;
export default TabQuestion;