import authHeader from './auth-header';
import Api from './api';

const API_URL = process.env.REACT_APP_AQUA_API_ENDPOINT

class ResponseDownloadService {	
  
  async find(responseDownload: any) {
    var questions = "";
    if (responseDownload.questions != null && responseDownload.questions.length > 0) {
      questions = responseDownload.questions.join(',');
    }
    return await Api().get(`${API_URL}/respondentResponse/find?startDate=${responseDownload.startDate}&endDate=${responseDownload.endDate}&questions=${responseDownload.questions}`, { headers:  authHeader() as any });      
  }
  
  async download(responseDownload: any) {
    return await Api().post(`${API_URL}/respondentResponse/download`, responseDownload, 
    { method: 'POST',
      headers:  authHeader() as any });      
  }

  async getMetadata() {
    return await Api().get(`${API_URL}/respondentResponse/metadata`, { headers:  authHeader() as any });      
  }  
}

export default new ResponseDownloadService();