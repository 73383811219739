import React, { Component } from 'react';
import './SubSurveyFormComponent.scss';
import { DateTime } from "luxon";
import {Label, DatePickerCalendar} from 'app/components/Basics/';
import {SubSurveyContext} from '../../SubSurveyContext'

class SubSurveyForm extends Component {

    constructor(props){
        super(props);
        this.state = {
        }        
    }

	setStartDate(date) {		
		var subSurvey = this.props.subSurvey;
		subSurvey.startDate = date;		
		this.context.updateSelectedSubSurvey(subSurvey);
	}

	setEndDate(date) {		
		var subSurvey = this.props.subSurvey;
		subSurvey.endDate = date;		
		this.context.updateSelectedSubSurvey(subSurvey);
	}
	
    render() {
		return (
            <div className='AppFormComponent'>
				<div className="SubSurveyFormComponent form-row">
					<div className="form-row-left">
						<h4>Sub-Survey: <span className='orange'>{this.props.subSurvey.name}</span>  </h4>
					</div>
				</div>
				<div className="SubSurveyFormComponent form-row">
					<div className="form-row-left">						
						<div className="field-date-from">
							<Label>Date From</Label>
							<DatePickerCalendar disabled={!this.context.canChangeData} value={this.props.subSurvey.startDate} onChange={this.setStartDate.bind(this)} />
						</div>						
						<div className="field-date-from">
							<Label>Date To</Label>
							<DatePickerCalendar disabled={!this.context.canChangeData} value={this.props.subSurvey.endDate} onChange={this.setEndDate.bind(this)} />
						</div>
					</div>
				</div>
			</div>
        );
    }
}

SubSurveyForm.contextType = SubSurveyContext;
export default SubSurveyForm;