import React, { Component } from 'react';
import {Button} from 'app/components/Basics/'
import { ButtonIcon } from '@ecosystem/ui-lib/core/components/ButtonIcon';
import { ReactComponent as PlusIcon } from 'app/assets/icons/Plus.svg';
import { Table, GenericCell } from '@ecosystem/ui-lib/core/components/Table';
import './TemplatesTableComponent.scss';
import { DateTime } from "luxon";
import Modal from 'app/components/Modal';
import TemplateForm from '../TemplatesForm/TemplateFormComponent'
import ValidateNqTemplate from '../../NqTemplateValidation'
import { ReactComponent as EditPen } from 'app/assets/icons/edit-pen.svg';
import { Icons } from '@ecosystem/ui-lib/core/components/Icon';
import {NqTemplateContext} from '../../NqTemplateContext'
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import NewNqTemplate from '../../models/NqTemplateModel';

class TemplatesTable extends Component {

    constructor(props){
        super(props);
        this.state = {            
            showNqTemplateFormModal: false,
			selectedNqTemplate: null
        }        
    }	

	addNqTemplateRow(template) {        
        const cols = [];
		var formatedStartDate = "";
		var formatedLastModifiedDate = "";
		if (template.startDate) {
			const startDate = DateTime.fromISO(template.startDate);
			formatedStartDate = startDate.toFormat("dd/MM/yyyy");
		}
		if (template.dateLastModified) {
			const lastModifiedDate = DateTime.fromISO(template.dateLastModified);
			formatedLastModifiedDate = lastModifiedDate.toFormat("dd/MM/yyyy HH:mm:ss");
		}
		cols.push(<GenericCell className="column-edit" primary={<EditPen className='edit-icon' onClick={(e) => {this.openFormModal(e, template, true)}}/>}/>);
        cols.push(<GenericCell className="column-name" primary={<div>
			<div className="template-name">{template.name}</div>
			<div className="modified-date">{formatedLastModifiedDate}</div>
		</div>}/>);
        cols.push(<GenericCell className="column-template-id" primary={template.nqTemplateId}/>);
		cols.push(<GenericCell alignment="left" className="column-version" primary={template.version}/>);        
		cols.push(<GenericCell className="column-survey-phase" primary={this.getSurveyPhaseName(template.surveyPhaseId)}/>);
        cols.push(<GenericCell className="column-survey-type" primary={this.getSurveyTypeName(template.surveyTypeId)}/>);
		cols.push(<GenericCell className="column-start-date" primary={formatedStartDate}/>);
		cols.push(<GenericCell className="column-active" primary={template.enabled ? "Yes" : "No"}/>);
		cols.push(<GenericCell className="column-remarks" primary={template.remarks}/>);		
		cols.push(<GenericCell className="column-remove" primary={<div><ButtonIcon isDisabled={!this.context.canChangeData} onClick={() => this.deleteNqTemplateHandler(template.name)}  icon={Icons.Close}/></div>}/>);
        return cols;
    }

	getSurveyPhaseName(surveyPhaseId) {		
        var surveyPhase = this.props.metadata.surveyPhases.find(t=> parseInt(t.value) === surveyPhaseId);
		if (surveyPhase) {
			return surveyPhase.label;
		}
		else {
			return "";
		}
    }

	getSurveyTypeName(surveyTypeId) {		
        var surveyType = this.props.metadata.surveyTypes.find(t=> parseInt(t.value) === surveyTypeId);
		if (surveyType) {
			return surveyType.label;
		}
		else {
			return "";
		}
    }

	getSelectedSurveyPhaseOption(surveyPhaseId) {		
        return this.props.metadata.surveyPhases.find(t=> parseInt(t.value) === surveyPhaseId);
    }

	closeFormModal() {
		this.setState({
			showNqTemplateFormModal: false,
			selectedNqTemplate: null,
			originalSelectedNqTemplate: null,
			editExistingTemplate: null
		});
	}

	openFormModal(event, template, editExistingTemplate) {		
		let originalTemplate = cloneDeep(template);
		this.setState({
			showNqTemplateFormModal: true,
			editExistingTemplate: editExistingTemplate,
			selectedNqTemplate: cloneDeep(template),
			originalSelectedNqTemplate: originalTemplate
		});
	}

	saveNqTemplate() {		
		let action = this.state.editExistingTemplate === true ? "update" : "create"
		let result = this.context.saveNqTemplate(this.state.selectedNqTemplate, action);		
		result.then(success => {
			if (success) {
				this.closeFormModal();
			}
		});
	}

	deleteNqTemplateHandler(templateName) {		
		this.setState({ 
			deleteTemplateModalText: `Are you sure you want to delete the template with name '${templateName}'?`,
			templateToDelete: templateName
		});
	}

	hideDeleteModal() {
        this.setState({deleteTemplateModalText: '', templateToDelete: ''})
    } 

	confirmDelete() {		
		this.context.deleteNqTemplate(this.state.templateToDelete);
        this.hideDeleteModal();		
    }

	disableSave() {
		if (!this.context.canChangeData) return true;
		var errors = ValidateNqTemplate(this.state.selectedNqTemplate);		
		if (errors.length > 0) {
			return true;
		}
		return isEqual(this.state.selectedNqTemplate, this.state.originalSelectedNqTemplate);		
	}

    render() {
		const rows = []; 
		if (this.props.templates) {   
			this.props.templates.forEach((template) => {
				rows.push(this.addNqTemplateRow(template));
			});
		}

		return (
            <div className="AppFormComponent NqTemplateTable">
				<div className="form-row">
						<div className="form-row-left">							
						</div>
						<div className="form-row-right">
							<Button disabled={!this.context.canChangeData} secondary icon={<PlusIcon/>} onClick={() => this.openFormModal("", new NewNqTemplate(), false)}>Template</Button>
						</div>
                	</div>
						<Table
								className="NqTemplateList"
								columns={[
									{
										headerKey: `column-edit`,
										className: "AppFormTableHeader column-edit",
										name: ``
									},
									{   headerKey: `column-name`,
										className: "AppFormTableHeader column-name",
										name: `Name`
									},
									{
										headerKey: `column-template-id`,
										className: "AppFormTableHeader column-template-id",
										name: `Id`
									},
									{
										headerKey: `column-version`,
										className: "AppFormTableHeader column-version",
										name: `Version`
									},
									{
										headerKey: `column-survey-phase`,
										className: "AppFormTableHeader column-survey-phase",
										name: `Survey Phase`
									},
									{
										headerKey: `column-survey-type`,
										className: "AppFormTableHeader column-survey-type",
										name: `Survey Type`
									},
									{
										headerKey: `column-start-date`,
										className: "AppFormTableHeader column-start-date",
										name: `Start Date`
									},
									{
										headerKey: `column-active`,
										className: "AppFormTableHeader column-active",
										name: `Active`
									},
									{
										headerKey: `column-remarks`,
										className: "AppFormTableHeader column-remarks",
										name: `Remarks`
									},									
									{
										headerKey: `column-delete`,
										className: "AppFormTableHeader column-delete",
										name: ``
									}
								]}
								rows= {rows}                
								sortingOptions={{headerKey: null}}
								isHeaderSticky={false}
								isFirstColumnSticky={false}
							/>

							{this.state.showNqTemplateFormModal && ( <Modal size="fit-content" content={
									<TemplateForm metadata={this.props.metadata} template={this.state.selectedNqTemplate} editExistingTemplate={this.state.editExistingTemplate} />
								}
								confirmButtonLabel="Save"
								disableConfirmButton={this.disableSave()}				
								confirmModalHandler={this.saveNqTemplate.bind(this)}				
								cancelButtonLabel="Cancel"
								cancelModalHandler={this.closeFormModal.bind(this)} 					
								/>)
							}
							{this.state.deleteTemplateModalText && ( <Modal content={this.state.deleteTemplateModalText} size="small" confirmModalHandler={this.confirmDelete.bind(this)} cancelModalHandler={this.hideDeleteModal.bind(this)} />)}
						</div>
        );
    }
}
TemplatesTable.contextType = NqTemplateContext;
export default TemplatesTable;