import React, { Component } from 'react';
import SubSurveyHome from './components/SubSurveyHome/SubSurveyHomeComponent';
import SubSurveyService from "../../../services/subSurvey.service";
import  GetErrorMessage  from '../../../helpers/error-message'
import {ShowError, ShowSuccess} from "../../../components/toast"
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import './SubSurveyComponent.scss';
import {SubSurveyContext} from './SubSurveyContext'

class SubSurvey extends Component {
	constructor (props) {
	super(props);
		this.state = {			
			subSurveys: [],			
			loading: false,
			errorMessage: null,
			selectedSubSurvey: null,			
			uiBlocked: false
		}		
	}
	
	componentDidMount() {
		this.querySubSurveys();
	}

	querySubSurveys() {
		this.toggleUiBlock(true);
		this.setState({ subSurveys: [] });
		SubSurveyService.find()
			.then(response =>{				
				this.setState({subSurveys: response.data});
				this.toggleUiBlock(false);
				return response;          
			})
			.catch(error => {			  
				let errorMessage = GetErrorMessage(error);
				console.log("errorMessage on catch");
				console.log(errorMessage);				
				this.setState({ errorMessage: errorMessage});
				this.toggleUiBlock(false);
			})
	}	

	toggleUiBlock(blockUi) {
		this.setState ({uiBlocked: blockUi});
	}	

	updateSelectedSubSurvey(selectedSubSurvey) {		
		this.setState({selectedSubSurvey: selectedSubSurvey})
	}	

	saveSubSurvey(subSurvey, action) {
		if (action === "update") {
            return SubSurveyService.updateSubSurvey(subSurvey)
                .then(response =>{                    
                    this.toggleUiBlock(false);
                    this.setState({successMessage: 'The NQ subSurvey was saved successfully' });                    
					this.refreshSubSurveys();
					return true;
                })
                .catch(error => {
					let errorMessage = GetErrorMessage(error);
					console.log("errorMessage on catch");
					console.log(errorMessage);
					this.toggleUiBlock(false);
					this.setState({ errorMessage: errorMessage});
					return false;
                });
        }       
	}

	refreshSubSurveys() {
		this.querySubSurveys();		
	}

    render() {
        return (
			<BlockUi tag="div" blocking={this.state.uiBlocked}>  
			<main className="app-body">
				<SubSurveyContext.Provider value={
					{
						updateSelectedSubSurvey: this.updateSelectedSubSurvey.bind(this), 
						saveSubSurvey: this.saveSubSurvey.bind(this), 						
						selectedSubSurvey: this.state.selectedSubSurvey,
						canChangeData:this.props.userPermissions?.CanChangeSubSurvey
					}
				}>                			
					<div className="app-content scrollbox">						
						<SubSurveyHome subSurveys={this.state.subSurveys} toggleUiBlock={this.toggleUiBlock.bind(this)} metadata={this.state.metadata} />
					</div>
				</SubSurveyContext.Provider>
				
				{this.state.successMessage && ( <ShowSuccess clearMessage={() => this.setState({successMessage: ''})} message={this.state.successMessage} /> )}
				{this.state.errorMessage && ( <ShowError clearErrorMessage={() => this.setState({errorMessage: ''})} errorMessage={this.state.errorMessage} /> )}
				
			</main>
			</BlockUi>
        );
    }
}

export default SubSurvey;