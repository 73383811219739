import authHeader from './auth-header';
import Api from './api';

const API_URL = process.env.REACT_APP_AQUA_API_ENDPOINT

export default class AdHocSurveyService {
  _surveyType: number = 0;
  constructor(surveyType: number) {
    this._surveyType = surveyType;
  }

  getHeaders() {
    var header = authHeader() as Record<string, string>;
    header.adhocSurveyType = this._surveyType.toString();
    return header;
  }

  async getAdHocSurveyById(adhocSurveyId: string) {
    return await Api().get(`${API_URL}/adhocSurvey/${adhocSurveyId}`, { headers: this.getHeaders() as any });
  }

  async find(surveyType: number, term: string, date: any, isOverview: any) {
    if (date == null) {
      date = '';
    }
    if (isOverview == null) {
      isOverview = false;
    }
    return await Api().get(`${API_URL}/adhocSurvey/find?text=${term}%&surveyType=${surveyType}&date=${date}&isOverview=${isOverview}`, { headers: this.getHeaders() as any });      
  }

  async updateAdHocSurvey(adhocSurvey: any) {
    var formData = new FormData();
    formData.append('schemaFile', adhocSurvey.schemaFile);
    var adhocSurveyCopy = Object.assign({}, adhocSurvey);
    delete adhocSurveyCopy.schemaFile;
    formData.append('adHocSurveyDtoString', JSON.stringify(adhocSurveyCopy));
    return await Api().put(`${API_URL}/adhocSurvey/${adhocSurvey.id}`, formData,
      {
        method: 'PUT',
        headers: this.getHeaders() as any
      });
  }

  async createAdHocSurvey(adhocSurvey: any) {
    var formData = new FormData();
    formData.append('schemaFile', adhocSurvey.schemaFile);
    var adhocSurveyCopy = Object.assign({}, adhocSurvey);
    delete adhocSurveyCopy.schemaFile;
    formData.append('adHocSurveyDtoString', JSON.stringify(adhocSurveyCopy));
    return await Api().post(`${API_URL}/adhocSurvey/`, formData,
      {
        method: 'POST',
        headers: this.getHeaders() as any
      });
  }

  async deleteAdHocSurvey(adhocSurvey: any) {
    return await Api().delete(`${API_URL}/adhocSurvey/${adhocSurvey.id}`,
      {
        method: 'DELETE',
        headers: this.getHeaders() as any
      });
  }

  async getMetadata() {
    return await Api().get(`${API_URL}/adhocSurvey/metadata`, { headers: this.getHeaders() as any });
  }

  async getAdHocSurveyTestLinks(adhocSurveyId: string) {
    return await Api().get(`${API_URL}/adHocSurveyTestLinks/create?questionSetId=${adhocSurveyId}`, { headers: this.getHeaders() as any });
  }
}