function ValidateResponseDownloadSet(selectedResponseDownloadSet) {
	var validationErrors = [];	
	if (!selectedResponseDownloadSet.startDate || selectedResponseDownloadSet.startDate === '') {
		addErrorMessage(validationErrors, 'startDate', 'Start date is empty');
	}
	if (!selectedResponseDownloadSet.endDate || selectedResponseDownloadSet.endDate === '') {
		addErrorMessage(validationErrors, 'startDate', 'End date is empty');
	}	
	if (!selectedResponseDownloadSet.questions || selectedResponseDownloadSet.questions.length === 0) {
		addErrorMessage(validationErrors, 'questions', 'Questions cannot be empty');
	}	
	
	return validationErrors;
}

function addErrorMessage(validationErrors, field, message) {      
	validationErrors.push({ field: field, errorMessage: message});      
}

export default ValidateResponseDownloadSet;