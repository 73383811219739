import React, { Component } from 'react';
import { ButtonIcon } from '@ecosystem/ui-lib/core/components/ButtonIcon';
import { MemoryRouter,Switch,Route,Redirect,withRouter} from "react-router-dom";
import {TabBar} from 'app/components/Basics';
import { ReactComponent as DuplicateIcon } from 'app/assets/icons/Duplicate.svg';
import GlobalControlLine from './components/GlobalControlLine/GlobalControlLineComponent';
import GlobalFieldLine from './components/GlobalFieldLine/GlobalFieldLineComponent';
import {IndepthSetFormContext} from './InDepthContext'
import InDepthService from "../../services/indepth.service";
import  GetErrorMessage  from '../../helpers/error-message'
import {ShowError, ShowSuccess} from "../../components/toast"
import NewInDepthSet from './InDepthModel';
import Modal from '../../components/Modal';
import cloneDeep from 'lodash/cloneDeep';
import TabQuestions from './components/TabQuestions/TabQuestionsComponent';
import TabProgramSelection from './components/TabProgramSelection/TabProgramSelectionComponent';
import isEqual from 'lodash/isEqual';
import ValidateInDepthSet from './InDepthSetValidation'

class IndepthSetForm extends Component {
    tabs = [];
    constructor(props){
        super(props);
        this.state = {           
            activeTab:'questions',
            selectedIndepthSet: NewInDepthSet(),
            errors: []           
        };
    }

    userCanChangeData() {
        //user cannot change indepth set with netquest questions
		//return this.props.canChangeData && ! this.state.selectedIndepthSet?.questions.some(q => q.isSys === 3);
        return this.props.canChangeData;
	}

    isNetQuestSurvey() {
        return this.state.selectedIndepthSet?.isNetquest || 
            this.state.selectedIndepthSet?.questions.some(q => q.isSys === 3);
    }
    getTabs(){        
        return [
            { id:"questions", label: 'Questions', url: 'questions', disabled:false, error:false},
            { id:"programSelection", label: 'Program Selection', url: 'programSelection', disabled: false}
        ];
    }

	componentWillReceiveProps(nextProps) {
		if (nextProps.selectedIndepthSet != null) {
			this.setState({ 
				selectedIndepthSet: nextProps.selectedIndepthSet,
				activeTab: "questions"
			});
		}	

        if (!isEqual(this.props.selectedIndepthSet, nextProps.selectedIndepthSet)) {
            this.setState({errors: []});            
        }
    }	

    updateselectedIndepthSet(newSelectedIndepthSet) {
		this.setState({selectedIndepthSet: newSelectedIndepthSet})
    }

    addErrorMessage(field, message) {
        var errors = this.state.errors;
        errors.push({ field: field, errorMessage: message});
        this.setState({errors: errors})
    }	

    SaveIndepthSet(indepthSet) {                
        this.props.toggleUiBlock(true);
        if (indepthSet.id) {
            InDepthService.updateIndepthSet(indepthSet)
                .then(response =>{                    
                    this.props.toggleUiBlock(false);
                    this.setState({successMessage: 'The in-depth set was saved successfully' });                    
					this.props.getIndepthSetById(indepthSet.id);
                })
                .catch(error => {
					let errorMessage = GetErrorMessage(error);
					console.log("errorMessage on catch");
					console.log(errorMessage);
					this.props.toggleUiBlock(false);
					this.setState({ errorMessage: errorMessage});
                });
        }
        else {
            InDepthService.createIndepthSet(indepthSet)
                .then(response => {					
                    const newIndepthSet = response.data;                    
                    this.props.toggleUiBlock(false);
                    this.setState({successMessage: 'The in-depth set was created successfully' });                                        
                    this.setState({selectedIndepthSet: newIndepthSet});					
					this.props.getIndepthSetById(newIndepthSet.id);
					
                })
                .catch(error => {
					let errorMessage = GetErrorMessage(error);
					console.log("errorMessage on catch");
					console.log(errorMessage);
					this.props.toggleUiBlock(false);
					this.setState({ errorMessage: errorMessage});
                });
        }

    }
    deleteIndepthSet = event => {
		if (this.userCanChangeData()) {    
        	this.setState({ deleteQuestionModalText: `Are you sure you want to delete the in-depth set with name '${this.state.selectedIndepthSet.name}'?`});
		}
    }

    duplicateIndepthSet = event => {        
        let clonedIndepthSet = cloneDeep(this.state.selectedIndepthSet);
        clonedIndepthSet.id = null;
        clonedIndepthSet.name = `${clonedIndepthSet.name}_copy`;        
		this.props.updateselectedIndepthSet(clonedIndepthSet);
    }

    confirmDelete() {        
        this.hideDeleteModal();
        this.props.toggleUiBlock(true);
        const indepthSetId = this.state.selectedIndepthSet.id;
        InDepthService.deleteIndepthSet(this.state.selectedIndepthSet)
        .then(response =>{            
            this.setState({successMessage: 'The in-depth set was deleted successfully' });            
            this.props.removeDeletedIndepthSet(indepthSetId);
			setTimeout(() => {
				this.props.toggleUiBlock(false);
				this.props.closeEditor();				
			}, 2000);

            
        })
        .catch(error => {
            let errorMessage = GetErrorMessage(error);
            console.log("errorMessage on catch");
            console.log(errorMessage);
            this.props.toggleUiBlock(false);
            this.setState({ errorMessage: errorMessage});
        });
    }

    hideDeleteModal() {
        this.setState({deleteQuestionModalText: ''})
    }    

    handleSubmitAction = event => { 
		if (this.userCanChangeData()) {
			var validationErrors = ValidateInDepthSet(this.state.selectedIndepthSet);
			this.setState({errors: validationErrors});
			if (validationErrors && validationErrors.length === 0) {                    
				this.SaveIndepthSet(this.state.selectedIndepthSet);
			}        
		}
        return;
    }

    render() {

        this.tabs = this.getTabs(this.state.selectedIndepthSet);
        return (        
               
            <div className="AppFormComponent InDepthForm">                 
                <IndepthSetFormContext.Provider value={{
					updateselectedIndepthSet: this.updateselectedIndepthSet.bind(this), 
					selectedIndepthSet: this.state.selectedIndepthSet,
					canChangeData: this.userCanChangeData(),
                    isNetQuestSurvey: this.isNetQuestSurvey()}}>                
                    <form ref={ form => this.formEl = form } onSubmit={ this.handleSubmitAction }>
                        <div className="form-head">
                            <GlobalControlLine errors={this.state.errors} 
                                handleSubmitAction={this.handleSubmitAction.bind(this)} 
                                deleteIndepthSet={this.deleteIndepthSet.bind(this)}
                                duplicateIndepthSet={this.duplicateIndepthSet.bind(this)}
                                closeEditorButtonOnClick={this.props.closeEditorButtonOnClick}
								didSelectedIndepthSetChange={this.props.didSelectedIndepthSetChange}/>
                            <GlobalFieldLine folders={this.props.folders} surveyTypes={this.props.metadata.surveyTypes} />
                        </div>
                        <div className="form-sections">
                            <MemoryRouter>
                                <div className="tab-bar">
                                    <TabBar key="IndepthSetForm" activeTab={this.state.activeTab} tabs={this.tabs} onTabActivate={item=>this.setState({activeTab:item.id})}/>
                                    {/* <div title="Load the current tab settings from another question." className="duplicate-tab"><ButtonIcon icon={DuplicateIcon}/></div> */}
                                </div>
                                <div className="section-content">
                                    <Switch>
                                        <Route exact path="/"><Redirect to={this.state.activeTab}/></Route>
                                        <Route exact path="/questions"><TabQuestions errors={this.state.errors} isAskAlwaysEnabled={this.props.isAskAlwaysEnabled} /></Route>
                                        <Route exact path="/programSelection"><TabProgramSelection metadata={this.props.metadata} errors={this.state.errors}/></Route>                                     
                                    </Switch>
                                    <Redirect to={this.state.activeTab}/>
                                </div>
                            </MemoryRouter>
                        </div>
                    </form>                    
                </IndepthSetFormContext.Provider>

                {this.state.errorMessage && ( <ShowError clearErrorMessage={() => this.setState({errorMessage: ''})} errorMessage={this.state.errorMessage} /> )}
                {this.state.successMessage && ( <ShowSuccess clearMessage={() => this.setState({successMessage: ''})} message={this.state.successMessage} /> )}
                {this.state.deleteQuestionModalText && ( <Modal content={this.state.deleteQuestionModalText} size="small" confirmModalHandler={this.confirmDelete.bind(this)} cancelModalHandler={this.hideDeleteModal.bind(this)} />)}
            </div>            
        );
    }
}
export default withRouter(IndepthSetForm); 