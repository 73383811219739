import React, { Component } from "react";
import { IntroPage } from "app/components/IntroPage";
import { Card } from '@ecosystem/ui-lib/core/components/Card';
import TemplatesTable from "../TemplatesTable/TemplatesTableComponent"
import './NqTemplateHomeComponent.scss';

class NqTemplateHome extends Component{

	constructor (props) {
		super(props);
			this.state = {				
				activeTemplates: [],
				errorMessage: null,				
				uiBlocked: false,
				editorOpen: false,
				dateFormat: ""
			}		
	}
	
    render() {
        return (
            <IntroPage headerText={"Active NetQuest Templates"}>       
				<Card>
					<TemplatesTable metadata={this.props.metadata} templates={this.props.templates}/>					
				</Card>         
            </IntroPage>
        )
    }
}

export default NqTemplateHome;