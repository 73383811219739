import React, { Component } from 'react';
import { Label, Button } from 'app/components/Basics';

class Upload extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
    this.state = {
      selectedFileName: '',
    };
  }

  handleButtonClick = () => {
    this.fileInputRef.current.click();
  };

  handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      this.setState({ selectedFileName: file.name });
      this.props.onFileSelected(file);
    }
  };

  render() {
    const { buttonLabel, buttonDisabled, acceptTypes, existingFile } = this.props;

    return (
      <div>
        {this.state.selectedFileName && (
          <Label>{this.state.selectedFileName}</Label>
        )}
        {this.state.selectedFileName === '' && existingFile && (
          <Label>{existingFile}</Label>
        )}
        <Button onClick={this.handleButtonClick} disabled={buttonDisabled}>
          {buttonLabel}
        </Button>
        <input
          type="file"
          style={{ display: 'none' }}
          onChange={this.handleFileChange}
          accept={acceptTypes}
          ref={this.fileInputRef}
        />
      </div>
    );
  }
}
export default Upload;