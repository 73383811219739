import React, { Component } from 'react';
import { withRouter} from "react-router-dom";
import GlobalControlLine from './components/GlobalControlLine/GlobalControlLineComponent';
import {ChannelFormContext} from './ChannelContext'
import ChannelService from "../../../services/channel.service";
import  GetErrorMessage  from '../../../helpers/error-message'
import {ShowError, ShowSuccess} from "../../../components/toast"
import NewChannel from './ChannelModel';
import Modal from '../../../components/Modal';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import ValidateChannel from './ChannelValidation'
import GlobalFieldLine from './components/GlobalFieldLine/GlobalFieldLineComponent';

class ChannelForm extends Component {
    tabs = [];
    constructor(props){
        super(props);
        this.state = {           
            activeTab:'channels',
            selectedChannel: NewChannel(),
            errors: []           
        }        
    }    

	getTabs(){        
        return [
            { id:"channels", label: 'Channels', url: 'channels', disabled:false, error:false}
        ];
    }
	
	componentWillReceiveProps(nextProps) {
		if (nextProps.selectedChannel != null) {
			this.setState({ 
				selectedChannel: nextProps.selectedChannel,
				activeTab: "channels"
			});
		}	

        if (!isEqual(this.props.selectedChannel, nextProps.selectedChannel)) {
            this.setState({errors: []});            
        }
    }	

    updateSelectedChannel(newSelectedChannel) {
		this.setState({selectedChannel: newSelectedChannel})
    }

    addErrorMessage(field, message) {
        var errors = this.state.errors;
        errors.push({ field: field, errorMessage: message});
        this.setState({errors: errors})
    }	

    SaveChannel(channel) {                
        this.props.toggleUiBlock(true);
        if (!channel._isNew) {
            ChannelService.updateChannel(channel)
                .then(response =>{                    
                    this.props.toggleUiBlock(false);
                    this.setState({successMessage: 'The channel was saved successfully' });                    
					this.props.getChannelById(channel.id);
                })
                .catch(error => {
					let errorMessage = GetErrorMessage(error);
					console.log("errorMessage on catch");
					console.log(errorMessage);
					this.props.toggleUiBlock(false);
					this.setState({ errorMessage: errorMessage});
                });
        }
        else {
            ChannelService.createChannel(channel)
                .then(response => {					
                    const newChannel = response.data;                    
                    this.props.toggleUiBlock(false);
                    this.setState({successMessage: 'The channel was created successfully' });                                        
                    this.setState({selectedChannel: newChannel});					
					this.props.getChannelById(newChannel.id);
					
                })
                .catch(error => {
					let errorMessage = GetErrorMessage(error);
					console.log("errorMessage on catch");
					console.log(errorMessage);
					this.props.toggleUiBlock(false);
					this.setState({ errorMessage: errorMessage});
                });
        }

    }
    deleteChannel = event => {
		if (this.props.canChangeData) {    
        	this.setState({ deleteModalText: `Are you sure you want to delete the channel with name '${this.state.selectedChannel.name}'?`});
		}
    }

    duplicateChannel = event => {		
        let clonedChannel = cloneDeep(this.state.selectedChannel);		
        clonedChannel.id = null;
        clonedChannel._isNew = true;
        clonedChannel.name = `${clonedChannel.name}_copy`;
		this.props.updateSelectedChannel(clonedChannel);
    }

    confirmDelete() {        
        this.hideDeleteModal();
        this.props.toggleUiBlock(true);
        const channelId = this.state.selectedChannel.id;
        ChannelService.deleteChannel(this.state.selectedChannel)
        .then(response =>{            
            this.setState({successMessage: 'The channel was deleted successfully' });            
            this.props.removeDeletedChannel(channelId);
			setTimeout(() => {
				this.props.toggleUiBlock(false);
				this.props.closeEditor();				
			}, 2000);

            
        })
        .catch(error => {
            let errorMessage = GetErrorMessage(error);
            console.log("errorMessage on catch");
            console.log(errorMessage);
            this.props.toggleUiBlock(false);
            this.setState({ errorMessage: errorMessage});
        });
    }

    hideDeleteModal() {
        this.setState({deleteModalText: ''})
    }    

    handleSubmitAction = event => { 
		if (this.props.canChangeData) {
			var validationErrors = ValidateChannel(this.state.selectedChannel);
			this.setState({errors: validationErrors});
			if (validationErrors && validationErrors.length === 0) {                    
				this.SaveChannel(this.state.selectedChannel);
			}        
		}
        return;
    }

    getGlobalFieldLineKey() {
        return this.state.selectedChannel._isNew ? this.state.selectedChannel_?.id : "new";
    }

    render() {

        this.tabs = this.getTabs(this.state.selectedChannel);
        return (        
               
            <div className="AppFormComponent ChannelForm">                 
                <ChannelFormContext.Provider value={{
					updateSelectedChannel: this.updateSelectedChannel.bind(this), 
					selectedChannel: this.state.selectedChannel,
					canChangeData: this.props.canChangeData}}>                
                    <form ref={ form => this.formEl = form } onSubmit={ this.handleSubmitAction }>
                        <div className="form-head">
                            <GlobalControlLine errors={this.state.errors} 
                                handleSubmitAction={this.handleSubmitAction.bind(this)} 
                                deleteChannel={this.deleteChannel.bind(this)}
                                duplicateChannel={this.duplicateChannel.bind(this)}
                                closeEditorButtonOnClick={this.props.closeEditorButtonOnClick}
								didSelectedChannelChange={this.props.didSelectedChannelChange}/>    
                            <GlobalFieldLine metadata={this.props.metadata} canChangeData={this.props.canChangeData} key={this.getGlobalFieldLineKey()} />                        
                        </div>                        
                    </form>                    
                </ChannelFormContext.Provider>

                {this.state.errorMessage && ( <ShowError clearErrorMessage={() => this.setState({errorMessage: ''})} errorMessage={this.state.errorMessage} /> )}
                {this.state.successMessage && ( <ShowSuccess clearMessage={() => this.setState({successMessage: ''})} message={this.state.successMessage} /> )}
                {this.state.deleteModalText && ( <Modal content={this.state.deleteModalText} size="small" confirmModalHandler={this.confirmDelete.bind(this)} cancelModalHandler={this.hideDeleteModal.bind(this)} />)}
            </div>            
        );
    }
}
export default withRouter(ChannelForm); 