import React, { Component } from 'react';
import { IntroPage } from "app/components/IntroPage";
import { Card } from '@ecosystem/ui-lib/core/components/Card';
import TemplatesTable from "./components/TemplatesTable/TemplatesTableComponent"
import {Spacer, Button} from 'app/components/Basics/'
import { Icons } from '@ecosystem/ui-lib/core/components/Icon';
import {NqTemplateContext} from './NqTemplateContext'

class NqTemplateList extends Component {
    tabs = [];
    constructor(props){
        super(props);
        this.state = {
        }        
    }
    render() {       
        return (               
            <div className="AppFormComponent">
				<div className='nqTemplateList'>
					<div className="GlobalControlLineComponent form-row">   
						<div className="IntroHeader">
							NetQuest {this.props.nqTemplateMenuItem.name} Templates
						</div>
							<div className="contol-buttons">
								<Button title={`Close ${this.props.nqTemplateMenuItem.name} template list`} onClick={this.props.closeList} simple icon={Icons.Close}>Close</Button>
							</div>	
					</div>				
					<Card>
						<TemplatesTable metadata={this.props.metadata} templates={this.props.templates}/>
					</Card>
				</div>            
			</div>
        );
    }
}
NqTemplateList.contextType = NqTemplateContext;
export default NqTemplateList; 