
import React, { Component } from 'react';
import {SideBar, SideBarHead, SideList, SideListItemGeneric} from 'app/components/SideBar/';
import UserForm from './UserForm';
import NewUser from './UserModel';
import UserHome from './components/UserHome/UserHomeComponent';
import UserService from "../../../services/user.service";
import  GetErrorMessage  from '../../../helpers/error-message'
import {ShowError, ShowSuccess} from "../../../components/toast"
import { Loading } from '@ecosystem/ui-lib/core/components/Loading';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import Modal from '../../../components/Modal';
import { Button } from 'app/components/Basics/'
import { ReactComponent as PagesIcon } from 'app/assets/icons/pagepage.svg';
import GeneratePassword from 'app/helpers/password-generator';
import './UserComponent.scss';

class User extends Component {
	constructor (props) {
	super(props);
		this.state = {
			search: '',			
			allData: [],
			filteredData: [],
			folders: [],
			loading: false,
			errorMessage: null,
			selectedUser: null,
			originalSelectedUser: null,
			uiBlocked: false,
			editorOpen: false,			
			metadata: {
				roles: []
			}
		}		
	}

	setSearchState(event) {    
		var value = event.target.value;    
		this.setState({search: value});
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.search !== prevState.search) {     
			this.filterUsers(this.state.search);
		}

		if (this.state.editorOpen !== prevState.editorOpen) {
			if (this.state.editorOpen) {
			//retrigger selected question if editor was closed        
			this.setState({selectedUser: this.state.selectedUser});
			}
		}    
	}

	filterUsers(term) {		
		if (term !== '') {
			const filteredData = this.state.allData
			.filter(i => (i.name  && i.name.toLowerCase().includes(term.toLowerCase())));
			let newFilteredData = [...filteredData];
			this.setState({ filteredData: newFilteredData});      
		}
		else {
			let newFilteredData = [...this.state.allData];
			this.setState({ filteredData: newFilteredData});     
		}
	}

	componentDidMount() {		
		this.queryMetadata();
		this.queryUsers('');
	}	

	openEditor() {
		this.setState({editorOpen: true});		
	}	

	closeEditorButtonOnClick() {
		if (this.shouldLosePossibleChangesOnEditor(this.closeEditor)) {
			this.closeEditor();
		}
	}

	closeEditor() {
		this.setState({ editorOpen: false });
		this.setSelectedItem(null);
	}

	queryMetadata() {		
		UserService.getMetadata()
				.then(response =>{					
					this.setState({
						metadata: response.data					
					});
					return response;          
				})
				.catch(error => {			  
					let errorMessage = GetErrorMessage(error);
					console.log("errorMessage on catch");
					console.log(errorMessage);
					this.setState({ loading: false, errorMessage: errorMessage});            
			})
	}	

	queryUsers(term) {
		this.setState({ loading: true, filteredData: [] });
		UserService.find(term)
			.then(response =>{				
				this.setState({ filteredData: response.data,
					allData:  response.data,					
					loading: false				
				}, function() { this.filterUsers(this.state.search); });
				return response;          
			})
			.catch(error => {			  
				let errorMessage = GetErrorMessage(error);
				console.log("errorMessage on catch");
				console.log(errorMessage);
				this.setState({ loading: false, errorMessage: errorMessage});            
			})
	}

	getUserById(id) {		
		this.toggleUiBlock(true)
		UserService.getUserById(id)
			.then(response =>{				
				this.setState({originalSelectedUser: cloneDeep(response.data)});
				this.updateSelectedUser(response.data);
				this.updateSavedUserOnList(response.data);				
				this.toggleUiBlock(false)
				return response;          
			})
			.catch(error => {			  
				let errorMessage = GetErrorMessage(error);
				console.log("errorMessage on catch");
				console.log(errorMessage);
				this.setState({ errorMessage: errorMessage});    
				this.toggleUiBlock(false)        
			})
	}	

	getSelectedItem(userId) {		
		this.setState({clickedUserId: userId});		
		this.openEditor();
		if (this.shouldLosePossibleChangesOnEditor(()=>this.getUserById(userId))) {			
			this.getUserById(userId)			
		}	
	}

	setSelectedItem(selectedUser) {		
		this.updateSelectedUser(selectedUser);
	}	

	shouldLosePossibleChangesOnEditor(callbackAfterLoseChangesConfirmation) {		
		if (this.didSelectedUserChange()) {
			this.showLoseChangeConfirmationModal(callbackAfterLoseChangesConfirmation);
			return false;	
		}
		else {	
			return true;
		}	
	}

	didSelectedUserChange() {
		let selectedUser = this.state.selectedUser;
		if (!selectedUser) return false;
		return !isEqual(selectedUser, this.state.originalSelectedUser);		
	  }

	showLoseChangeConfirmationModal(callbackAfterLoseChangesConfirmation) {
		this.setState({ 
			loseChangesModalText: `There are unsaved changes to the user '${this.state.selectedUser.name}'. Are you sure do you want to leave without saving?`,
			callbackAfterLoseChangesConfirmation: callbackAfterLoseChangesConfirmation
		});
	}

	confirmLoseChangesModalHandler() {
		let filteredData = this.rollbackToOriginalUser(this.state.filteredData, this.state.originalSelectedUser);
		let allData = this.rollbackToOriginalUser(this.state.allData, this.state.originalSelectedUser);	

		this.setState({allData: allData, filteredData: filteredData
		}, this.state.callbackAfterLoseChangesConfirmation);
		this.setState({clickedUserId: null});
		this.hideLoseChangesModal();
	}

	rollbackToOriginalUser(list, originalSelectedItem) {
		const index = list.findIndex(item => item.id === this.state.selectedUser.id);	
		list[index] = originalSelectedItem;
		return list;
	}

	hideLoseChangesModal() {
		this.setState({loseChangesModalText: ''})
	}

	isItemSelected(userId) {    
		if (this.state && this.state.selectedUserSet && 
			this.state.selectedUser.id === userId) {
			return true;
		} else {
			return false;
		}
	}

	toggleUiBlock(blockUi) {
		this.setState ({uiBlocked: blockUi});
	} 

	addNewUserHandler = event => {
		if (this.shouldLosePossibleChangesOnEditor(() => this.addNewUser())) {
			this.addNewUser()
		}    
		event.preventDefault();
	}

	addNewUser() {
		const newUser = NewUser();
		newUser.userPassword = GeneratePassword();		
		this.setSelectedItem(null);
		this.setSelectedItem(newUser);
		this.openEditor();
	}

	removeDeletedUser(userId) {    
		const newFilteredData = this.state.filteredData.filter(i => i.id !== userId);
		const newAllData = this.state.allData.filter(i => i.id !== userId);
		this.setState({ filteredData: newFilteredData,
			allData: newAllData
			});    
	}

	updateSavedUserOnList(selectedUser) {
		let newFilteredData = [...this.updateUserList(this.state.filteredData, selectedUser)];	
		let newAllData = [...this.updateUserList(this.state.allData, selectedUser)];
		this.setState({filteredData: []});
		this.setState({filteredData: newFilteredData, 
			allData: newAllData
		});
	}

	updateUserList(list, selectedUser) {		
		var index = list.findIndex(q => q.id === selectedUser.id);
		if (index === -1) {
			list.push(selectedUser);      
		}
		else {
			list[index] = selectedUser;      
		}
		return list;
	}
	
	getListItems() {
		return this.state.filteredData.map(user => 
			<div key={user.id}>
				<SideListItemGeneric key={user.id}
					id={user.id}					
					getSelectedItem={this.getSelectedItem.bind(this)}				
					isItemSelected={this.isItemSelected(user.id)}
					menuItemId={user.id}
				>
					{user.userName}
				</SideListItemGeneric>
		</div>
		)
	}

	updateSelectedUser(selectedUser) {		
		this.setState({selectedUser: selectedUser})
	}	

    render() {

        return (
			<BlockUi tag="div" blocking={this.state.uiBlocked}>  
			<main className="app-body">         
				<div className={`${this.props.isSurveyTest ? "survey-test-sidebar" : ""}`}>
					<SideBar>
						<SideBarHead  canChangeData={this.props.userPermissions?.CanChangeUser} tagName="SideBarHead" headline="User" addButton addButtonLabel="User" addButtonCallBack={this.addNewUserHandler.bind(this)}
							onChange={this.setSearchState.bind(this)}
						/>                  
						<SideList tagName="SideList">                  
							<Loading active={this.state.loading}></Loading>							
							<div>								
								{this.getListItems()}
							</div>
						</SideList>					
					</SideBar>
				</div>          

				<div className="app-content scrollbox">
					{this.state.editorOpen && ( <UserForm openEditor={this.openEditor.bind(this)} 
							didSelectedUserChange={this.didSelectedUserChange.bind(this)}
							closeEditorButtonOnClick={this.closeEditorButtonOnClick.bind(this)} 
							closeEditor={this.closeEditor.bind(this)}
							removeDeletedUser={this.removeDeletedUser.bind(this)} 
							toggleUiBlock={this.toggleUiBlock.bind(this)} 
							selectedUser={this.state.selectedUser}
							updateSelectedUser={this.updateSelectedUser.bind(this)}
							updateSavedUserOnList={this.updateSavedUserOnList.bind(this)}
							getUserById={this.getUserById.bind(this)}							
							canChangeData={this.props.userPermissions?.CanChangeUser}
							metadata={this.state.metadata}
							/> )}
					{!this.state.editorOpen && ( <UserHome></UserHome>)}					
				</div>				
				
				{this.state.successMessageFolder && ( <ShowSuccess clearMessage={() => this.setState({successMessageFolder: ''})} message={this.state.successMessageFolder} /> )}
				{this.state.errorMessage && ( <ShowError clearErrorMessage={() => this.setState({errorMessage: ''})} errorMessage={this.state.errorMessage} /> )}
				{this.state.loseChangesModalText && ( <Modal size="small" content={this.state.loseChangesModalText} confirmModalHandler={this.confirmLoseChangesModalHandler.bind(this)} cancelModalHandler={this.hideLoseChangesModal.bind(this)} />)}
			</main>
			</BlockUi>
        );
    }
}

export default User;